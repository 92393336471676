import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

const _ONBOARDING_SCHEDULE_PARAMS = `
  $since: DateTime
  $to: DateTime
  $showFilled: Boolean
  $showUnfilled: Boolean
  $owners: [String]
  $markets: [Int]
  $shiftSince: DateTime
  $shiftTo: DateTime
  $hasShift: Boolean
  $isComplete: Boolean
  $includeUrgent: Boolean
  ${_PAGING_PARAMS}
`;

const _ONBOARDING_SCHEDULE_VARIABLES = `
  since: $since
  to: $to
  showFilled: $showFilled
  showUnfilled: $showUnfilled
  owners: $owners
  markets: $markets
  shiftSince: $shiftSince
  shiftTo: $shiftTo
  hasShift: $hasShift
  isComplete: $isComplete
  includeUrgent: $includeUrgent
  ${_PAGING_VARIABLES}
`;

const _WORKER = `
  uuid
  firstName
  lastName
  profileImg
  hoursThisWeek
  isBackcheckComplete
  isI9Verified
  isW2PayproviderComplete
  isFlagged
  tier
  fitFor {
    id
    name
  }
  notificationPreferences {
    isPushNotificationEnabled
  }
  onboardingDate
  onboardedBy {
    firstName
    lastName
    uuid
  }
  markets {
    id
    name
    abbreviation
  }
  notes {
    uuid
    type
    content
  }
`;

const _SHIFT = `
  id
  uuid
  isCancelled
  status
  duration
  paymentType
  flexibleStartTime
  flexibleEndTime
  visibleWorkerCount
  acceptedCount
  acceptLimit
  isRetailerApproved
  overbookLimit
  retailerLocation{
    uuid
    name
    type
    timezone
    compliance {
      breakRules {
        shiftMinutesToRequireBreak
      }
    }
    retailer {
      uuid
      name
      logoImage
    }
    market {
      name
      abbreviation
    }
  }
  start
  end
  requestType
  tags {
    id
    name
    description
    isArchived
    isListVisible
  }
  notes {
    uuid
    content
    updatedAt
    type
    targetUuid
    createdBy {
      firstName
      lastName
    }
  }
`;

const _WORKER_SHIFT = `
  uuid
  hasCompletedCompliancePaperwork
  status
  workershiftEvents {
    eventType
    timestamp
    approved
    expectedTimeRangeStart
    expectedTimeRangeEnd
    noteCount
    lat
    lon
    notes {
      uuid
      content
      createdBy {
        firstName
        lastName
      }
      createdAt
      extraData
    }
  }
`;

export const ONBOARDING_SCHEDULE_QUERY = `
  query ONBOARDING_SCHEDULE_QUERY (${_ONBOARDING_SCHEDULE_PARAMS}) {
    onboardingSchedule (${_ONBOARDING_SCHEDULE_VARIABLES}) {
      uuid
      when
      worker {
        ${_WORKER}
        nextShift {
          ${_WORKER_SHIFT}
          shift {
            ${_SHIFT}
          }
        }
      }
    }
  }
`;
