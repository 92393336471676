import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { useFiltersStore } from '../FiltersStore';
import {
  defaultActionItemsFiltersState,
  defaultCampaignsFiltersState,
  defaultGlobalFiltersState,
  defaultLocationsFiltersState,
  defaultOnboardingsFiltersState,
  defaultShiftsFiltersState,
  defaultUsersFiltersState,
  getParsedActionItemsFilters,
  getParsedCampaignsFilters,
  getParsedGlobalFilters,
  getParsedLocationsFilters,
  getParsedOnboardingFilters,
  getParsedShiftActionItemsFilters,
  getParsedShiftsFilters,
  getParsedWorkersFilters,
} from '../slices';

interface useGraphFiltersProps {
  includeGlobal?: boolean;
}

const defaultUseGraphFiltersProps: useGraphFiltersProps = {
  includeGlobal: true,
};

export const useGlobalGraphFilters = () => {
  const filterKeys = Object.keys(defaultGlobalFiltersState);

  const filterValues = useStoreWithEqualityFn(
    useFiltersStore,
    state =>
      filterKeys.reduce((acc, key) => {
        acc[key] = state[key];
        return acc;
      }, {}),
    shallow,
  );

  const parsedFilters = useMemo(() => {
    return getParsedGlobalFilters(filterValues);
  }, [filterValues]);

  return { parsedFilters, filterValues };
};

export const useShiftsGraphFilters = ({
  includeGlobal,
}: useGraphFiltersProps = defaultUseGraphFiltersProps) => {
  const filterKeys = Object.keys(defaultShiftsFiltersState);

  const filterValues = useStoreWithEqualityFn(
    useFiltersStore,
    state =>
      filterKeys.reduce((acc, key) => {
        acc[key] = state[key];
        return acc;
      }, {}),
    shallow,
  );

  const parsedFilters = useMemo(() => {
    return getParsedShiftsFilters(filterValues);
  }, [filterValues]);

  const { parsedFilters: parsedGlobalFilters } = useGlobalGraphFilters();

  return {
    parsedFilters: {
      ...parsedFilters,
      ...(includeGlobal ? parsedGlobalFilters : {}),
    },
    filterValues,
    parsedGlobalFilters,
  };
};

export const useLocationsGraphFilters = ({
  includeGlobal,
}: useGraphFiltersProps = defaultUseGraphFiltersProps) => {
  const filterKeys = Object.keys(defaultLocationsFiltersState);

  const filterValues = useStoreWithEqualityFn(
    useFiltersStore,
    state =>
      filterKeys.reduce((acc, key) => {
        acc[key] = state[key];
        return acc;
      }, {}),
    shallow,
  );

  const parsedFilters = useMemo(() => {
    return getParsedLocationsFilters(filterValues);
  }, [filterValues]);

  const { parsedFilters: parsedGlobalFilters } = useGlobalGraphFilters();

  return {
    parsedFilters: {
      ...parsedFilters,
      ...(includeGlobal ? parsedGlobalFilters : {}),
    },
    filterValues,
    parsedGlobalFilters,
  };
};

export const useOnboardingsGraphFilters = ({
  includeGlobal,
}: useGraphFiltersProps = defaultUseGraphFiltersProps) => {
  const filterKeys = Object.keys(defaultOnboardingsFiltersState);

  const filterValues = useStoreWithEqualityFn(
    useFiltersStore,
    state =>
      filterKeys.reduce((acc, key) => {
        acc[key] = state[key];
        return acc;
      }, {}),
    shallow,
  );

  const parsedFilters = useMemo(() => {
    return getParsedOnboardingFilters(filterValues);
  }, [filterValues]);

  const { parsedFilters: parsedGlobalFilters } = useGlobalGraphFilters();

  return {
    parsedFilters: {
      ...parsedFilters,
      ...(includeGlobal ? parsedGlobalFilters : {}),
    },
    filterValues,
    parsedGlobalFilters,
  };
};

interface useWorkersGraphFilterProps extends useGraphFiltersProps {
  isActivateWorkers?: boolean;
}

const defaultWorkersGraphFilterProps: useWorkersGraphFilterProps = {
  ...defaultUseGraphFiltersProps,
  isActivateWorkers: false,
};

export const useWorkersGraphFilters = ({
  includeGlobal,
  isActivateWorkers,
}: useWorkersGraphFilterProps = defaultWorkersGraphFilterProps) => {
  const filterKeys = Object.keys(defaultUsersFiltersState);

  const filterValues = useStoreWithEqualityFn(
    useFiltersStore,
    state =>
      filterKeys.reduce((acc, key) => {
        acc[key] = state[key];
        return acc;
      }, {}),
    shallow,
  );

  const parsedFilters = useMemo(() => {
    return getParsedWorkersFilters(filterValues, isActivateWorkers);
  }, [filterValues, isActivateWorkers]);

  const { parsedFilters: parsedGlobalFilters } = useGlobalGraphFilters();

  return {
    parsedFilters: {
      ...parsedFilters,
      ...(includeGlobal ? parsedGlobalFilters : {}),
    },
    filterValues,
    parsedGlobalFilters,
  };
};

interface useActionItemsGraphFiltersProps extends useGraphFiltersProps {
  context?: 'shifts' | 'tasks';
}

export const useActionItemsGraphFilters = ({
  context = 'tasks',
  includeGlobal = true,
}: useActionItemsGraphFiltersProps = {}) => {
  const filterKeys = Object.keys(defaultActionItemsFiltersState);

  const filterValues = useStoreWithEqualityFn(
    useFiltersStore,
    state =>
      filterKeys.reduce((acc, key) => {
        acc[key] = state[key];
        return acc;
      }, {}),
    shallow,
  );

  const parsedFilters = useMemo(() => {
    if (context === 'shifts') {
      return getParsedShiftActionItemsFilters(filterValues);
    }
    return getParsedActionItemsFilters(filterValues);
  }, [filterValues, context]);

  const { parsedFilters: parsedGlobalFilters } = useGlobalGraphFilters();

  return {
    parsedFilters: {
      ...parsedFilters,
      ...(includeGlobal ? parsedGlobalFilters : {}),
    },
    filterValues,
    parsedGlobalFilters,
  };
};

export const useCampaignsGraphFilters = ({
  includeGlobal = true,
}: useGraphFiltersProps = defaultUseGraphFiltersProps) => {
  const filterKeys = Object.keys(defaultCampaignsFiltersState);

  const filterValues = useStoreWithEqualityFn(
    useFiltersStore,
    state =>
      filterKeys.reduce((acc, key) => {
        acc[key] = state[key];
        return acc;
      }, {}),
    shallow,
  );

  const parsedFilters = useMemo(() => {
    return getParsedCampaignsFilters(filterValues);
  }, [filterValues]);

  const { parsedFilters: parsedGlobalFilters } = useGlobalGraphFilters();

  return {
    parsedFilters: {
      ...parsedFilters,
      ...(includeGlobal ? parsedGlobalFilters : {}),
    },
    filterValues,
    parsedGlobalFilters,
  };
};
