import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import { GQL, paging, PlaybookStepStatus } from 'types';

export interface fetchPlaybooksProps extends paging {
  uuids?: string[];
  uuid?: string;
  onlyLatest?: boolean;
}

export const fetchPlaybooks = async (
  data: fetchPlaybooksProps,
  query = Queries.PLAYBOOKS_QUERY,
  contextOverride?: string,
  returnKey: string | string[] = ['playbooks', 'playbooks_paging'],
) => {
  return await fetchObject({
    route: applyContext(GQL, contextOverride || 'PLAYBOOKS_QUERY'),
    query,
    data,
    returnKey,
  });
};

export interface mutateUpdatePlaybookStepsProps {
  playbookSteps: string[]; // uuids
  status?: PlaybookStepStatus;
  processTime?: string; // datetime
}

export const mutateUpdatePlaybookSteps = async (
  data: mutateUpdatePlaybookStepsProps,
) => {
  return await mutateObject({
    route: applyContext(GQL, 'UPDATE_PLAYBOOK_STEP_MUTATION'),
    query: Mutations.UPDATE_PLAYBOOK_STEP_MUTATION,
    data,
    returnKey: 'updatePlaybookStep',
  });
};

export interface mutateCancelPlaybookProps {
  shift: string;
}

export const mutateCancelPlaybook = async (
  cancelPlaybookData: mutateCancelPlaybookProps,
) => {
  return await mutateObject({
    route: applyContext(GQL, 'CANCEL_PLAYBOOK'),
    query: Mutations.CANCEL_CURRENT_PLAYBOOK_MUTATION,
    data: cancelPlaybookData,
    returnKey: 'cancelPlaybook',
  });
};

export interface mutateSetPlaybookProps {
  locations: string[];
  playbook: string;
}

export const mutateSetPlaybook = async (data: mutateSetPlaybookProps) => {
  return await mutateObject({
    route: applyContext(GQL, 'SET_ACTIVE_PLAYBOOK_MUTATION'),
    query: Mutations.SET_ACTIVE_PLAYBOOK_MUTATION,
    data,
    returnKey: 'setActivePlaybook',
  });
};

export interface mutateFastForwardPlaybookProps {
  shift?: string; // shift uuid
  step?: string; // step uuid
}

export const mutateFastForwardPlaybook = async (
  data: mutateFastForwardPlaybookProps,
) => {
  return await mutateObject({
    route: applyContext(GQL, 'FAST_FORWARD_PLAYBOOK_MUTATION'),
    query: Mutations.FAST_FORWARD_PLAYBOOK_MUTATION,
    data,
    returnKey: 'fastForwardPlaybook',
  });
};

export interface mutateChangeShiftPlaybookProps {
  shift: string;
  playbook: string;
}

export const mutateChangeShiftPlaybook = async (
  data: mutateChangeShiftPlaybookProps,
) => {
  return await mutateObject({
    route: applyContext(GQL, 'CHANGE_SHIFT_PLAYBOOK_MUTATION'),
    query: Mutations.CHANGE_SHIFT_PLAYBOOK_MUTATION,
    data,
    returnKey: 'changeShiftPlaybook',
  });
};
