import { useEffect } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

// This allows use to more easily create a store
// that depends on state hydration at some level
// in our case, the store doesn't get set until
// it creates localStorage::reflex-settings
// from: https://github.com/pmndrs/zustand/issues/1145
/* eslint-disable @typescript-eslint/no-explicit-any */
export function createPersistedStore<T>(createStore, persistOptions) {
  const usePersistedStore = create(persist(createStore, persistOptions));

  const useMounted = create(() => false);

  const initialState = createStore(
    () => {},
    () => createStore(() => {}),
  );

  const useStore = (
    selector: (state: T) => any,
    compare?: (prev: any, next: any) => boolean,
  ) => {
    const store = usePersistedStore(selector, compare);
    const mounted = useMounted();

    useEffect(() => {
      if (!mounted) {
        useMounted.setState(true);
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return mounted ? store : selector(initialState);
  };

  useStore.getState = usePersistedStore.getState;
  useStore.setState = usePersistedStore.setState;

  return useStore;
}
/* eslint-enable @typescript-eslint/no-explicit-any */
