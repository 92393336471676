import { uuid } from 'types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface CommunicationBatchStore {
  uuid: uuid;
  setUuid: (uuid: uuid) => void;
  totals: Record<string, number>;
  updateTotals: (uuid: uuid, total: number) => void;
}

const communicationBatchStore = (set): CommunicationBatchStore => ({
  uuid: '',
  setUuid: uuid => set(() => ({ uuid }), false, 'setUuid'),
  totals: {},
  updateTotals: (uuid, total) =>
    set(
      prev => ({ totals: { ...prev.totals, [uuid]: total } }),
      false,
      'updateTotals',
    ),
});

export const useCommunicationBatchStore = create(
  devtools(communicationBatchStore),
);
