import { uuid } from './common';
import { FeatureFlags } from './featureFlags';

export enum LocationPermission {
  VIEW_FLEXES = 'VIEW_FLEXES',
  EDIT_FLEXES = 'EDIT_FLEXES',
  VIEW_ROSTER = 'VIEW_ROSTER',
  EDIT_ROSTER = 'EDIT_ROSTER',
  EDIT_REVIEWS = 'EDIT_REVIEWS',
  EDIT_PERMISSIONS = 'EDIT_PERMISSIONS',
  VIEW_REPORTS = 'VIEW_REPORTS',
  SET_BUDGETS = 'SET_BUDGETS',
  APPROVE_BUDGETS = 'APPROVE_BUDGETS',
}

export const PermissionGroups = {
  flexes: [LocationPermission.VIEW_FLEXES, LocationPermission.EDIT_FLEXES],
  budgets: [LocationPermission.SET_BUDGETS, LocationPermission.APPROVE_BUDGETS],
  reports: [LocationPermission.VIEW_REPORTS],
  roster: [
    LocationPermission.VIEW_ROSTER,
    LocationPermission.EDIT_ROSTER,
    LocationPermission.EDIT_REVIEWS,
  ],
  admin: [LocationPermission.EDIT_PERMISSIONS],
};

export const LocationPermissionDescriptions = {
  [LocationPermission.VIEW_FLEXES]: 'See upcoming and past Flexes',
  [LocationPermission.EDIT_FLEXES]: 'Book a Flex at location',
  [LocationPermission.VIEW_ROSTER]: "See locations's roster",
  [LocationPermission.EDIT_ROSTER]: "Make changes to location's roster",
  [LocationPermission.EDIT_REVIEWS]: 'Leave reviews about Reflexers',
  [LocationPermission.EDIT_PERMISSIONS]:
    "Edit people's permissions at this location",
  [LocationPermission.VIEW_REPORTS]: 'View reports and usage for location',
} as const;

// FUTURE: consider removing
// empty for now, because wired to different modals + mutations
// in retailer web
export const WEB_IGNORE_PERMISSIONS = [];

export const WEB_FEATURE_FLAGGED_PERMISSIONS = [
  {
    flag: FeatureFlags.BUDGETS_ENABLED,
    permissions: [
      LocationPermission.SET_BUDGETS,
      LocationPermission.APPROVE_BUDGETS,
    ],
  },
];

export const LocationPermissionLabels = {
  [LocationPermission.VIEW_FLEXES]: 'View Flexes',
  [LocationPermission.EDIT_FLEXES]: 'Request Flexes',
  [LocationPermission.VIEW_ROSTER]: 'View Roster',
  [LocationPermission.EDIT_ROSTER]: 'Edit Roster',
  [LocationPermission.EDIT_REVIEWS]: 'Leave Reviews',
  [LocationPermission.EDIT_PERMISSIONS]: 'Edit Access',
  [LocationPermission.VIEW_REPORTS]: 'View Reports',
  [LocationPermission.SET_BUDGETS]: 'Set Budgets',
  [LocationPermission.APPROVE_BUDGETS]: 'Approve Budgets',
} as const;

export type permissionsMap = Record<uuid, LocationPermission[]>; // location uuid
export type userPermissionMap = Record<LocationPermission, boolean>;

// FUTURE: move ICON_NAMES to types
// so that we can use this enum here
export const LocationPermissionIcons = {
  [LocationPermission.VIEW_FLEXES]: 'calendaralt', // ICON_NAMES.calendaralt
  [LocationPermission.EDIT_FLEXES]: 'sparkleplus', // ICON_NAMES.sparkleplus
  [LocationPermission.VIEW_ROSTER]: 'usersalt', // ICON_NAMES.usersalt
  [LocationPermission.EDIT_ROSTER]: 'useredit', // ICON_NAMES.useredit
  [LocationPermission.EDIT_REVIEWS]: 'star', // ICON_NAMES.star
  [LocationPermission.EDIT_PERMISSIONS]: 'useraccess', // ICON_NAMES.useraccess
  [LocationPermission.VIEW_REPORTS]: 'barchart', // ICON_NAMES.useraccess
  [LocationPermission.SET_BUDGETS]: 'calendaredit',
  [LocationPermission.APPROVE_BUDGETS]: 'calendarcheckalt',
} as const;
