import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

export const PREVIEW_MESSAGE_USERS_QUERY = `
  query PREVIEW_MESSAGE_USERS_QUERY(
    $users: [String]
    $textBody: String
    $pushBody: String
    $shift: String
    $media: [String]
    $zipcodeMaxRange: Float
    $excludedFilters: [String]
  ) {
    previewMessageUsers(
      to: $users
      textBody: $textBody
      pushBody: $pushBody
      shift: $shift
      media: $media
      zipcodeMaxRange: $zipcodeMaxRange
      excludedFilters: $excludedFilters
    ) {
      filterCounts {
        name
        count
        overridable
      }
      textPreview
      pushPreview
      previewSendCount

    }
  }
`;

const _COMMUNICATION_BATCH_PARAMS = `
  $createdSince: DateTime
  $createdTo: DateTime
  $shifts: [String]
  $sentBy: [String]
  $sentBySystem: Boolean
  ${_PAGING_PARAMS}
`;

const _COMMUNICATION_BATCH_VARIABLES = `
  createdSince: $createdSince
  createdTo: $createdTo
  shifts: $shifts
  sentBy: $sentBy
  sentBySystem: $sentBySystem
  ${_PAGING_VARIABLES}
`;

export const SHIFT_COMMUNICATION_BATCH_QUERY = `
  query SHIFT_COMMUNICATION_BATCH_QUERY(${_COMMUNICATION_BATCH_PARAMS}){
    communicationBatch(${_COMMUNICATION_BATCH_VARIABLES}) {
      uuid
      createdAt
      name
      meta
      sentBy {
        firstName
        lastName
      }
      pushPreview {
        content
        meta
      }
      pushCount
      pushCountDistinct
      textPreview {
        content
      }
      textCount
      textCountDistinct
      projectedSendCount
      communications(limit: 5) {
        method
        to {
          firstName
          lastName
          uuid
          profileImg
        }
      }
    }
  }
`;

const _COMMUNICATION_PARAMS = `
  $batch: [String]
  ${_PAGING_PARAMS}
`;

const _COMMUNICATION_VARIABLES = `
  batch: $batch
  ${_PAGING_VARIABLES}
`;

export const COMMUNICATION_QUERY = `
  query COMMUNICATION_QUERY(${_COMMUNICATION_PARAMS}){
    communication(${_COMMUNICATION_VARIABLES}) {
      method
      to {
        firstName
        lastName
        profileImg
        uuid
        totalShiftsCompleted
        lastShiftDate
      }
    }
  }
`;
