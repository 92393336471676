import {
  ActionMap,
  graphRetailerLocationWorkers,
  location,
  LocationRelations,
} from 'types';
import { formState, formStateDefaults } from 'utils/types/form';

export interface LocationsStateInterface {
  activeLocation: location;
  activeLocationWorkers: graphRetailerLocationWorkers;
  formState: formState;
  editingRelations?: LocationRelations;
  search?: string;
}

export const initialState = {
  activeLocation: {},
  activeLocationWorkers: {},
  editingRelations: null,
  formState: formStateDefaults,
};

export enum LocationsActions {
  REMOVE_ALL_LOCATIONS = 'REMOVE_ALL_LOCATIONS',
  UPDATE_ACTIVE_LOCATIONS = 'UPDATE_ACTIVE_LOCATION',
  UPDATE_ACTIVE_LOCATION_WORKERS = 'UPDATE_ACTIVE_LOCATION_WORKERS',
  UPDATE_FORM_STATE = 'UPDATE_LOCATION_FORM_STATE',
  UPDATE_FORM_PROCESSING = 'UPDATE_LOCATION_FORM_IS_PROCESSING',
  UPDATE_FORM_SUCCESS = 'UPDATE_LOCATION_FORM_IS_SUCCESS',
  UPDATE_FORM_ERRORS = 'UPDATE_LOCATION_FORM_ERRORS',
  UPDATE_EDITING_RELATIONS = 'UPDATE_EDITING_RELATIONS',
}

type LocationsPayload = {
  [LocationsActions.REMOVE_ALL_LOCATIONS]: null;
  [LocationsActions.UPDATE_ACTIVE_LOCATIONS]: location;
  [LocationsActions.UPDATE_ACTIVE_LOCATION_WORKERS]: graphRetailerLocationWorkers;
  [LocationsActions.UPDATE_FORM_PROCESSING]: boolean;
  [LocationsActions.UPDATE_FORM_SUCCESS]: boolean;
  [LocationsActions.UPDATE_FORM_ERRORS]: Array<string>;
  [LocationsActions.UPDATE_EDITING_RELATIONS]: LocationRelations;
};

type LocationsActionTypes =
  ActionMap<LocationsPayload>[keyof ActionMap<LocationsPayload>];

/* eslint-disable no-case-declarations */
export const locationsReducer = (draft, action: LocationsActionTypes) => {
  const { type } = action;
  switch (type) {
    case LocationsActions.UPDATE_ACTIVE_LOCATIONS:
      draft.activeLocation = action?.payload;
      break;
    case LocationsActions.UPDATE_ACTIVE_LOCATION_WORKERS:
      draft.activeLocationWorkers = action?.payload;
      break;
    case LocationsActions.REMOVE_ALL_LOCATIONS:
      draft.activeLocation = {};
      break;
    case LocationsActions.UPDATE_FORM_PROCESSING:
      draft.formState.isProcessing = action?.payload;
      break;
    case LocationsActions.UPDATE_FORM_SUCCESS:
      draft.formState.isSuccess = action?.payload;
      break;
    case LocationsActions.UPDATE_FORM_ERRORS:
      draft.formState.errors = action?.payload;
      break;
    case LocationsActions.UPDATE_EDITING_RELATIONS:
      draft.editingRelations = action?.payload;
      break;
    default:
      return draft;
  }
  return draft;
};
