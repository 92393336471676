import { PaymentContractTypeOptions } from './billing';
import { uuid } from './common';
import { customer } from './customer';
import {
  apiRetailerLocation,
  location,
  LocationType,
} from './location';
import { organization } from './organization';
import { graphReview } from './review';
import { terms } from './terms';
import { user } from './user';
import { workerExperience } from './workerExperience';

export enum RetailerSize {
  NATIONAL = 'National',
  REGIONAL = 'Regional',
  BOUTIQUE = 'Boutique',
}

export enum RetailerCategory {
  BIG_BOX = 'BIG_BOX',
  ELEVATED = 'ELEVATED',
  LUXURY = 'LUXURY',
  MID = 'MID',
  NONRETAIL = 'NONRETAIL',
  OTHER = 'OTHER',
}

export const RetailerCategoryLabel = {
  [RetailerCategory.BIG_BOX]: [
    RetailerCategory.BIG_BOX,
    'Big Box, Discount, or Resale ($1-50)',
  ],
  [RetailerCategory.ELEVATED]: [
    RetailerCategory.ELEVATED,
    'Elevated Brands ($80-300)',
  ],
  [RetailerCategory.LUXURY]: [RetailerCategory.LUXURY, 'Luxury Brands ($300+)'],
  [RetailerCategory.MID]: [
    RetailerCategory.MID,
    'Fast Fashion & Mid-level brands ($20-80)',
  ],
  [RetailerCategory.NONRETAIL]: [
    RetailerCategory.NONRETAIL,
    'Non-retail; Professional or Customer Facing',
  ],
  [RetailerCategory.OTHER]: [RetailerCategory.OTHER, 'Non-retail; Other'],
};

export enum RetailerSpecialty {
  ACCESSORY = 'ACCESSORY',
  ATHLETIC = 'ATHLETIC',
  BABY = 'Big BABY',
  FORMAL = 'FORMAL',
  HOME = 'HOME',
  JEWELRY = 'JEWELRY',
  NONE = 'NONE',
  PARTY = 'PARTY',
  SHOES = 'SHOES',
  SPORTS = 'SPORTS',
  STATIONERY = 'STATIONERY',
}

export const RetailerSpecialtyLabel = {
  [RetailerSpecialty.ACCESSORY]: [
    RetailerSpecialty.ACCESSORY,
    'Purses, Bags & Accessories',
  ],
  [RetailerSpecialty.ATHLETIC]: [
    RetailerSpecialty.ATHLETIC,
    'Athletic/Athleisure',
  ],
  [RetailerSpecialty.BABY]: [RetailerSpecialty.BABY, 'Baby & Kids'],
  [RetailerSpecialty.FORMAL]: [RetailerSpecialty.FORMAL, 'Bridal/Formalwear'],
  [RetailerSpecialty.HOME]: [RetailerSpecialty.HOME, 'Home & Bath + Furniture'],
  [RetailerSpecialty.JEWELRY]: [RetailerSpecialty.JEWELRY, 'Jewelry & Watches'],
  [RetailerSpecialty.NONE]: [RetailerSpecialty.NONE, 'Not Specialized'],
  [RetailerSpecialty.PARTY]: [
    RetailerSpecialty.PARTY,
    'Party, Costumes, Cosplay & Halloween',
  ],
  [RetailerSpecialty.SHOES]: [RetailerSpecialty.SHOES, 'Shoes & Boots'],
  [RetailerSpecialty.SPORTS]: [
    RetailerSpecialty.SPORTS,
    'Specialty Sports or Outdoors',
  ],
  [RetailerSpecialty.STATIONERY]: [
    RetailerSpecialty.STATIONERY,
    'Stationery, Paper & Books',
  ],
};

export type apiRetailer = {
  id?: number;
  name?: string;
  description?: string;
  brand_img?: string;
  locations?: Array<apiRetailerLocation>;
  open_shifts?: number;
  is_active?: boolean;
};

export type graphRetailer = {
  id?: string;
  uuid?: uuid;
  isActive?: boolean;
  createdAt?: string; // DateTime
  updatedAt?: string; // DateTime
  name?: string;
  description?: string;
  paymentType?: PaymentContractTypeOptions;
  brandImg?: string; // DEPRECATED. Use "logoImage" instead.
  logoImage?: string;
  brandImage?: string;
  customer?: Array<customer>; // FUTURE "customerS"
  locations?: Array<location>;
  retailEmployees?: Array<user>; // DEPRECATED. Use "customer.employees" instead.
  openShifts?: number;
  workerReviews?: Array<graphReview>;
  workerExperience?: Array<workerExperience>;
  customers?: customer[];
  terms?: terms[];
  accountOwner?: user;
  organization?: organization[];
  linkWebsite?: string;
  linkInstagram?: string;
  linkTiktok?: string;
};

export type retailer = graphRetailer;

export type graphRetailerLight = {
  name: string;
  uuid: string;
};

// DEPRECATED
export type apiRetailerLight = {
  id?: number;
  name?: string;
  description?: string;
  brand_img?: string;
};

// DEPRECATED
export type apiRetailerLocationLight = {
  id?: number;
  name?: string;
  type?: LocationType;
  uuid?: string;
  market?: {
    name?: string;
  };
  retailer_name?: string;
  retailer_id?: number;
  timezone?: string;
  retailer_brand_img?: string;
  compliance?: apiRetailerCompliance;
};

// SOON: combine this with requiredBreak
export interface apiBreakRules {
  min_duration?: number;
  break_length?: number;
  paid?: boolean;
  requires_timestamps?: boolean;
  blocks_payment?: boolean;
  shift_minutes_to_require_break?: number;
}

export interface apiRetailerCompliance {
  shift_start_delta?: number;
  shift_gap_delta?: number;
  max_shifts_per_day?: number;
  max_hours_per_day?: number;
  shift_max_length?: number;
  shift_min_length?: number;
  break_rules?: apiBreakRules;
}

// DEPRECATED
export interface normalizedRetailer {
  id: number;
  name: string;
  description: string;
  image: string;
  locations: Array<apiRetailerLocation>;
  openShifts: number;
  isActive: boolean;
}

// DEPRECATED
export interface normalizedRetailerLight {
  id: number;
  name: string;
  description: string;
  image: string;
}
