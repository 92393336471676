export const REMOVE_WORKERSHIFT_USERS_MUTATION = `
  mutation DELETE_WORKER_SHIFT_MUTATION (
    $wso: [String],
    $users: [String],
    $shift: String,
    $force: Boolean,
  ) {
    deleteWorkerShift (
      wso: $wso
      users: $users
      shift: $shift
      force: $force
    ) {
      ok
      messages {
        code
      }
    }
  }
`;

export const CREATE_WORKER_SHIFT_MUTATION = `
  mutation CREATE_WORKER_SHIFT_MUTATION(
    $users: [String]
    $shifts: [String]
    $useAsync: Boolean
  ) {
    createWorkerShift(users: $users, shifts: $shifts, useAsync: $useAsync) {
      ok
      messages {
        code
        description
      }
      uuid
    }
  }
`;

export const UPDATE_WORKER_SHIFT_MUTATION = `
  mutation UPDATE_WORKER_SHIFT_MUTATION(
    $wso: [String],
    $users: [String],
    $shift: String,
    $status: String,
    $confirmationStatus: String,
    $cancelClassification: String,
    $cancelNote: String,
    $acceptedDisclaimers: [String],
    $workerCancellationPayoutRate: Float,
  ) {
    updateWorkerShift(
      wso: $wso,
      users: $users,
      shift: $shift,
      status: $status,
      confirmationStatus: $confirmationStatus,
      cancelClassification: $cancelClassification,
      cancelNote: $cancelNote
      acceptedDisclaimers: $acceptedDisclaimers,
      workerCancellationPayoutRate: $workerCancellationPayoutRate,
  ) {
      ok
      messages {
        code
      }
    }
  }
`;

const _CREATED_SHIFT_FRAGMENT = `
  id
  uuid
  createdAt
  start
  end
  duration
  retailerLocation {
    name
    timezone
    terms {
      billingRate
      cancellationFee {
        amounts {
          timing
          amount
          unit
        }
      }
    }
  }
  workerRoles {
    name
    icon
  }
  requestType
  requestDetails
`;

export const CREATE_SHIFT_MUTATION = `
   mutation CREATE_SHIFT_MUTATION(
    $location: String!
    $shifts: [ShiftInput]!
    ) {
    createShift(
      location: $location, 
      shifts: $shifts
    ) {
      ok
      messages {
        code
        description
      }
      approvedShifts {
        ${_CREATED_SHIFT_FRAGMENT}
      }
      unapprovedShifts {
        ${_CREATED_SHIFT_FRAGMENT}
      }
      uuids
    }
  }
`;

export const UPDATE_SHIFT_MUTATION = `
  mutation UPDATE_SHIFT_MUTATION(
    $uuid: String!,
    $flexibleStartTime: Boolean,
    $start: DateTime,
    $flexibleEndTime: Boolean,
    $end: DateTime,
    $description: String,
    $overbookLimit: Int,
  ) {
    updateShift(
      uuid: $uuid,
      flexibleStartTime: $flexibleStartTime,
      start: $start,
      flexibleEndTime: $flexibleEndTime,
      end: $end,
      description: $description,
      overbookLimit: $overbookLimit,
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const STAFF_CANCEL_SHIFT_MUTATION = `
  mutation CANCEL_SHIFT_MUTATION(
    $shift: String!
    $cancelNote: String!
    $cancelClassification: String!
    $workerCancellationPayoutRate: Float
  ) {
    cancelShift(
      shift: $shift
      cancelNote: $cancelNote
      cancelClassification: $cancelClassification
      workerCancellationPayoutRate: $workerCancellationPayoutRate
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const RETAILER_CANCEL_SHIFT_MUTATION = `
  mutation RETAILER_CANCEL_SHIFT_MUTATION(
    $id: Int!,
    $retailerInput: String,
  ) {
    retailerCancelShift(
      id: $id,
      retailerInput: $retailerInput,
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const UPSERT_WORKERSHIFT_EVENT = `
  mutation UPSERT_WORKERSHIFT_EVENT(
    $workershift: String
    $timestamp: DateTime
    $note: String
    $eventType: String
    $userConfirmed: Boolean
    $distanceApproved: Boolean
  ) {
    upsertWorkershiftEvent(
      workershift: $workershift
      timestamp: $timestamp
      note: $note
      eventType: $eventType
      userConfirmed: $userConfirmed
      distanceApproved: $distanceApproved
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const MOVE_WSO_TO_NEW_SHIFT = `
  mutation WSO_TO_NEW_SHIFT (
    $workershift: String
    $location: String
    $start: DateTime
    $end: DateTime
    $roles: [String]
    $reason: String
    $bonusAmount: Decimal
  ) {
    wsoToNewShift (
      workershift: $workershift
      location: $location
      start: $start
      end: $end
      roles: $roles
      reason: $reason
      bonusAmount: $bonusAmount
    ) {
      ok
      newShiftUuid
      messages {
        code
        description
      }
    }
  }
`;

export const MOVE_WSO_TO_EXISTING_SHIFT = `
  mutation WSO_TO_EXISTING_SHIFT (
    $workershift: String
    $shift: String
  ) {
    wsoToExistingShift (
      workershift: $workershift
      shift: $shift
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const CLAIM_FLEX_MUTATION = `
  mutation CLAIM_FLEX_MUTATION($shift: String, $status: String) {
    shiftAction(shift: $shift, status: $status) {
      ok
      messages {
        description
        code
      }
    }
  }
`;
