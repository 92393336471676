export const _PAGING_PARAMS = `
  $pageSize: Int
  $page: Int
  $search: String
  $orderBy: String
`;

export const _PAGING_VARIABLES = `
  pageSize: $pageSize
  page: $page
  search: $search
  orderBy: $orderBy
`;
