import { market } from 'types';

interface getMarketNameConfig {
  displayState?: boolean;
}

export const getMarketName = (
  market: market,
  config: getMarketNameConfig = {},
) => {
  const { displayState = true } = config;
  return `${market?.name}${displayState && market?.state ? `, ${market?.state}` : ''}`;
};

interface getMarketNamesConfig extends getMarketNameConfig {
  maxDisplayed?: number;
}

export const getMarketNames = (
  markets: market[],
  config: getMarketNamesConfig = {},
) => {
  const { maxDisplayed = 2, ...getMarketNameConfig } = config;
  if (!markets || markets.length === 0) return '';

  const displayedMarkets = markets.slice(0, maxDisplayed);
  const remainingCount = Math.max(0, markets.length - maxDisplayed);

  const displayedNames = displayedMarkets
    .map(m => getMarketName(m, getMarketNameConfig))
    .join(', ');

  return remainingCount > 0
    ? `${displayedNames} + ${remainingCount} more`
    : displayedNames;
};
