import { Prettify } from './_helpers';

export enum FeatureFlags {
  BUDGETS_ENABLED = 'budgetsEnabled',
  NEW_REVIEW_FORMAT = 'newReviewFormat',
  REQUEST_TYPE_LIST = 'requestTypeList',
  REQUEST_TYPE_SPECIFIC = 'requestTypeSpecific',
  REQUEST_TYPE_FAVORITES = 'requestTypeFavorites',
  REQUEST_TYPE_REPEATS = 'requestTypeRepeats',
  KILLSWITCH_QUERY_PARAM_MODALS = 'ksQueryParamModals',
}

export type RequestTypeFeatureFlags = {
  [FeatureFlags.REQUEST_TYPE_LIST]: boolean;
  [FeatureFlags.REQUEST_TYPE_SPECIFIC]: boolean;
  [FeatureFlags.REQUEST_TYPE_FAVORITES]: boolean;
  [FeatureFlags.REQUEST_TYPE_REPEATS]: boolean;
};

export type FeatureFlagReturn = Prettify<
  RequestTypeFeatureFlags & {
    [FeatureFlags.BUDGETS_ENABLED]: boolean;
    [FeatureFlags.NEW_REVIEW_FORMAT]: 'review-a' | 'review-b' | string;
    [FeatureFlags.KILLSWITCH_QUERY_PARAM_MODALS]: boolean;
  }
>;

export const FEATURE_FLAGS_DEV_OVERRIDE: FeatureFlagReturn = {
  [FeatureFlags.REQUEST_TYPE_LIST]: true,
  [FeatureFlags.REQUEST_TYPE_SPECIFIC]: true,
  [FeatureFlags.REQUEST_TYPE_FAVORITES]: true,
  [FeatureFlags.REQUEST_TYPE_REPEATS]: true,
  [FeatureFlags.BUDGETS_ENABLED]: true,
  [FeatureFlags.NEW_REVIEW_FORMAT]: 'review-a',
  [FeatureFlags.KILLSWITCH_QUERY_PARAM_MODALS]: true,
};
