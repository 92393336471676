import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { getFullLocationName } from 'helpers';
import Link from 'next/link';
import { APP_BUDGETS, location } from 'types';

interface EmptyBudgetWidgetProps {
  locations?: location[];
  selectedLocation?: string;
  canEditLocations?: location[];
}

export const EmptyBudgetWidget: FC<EmptyBudgetWidgetProps> = ({
  locations,
  selectedLocation,
  canEditLocations,
}) => {
  const activeLocation =
    locations?.find(location => location?.uuid === selectedLocation) || {};
  const canEdit = canEditLocations
    ?.map(l => l?.uuid)
    .includes(selectedLocation);
  const hasActiveSelection = Object.keys(activeLocation)?.length;

  return (
    <Flex>
      <Text lineHeight="shorter" color="blackAlpha.600" maxWidth="28ch" px={3}>
        {!hasActiveSelection ? (
          <>
            {'No budgets set for the current period.'}
            {canEditLocations?.length > 0 && (
              <>
                {' Set one '}
                <Text
                  display="inline"
                  textDecoration="underline"
                  as="span"
                  textUnderlineOffset="2px"
                  color="main.blue"
                >
                  <Link href={APP_BUDGETS}>{'here'}</Link>
                </Text>
                {'.'}
              </>
            )}
          </>
        ) : (
          <>
            {`${getFullLocationName(activeLocation)} does not have have a budget${canEdit ? '' : ' set'} for the current period.`}
            {canEdit && (
              <>
                {' Set one '}
                <Text
                  display="inline"
                  textDecoration="underline"
                  as="span"
                  textUnderlineOffset="2px"
                  color="main.blue"
                >
                  <Link href={APP_BUDGETS}>{'here'}</Link>
                </Text>
                {'.'}
              </>
            )}
            s{' '}
          </>
        )}
      </Text>
    </Flex>
  );
};
