import { differenceInHours } from 'date-fns';
import {
  shift,
  ShiftStatus,
  user,
  workershift,
  WorkerShiftStatus,
  WorkerTierOptions,
} from 'types';
import { MAX_HOURS_WARNING } from 'utils/types/configs';

export const getTierColor = (workerTier: WorkerTierOptions) => {
  if ([WorkerTierOptions.R1, WorkerTierOptions.R2].includes(workerTier))
    return 'green';
  if ([WorkerTierOptions.G1, WorkerTierOptions.N1].includes(workerTier))
    return 'yellow';
  if (
    [WorkerTierOptions.G2, WorkerTierOptions.N2, WorkerTierOptions.R3].includes(
      workerTier,
    )
  )
    return 'red';
  return 'gray';
};

export const getWorkersByTier = ({
  users,
  tier,
}: {
  users: user[];
  tier: WorkerTierOptions | string;
}) => {
  const tierSegments = {};
  Object.keys(WorkerTierOptions).map(key => {
    tierSegments[key] = users
      .map(worker => {
        if (worker.tier === WorkerTierOptions[key]) return worker;
      })
      .filter(worker => !!worker);
  });
  return tierSegments[tier];
};

export const getWorkersByWorked = ({
  users,
  timing,
  condition,
}: {
  users: user[];
  timing: 'total' | 'week';
  condition: boolean;
}) => {
  switch (timing) {
    case 'total': {
      if (condition) return users.filter(u => u?.totalShiftsCompleted > 0);
      return users.filter(u => u?.totalShiftsCompleted === 0);
    }
    case 'week': {
      if (condition) return users.filter(u => u?.hoursThisWeek > 0);
      return users.filter(u => u?.hoursThisWeek === 0);
    }
    default:
      return users;
  }
};

enum ShiftTiming {
  UPCOMING = 'upcoming',
  PAST = 'past',
}

const getShiftTiming = (wso: workershift) => {
  const now = new Date();
  if (differenceInHours(new Date(wso.shift.end), now) >= 0) {
    return ShiftTiming.UPCOMING;
  }
  return ShiftTiming.PAST;
};

const hasShiftAccess = (wso: workershift) => {
  return (
    wso.shift.acceptedCount < wso.shift.acceptLimit + wso.shift.overbookLimit
  );
};

interface createShiftMapReturn {
  cancelled: Array<workershift>;
  upcomingVisible: Array<workershift>;
  upcomingInvisible: Array<workershift>;
  upcomingOwn: Array<workershift>;
  pastOwn: Array<workershift>;
}

export const createShiftMap = (
  workerShifts: Array<workershift>,
): createShiftMapReturn => {
  if (!workerShifts || !workerShifts.length)
    return {
      cancelled: [],
      upcomingVisible: [],
      upcomingInvisible: [],
      upcomingOwn: [],
      pastOwn: [],
    };
  const workerShiftsList = workerShifts.filter(
    wso => wso.shift.status !== ShiftStatus.CANCELLED,
  );
  const cancelled = workerShifts.filter(
    wso => wso.status === WorkerShiftStatus.CANCELLED,
  );
  const ownedShifts = workerShiftsList.filter(wso =>
    [
      WorkerShiftStatus.ACCEPTED,
      WorkerShiftStatus.COMPLETE,
      WorkerShiftStatus.OVERBOOKED,
    ].includes(wso.status),
  );
  const access = workerShiftsList.filter(
    wso => wso.status === WorkerShiftStatus.NEW,
  );
  const accessInvisible = access.filter(wso => !hasShiftAccess(wso));
  const accessVisible = access.filter(wso => hasShiftAccess(wso));
  const upcomingOwn = ownedShifts.filter(
    wso => getShiftTiming(wso) === ShiftTiming.UPCOMING,
  );
  const pastOwn = ownedShifts.filter(
    wso => getShiftTiming(wso) === ShiftTiming.PAST,
  );
  const upcomingVisible = accessVisible.filter(
    wso => getShiftTiming(wso) === ShiftTiming.UPCOMING,
  );
  const upcomingInvisible = accessInvisible.filter(
    wso => getShiftTiming(wso) === ShiftTiming.UPCOMING,
  );

  return {
    cancelled,
    upcomingVisible,
    upcomingInvisible,
    upcomingOwn,
    pastOwn,
  };
};

// cousin to helpers/shifts:getAssignedWorkerFlags
// assumes will be working w2 shift...
export const getWorkerMetaFlags = ({
  user,
  shift,
  workershift,
}: {
  user: user;
  shift?: shift;
  workershift?: workershift;
}) => {
  // user checks
  const needsBackgroundCheck = !user?.isBackcheckComplete;
  const needsI9 = !user?.isI9Verified;
  const needsW2PayProvider = !user?.isW2PayproviderComplete;
  const needsMobile = !user?.notificationPreferences;
  const isNoPush = !user?.notificationPreferences?.isPushNotificationEnabled;
  const isOverHours = user?.hoursThisWeek > MAX_HOURS_WARNING;
  const isFlagged = user?.isFlagged;

  // workershift checks
  const hasWorkershift = workershift && Object.keys(workershift)?.length > 0;
  const needsPaperwork = hasWorkershift
    ? !workershift?.hasCompletedCompliancePaperwork
    : false;

  // shift checks
  const hasShift = shift && Object.keys(shift)?.length > 0;
  const isRepeatAtStore = hasShift ? user?.hasWorkedAtLocation : false;
  const isNewToStore = hasShift ? !user?.hasWorkedAtLocation : false;
  const isNewToPlatform = hasShift ? user?.totalShiftsCompleted === 0 : false;

  return {
    needsBackgroundCheck,
    needsI9,
    needsW2PayProvider,
    needsPaperwork,
    needsMobile,
    isNoPush,
    isOverHours,
    isFlagged,
    hasRequirementProblem: [
      needsBackgroundCheck,
      needsI9,
      needsW2PayProvider,
      needsPaperwork,
    ].some(x => x),
    isRepeatAtStore,
    isNewToPlatform,
    isNewToStore,
    hasAnyFlag: [
      needsBackgroundCheck,
      needsI9,
      needsW2PayProvider,
      needsPaperwork,
      needsMobile,
      isNoPush,
      isOverHours,
      isRepeatAtStore,
      isNewToPlatform,
      isNewToStore,
      isFlagged,
    ].some(x => x),
  };
};
