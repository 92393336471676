export const _SHIFT_CRITERIA_EXTENSION_FIELDS = `
  name
  args
  action
  status
  id
  lastProcessed
`;

export const _SHIFT_CRITERIA_FIELDS = `
  createdAt
  lastProcessed
  name
  processTime
  stepNumber
  timingPercentage
  updatedAt
  shift {
    uuid
  }
  extensions {
   ${_SHIFT_CRITERIA_EXTENSION_FIELDS} 
  }
`;

export const _SHIFT_CRITERIA_COHORT_FIELDS = `
  approvedAt
  avgPrioritization
  avgRating
  favoriteAt
  hasReviews
  hasWorkedAtLocation
  hasWorkedOnPlatform
  market
  notTags
  notTier
  shiftsCompleted
  tags
  tier
`;

const _PLAYBOOK_MESSAGE_TEMPLATE_PARAMS = `
  $isActive: Boolean
`;
const _PLAYBOOK_MESSAGE_TEMPLATE_VARIABLES = `
  isActive: $isActive
`;

export const PLAYBOOK_MESSAGE_TEMPLATE_QUERY = `
  query PLAYBOOK_MESSAGE_TEMPLATE_QUERY(${_PLAYBOOK_MESSAGE_TEMPLATE_PARAMS}) {
    playbookMessageTemplates(${_PLAYBOOK_MESSAGE_TEMPLATE_VARIABLES}) {
      id
      name
      subject
      body
    }
  }
`;
