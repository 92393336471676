import { FC, ReactNode } from 'react';
import { Box, BoxProps, Flex } from '@chakra-ui/react';
import Head from 'next/head';
import {
  headerPatternColorOptions,
  patternOptions,
  useSettingsStore,
} from 'utils/stores';
import { BottomNavigation, TopNavigation } from 'components/nav';
import styles from './PageLayout.module.css';

const headerStyles = {
  width: '100%',
  height: '100%',
  top: '-16px',
  left: '50%',
  transform: 'translateX(-50%)',
  margin: '0 auto',
  backgroundPosition: 'top center',
  backgroundRepeat: 'no-repeat',
};

interface PageProps {
  meta?: {
    title?: string;
  };
  containerProps?: BoxProps;
  wrapperProps?: BoxProps;
  hasTopDecoration?: boolean;
  hasTopNavigation?: boolean;
  hasBottomNavigation?: boolean;
  children?: ReactNode;
}

export const PageLayout: FC<PageProps> = ({
  meta,
  containerProps,
  wrapperProps,
  hasTopDecoration = true,
  hasTopNavigation = true,
  hasBottomNavigation = true,
  children,
}) => {
  const [themeColor, themePattern] = useSettingsStore(s => [
    s.themeColor,
    s.themePattern,
  ]);
  const showDecoration = hasTopDecoration && themeColor;
  const showPattern = !!themePattern;
  const isProduction =
    process.env.NEXT_PUBLIC_VERCEL_ENV !== 'preview' &&
    process.env.NODE_ENV !== 'development';

  return (
    <>
      {meta && <Head>{meta?.title && <title>{meta.title}</title>}</Head>}
      <Flex
        flexDirection="column"
        flexGrow={1}
        width="full"
        {...(hasTopDecoration && {
          position: 'relative',
          backgroundColor: 'main.light',
        })}
        {...containerProps}
      >
        {hasTopNavigation && <TopNavigation containerProps={{ zIndex: 3 }} />}
        <Flex
          flexDirection="column"
          width="full"
          maxWidth="6xl"
          overflow="hidden"
          {...(hasTopDecoration && {
            zIndex: 2,
            mt: { base: 2, md: 2, lg: 4 },
            mx: 'auto',
            shadow: 'lg',
            borderRadius: { base: 'lg', md: '2xl' },
            backgroundColor: 'main.white',
            minHeight: 700,
          })}
          {...wrapperProps}
        >
          {children}
        </Flex>
        {hasBottomNavigation && (
          <BottomNavigation containerProps={{ zIndex: 2 }} />
        )}
        {showDecoration && (
          <>
            <Box
              width="100%"
              height="415px"
              overflowY="hidden"
              position="absolute"
              top={0}
              left={0}
              className="decoration-container"
            >
              <Box
                {...headerStyles}
                position="absolute"
                backgroundImage="/images/wavy.svg"
                zIndex={1}
                aria-hidden="true"
                sx={{
                  '--decoration-pattern-image':
                    isProduction && showPattern
                      ? `url(/images/patterns/${patternOptions[themePattern]})`
                      : 'noop',
                }}
                className={styles.decoration}
                filter={headerPatternColorOptions[themeColor]}
              />
              {!isProduction && (
                <Box
                  {...headerStyles}
                  position="absolute"
                  backgroundImage="/images/wavy-highlight.svg"
                  zIndex={1}
                  aria-hidden="true"
                  className="header-decoration-dev-indicator"
                  opacity={0.35}
                />
              )}
              <Box
                {...headerStyles}
                position="absolute"
                backgroundImage="/images/wavy-exclusion.svg"
                zIndex={1}
                transform="translateX(-50%)"
                top="187px"
                left="50%"
                className="decoration-mask-effect"
              />
            </Box>
          </>
        )}
      </Flex>
    </>
  );
};
