import { _SHIFTS_PARAMS, _SHIFTS_VARIABLES } from './shift.helpers';

const _SHIFT_WORKER = `
  uuid
  profileImg
  workerShift {
    confirmationStatus
    status
    uuid
    workershiftEvents {
      expectedTimeRangeStart
      expectedTimeRangeEnd
      timestamp
      eventType
      approved
    }
  }
`;

export const SHIFTS_FOR_WORKER_QUERY = `
  query SHIFTS_FOR_WORKER_QUERY (${_SHIFTS_PARAMS}) {
    shifts (${_SHIFTS_VARIABLES}) {
      uuid
      id
      start
      end
      duration
      status
      description
      pendingReviews {
        id
        worker {
          uuid
        }
        status
        reviewType
      }
      workerRoles {
        name
        icon
      }
      paymentType
      payments {
        rate
        quantity
        type
      }
      assignedWorkers {
        ${_SHIFT_WORKER}
      }
      overbookedWorkers {
        ${_SHIFT_WORKER}
      }
      retailerLocation {
        uuid
        name
        type
        timezone
        mapHref
        mapImg
        retailerSheetHref
        useV2FlexDetails
        compliance {
          name
          shiftStartDelta
          shiftGapDelta
          maxShiftsPerDay
          maxHoursPerDay
          shiftMaxLength
          shiftMinLength
          preFlexViewDisclaimers {
            uuid
            name
            title
            body
          }
          preFlexClaimDisclaimers {
            uuid
            name
            title
            body
          }
          postFlexClaimDisclaimers {
            uuid
            name
            title
            body
          }
          breakRules {
            shiftMinutesToRequireBreak
            breakLength
            paid
          }
        }
        retailer {
          uuid
          name
          logoImage
          brandImage
          linkInstagram
          linkTiktok
          linkWebsite
        }
        address {
          city
          state
          address1
          address2
          zip
        }
      }
    }
  }
`;
