export const JOBS_QUERY = ` 
query JOBS_QUERY (
  $uuid: String
  $objectType: JobNamespaceType
){
  jobs(
    uuid: $uuid
    objectType: $objectType
  ) {
    taskId 
    jobKey
    status
    jobType
  }
}
`;
