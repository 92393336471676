// FUTURE: move this outside of filters
// it is used without filter bindings
import { cloneElement, ReactElement, useEffect } from 'react';
import {
  Button,
  Flex,
  FlexProps,
  Text,
  TextProps,
  useDisclosure,
} from '@chakra-ui/react';
import { convertDataToSelectOptions } from 'helpers';
import { SelectOption } from 'types';
import { Modal } from 'ui';
import {
  defaultFiltersValues,
  SliceFilterKeys,
  useFiltersStore,
} from 'utils/stores/FiltersStore';

export enum ItemSelectorSize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export interface ItemSelectorProps<ObjectType> {
  isMulti?: boolean;
  filterKey?: keyof SliceFilterKeys;
  valueOverride?: string;
  valueTextProps?: TextProps;
  label?: string;
  placeholder?: string;
  containerProps?: FlexProps;
  valueContainerProps?: FlexProps;
  size?: ItemSelectorSize;
  modalComponent?: ReactElement;
  modalTitle?: string | ReactElement;
  onClick?: () => void;
  onSelect?: (object: ObjectType) => void;
  onClear?: () => void;
  showClear?: boolean;
  itemConverter?: (object: ObjectType) => SelectOption[];
  valueKey?: string;
  shouldCloseModal?: boolean;
  valueContainerExtras?: ReactElement;
}

export const ItemSelector = <ObjectType,>({
  isMulti = false,
  filterKey,
  valueOverride,
  valueTextProps,
  label,
  placeholder = 'Choose...',
  containerProps,
  valueContainerProps,
  size = ItemSelectorSize.MD,
  modalComponent,
  modalTitle,
  onClick = () => null,
  onSelect = () => null,
  onClear = () => null,
  itemConverter,
  showClear = true,
  valueKey = 'uuid',
  shouldCloseModal = false,
  valueContainerExtras,
}: ItemSelectorProps<ObjectType>) => {
  const { [filterKey]: value, updateFilter } = useFiltersStore();

  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();
  const canClear =
    (Array.isArray(value) && value?.length > 0) || !!valueOverride;
  const hasValue =
    (!!value && Array.isArray(value) && value.length > 0) || !!valueOverride;

  const getValueDisplay = () => {
    if (valueOverride) {
      return valueOverride;
    }
    return isMulti
      ? Array.isArray(value) && value?.length > 1
        ? `${value?.length} Selected...`
        : value?.[0]?.label
      : value[0]?.label;
  };
  const displayedValue =
    hasValue || valueOverride ? getValueDisplay() : placeholder;

  const handleClear = e => {
    e.stopPropagation();
    onClear();
    !!filterKey && updateFilter(filterKey, defaultFiltersValues[filterKey]);
  };

  const handleClick = () => {
    !!modalComponent && openModal();
    onClick();
  };

  const handleSelect = (object: ObjectType) => {
    onSelect(object);
    const valueAsSelectOption = itemConverter
      ? itemConverter(object)
      : convertDataToSelectOptions([object] as object[], 'name', valueKey);

    if (!isMulti) {
      !!filterKey && updateFilter(filterKey, valueAsSelectOption);
      closeModal();
    }

    if (isMulti) {
      const currentValues = useFiltersStore.getState()[
        filterKey
      ] as SelectOption[];
      const shouldRemove = currentValues
        ?.map(v => v.value)
        .includes(valueAsSelectOption[0].value);
      if (filterKey) {
        if (shouldRemove) {
          updateFilter(
            filterKey,
            currentValues.filter(v => v.value !== valueAsSelectOption[0].value),
          );
        } else {
          updateFilter(filterKey, [...currentValues, valueAsSelectOption[0]]);
        }
      }
    }
  };

  const handleRemove = (option: SelectOption) => {
    const currentValues = useFiltersStore.getState()[
      filterKey
    ] as SelectOption[];
    updateFilter(
      filterKey,
      currentValues.filter(v => v.value !== option.value),
    );
  };

  useEffect(() => {
    if (!shouldCloseModal) return;
    closeModal();
  }, [shouldCloseModal, closeModal]);

  return (
    <>
      <Flex
        flexDirection="column"
        gap={1}
        onClick={handleClick}
        {...containerProps}
      >
        <Flex width="100%" alignItems="center">
          {label && (
            <Text flexGrow={1} variant="label-sm">
              {label}
            </Text>
          )}
          {showClear && canClear && (
            <Button
              size="xs"
              variant="transparent"
              height="initial"
              onClick={handleClear}
            >
              {'Clear'}
            </Button>
          )}
        </Flex>
        <Flex
          {...getValueContainerStyles(size, hasValue)}
          {...valueContainerProps}
        >
          <Text
            flexGrow={1}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            pr={2}
            {...valueTextProps}
          >
            {displayedValue}
          </Text>
          {valueContainerExtras}
        </Flex>
      </Flex>
      {!!modalComponent && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          closeButtonContainerProps={{ mt: 3, color: 'blackAlpha.400' }}
          containerProps={{ px: 2, pb: 0 }}
        >
          <Flex flexDirection="column" pt={4}>
            {modalTitle && typeof modalTitle === 'string' ? (
              <Text pl={2} variant="header-xl">
                {modalTitle}
              </Text>
            ) : (
              modalTitle
            )}
            {cloneElement(modalComponent, {
              onSelect: handleSelect,
              onRemove: handleRemove,
              currentValues: value,
            })}
          </Flex>
        </Modal>
      )}
    </>
  );
};

export const getValueContainerStyles = (
  size: ItemSelectorSize,
  hasValue: boolean,
): FlexProps => {
  const commonStyles = {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'blackAlpha.200',
    alignItems: 'center',
    cursor: 'pointer',
    color: hasValue ? 'blackAlpha.800' : 'blackAlpha.500',
    _hover: {
      backgroundColor: 'blackAlpha.50',
    },
  };
  switch (size) {
    case ItemSelectorSize.SM:
      return {
        minH: 12,
        pl: 4,
        pr: 1,
        fontSize: 'lg',
        borderRadius: 'lg',
        ...commonStyles,
      };
    case ItemSelectorSize.MD:
      return {
        minH: 10,
        pl: 3,
        pr: 1,
        fontSize: 'md',
        borderRadius: 'md',
        ...commonStyles,
      };
    case ItemSelectorSize.LG:
      return {
        minH: 12,
        pl: 4,
        pr: 1,
        fontSize: 'lg',
        borderRadius: 'lg',
        ...commonStyles,
      };
    default:
      return {};
  }
};

export const filterComponentStyles: FlexProps = {
  width: '100%',
  maxWidth: '200px',
  alignSelf: 'flex-start',
};
