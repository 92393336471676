export interface ReflexLoggerReturn {
  log: (msg: string) => void;
  warn: (msg: string) => void;
  error: (msg: string) => void;
  fatal: (msg: string) => void;
}

export const ReflexLoggerDefaultState: ReflexLoggerReturn = {
  log: () => {},
  warn: () => {},
  error: () => {},
  fatal: () => {},
};

// FUTURE: more input options, default log level, callback, etc.
// This may be useful to kick off side effects in the future upon logging certain events.
export const reflexLogger = (enabled: boolean): ReflexLoggerReturn => {
  if (!enabled) {
    return ReflexLoggerDefaultState;
  }

  return {
    log: msg => {
      console.log(msg);
    },
    warn: msg => {
      console.warn(msg);
    },
    error: msg => {
      console.error(msg);
    },
    fatal: msg => {
      throw new Error(msg);
    },
  };
};
