import { FC } from 'react';
import { Button, Flex, FlexProps, Text } from '@chakra-ui/react';
import {
  fetchUsers,
  fetchUsersProps,
  formatUserNameForDisplay,
  Queries,
} from 'helpers';
import { Icon, ICON_NAMES } from 'icons';
import { DataKeys, SelectOption, user, UserRole, UserStatus } from 'types';
import {
  SelectButtonVariant,
  SelectList,
  SelectListProps,
} from 'components/common/SelectList';

type SelectStaffUserItemProps = user &
  SelectListProps<user, fetchUsersProps> & {
    onSelect?: (user: user) => void;
    selectButtonText?: string;
    containerProps?: FlexProps;
    selectButtonVariant?: SelectButtonVariant;
    currentValues?: SelectOption[];
  };

const SelectStaffUserItem = ({
  onSelect,
  selectButtonText = 'Select',
  containerProps,
  selectedValues,
  selectButtonVariant,
  currentValues,
  ...user
}: SelectStaffUserItemProps) => {
  const isSelected =
    currentValues?.length > 0 &&
    currentValues?.map(v => v?.value).includes(user?.uuid.toString());

  return (
    <Flex
      py={2}
      px={4}
      alignItems="center"
      {...(selectButtonVariant === SelectButtonVariant.CHECK && onSelect
        ? {
            onClick: () => onSelect(user),
            cursor: 'pointer',
          }
        : {})}
      {...containerProps}
    >
      <Text flexGrow={1}>{formatUserNameForDisplay(user)}</Text>
      {selectButtonVariant === SelectButtonVariant.DEFAULT && (
        <Button
          size="sm"
          variant="transparent"
          onClick={() => {
            !!onSelect && onSelect(user);
          }}
        >
          {isSelected ? 'Remove' : selectButtonText}
        </Button>
      )}
      {selectButtonVariant === SelectButtonVariant.CHECK && (
        <Icon
          name={ICON_NAMES.check}
          h={6}
          w={6}
          color="green.500"
          opacity={
            selectedValues?.map(val => val?.uuid)?.includes(user?.uuid) ? 1 : 0
          }
        />
      )}
    </Flex>
  );
};

export const SelectStaffUserList: FC<
  SelectListProps<user, fetchUsersProps>
> = ({ onSelect, selectButtonText = 'Select', ...props }) => {
  return (
    <SelectList<user, fetchUsersProps>
      query={Queries.STAFF_USERS_LIST_QUERY}
      dataKey={DataKeys.USERS}
      fetchFunction={fetchUsers}
      returnKey={['users', 'users_paging']}
      pagingKey="users_paging"
      queryVariables={{
        role: [UserRole.STAFF],
        status: [UserStatus.ACTIVE],
        orderBy: 'first_name',
      }}
      itemComponent={
        <SelectStaffUserItem
          onSelect={onSelect}
          selectButtonText={selectButtonText}
        />
      }
      onSelect={onSelect}
      {...props}
    />
  );
};
