import { useCallback, useEffect, useMemo, useRef } from 'react';
import { ColumnInterface, useTable } from 'react-table';
import { BoxProps, Flex, Text } from '@chakra-ui/react';
import { useDataQuery, useDataQueryProps } from 'hooks';
import { PageSizes, PagingInfo } from 'types';
import {
  PaginationControls,
  PaginationControlsProps,
  PagingList,
  PagingListProps,
  RealSimpleLoading,
} from 'ui';

export type OnPagedListDataUpdate = ({
  data,
  paging,
  status,
  meta,
}: {
  data?: unknown;
  paging?: PagingInfo;
  status?: string;
  meta?: any;
}) => void;

interface PagedListProps<_, QueryVariables>
  extends useDataQueryProps<QueryVariables> {
  containerProps?: BoxProps;
  headingComponent?: React.ReactNode;
  headingSettings?: {
    hideOnLoad?: boolean;
    hideWhenEmpty?: boolean;
  };
  listColumns?: () => ColumnInterface[];
  pagingListProps?: PagingListProps;
  paginationProps?: Partial<PaginationControlsProps>;
  onDataUpdate?: OnPagedListDataUpdate;
}

export const PagedList = <ObjectType, QueryVariables>({
  containerProps,
  headingComponent,
  headingSettings,
  listColumns,
  canFetch = false,
  fetchFunction,
  pagingListProps,
  paginationProps,
  query,
  queryKey,
  queryVariables,
  dataKey,
  returnKey,
  pagingKey,
  contextOverride,
  onDataUpdate,
  queryOptions,
  metaKey,
}: PagedListProps<ObjectType, QueryVariables>) => {
  const { status, paging, meta, ...results } = useDataQuery<
    ObjectType,
    QueryVariables
  >({
    queryVariables,
    canFetch,
    fetchFunction,
    query,
    queryKey,
    dataKey,
    returnKey,
    pagingKey,
    pagingParams: {
      page: paginationProps?.currentPage || 0,
      pageSize: paginationProps?.pageSize || PageSizes.twentyFive,
    },
    contextOverride,
    queryOptions,
    metaKey,
  });

  const dataResults = results?.[dataKey] || undefined;
  const prevValuesRef = useRef({ dataResults, paging, status, meta });

  const data = useMemo(() => (dataResults ? dataResults : []), [dataResults]);
  const columns = useMemo(() => listColumns(), [listColumns]);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const useTableReturn = useTable({ columns, data });

  const canShowHeading = (() => {
    const hasHeadingComponent = !!headingComponent;
    if (!hasHeadingComponent) return false;
    const { hideOnLoad = false, hideWhenEmpty = false } = headingSettings || {};
    if (hideOnLoad && status === 'loading') return false;
    if (hideWhenEmpty && !data.length) return false;
    return true;
  })();

  const handleDataUpdate = useCallback(() => {
    if (!dataResults || !onDataUpdate) return;

    const currentValues = { dataResults, paging, status, meta };
    const prevValues = prevValuesRef.current;

    if (JSON.stringify(currentValues) !== JSON.stringify(prevValues)) {
      onDataUpdate({ data: dataResults, paging, status, meta });
      prevValuesRef.current = currentValues;
    }
  }, [dataResults, paging, status, meta, onDataUpdate]);

  useEffect(() => {
    handleDataUpdate();
  }, [handleDataUpdate]);

  return (
    <Flex flexDirection="column" {...containerProps}>
      {canShowHeading && headingComponent}
      <PagingList
        {...useTableReturn}
        isLoading={status === 'loading'}
        emptyComponent={
          pagingListProps?.emptyComponent ? (
            pagingListProps?.emptyComponent
          ) : (
            <></>
          )
        }
        loadingComponent={
          pagingListProps?.loadingComponent ? (
            pagingListProps?.loadingComponent
          ) : (
            <></>
          )
        }
        containerProps={{
          maxWidth: '100vw',
          width: '100%',
          ...pagingListProps?.containerProps,
        }}
        rowProps={{
          width: '100%',
          position: 'relative',
          ...pagingListProps?.rowProps,
        }}
      />
      <PaginationControls
        total={paging?.total_count}
        allowPageSizeChange={false}
        containerProps={{ px: 6, mb: 4 }}
        pagingContainerProps={{ justify: 'flex-start' }}
        pagingLabelProps={{ ml: 0, flexGrow: 1, fontSize: 'md' }}
        isLoading={status === 'loading'}
        iconProps={{ w: 5, h: 5 }}
        {...paginationProps}
      />
    </Flex>
  );
};

interface CommonPagingListProps {
  emptyText?: string;
  numRows?: number;
}

const commonPagingListPropsDefaults = {
  emptyText: 'No Results Found',
  numRows: 10,
};

export const commonPagingListProps = (
  props: CommonPagingListProps = commonPagingListProps,
) => {
  const { emptyText, numRows } = { ...commonPagingListPropsDefaults, ...props };

  const generateZIndexes = () => {
    const styles = {};
    for (let i = 1; i <= numRows; i++) {
      styles[`.list-item:nth-of-type(${i})`] = {
        zIndex: numRows - i + 1,
      };
    }
    return styles;
  };

  return {
    emptyComponent: (
      <Flex
        px={6}
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        flexGrow={1}
      >
        <Text mb={4} fontWeight="medium" alignSelf="flex-start">
          {emptyText}
        </Text>
      </Flex>
    ),
    loadingComponent: <RealSimpleLoading />,
    rowProps: {
      className: 'list-item',
      borderBottomWidth: 1,
      borderStyle: 'solid',
      borderColor: 'blackAlpha.100',
    },
    containerProps: {
      flexGrow: 1,
      sx: {
        '.list-item:nth-of-type(odd)': {
          backgroundColor: 'main.sky',
        },
        ...generateZIndexes(),
      },
    },
  };
};

export const commonPagingProps = {
  containerProps: { px: 6, mt: 5, mb: 4 },
  pagingLabelProps: { fontSize: 'sm', flexGrow: 1, ml: 0 },
  hideWhenLoading: true,
  emptyText: '',
};
