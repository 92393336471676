import { FC } from 'react';
import {
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { ICON_NAMES } from 'icons';
import { TextAndIcon } from './TextAndIcon';

interface CalloutProps {
  title: string;
  icon: ICON_NAMES;
  description?: string;
  color?: string;
  containerProps?: BoxProps;
  wrapperProps?: BoxProps;
  descriptionProps?: TextProps;
  descriptionContainerProps?: BoxProps;
  additionalDescription?: JSX.Element;
  buttonText?: string;
  buttonProps?: ButtonProps;
  onButtonClick?: () => void;
}

export const Callout: FC<CalloutProps> = ({
  title,
  icon,
  description,
  color,
  containerProps,
  wrapperProps,
  descriptionProps,
  descriptionContainerProps,
  additionalDescription = () => null,
  buttonText,
  buttonProps,
  onButtonClick,
}) => {
  return (
    <Flex
      backgroundColor={color ? `${color}.100` : 'oz.body'}
      mb={4}
      pt={2}
      pb={3}
      pl={2}
      pr={4}
      borderRadius="md"
      {...containerProps}
    >
      <Flex flexDirection="column" {...wrapperProps}>
        <TextAndIcon
          text={title}
          icon={icon}
          textProps={{
            color: color ? `${color}.600` : 'oz.primary',
            fontWeight: 'semi',
          }}
          iconProps={{ color: color ? `${color}.600` : 'oz.primary', mr: 1 }}
        />
        {description && (
          <Flex flexDirection="column" {...descriptionContainerProps}>
            <>
              <Text
                lineHeight="shorter"
                fontSize="sm"
                color={`${color}.600`}
                {...descriptionProps}
              >
                {description}
              </Text>
              {additionalDescription}
            </>
          </Flex>
        )}
      </Flex>
      {buttonText && !!onButtonClick && (
        <Button onClick={onButtonClick} {...buttonProps}>
          {buttonText}
        </Button>
      )}
    </Flex>
  );
};
