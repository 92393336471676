import { applyContext, mutateObject, Mutations } from 'helpers';
import { GQL, LocationPermission } from 'types';

export interface LocationPermissionsMutationInterface {
  users: string[];
  locations: string[];
  permissionTypes: LocationPermission[];
}
export type mutateAddLocationPermissionsProps =
  LocationPermissionsMutationInterface;
export type mutateRemoveLocationPermissionsProps =
  LocationPermissionsMutationInterface;

export const mutateAddLocationPermissions = async (
  data: mutateAddLocationPermissionsProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'ADD_LOCATION_PERMISSIONS'),
    query: Mutations.ADD_LOCATION_PERMISSIONS,
    data,
    returnKey: 'addLocationPermissions',
  });

export const mutateRemoveLocationPermissions = async (
  data: mutateRemoveLocationPermissionsProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'REMOVE_LOCATION_PERMISSIONS'),
    query: Mutations.REMOVE_LOCATION_PERMISSIONS,
    data,
    returnKey: 'removeLocationPermissions',
  });

export interface mutateBulkPermissionsProps {
  users: string[];
  permissionTypes: LocationPermission[];
}

export const mutateBulkPermissions = async (data: mutateBulkPermissionsProps) =>
  await mutateObject({
    route: applyContext(GQL, 'BULK_EDIT_ORGANIZATION_LOCATION_PERMISSIONS'),
    query: Mutations.BULK_EDIT_ORGANIZATION_LOCATION_PERMISSIONS,
    data,
    returnKey: 'bulkEditOrganizationLocationPermissions',
  });

export interface mutateRemoveAllPermissionsProps {
  users: string[];
}

export const mutateRemoveAllPermissions = async (
  data: mutateRemoveAllPermissionsProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'REMOVE_ALL_PERMISSIONS'),
    query: Mutations.REMOVE_ALL_LOCATION_PERMISSIONS,
    data,
    returnKey: 'removeAllPermissions',
  });
