import { FC } from 'react';
import {
  BoxProps,
  ButtonProps,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
  ModalProps as ChakraModalProps,
} from '@chakra-ui/react';

interface ModalProps extends ChakraModalProps {
  contentProps?: ModalContentProps;
  bodyProps?: BoxProps;
  closeButtonProps?: ButtonProps;
}

export const Modal: FC<ModalProps> = ({
  onClose,
  isOpen,
  size = 'lg',
  children,
  closeOnOverlayClick = false,
  contentProps,
  bodyProps,
  closeButtonProps,
  ...modalProps
}) => (
  <ChakraModal
    onClose={onClose}
    isOpen={isOpen}
    size={size}
    closeOnOverlayClick={closeOnOverlayClick}
    data-testid="modal-overlay"
    {...modalProps}
  >
    <ModalOverlay />
    <ModalContent {...contentProps}>
      <ModalCloseButton {...closeButtonProps} />
      <ModalBody pb={8} {...bodyProps}>
        {children}
      </ModalBody>
    </ModalContent>
  </ChakraModal>
);
