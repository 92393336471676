import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import {
  paging,
  ReflexResponse,
  UserFitFor,
  UserRole,
  UserStatus,
  uuid as uuidType,
  WorkerShiftStatus,
  WorkerTierOptions,
} from 'types';
import { GQL } from 'types/src/routes';

export interface fetchUsersProps extends paging {
  acquisitionCampaigns?: string[];
  activatedBy?: string[];
  attributionCodes?: string[];
  canScheduleCall?: boolean;
  excludeAttributeDormant?: boolean;
  excludeBlockedAt?: string[]; // Location uuids
  excludeFavoriteAt?: uuidType[];
  excludeHasWorkedAt?: uuidType[];
  excludeShifts?: string[];
  favoriteAt?: uuidType[];
  flags?: string[];
  hasAddress?: boolean;
  hasExperience?: boolean;
  hasLocationRelation?: boolean;
  hasProfileImage?: boolean;
  hasWorked?: boolean;
  hasWorkedAt?: uuidType[];
  isBooked?: boolean;
  isFlagged?: boolean;
  locations?: uuidType[];
  locationsForPermissions?: uuidType[]; // location uuids
  locationsForPreferences?: uuidType[]; // location uuids
  market?: number; // Market ID
  markets?: number[]; // Market ID
  metabaseQuestion?: string;
  notWorkedSince?: string; // "YYYY-MM-DD"
  onboardedBy?: uuidType[];
  organization?: string; // Organization uuid
  organizations?: string[]; // Organization uuids
  retailers?: string[];
  reviewPrioritizationAvgMax?: number;
  reviewPrioritizationAvgMin?: number;
  reviewRatingAvgMax?: number;
  reviewRatingAvgMin?: number;
  role?: UserRole[];
  shiftsCompletedMax?: number;
  shiftsCompletedMin?: number;
  since?: string; // "YYYY-MM-DD" checks for users "created_at" date
  status?: UserStatus[];
  tags?: string[]; // Tag ID
  tagsMissing?: string[]; // Tag ID
  tier?: Array<WorkerTierOptions | string>;
  to?: string; // "YYYY-MM-DD" checks for users "created_at" date
  uuid?: string;
  uuids?: string[];
  withAccessTo?: string[]; // Location uuids
  wsoStatus?: WorkerShiftStatus[];
  zipcode?: string;
  zipcodeMaxDistance?: number;
  zipcodeMinDistance?: number;
}

export const fetchUsers = async (
  data: fetchUsersProps,
  query = Queries.USERS_QUERY,
  contextOverride?: string,
  returnKey: string | string[] = ['users', 'users_paging'],
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(
      GQL,
      !contextOverride ? 'USERS_QUERY' : contextOverride,
    ),
    query,
    data,
    returnKey,
  });

export interface commonUserGraphProps {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
}

export interface mutateUserCreateProps extends commonUserGraphProps {
  email: string;
  password: string;
  approvedLocations?: Array<uuidType>;
  isRetail?: boolean;
  isWorker?: boolean;
  status?: UserStatus;
  phoneNumber?: string;
  requiresPasswordUpdate?: boolean;
  organization?: string; // Organization uuid
}

export const mutateUserCreate = async (
  data: mutateUserCreateProps,
): Promise<ReflexResponse> =>
  await mutateObject({
    route: applyContext(GQL, 'CREATE_USER_MUTATION'),
    query: Mutations.CREATE_USER_MUTATION,
    data,
    returnKey: 'createUser',
  });

export interface mutateUsersUpdateProps extends commonUserGraphProps {
  activatedBy?: string; // UUID
  canScheduleCall?: boolean;
  clearFitFor?: boolean;
  clearOnboardedBy?: boolean;
  email?: string;
  firstName?: string;
  fitFor?: UserFitFor[];
  is1099PayproviderComplete?: boolean;
  isBackcheckComplete?: boolean;
  isI9Verified?: boolean;
  isW2PayproviderComplete?: boolean;
  lastName?: string;
  markets?: Array<number>;
  onboardedBy?: string; // UUID
  onboarding?: string; // JSON string
  onboardingDate?: Date;
  phoneNumber?: string;
  status?: UserStatus;
  tier?: WorkerTierOptions;
  title?: string;
  users: Array<uuidType>;
}

export const mutateUsersUpdate = async (data: mutateUsersUpdateProps) =>
  await mutateObject({
    route: applyContext(GQL, 'UPDATE_USERS_MUTATION'),
    query: Mutations.UPDATE_USER_MUTATION,
    data,
    returnKey: 'updateUsers',
  });

export interface mutateUpdateUserProfileProps extends commonUserGraphProps {
  aboutMe?: string;
  address?: string;
  birthdate?: string; // YYYY-mm-dd
  city?: string;
  clearFitFor?: boolean;
  fitFor?: UserFitFor[];
  language?: string;
  location?: string;
  pronouns?: string;
  state?: string;
  title?: string;
  zip?: string;
}

export const mutateUpdateUserProfile = async (
  data: mutateUpdateUserProfileProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'UPDATE_USER_PROFILE_MUTATION'),
    query: Mutations.UPDATE_USER_PROFILE_MUTATION,
    data,
    returnKey: 'updateUserProfile',
  });

export interface mutateRequestRemoveOrganizationAccessProps {
  note: string;
  userUuid: string; // user uuid to remove
}

export const mutateRequestRemoveOrganizationAccess = async (
  data: mutateRequestRemoveOrganizationAccessProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'RETAILER_REMOVE_ORGANIZATION_ACCESS_MUTATION'),
    query: Mutations.RETAILER_REMOVE_ORGANIZATION_ACCESS_MUTATION,
    data,
    returnKey: 'retailerRemoveOrganizationAccess',
  });

export interface mutateGenericRetailerNoteProps {
  note: string;
}

export const mutateGenericRetailerNote = async (
  data: mutateGenericRetailerNoteProps,
): Promise<ReflexResponse> =>
  await mutateObject({
    route: applyContext(GQL, 'CREATE_RETAILER_NOTE_MUTATION'),
    query: Mutations.CREATE_RETAILER_NOTE_MUTATION,
    data,
    returnKey: 'genericRetailerNote',
  });

export interface mutateSoftDeleteUserProps {
  uuid: string;
  confirmationString: string;
}

export const mutateSoftDeleteUser = async (
  data: mutateSoftDeleteUserProps,
): Promise<ReflexResponse> =>
  await mutateObject({
    route: applyContext(GQL, 'SOFT_DELETE_USER_MUTATION'),
    query: Mutations.SOFT_DELETE_USER_MUTATION,
    data,
    returnKey: 'softDeleteUser',
  });

export interface mutateCompleteOnboardingProps {
  workerUuid: string;
  status: UserStatus;
  tier?: WorkerTierOptions;
  markets?: number[]; // id[]
  fitFor?: UserFitFor[];
  note?: string;
}

export const mutateCompleteOnboarding = async (
  data: mutateCompleteOnboardingProps,
): Promise<ReflexResponse> =>
  await mutateObject({
    route: applyContext(GQL, 'COMPLETE_ONBOARDING_MUTATION'),
    query: Mutations.COMPLETE_ONBOARDING_MUTATION,
    data,
    returnKey: 'completeOnboarding',
  });
