import { getEnumKeyByValue } from 'helpers';
import { shift, SortState, user, UserStatus, WorkerTierOptions } from 'types';

export { SortState };
export type ShiftSortOptionsType = keyof typeof ShiftSortOptions;
export type UserSelectionMap = Partial<{ [key in UserSelectKeys]: boolean }>;
export type MessageSelectionMap = Partial<{
  [key in MessageSelectKeys]: boolean;
}>;
export type ShiftSortMap = Partial<{
  [value in ShiftSortOptionsType]: SortState;
}>;
export type usersDict = Record<string, Partial<user>>;
export type updateUsersParams = {
  users?: user[];
  action: 'add' | 'remove';
};

export const ShiftSortOptions = {
  custom_status: 'Status',
  created_at: 'Created',
  updated_at: 'Updated',
  worker__first_name: 'Name',
} as const;

export enum UserSelectKeys {
  FAVORITES = '1. Favorites',
  STORE_REPEATS = '2. Store Repeats',
  HIGH_TIER = '3. High Tier',
  MID_TIER = '4. Mid Tier',
  NEW_HIGH_EXPERIENCE = '5. High Experience',
  NEW_MID_EXPERIENCE = '6. Mid Experience',
  NEW_LOW_EXPERIENCE = '7. Low Experience',
  LOW_RATED = '8. Low Rated',
  TIER_R1 = 'R1',
  TIER_R2 = 'R2',
  TIER_R3 = 'R3',
  TIER_R4 = 'R4',
  TIER_R5 = 'R5',
  TIER_G1 = 'G1',
  TIER_G2 = 'G2',
  TIER_G3 = 'G3',
  TIER_G4 = 'G4',
  TIER_G5 = 'G5',
  TIER_N1 = 'N1',
  TIER_N2 = 'N2',
  TIER_N3 = 'N3',
  TIER_N4 = 'N4',
  TIER_N5 = 'N5',
}

export enum MessageSelectKeys {
  ANY = 'Any',
  NOT_MESSAGED = 'None',
  MESSAGED_ONCE = '1',
  MESSAGED_TWICE = '2',
  MESSAGED_LOTS = '3+',
}

export const selectUserDefinitions = {
  [UserSelectKeys.FAVORITES]: (shift: shift) => ({
    favoriteAt: [shift?.retailerLocation?.uuid],
    status: [UserStatus.ACTIVE],
  }),
  [UserSelectKeys.STORE_REPEATS]: (shift: shift) => ({
    hasWorkedAt: [shift?.retailerLocation?.uuid],
    excludeFavoriteAt: [shift?.retailerLocation?.uuid],
    status: [UserStatus.ACTIVE],
    tier: [WorkerTierOptions.R1, WorkerTierOptions.R2],
  }),
  [UserSelectKeys.HIGH_TIER]: (shift: shift) => ({
    excludeHasWorkedAt: [shift?.retailerLocation?.uuid],
    excludeFavoriteAt: [shift?.retailerLocation?.uuid],
    tier: [WorkerTierOptions.R1],
    status: [UserStatus.ACTIVE],
    markets: [shift?.retailerLocation?.market?.id],
  }),
  [UserSelectKeys.MID_TIER]: (shift: shift) => ({
    excludeHasWorkedAt: [shift?.retailerLocation?.uuid],
    excludeFavoriteAt: [shift?.retailerLocation?.uuid],
    tier: [WorkerTierOptions.R2],
    status: [UserStatus.ACTIVE],
    markets: [shift?.retailerLocation?.market?.id],
  }),
  [UserSelectKeys.NEW_HIGH_EXPERIENCE]: (shift: shift) => ({
    tier: [WorkerTierOptions.R4],
    status: [UserStatus.ACTIVE],
    markets: [shift?.retailerLocation?.market?.id],
    hasWorked: false,
  }),
  [UserSelectKeys.NEW_MID_EXPERIENCE]: (shift: shift) => ({
    tier: [WorkerTierOptions.G4],
    status: [UserStatus.ACTIVE],
    markets: [shift?.retailerLocation?.market?.id],
    hasWorked: false,
  }),
  [UserSelectKeys.NEW_LOW_EXPERIENCE]: (shift: shift) => ({
    tier: [WorkerTierOptions.N4],
    status: [UserStatus.ACTIVE],
    markets: [shift?.retailerLocation?.market?.id],
    hasWorked: false,
  }),
  [UserSelectKeys.LOW_RATED]: (shift: shift) => ({
    tier: [WorkerTierOptions.R3],
    status: [UserStatus.ACTIVE],
    markets: [shift?.retailerLocation?.market?.id],
    hasWorked: true,
  }),
  [UserSelectKeys.TIER_R1]: () => ({
    tier: [WorkerTierOptions.R1],
  }),
  [UserSelectKeys.TIER_R2]: () => ({
    tier: [WorkerTierOptions.R2],
  }),
  [UserSelectKeys.TIER_R3]: () => ({
    tier: [WorkerTierOptions.R3],
  }),
  [UserSelectKeys.TIER_R4]: () => ({
    tier: [WorkerTierOptions.R4],
  }),
  [UserSelectKeys.TIER_R5]: () => ({
    tier: [WorkerTierOptions.R5],
  }),
  [UserSelectKeys.TIER_G1]: () => ({
    tier: [WorkerTierOptions.G1],
  }),
  [UserSelectKeys.TIER_G2]: () => ({
    tier: [WorkerTierOptions.G2],
  }),
  [UserSelectKeys.TIER_G3]: () => ({
    tier: [WorkerTierOptions.G3],
  }),
  [UserSelectKeys.TIER_G4]: () => ({
    tier: [WorkerTierOptions.G4],
  }),
  [UserSelectKeys.TIER_G5]: () => ({
    tier: [WorkerTierOptions.G5],
  }),
  [UserSelectKeys.TIER_N1]: () => ({
    tier: [WorkerTierOptions.N1],
  }),
  [UserSelectKeys.TIER_N2]: () => ({
    tier: [WorkerTierOptions.N2],
  }),
  [UserSelectKeys.TIER_N3]: () => ({
    tier: [WorkerTierOptions.N3],
  }),
  [UserSelectKeys.TIER_N4]: () => ({
    tier: [WorkerTierOptions.N4],
  }),
  [UserSelectKeys.TIER_N5]: () => ({
    tier: [WorkerTierOptions.N5],
  }),
};

export const userSelectOptionsGenerator = value =>
  Object.values(UserSelectKeys).reduce(
    (acc, key) => {
      acc[key] = value;
      return acc;
    },
    {} as Record<UserSelectKeys, boolean>,
  );

export const messageSelectOptionsGenerator = value =>
  Object.keys(MessageSelectKeys).reduce(
    (acc, key) => {
      acc[key] = value;
      return acc;
    },
    {} as Record<MessageSelectKeys, boolean>,
  );

export const getFilteredSelectedUsers = (
  allSelectedUsers: user[],
  messageSelectOptions: MessageSelectionMap,
  messageCounts: Record<string, number>,
  allWorkers: user[],
): user[] => {
  const activeMessageSelectOption = Object.entries(messageSelectOptions).reduce(
    (acc, [key, value]) => {
      if (value) acc.push(key);
      return acc;
    },
    [],
  )[0];

  const filterUsersByMessageCount = (
    users: user[],
    messageCountCondition: (value: number) => boolean,
  ) => {
    const filteredCounts = Object.entries(messageCounts)
      .filter(([, value]) => messageCountCondition(value as number))
      .map(([key]) => key);
    return users?.filter(u => filteredCounts.includes(u?.uuid));
  };

  const filterByMessageSelection = (
    users: user[],
    activeMessageSelectOption,
  ) => {
    switch (activeMessageSelectOption) {
      case getEnumKeyByValue(
        MessageSelectKeys,
        MessageSelectKeys.NOT_MESSAGED,
      ): {
        return filterUsersByMessageCount(users, value => value === 0);
      }
      case getEnumKeyByValue(
        MessageSelectKeys,
        MessageSelectKeys.MESSAGED_ONCE,
      ): {
        return filterUsersByMessageCount(users, value => value === 1);
      }
      case getEnumKeyByValue(
        MessageSelectKeys,
        MessageSelectKeys.MESSAGED_TWICE,
      ): {
        return filterUsersByMessageCount(users, value => value === 2);
      }
      case getEnumKeyByValue(
        MessageSelectKeys,
        MessageSelectKeys.MESSAGED_LOTS,
      ): {
        return filterUsersByMessageCount(users, value => value > 2);
      }
      case getEnumKeyByValue(MessageSelectKeys, MessageSelectKeys.ANY):
      default: {
        return users;
      }
    }
  };

  const userEnhancer = (filteredUsers: user[]) =>
    filteredUsers
      .map(u => {
        const enhancedUser = allWorkers?.filter(
          user => user?.uuid === u?.uuid,
        )[0];
        if (!enhancedUser) return;
        return enhancedUser;
      })
      .filter(x => !!x);

  let filteredUsers = allSelectedUsers;
  filteredUsers = filterByMessageSelection(
    filteredUsers,
    activeMessageSelectOption,
  );

  return userEnhancer(filteredUsers);
};
