import { GQL, paging } from 'types';
import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';

export interface fetchOzNotificationsProps extends paging {}

export const fetchOzNotifications = async (
  data: fetchOzNotificationsProps,
  query = Queries.OZ_NOTIFICATIONS_QUERY,
  contextOverride?: string,
  returnKey: string | string[] = ['ozNotifications', 'ozNotifications_paging'],
) => {
  return await fetchObject({
    route: applyContext(GQL, contextOverride || 'OZ_NOTIFICATIONS_QUERY'),
    query,
    data,
    returnKey,
  });
};

export interface mutateOzNotificationReceiptProps {
  uuids: string[];
  status: string;
}

export const mutateOzNotificationReceipt = async (
  data: mutateOzNotificationReceiptProps,
  contextOverride?: string,
) => {
  return await mutateObject({
    route: applyContext(GQL, contextOverride || 'OZ_NOTIFICATIONS_MUTATION'),
    query: Mutations.OZ_NOTIFICATION_RECEIPT_MUTATION,
    data,
    returnKey: 'ozNotificationReceipt',
  });
};

export interface mutateRestoreOzNotificationProps {}

export const mutateRestoreOzNotifications = async (
  data: mutateRestoreOzNotificationProps,
  contextOverride?: string,
) => {
  return await mutateObject({
    route: applyContext(GQL, contextOverride || 'OZ_NOTIFICATIONS_MUTATION'),
    query: Mutations.RESTORE_OZ_NOTIFICATION_MUTATION,
    data,
    returnKey: 'restoreOzNotification',
  });
};

export interface mutateDismissAllOzNotificationsProps {}

export const mutateDismissAllOzNotifications = async (
  data: mutateDismissAllOzNotificationsProps,
  contextOverride?: string,
) => {
  return await mutateObject({
    route: applyContext(GQL, contextOverride || 'OZ_NOTIFICATIONS_MUTATION'),
    query: Mutations.DISMISS_ALL_OZ_NOTIFICATIONS_MUTATION,
    data,
    returnKey: 'dismissAllOzNotifications',
  });
};
