import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  cancelShiftProps,
  mutateCancelShift,
  mutateCreateWorkerShift,
  mutateCreateWorkerShiftProps,
  mutateUpdateShift,
  mutateUpdateShiftProps,
  mutateUpdateWorkerShifts,
  mutateUpdateWorkerShiftsProps,
  mutateWorkershiftDelete,
  mutateWsoToExistingShift,
  mutateWsoToExistingShiftProps,
  mutateWsoToNewShift,
  mutateWsoToNewShiftProps,
  noNulls,
} from 'helpers';
import { invalidateCache } from 'hooks';
import { DataKeys } from 'types';

export const useShiftActions = () => {
  const queryClient = useQueryClient();

  const handleInvalidateCache = () => {
    invalidateCache(
      [
        DataKeys.SHIFT,
        DataKeys.SHIFTS,
        DataKeys.WORKER_SHIFTS,
        DataKeys.ACTION_ITEMS,
      ],
      queryClient,
    );
  };
  const updateShift = useMutation(
    (args: mutateUpdateShiftProps) => mutateUpdateShift(noNulls(args)),
    {
      onSuccess: () => {
        handleInvalidateCache();
      },
    },
  );

  const cancelShift = useMutation(
    (args: cancelShiftProps) => mutateCancelShift(noNulls(args)),
    {
      onSuccess: () => {
        handleInvalidateCache();
      },
    },
  );

  const addWorkerShifts = useMutation(
    (args: mutateCreateWorkerShiftProps) =>
      mutateCreateWorkerShift({
        users: args?.users,
        wsos: args?.wsos,
        shifts: args?.shifts,
      }),
    {
      onSuccess: () => {
        handleInvalidateCache();
      },
    },
  );

  const removeWorkerShifts = useMutation(
    (args: mutateWorkershiftDelete) =>
      mutateWorkershiftDelete({
        wso: args?.wso,
        users: args?.users,
        shift: args?.shift,
        force: args?.force,
      }),
    {
      onSuccess: () => {
        handleInvalidateCache();
      },
    },
  );

  const updateWorkerShifts = useMutation(
    (args: mutateUpdateWorkerShiftsProps) =>
      mutateUpdateWorkerShifts({
        users: args?.users,
        wso: args?.wso,
        shift: args?.shift,
        status: args?.status,
        confirmationStatus: args?.confirmationStatus,
        cancelClassification: args?.cancelClassification,
        cancelNote: args?.cancelNote,
        workerCancellationPayoutRate: args?.workerCancellationPayoutRate,
      }),
    {
      onSuccess: () => {
        handleInvalidateCache();
      },
    },
  );

  const moveWsoToNewShift = useMutation(
    (args: mutateWsoToNewShiftProps) =>
      mutateWsoToNewShift({
        workershift: args?.workershift,
        location: args?.location,
        start: args?.start,
        end: args?.end,
        roles: args?.roles,
        reason: args?.reason,
        bonusAmount: args?.bonusAmount,
      }),
    {
      onSuccess: () => {
        handleInvalidateCache();
      },
    },
  );

  const moveWsoToExistingShift = useMutation(
    (args: mutateWsoToExistingShiftProps) =>
      mutateWsoToExistingShift({
        workershift: args?.workershift,
        shift: args?.shift,
      }),
    {
      onSuccess: () => {
        handleInvalidateCache();
      },
    },
  );

  return {
    cancelShift,
    updateShift,
    addWorkerShifts,
    removeWorkerShifts,
    updateWorkerShifts,
    moveWsoToNewShift,
    moveWsoToExistingShift,
  };
};
