import { Job, JobStatus } from 'types';
import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';

export type JobsStore = {
  jobsList: Record<string, Job>;
  appendToJobsList: (job: Job) => void;
  removeFromJobsList: (jobName: string) => void;
  updateJobStatus: (jobKey: string, status: JobStatus) => void;
  clearJobsList: () => void;
  setNotificationExpire: (jobKey: string, timeout?: number) => void;
};

const defaultStoreState = {
  jobsList: {},
};

const jobsStore = (set): JobsStore => ({
  ...defaultStoreState,
  appendToJobsList: (job: Job) => {
    set(prev => ({ jobsList: { ...prev.jobsList, [job.jobKey]: job } }));
  },
  removeFromJobsList: (jobKey: string) => {
    set(prev => {
      const newJobsList = { ...prev.jobsList };
      delete newJobsList[jobKey];
      return { jobsList: newJobsList };
    });
  },
  updateJobStatus: (jobKey: string, status: JobStatus) => {
    set(prev => {
      const newJobsList = { ...prev.jobsList };
      if (!newJobsList[jobKey]) return { ...prev };

      newJobsList[jobKey].status = status;
      return { jobsList: newJobsList };
    });
  },
  setNotificationExpire: (jobKey: string, timeout = 8000) => {
    setTimeout(() => {
      set(prev => {
        const newJobsList = { ...prev.jobsList };
        // This prevents removing the Running banner if a new task starts
        if (newJobsList[jobKey]?.status === JobStatus.STARTED) {
          return { ...prev };
        }
        delete newJobsList[jobKey];
        return { jobsList: newJobsList };
      });
    }, timeout);
  },

  clearJobsList: () => set({ jobList: {} }),
});

export const useJobsStore = create(subscribeWithSelector(devtools(jobsStore)));
