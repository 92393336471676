import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSocketToken } from 'helpers';
import { invalidateCache } from 'hooks';
import { socket } from 'socket';
import {
  DataKeys,
  Job,
  JobStatus,
  JobType,
  ozNotification,
  WebsocketEventType,
} from 'types';
import { useJobsQuery } from 'utils/hooks/useJobs';
import {
  useNotificationsStore,
  usePlaybookStore,
  useSettingsStore,
  useSocketHelperStore,
} from 'utils/stores';
import { useJobsStore } from 'utils/stores/JobsStore';

export const useSocketConnection = () => {
  const socketToken = useSocketToken();
  const [isConnected, setIsConnected] = useState(socket?.connected);
  const setCurrentConnectionId = useSocketHelperStore(
    s => s.setCurrentConnectionId,
  );

  useEffect(() => {
    if (isConnected || !socketToken) return;
    socket.auth = { token: socketToken };
    socket.connect();
  }, [socketToken, isConnected]);

  useEffect(() => {
    const onConnect = () => {
      setIsConnected(true);
      setCurrentConnectionId(socket?.id);
    };
    const onDisconnect = () => {
      setIsConnected(false);
      setCurrentConnectionId(null);
    };
    socket?.on(WebsocketEventType.CONNECT, onConnect);
    socket?.on(WebsocketEventType.DISCONNECT, onDisconnect);
    return () => {
      socket?.off(WebsocketEventType.CONNECT, onConnect);
      socket?.off(WebsocketEventType.DISCONNECT, onDisconnect);
    };
  }, [setCurrentConnectionId]);

  return { isConnected, setIsConnected };
};

export const useShiftSocketEvents = ({ uuid }) => {
  const { isConnected } = useSocketConnection();
  const queryClient = useQueryClient();

  const { data: jobsData } = useJobsQuery({ uuid });

  const [jobsList, appendToJobsList, updateJobStatus, setNotificationExpire] =
    useJobsStore(s => [
      s.jobsList,
      s.appendToJobsList,
      s.updateJobStatus,
      s.setNotificationExpire,
    ]);
  const [clearFastForwardSteps] = usePlaybookStore(s => [
    s.clearFastForwardSteps,
  ]);

  const onJobStatusEvent = useCallback(
    (jobEvent: Job) => {
      const { jobKey, status } = jobEvent;
      if (!jobKey.includes(uuid)) return;
      if (status === JobStatus.SUCCESS || status === JobStatus.FAILURE) {
        updateJobStatus(jobKey, status);
        setNotificationExpire(jobKey);
        invalidateCache(
          [DataKeys.SHIFT, DataKeys.SHIFTS, DataKeys.WORKER_SHIFTS],
          queryClient,
        );
        return;
      }
      appendToJobsList(jobEvent);
    },
    [
      uuid,
      queryClient,
      appendToJobsList,
      updateJobStatus,
      setNotificationExpire,
    ],
  );

  const onPlaybookEvent = useCallback(
    (jobEvent: Job) => {
      const { jobKey, status, jobType } = jobEvent || {};
      if (!jobKey.includes(uuid)) return;
      if (status !== JobStatus.SUCCESS) return;
      if (
        ![
          JobType.PROCESS_PLAYBOOK_STEP,
          JobType.PLAYBOOK_PAUSE_TOGGLED,
        ].includes(jobType)
      )
        return;
      invalidateCache(
        [DataKeys.SHIFT, DataKeys.SHIFTS, DataKeys.WORKER_SHIFTS],
        queryClient,
      );
      clearFastForwardSteps();
    },
    [uuid, queryClient, clearFastForwardSteps],
  );

  useEffect(() => {
    if (!jobsData) return;

    jobsData?.data?.forEach((job: Job) => {
      if (job.status === JobStatus.SUCCESS || job.status === JobStatus.FAILURE)
        return;
      appendToJobsList(job);
    });
  }, [uuid, jobsData, appendToJobsList]);

  useEffect(() => {
    if (!isConnected) return;
    socket?.on(WebsocketEventType.JOB_STATUS, onJobStatusEvent);
    socket?.on(WebsocketEventType.PLAYBOOK_EVENT, onPlaybookEvent);
    return () => {
      socket?.off(WebsocketEventType.JOB_STATUS, onJobStatusEvent);
      socket?.off(WebsocketEventType.PLAYBOOK_EVENT, onPlaybookEvent);
    };
  }, [isConnected, onJobStatusEvent, onPlaybookEvent]);

  return { jobsList };
};

export const useNotificationSocketEvents = () => {
  const { isConnected } = useSocketConnection();
  const queryClient = useQueryClient();
  const [updateNotifications] = useNotificationsStore(s => [
    s.updateNotifications,
  ]);
  const [receiveOzNotifications] = useSettingsStore(s => [
    s.receiveOzNotifications,
  ]);

  const onNotificationEvent = useCallback(
    (notification: ozNotification) => {
      if (receiveOzNotifications) {
        updateNotifications(notification, 'add');
      }
      invalidateCache([DataKeys.OZ_NOTIFICATIONS], queryClient);
    },
    [receiveOzNotifications, updateNotifications, queryClient],
  );

  useEffect(() => {
    if (!isConnected) return;
    socket?.on(WebsocketEventType.OZ_NOTIFICATION, onNotificationEvent);
    return () => {
      socket?.off(WebsocketEventType.OZ_NOTIFICATION, onNotificationEvent);
    };
  }, [isConnected, onNotificationEvent]);
};

export const useOnboardingScheduleSocketEvents = () => {
  const { isConnected } = useSocketConnection();
  const updateToInvalidate = useSocketHelperStore(s => s.updateToInvalidate);

  const onOnboardingScheduleEvent = useCallback(() => {
    updateToInvalidate(DataKeys.ONBOARDING_SCHEDULE, 'add');
  }, [updateToInvalidate]);

  useEffect(() => {
    if (!isConnected) return;
    socket?.on(
      WebsocketEventType.ONBOARDING_SCHEDULE,
      onOnboardingScheduleEvent,
    );
    return () => {
      socket?.off(
        WebsocketEventType.ONBOARDING_SCHEDULE,
        onOnboardingScheduleEvent,
      );
    };
  }, [isConnected, onOnboardingScheduleEvent]);
};

export const useActionItemSocketEvents = () => {
  const { isConnected } = useSocketConnection();
  const updateToInvalidate = useSocketHelperStore(s => s.updateToInvalidate);

  const onActionItemEvent = useCallback(() => {
    updateToInvalidate(DataKeys.ACTION_ITEMS, 'add');
  }, [updateToInvalidate]);

  useEffect(() => {
    if (!isConnected) return;
    socket?.on(WebsocketEventType.ACTION_ITEM, onActionItemEvent);
    return () => {
      socket?.off(WebsocketEventType.ACTION_ITEM, onActionItemEvent);
    };
  }, [isConnected, onActionItemEvent]);
};
