import {
  CommonStore,
  commonStore,
  fetchActionItems,
  fetchActionItemsProps,
  Queries,
} from 'helpers';
import { actionItem, Prettify } from 'types';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

interface ActionItemsStoreBase {
  totalItems: number;
  setTotalItems: (total: number) => void;
  selectedItems: Record<string, actionItem>;
  selectedItemsArray: actionItem[];
  selectedItemsUuidArray: string[];
  updateSelectedItems: (item: actionItem, action: 'add' | 'remove') => void;
  setSelectedItems: (items: Record<string, actionItem>) => void;
  clearSelectedItems: () => void;
  selectionIsActive: boolean;
  setSelectionIsActive: (isActive: boolean) => void;
  prevSelectedRowIndex: number;
  setPrevSelectedRowIndex: (index: number) => void;
  actionPageItems: Record<string, actionItem>;
  setActivePageItems: (items: actionItem[]) => void;
  selectAllPageItems: () => void;

  // Shift Action Items
  shiftActionItemsTotal: number;
  setShiftActionItemsTotal: (total: number) => void;
  expandedItems: Record<string, actionItem>;
  updateExpandedItems: (item: actionItem, action: 'add' | 'remove') => void;
  setExpandedItems: (items: Record<string, actionItem>) => void;
  clearExpandedItems: () => void;
  expandAllPageItems: () => void;
  selectedShiftItems: Record<string, actionItem>;
  selectedShiftItemsArray: actionItem[];
  selectedShiftItemsUuidArray: string[];
  updateSelectedShiftItems: (
    item: actionItem,
    action: 'add' | 'remove',
  ) => void;
  setSelectedShiftItems: (items: Record<string, actionItem>) => void;
  clearSelectedShiftItems: () => void;
  shiftPageItems: Record<string, actionItem>;
  setActiveShiftPageItems: (items: actionItem[]) => void;
  selectAllShiftPageItems: () => void;
  updateSelectedItemsFromServer: (
    queryVariables: fetchActionItemsProps,
    context?: 'tasks' | 'shifts',
  ) => Promise<void>;
}

export const ActionItemsSortOptions = {} as const; // can't sort action items yet

export type ActionItemsStore = Prettify<
  CommonStore<typeof ActionItemsSortOptions> & ActionItemsStoreBase
>;

const defaultState = {
  totalItems: 0,
  selectedItems: {},
  selectedItemsArray: [],
  selectedItemsUuidArray: [],
  actionPageItems: {},
  selectionIsActive: true,
  prevSelectedRowIndex: -1,

  // Shift Action Items
  shiftActionItemsTotal: 0,
  expandedItems: {},
  selectedShiftItems: {},
  selectedShiftItemsArray: [],
  selectedShiftItemsUuidArray: [],
  shiftPageItems: {},
};

const actionItemsStore = (set, get): ActionItemsStore => ({
  ...commonStore(set),
  ...defaultState,
  setTotalItems: total => set({ totalItems: total }),
  updateSelectedItems: (item, action) => {
    if (action === 'add') {
      set(prev => ({
        selectedItems: {
          ...prev.selectedItems,
          [item.uuid]: item,
        },
      }));
    } else {
      const currentItems = get().selectedItems;
      const newItems = {};
      Object.keys(currentItems).forEach(key => {
        if (key !== item?.uuid) newItems[key] = currentItems[key];
      });
      set({ selectedItems: newItems });
    }
  },
  setSelectedItems: items => set({ selectedItems: items }),
  clearSelectedItems: () => set({ selectedItems: {} }),
  setSelectionIsActive: isActive => set({ selectionIsActive: isActive }),
  setPrevSelectedRowIndex: index => set({ prevSelectedRowIndex: index }),
  setActivePageItems: items => {
    const itemsObject = items?.reduce(
      (acc, item) => {
        acc[item.uuid] = item;
        return acc;
      },
      {} as Record<string, actionItem>,
    );
    set({ activePageItems: itemsObject });
  },
  selectAllPageItems: () => set({ selectedItems: get().activePageItems }),

  // Shift Action Items
  setShiftActionItemsTotal: total => set({ shiftActionItemsTotal: total }),
  updateExpandedItems: (item, action) => {
    if (action === 'add') {
      set(prev => ({
        expandedItems: {
          ...prev.expandedItems,
          [item.uuid]: item,
        },
      }));
    } else {
      const currentItems = get().expandedItems;
      const newItems = {};
      Object.keys(currentItems).forEach(key => {
        if (key !== item?.uuid) newItems[key] = currentItems[key];
      });
      set({ expandedItems: newItems });
    }
  },
  setExpandedItems: items => set({ expandedItems: items }),
  clearExpandedItems: () => set({ expandedItems: {} }),
  expandAllPageItems: () => set({ expandedItems: get().shiftPageItems }),
  updateSelectedShiftItems: (item, action) => {
    if (action === 'add') {
      set(prev => ({
        selectedShiftItems: {
          ...prev.selectedShiftItems,
          [item.uuid]: item,
        },
      }));
    } else {
      const currentItems = get().selectedShiftItems;
      const newItems = {};
      Object.keys(currentItems).forEach(key => {
        if (key !== item?.uuid) newItems[key] = currentItems[key];
      });
      set({ selectedShiftItems: newItems });
    }
  },
  setSelectedShiftItems: items => set({ selectedShiftItems: items }),
  clearSelectedShiftItems: () => set({ selectedShiftItems: {} }),
  setActiveShiftPageItems: items => {
    const itemsObject = items?.reduce(
      (acc, item) => {
        acc[item.uuid] = item;
        if (item?.siblingTasks && Array.isArray(item?.siblingTasks)) {
          item?.siblingTasks?.forEach(siblingTask => {
            if (siblingTask?.uuid) {
              acc[siblingTask.uuid] = siblingTask;
            }
          });
        }
        return acc;
      },
      {} as Record<string, actionItem>,
    );
    set({ shiftPageItems: itemsObject });
  },
  selectAllShiftPageItems: () =>
    set({ selectedShiftItems: get().shiftPageItems }),

  //
  updateSelectedItemsFromServer: async (
    queryVariables: fetchActionItemsProps,
    context = 'tasks',
  ) => {
    const response = await fetchActionItems(
      queryVariables,
      Queries.ACTION_ITEMS_SELECT_QUERY,
      'ACTION_ITEMS_SELECT_QUERY',
    );
    if (!response.hasErrors) {
      const { actionItems } = response?.data || [];
      const itemsMap = actionItems?.reduce((acc, curr) => {
        acc[curr.uuid] = curr;
        if (
          context === 'shifts' &&
          curr?.siblingTasks &&
          Array.isArray(curr?.siblingTasks)
        ) {
          curr?.siblingTasks?.forEach(siblingTask => {
            if (siblingTask?.uuid) {
              acc[siblingTask.uuid] = siblingTask;
            }
          });
        }
        return acc;
      }, {});
      if (context === 'shifts') {
        set(prev => ({
          selectedShiftItems: { ...prev.selectedShiftItems, ...itemsMap },
        }));
      } else {
        set(prev => ({
          selectedItems: { ...prev.selectedItems, ...itemsMap },
        }));
      }
    }
  },
});

export const useActionItemsStore = create(
  subscribeWithSelector(actionItemsStore),
);

useActionItemsStore.subscribe(
  store => store.selectedItems,
  items => {
    useActionItemsStore.setState({
      selectedItemsArray: Object.values(items),
      selectedItemsUuidArray: Object.keys(items),
    });
    if (Object.keys(items).length === 0) {
      useActionItemsStore.setState({ prevSelectedRowIndex: -1 });
    }
  },
);

useActionItemsStore.subscribe(
  store => store.selectedShiftItems,
  items => {
    useActionItemsStore.setState({
      selectedShiftItemsArray: Object.values(items),
      selectedShiftItemsUuidArray: Object.keys(items),
    });
  },
);
