import { _PAGING_PARAMS, _PAGING_VARIABLES } from '../_paging';

export const _USERS_PARAMS = `
  $acquisitionCampaigns: [String]
  $activatedBy: [String]
  $attributionCodes: [String]
  $canScheduleCall: Boolean
  $excludeAttributeDormant: Boolean
  $excludeFavoriteAt: [String]
  $excludeHasWorkedAt: [String]
  $excludeShifts: [String]
  $favoriteAt: [String]
  $flags: [String]
  $hasAddress: Boolean
  $hasExperience: Boolean
  $hasLocationRelation: Boolean
  $hasProfileImage: Boolean
  $hasWorked: Boolean
  $hasWorkedAt: [String]
  $isBooked: Boolean
  $isFlagged: Boolean
  $locations: [String]
  $market: Int
  $markets: [Int]
  $metabaseQuestion: String
  $notWorkedSince: Date
  $onboardedBy: [String]
  $organization: String
  $organizations: [String]
  $retailers: [String]
  $reviewPrioritizationAvgMax: Float
  $reviewPrioritizationAvgMin: Float
  $reviewRatingAvgMax: Float
  $reviewRatingAvgMin: Float
  $role: [String]
  $shiftsCompletedMax: Int
  $shiftsCompletedMin: Int
  $since: Date
  $status: [String]
  $tags: [Int]
  $tagsMissing: [Int]
  $tier: [String]
  $to: Date
  $uuid: String
  $uuids: [String]
  $withAccessTo: [String]
  $workedSince: Date
  $zipcode: String
  $zipcodeMaxDistance: Float
  $zipcodeMinDistance: Float
  ${_PAGING_PARAMS}
`;

export const _USERS_VARIABLES = `
  acquisitionCampaigns: $acquisitionCampaigns
  activatedBy: $activatedBy
  attributionCodes: $attributionCodes
  canScheduleCall: $canScheduleCall
  excludeAttributeDormant: $excludeAttributeDormant
  excludeFavoriteAt: $excludeFavoriteAt
  excludeHasWorkedAt: $excludeHasWorkedAt
  excludeShifts: $excludeShifts
  favoriteAt: $favoriteAt
  flags: $flags
  hasAddress: $hasAddress
  hasExperience: $hasExperience
  hasLocationRelation: $hasLocationRelation
  hasProfileImage: $hasProfileImage
  hasWorked: $hasWorked
  hasWorkedAt: $hasWorkedAt
  isBooked: $isBooked
  isFlagged: $isFlagged
  locations: $locations
  market: $market
  markets: $markets
  metabaseQuestion: $metabaseQuestion
  notWorkedSince: $notWorkedSince
  onboardedBy: $onboardedBy
  organization: $organization
  organizations: $organizations
  retailers: $retailers
  reviewPrioritizationAvgMax: $reviewPrioritizationAvgMax
  reviewPrioritizationAvgMin: $reviewPrioritizationAvgMin
  reviewRatingAvgMax: $reviewRatingAvgMax
  reviewRatingAvgMin: $reviewRatingAvgMin
  role: $role
  shiftsCompletedMax: $shiftsCompletedMax
  shiftsCompletedMin: $shiftsCompletedMin
  since: $since
  status: $status
  tags: $tags
  tagsMissing: $tagsMissing
  tier: $tier
  to: $to
  uuid: $uuid
  uuids: $uuids
  withAccessTo: $withAccessTo
  workedSince: $workedSince
  zipcode: $zipcode
  zipcodeMaxDistance: $zipcodeMaxDistance
  zipcodeMinDistance: $zipcodeMinDistance
  ${_PAGING_VARIABLES}
`;

export const _PERMISSION_USERS_CORE = `
  uuid
  firstName
  lastName
  phoneNumber
  email
  preferredName
  profileImg
  pronouns
  status
  title
`;
