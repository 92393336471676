import {
  CommonStore,
  commonStore,
  defaultCommonStoreState,
  sortGenerator,
} from 'helpers';
import { PageSizes, Prettify, shift, SortState, user } from 'types';
import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';

export const ShiftsSortOptions = {
  start: 'Start',
  retailer_location__market__name: 'Market',
  retailer_location__name: 'Location',
} as const;

export type ShiftsStoreBase = {
  activeShift?: shift;
  setActiveShift: (shift: shift) => void;
  selectedShifts?: Record<string, shift>;
  selectedShiftsArray?: shift[];
  selectedShiftsUuidArray?: string[];
  updateSelectedShifts: (shift: shift, action: 'add' | 'remove') => void;
  setSelectedShifts: (shifts: Record<string, user>) => void;
  clearSelectedShifts: () => void;
  selectedUsers?: Record<string, user>;
  selectedUsersArray?: user[];
  selectedUsersUuidArray?: string[];
  updateSelectedUsers: (user: user, action: 'add' | 'remove') => void;
  setSelectedUsers: (users: Record<string, user>) => void;
  clearSelectedUsers: () => void;
  prevSelectedRowIndex: number;
  setPrevSelectedRowIndex: (index: number) => void;
};

export type ShiftsStore = Prettify<
  CommonStore<typeof ShiftsSortOptions> & ShiftsStoreBase
>;

const defaultState = {
  activeShift: {},
  sort: {
    ...sortGenerator(SortState.NONE, ShiftsSortOptions),
    start: SortState.ASC,
  },
  pagination: {
    ...defaultCommonStoreState.pagination,
    pageSize: PageSizes.twentyFive,
  },
  selectedShifts: {},
  selectedShiftsArray: [],
  selectedShiftsUuidArray: [],
  selectedUsers: {},
  selectedUsersArray: [],
  selectedUsersUuidArray: [],
  prevSelectedRowIndex: -1,
};

const ShiftsStore = (set, get): ShiftsStore => ({
  ...defaultCommonStoreState,
  ...commonStore(set),
  ...defaultState,
  setActiveShift: shift => set({ activeShift: shift }),
  updateSelectedShifts: (shift, action) => {
    if (action === 'add') {
      set(prev => ({
        selectedShifts: {
          ...prev.selectedShifts,
          [shift.uuid]: shift,
        },
      }));
    } else {
      const currentShifts = get().selectedShifts;
      const newShifts = {};
      Object.keys(currentShifts).forEach(key => {
        if (key !== shift?.uuid) newShifts[key] = currentShifts[key];
      });
      set({ selectedShifts: newShifts });
    }
  },
  setSelectedShifts: shifts => set({ selectedShifts: shifts }),
  clearSelectedShifts: () => set({ selectedShifts: {} }),
  updateSelectedUsers: (user, action) => {
    if (action === 'add') {
      set(prev => ({
        selectedUsers: {
          ...prev.selectedUsers,
          [user.uuid]: user,
        },
      }));
    } else {
      const currentUsers = get().selectedUsers;
      const newUsers = {};
      Object.keys(currentUsers).forEach(key => {
        if (key !== user?.uuid) newUsers[key] = currentUsers[key];
      });
      set({ selectedUsers: newUsers });
    }
  },
  setSelectedUsers: users => set({ selectedUsers: users }),
  clearSelectedUsers: () => set({ selectedUsers: {} }),
  setPrevSelectedRowIndex: index => set({ prevSelectedRowIndex: index }),
});

export const useShiftsStore = create(
  subscribeWithSelector(devtools(ShiftsStore)),
);

useShiftsStore.subscribe(
  store => store.selectedShifts,
  shifts => {
    useShiftsStore.setState({
      selectedShiftsArray: Object.values(shifts),
      selectedShiftsUuidArray: Object.keys(shifts),
    });
  },
);

useShiftsStore.subscribe(
  store => store.selectedUsers,
  users => {
    useShiftsStore.setState({
      selectedUsersArray: Object.values(users),
      selectedUsersUuidArray: Object.keys(users),
    });
  },
);
