import { FC, ReactElement } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionButtonProps,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionPanelProps,
  AccordionProps,
} from '@chakra-ui/react';

interface SimpleAccordionProps {
  items: Array<{
    id: string;
    title: ReactElement;
    panel: ReactElement;
  }>;
  containerProps?: AccordionProps;
  buttonProps?: AccordionButtonProps;
  panelProps?: AccordionPanelProps;
}

export const SimpleAccordion: FC<SimpleAccordionProps> = ({
  items,
  containerProps,
  buttonProps,
  panelProps,
}) => {
  return (
    <Accordion {...containerProps}>
      {items.map(item => (
        <AccordionItem key={item.id}>
          <AccordionButton {...buttonProps}>
            {item.title}
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel {...panelProps}>{item.panel}</AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};
