import { FC } from 'react';
import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import { Icon } from 'icons';
import { graphMessage } from 'types/src/api';
import { GQLResponseStatus } from 'types/src/message';
import { MotionBox } from 'ui';
import { SimpleAccordion } from 'components/common/SimpleAccordion';

export interface GraphResponseProps {
  messages?: Array<graphMessage>;
  headingText?: string;
  dropdownText?: string;
  successDetail?: string;
  gqlResponseLevel?: GQLResponseStatus;
  testId?: string;
  containerProps?: BoxProps;
}

interface ResponseStatusIconProps {
  gqlResponseLevel: GQLResponseStatus;
}

const ResponseStatusIcon: FC<ResponseStatusIconProps> = ({
  gqlResponseLevel,
}) => {
  switch (gqlResponseLevel) {
    case GQLResponseStatus.SUCCESS:
      return (
        <>
          <Box
            width="100%"
            height="100%"
            backgroundColor="green.100"
            position="absolute"
            top="0"
            left="0"
            borderRadius="full"
            zIndex={0}
          />
          <Icon
            name="check"
            color="green.500"
            position="relative"
            zIndex={1}
            w={6}
            h={6}
          />
        </>
      );
    case GQLResponseStatus.PARTIAL:
      return (
        <>
          <Box
            width="100%"
            height="100%"
            backgroundColor="yellow.100"
            position="absolute"
            top="0"
            left="0"
            borderRadius="full"
            zIndex={0}
          />
          <Icon
            name="check"
            color="yellow.500"
            position="relative"
            zIndex={1}
            w={6}
            h={6}
          />
        </>
      );
    case GQLResponseStatus.ERROR:
    case GQLResponseStatus.FAILED:
      return (
        <>
          <Box
            width="100%"
            height="100%"
            backgroundColor="red.100"
            position="absolute"
            top="0"
            left="0"
            borderRadius="full"
            zIndex={0}
          />
          <Icon
            name="exclamation"
            color="red.500"
            position="relative"
            zIndex={1}
            w={6}
            h={6}
          />
        </>
      );
    default:
      return <></>;
  }
};

export const GraphResponse: FC<GraphResponseProps> = ({
  messages,
  headingText = 'Success!',
  successDetail = '',
  dropdownText = 'Errors:',
  gqlResponseLevel = GQLResponseStatus.SUCCESS,
  testId,
  containerProps,
}) => {
  return (
    <Flex
      flexGrow={1}
      minHeight="320px"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      data-testid={testId}
      {...containerProps}
    >
      <Flex flexDirection="row">
        <MotionBox
          initial={{ opacity: 0, marginTop: '48px' }}
          animate={{ opacity: 1, marginTop: '4px' }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.2 }}
          position="relative"
          width={6}
          height={6}
          mr={2}
          display="flex"
          flexDirection="column"
        >
          <ResponseStatusIcon gqlResponseLevel={gqlResponseLevel} />
        </MotionBox>
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.5 }}
        >
          <Text fontSize="lg" fontWeight="bold" pt={0.5}>
            {headingText}
          </Text>
        </MotionBox>
      </Flex>
      <MotionBox
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.85 }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
      >
        <Text color="text.secondary" textAlign="center">
          {successDetail}
        </Text>
      </MotionBox>
      {!!dropdownText && messages?.length > 0 && (
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 1.25 }}
          mt={6}
          display="flex"
          flexDirection="column"
          borderStyle="solid"
          borderTopWidth={1}
          borderColor="main.light"
          width="90%"
        >
          <SimpleAccordion
            containerProps={{
              allowMultiple: true,
              borderColor: 'transparent',
              width: '100%',
            }}
            buttonProps={{
              color: 'text.hint',
              ml: 0,
              alignSelf: 'flex-start',
            }}
            items={[
              {
                id: 'workers',
                title: (
                  <Text color="text.hint" textAlign="left" flexGrow={1}>
                    {dropdownText}
                  </Text>
                ),
                panel: (
                  <Flex
                    flexDirection="column"
                    width="100%"
                    overflowX="hidden"
                    fontSize="xs"
                    px={2}
                    color="text.secondary"
                  >
                    {messages.map(message => (
                      <Flex key={message?.description}>
                        <Text fontWeight="semi" mr={2}>
                          {message?.code}
                        </Text>
                        <Text>{message?.description}</Text>
                      </Flex>
                    ))}
                  </Flex>
                ),
              },
            ]}
          />
        </MotionBox>
      )}
    </Flex>
  );
};
