const _ESSENTIAL_FIELDS = `
  uuid
  email
  isRetail
  isWorker
  isSupport
  isStaff
  status
  requiresPasswordUpdate
  permissionTypes
  organization { uuid }
  firstName
  lastName
  profileImg
  locationPermissionsMap
  hasMultipleBrands
`;

export const USER_ME_ESSENTIAL = `
  query usersQuery {
    me {
      ${_ESSENTIAL_FIELDS}
    }
  }
`;

export const USER_ME_ESSENTIAL_PLUS_ONBOARDING = `
  query usersQuery {
    me {
      ${_ESSENTIAL_FIELDS}
      onboardingDate
    }
  }
`;

export const USER_UUID_QUERY = `
  query usersQuery($uuid: String, $id: Int) {
    users(uuid: $uuid, id: $id) {
      uuid
      id
      isRetail
      isWorker
    }
  }
`;

export const USERS_QUERY = `
  query USERS_QUERY(
    $uuid: String
    $uuids: [String]
    $role: [String]
    $status: [String]
  ) {
    users (
      uuid: $uuid
      uuids: $uuids
      role: $role
      status: $status
    ) { 
      uuid
      status
      lastLogin
      firstName
      lastName
      preferredName
      profileImg
      phoneNumber
      email
      isWorker
      isRetail
      isW2PayproviderComplete
      is1099PayproviderComplete
      isBackcheckComplete
      hoursThisWeek
      totalShiftsCompleted
      lastShiftDate
    }
  }
`;

export const USER_ME_IDENTITY_EVENT_DATA = `
  query usersQuery {
    me {
      uuid
      firstName
      lastName
      email
      id
      profileImg
      phoneNumber
      status
      onboardingDate
      eritId
      isTextSubscribed
      isWorker
      isSupport
      isRetail
      markets {
        name
      }
    }
  }
`;
