import { FC } from 'react';
import { Text } from '@chakra-ui/react';

interface PageTitleProps {
  text: string;
}

export const PageTitle: FC<PageTitleProps> = ({ text }) => {
  return (
    <Text
      display="flex"
      fontSize={{ base: '2xl', md: '3xl' }}
      fontWeight="semi"
      lineHeight="none"
    >
      {text}
    </Text>
  );
};
