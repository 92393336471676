import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const MailPlus: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.573 13.706 21 7.808v4.08h2v-6c0-1.653-1.348-3-3-3H4c-1.652 0-3 1.347-3 3v12c0 1.651 1.348 3 3 3h8.05v-2H4c-.548 0-1-.453-1-1V7.807l8.427 5.898a1 1 0 0 0 1.146 0ZM12 11.666 3.107 5.442c.165-.327.505-.554.893-.554h16c.388 0 .728.227.893.554L12 11.667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 13a1 1 0 0 1 1 1v8a1 1 0 1 1-2 0v-8a1 1 0 0 1 1-1Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 18a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1Z"
      fill="currentColor"
    />
  </Icon>
);

MailPlus.displayName = 'MailPlusIcon';
