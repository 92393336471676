import { USER_TOKEN } from 'types/src/config';
import { MAINTENANCE_PAGE } from 'types/src/routes';
import packageJSON from '../../../package.json';

interface MetaHeaders {
  'reflex-platform': string;
  'reflex-version': string;
  'reflex-request-page': string;
}

export const getMetaHeaders = (): MetaHeaders => ({
  'reflex-platform': 'web',
  'reflex-version': packageJSON?.version,
  'reflex-request-page':
    typeof window === 'undefined'
      ? 'server-side'
      : window?.location?.href || 'no-page',
});

// allows us to be provider agnostic and use any promise-based request lib. Change as needed
// Can (in theory) be used in both client and server environments. Avoid client specific methods
const HTTP_CLIENT_LIB = global.fetch;

interface BaseRequestOptions {
  headers?: HeadersInit;
  body?: JSON;
}

/**
 * @function getCookieIfPossible
 * @summary retrieves cookie if running in browser and cookie exists
 * @param {string} cookieName
 * @returns {string || null}
 */
export const getCookieIfPossible = (cookieName: string) => {
  // only run in browser and if cookie exists
  if (!globalThis.document) return;
  if (!globalThis.document.cookie) return;

  const cookie = globalThis.document.cookie
    .split('; ')
    .find(row => row.startsWith(`${cookieName}=`));
  if (!cookie) return;
  return cookie.split('=')[1];
};

/**
 * @function redirectIfMaintenanceMode
 * @summary Promise-chainable fuction to redirect to maintenance page
 * @param {Response}
 * @returns {Promise<Response>}
 */
export const redirectIfMaintenanceMode = (res: Response): Promise<Response> => {
  const REFLEX_MAINTENANCE_MODE_HTTP_STATUS = 512;
  if (res.status == REFLEX_MAINTENANCE_MODE_HTTP_STATUS) {
    if (global.window) {
      window.location.href = MAINTENANCE_PAGE;
      return Promise.reject(res);
    }
  }
  return Promise.resolve(res);
};

const getDefaultHeaders = () => {
  const defaultHeaders = new Headers({
    'Content-Type': 'application/json',
    ...getMetaHeaders(),
  });

  // If calling in browser, add reflex cookie
  if (getCookieIfPossible(USER_TOKEN)) {
    defaultHeaders.append(
      'X-Authorization',
      `Token ${getCookieIfPossible(USER_TOKEN)}`,
    );
    defaultHeaders.append(
      'Authorization',
      `Token ${getCookieIfPossible(USER_TOKEN)}`,
    );
  }

  return defaultHeaders;
};

const BaseAPI = {
  /**
   * @function delete
   * @summary Wrapper for DELETE HTTP calls
   * @param {string} url required.
   * @param {BaseRequestOptions} options
   * @returns {Promise} fetcher
   */
  delete: (url, options): Promise<Response> => {
    return HTTP_CLIENT_LIB(url, {
      method: 'DELETE',
      headers: options?.headers
        ? new Headers({
            ...getDefaultHeaders(),
            ...options.headers,
          })
        : getDefaultHeaders(),
      // must match type in content-type header.
      body: options?.body ? JSON.stringify(options.body) : null,
    });
  },

  /**
   * @function get
   * @summary Wrapper for GET HTTP calls
   * @param {string} url required.
   * @param {BaseRequestOptions} options
   * @returns {Promise} fetcher
   */
  get: (url: string, options?: BaseRequestOptions): Promise<Response> => {
    return HTTP_CLIENT_LIB(url, {
      method: 'GET',
      headers: options?.headers
        ? new Headers({
            ...getDefaultHeaders(),
            ...options.headers,
          })
        : getDefaultHeaders(),
      // must match type in content-type header.
      body: options?.body ? JSON.stringify(options.body) : null,
    });
  },

  /**
   * @function gql
   * @summary Util function for GraphQL queries and mutations
   * @param {string} url required.
   * @param {string} query required.
   * @param {object} variables optional.
   * @returns {Promise} fetcher
   */
  gql: (
    url: string,
    query: string,
    variables?: object,
    options?: BaseRequestOptions,
  ): Promise<Response> => {
    return HTTP_CLIENT_LIB(url, {
      method: 'POST',
      headers: options?.headers
        ? new Headers({
            ...getDefaultHeaders(),
            ...options.headers,
          })
        : getDefaultHeaders(),
      body: JSON.stringify({
        query,
        variables,
      }),
    });
  },

  /**
   * @function options
   * @summary Wrapper for OPTIONS HTTP calls
   * @param {string} url required.
   * @param {BaseRequestOptions} options
   * @returns {Promise} fetcher
   */
  options: (url, options): Promise<Response> => {
    return HTTP_CLIENT_LIB(url, {
      method: 'OPTIONS',
      headers: options?.headers
        ? new Headers({
            ...getDefaultHeaders(),
            ...options.headers,
          })
        : getDefaultHeaders(),
      // must match type in content-type header.
      body: options?.body ? JSON.stringify(options.body) : null,
    });
  },

  /**
   * @function patch
   * @summary Wrapper for PATCH HTTP calls
   * @param {string} url required.
   * @param {BaseRequestOptions} options
   * @returns {Promise} fetcher
   */
  patch: (url, options): Promise<Response> => {
    return HTTP_CLIENT_LIB(url, {
      method: 'PATCH',
      headers: options?.headers
        ? new Headers({
            ...getDefaultHeaders(),
            ...options.headers,
          })
        : getDefaultHeaders(),
      // must match type in content-type header.
      // FUTURE: case statement to avoid stringifying blob type data for file uploads
      body: options?.body ? JSON.stringify(options.body) : null,
    });
  },

  /**
   * @function post
   * @summary Wrapper for POST HTTP calls
   * @param {string} url required.
   * @param {BaseRequestOptions} options
   * @returns {Promise} fetcher
   */
  post: (url, options): Promise<Response> => {
    return HTTP_CLIENT_LIB(url, {
      method: 'POST',
      headers: options?.headers
        ? new Headers({
            ...getDefaultHeaders(),
            ...options.headers,
          })
        : getDefaultHeaders(),
      // must match type in content-type header.
      // FUTURE: case statement to avoid stringifying blob type data for file uploads
      body: options?.body ? JSON.stringify(options.body) : null,
    });
  },

  /**
   * @function put
   * @summary Wrapper for PUT HTTP calls
   * @param {string} url required.
   * @param {BaseRequestOptions} options
   * @returns {Promise} fetcher
   */
  put: (url, options): Promise<Response> => {
    return HTTP_CLIENT_LIB(url, {
      method: 'PUT',
      headers: options?.headers
        ? new Headers({
            ...getDefaultHeaders(),
            ...options.headers,
          })
        : getDefaultHeaders(),
      // must match type in content-type header.
      // FUTURE: case statement to avoid stringifying blob type data for file uploads
      body: options?.body ? JSON.stringify(options.body) : null,
    });
  },
};

export default BaseAPI;
export const _gql = BaseAPI.gql;
export const _get = BaseAPI.get;
export const _post = BaseAPI.post;
export const _put = BaseAPI.put;
export const _patch = BaseAPI.patch;
export const _delete = BaseAPI.delete;
export const _options = BaseAPI.options;
