export const selectColors = {
  backgroundHover: 'main.light',
  backgroundSelected: 'blackAlpha.200',
  backgroundActive: 'main.medium',
  textActive: 'text.secondary',
  textSelected: 'text.secondary',
  tagDefault: 'main.light',
};

export const selectStyles = (provided, state) => ({
  clearIndicator: {
    ...provided,
    '--close-button-size': '28px',
    fontSize: '10px',
  },
  container: {
    ...provided,
    width: '100%',
  },
  control: {
    ...provided,
  },
  crossIcon: {
    ...provided,
  },
  dropdownIndicator: {
    ...provided,
    px: 3,
  },
  downChevron: {
    ...provided,
  },
  group: {
    provided,
  },
  groupHeading: {
    ...provided,
  },
  indicatorsContainer: {
    ...provided,
  },
  indicatorSeparator: {
    ...provided,
    borderColor: 'main.light',
    maxHeight: '20px',
  },
  input: {
    ...provided,
    gridArea: 'inherit', // LATER: fix safari input with better approach
  },
  inputContainer: {
    ...provided,
  },
  loadingIndicator: {
    ...provided,
  },
  loadingMessage: {
    ...provided,
  },
  menu: {
    ...provided,
  },
  menuList: {
    ...provided,
    minW: 8,
  },
  multiValue: {
    ...provided,
    backgroundColor: state.data?.isFixed ? selectColors.backgroundActive : null,
    color: state.data?.isFixed ? 'text.primary' : null,
  },
  multiValueLabel: { ...provided },
  multiValueRemove: { ...provided },
  noOptionsMessage: { ...provided },
  option: {
    ...provided,
    color: state.isSelected ? selectColors.textSelected : null,
    backgroundColor: state.isSelected ? selectColors.backgroundSelected : null,
    fontWeight: 'medium',
    _hover: {
      backgroundColor: state.isSelected ? null : selectColors.backgroundHover,
    },
    _active: { backgroundColor: selectColors.backgroundActive },
  },
  placeholder: {
    ...provided,
  },
  singleValue: {
    ...provided,
  },
  valueContainer: {
    ...provided,
    display: 'flex',
    pt: state.hasValue && !state.isMulti ? 1 : null,
    pl: state.hasValue ? 2 : null,
  },
});

export const selectStyleOverrides = {
  clearIndicator: (provided, state) =>
    selectStyles(provided, state).clearIndicator,
  container: (provided, state) => selectStyles(provided, state).container,
  dropdownIndicator: (provided, state) =>
    selectStyles(provided, state).dropdownIndicator,
  indicatorSeparator: (provided, state) =>
    selectStyles(provided, state).indicatorSeparator,
  input: (provided, state) => selectStyles(provided, state).input,
  menu: (provided, state) => selectStyles(provided, state).menu,
  menuList: (provided, state) => selectStyles(provided, state).menuList,
  multiValue: (provided, state) => selectStyles(provided, state).multiValue,
  option: (provided, state) => selectStyles(provided, state).option,
  valueContainer: (provided, state) =>
    selectStyles(provided, state).valueContainer,
};
