import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

const _OZ_NOTIFICATION = `
  uuid
  timestamp
  title
  icon
  subtext
  link
  status
`;

export const OZ_NOTIFICATIONS_QUERY = `
  query OZ_NOTIFICATIONS_QUERY(${_PAGING_PARAMS}) {
    ozNotifications(${_PAGING_VARIABLES}) {
      ${_OZ_NOTIFICATION}
    }
  }
`;
