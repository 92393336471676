import { FC } from 'react';
import { ActionState, useOverbookStore, useShiftStore } from 'utils/stores';
import { QueryParamModal, useViewStore } from 'utils/stores/ViewStore';
import { Modal } from 'components/common/Modal';
import { OverbookChoice } from './OverbookChoice';
import { OverbookConfirm } from './OverbookConfirm';
import { OverbookFinale } from './OverbookFinale';
import { OverbookShiftForm } from './OverbookShiftForm';

export const OverbookModalContainer: FC = () => {
  const [modalOpen, closeModal] = useViewStore(s => [
    s.modalOpen,
    s.closeModal,
  ]);
  const [meta, resetStore] = useOverbookStore(s => [s.meta, s.resetStore]);
  const [shift] = useShiftStore(s => [s.shift]);

  const handleCloseModal = () => {
    closeModal();
    resetStore();
  };

  return (
    <Modal
      isOpen={modalOpen === QueryParamModal.OVERBOOK_TOOLS}
      onClose={handleCloseModal}
      size={
        [
          ActionState.FINISHED,
          ActionState.CHOICE,
          ActionState.CONFIRM,
          ActionState.SELECT,
        ].includes(meta.actionState)
          ? 'sm'
          : 'lg'
      }
    >
      {[ActionState.CHOICE, ActionState.SELECT].includes(meta.actionState) && (
        <OverbookChoice />
      )}
      {meta.actionState === ActionState.FORM && (
        <OverbookShiftForm defaultLocation={shift?.retailerLocation} />
      )}
      {meta.actionState === ActionState.CONFIRM && <OverbookConfirm />}
      {meta.actionState === ActionState.FINISHED && <OverbookFinale />}
    </Modal>
  );
};
