export enum OzNotificationReceiptStatus {
  UNREAD = 'UNREAD',
  READ = 'READ',
  DISMISSED = 'DISMISSED',
}

export enum OzNotificationType {
  ACTION_ITEM = 'ACTION_ITEM',
  RETAILER = 'RETAILER',
  LOCATION = 'LOCATION',
  ONBOARDING_SCHEDULE = 'ONBOARDING_SCHEDULE',
  SHIFT = 'SHIFT',
  USER = 'USER',
}

export type ozNotification = {
  uuid: string;
  paths?: string[];
  isRead?: boolean;
  timestamp: string;
  title?: string;
  icon?: string;
  subtext?: string;
  link?: string;
  status?: OzNotificationReceiptStatus;
};
