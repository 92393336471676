import { FC } from 'react';
import { BoxProps, Flex, Tooltip } from '@chakra-ui/react';
import { Icon } from 'icons';
import {
  LocationPermission,
  LocationPermissionIcons,
  LocationPermissionLabels,
  userPermissionMap,
} from 'types';

const iconStyles = (isActive: boolean, activeColor = 'main.concrete') => ({
  h: '18px',
  w: '18px',
  color: 'blackAlpha.200',
  ...(isActive && {
    color: activeColor,
  }),
});

interface PermissionsInlineProps {
  containerProps?: BoxProps;
  permissions?: userPermissionMap;
  ignorePermissions?: LocationPermission[];
  iconActiveColor?: string;
}

// FUTURE: implement hover over each for details
export const PermissionsInline: FC<PermissionsInlineProps> = ({
  containerProps,
  permissions,
  ignorePermissions,
  iconActiveColor,
}) => {
  return (
    <Flex gap={1.5} position="relative" {...containerProps}>
      {Object.keys(permissions).map(key => {
        if (ignorePermissions?.includes(key as LocationPermission)) return null;
        return (
          <Tooltip
            key={key}
            label={LocationPermissionLabels[key]}
            gutter={0}
            placement="top"
            shouldWrapChildren
          >
            <Icon
              key={key}
              name={LocationPermissionIcons[key]}
              {...iconStyles(permissions[key] === true, iconActiveColor)}
            />
          </Tooltip>
        );
      })}
    </Flex>
  );
};
