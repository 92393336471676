const _LOCATION_PERMISSION_VARS = `
  $users: [String]
  $locations: [String]
  $permissionTypes: [String]
`;
const _LOCATION_PERMISSION_PARAMS = `
  users: $users
  locations: $locations
  permissionTypes: $permissionTypes
`;

export const ADD_LOCATION_PERMISSIONS = `
  mutation ADD_LOCATION_PERMISSIONS(${_LOCATION_PERMISSION_VARS}) {
    addLocationPermissions(${_LOCATION_PERMISSION_PARAMS}) {
      ok
      messages {
        code
        
      }
    }
  }
`;

export const REMOVE_LOCATION_PERMISSIONS = `
  mutation REMOVE_LOCATION_PERMISSIONS(${_LOCATION_PERMISSION_VARS}) {
    removeLocationPermissions(${_LOCATION_PERMISSION_PARAMS}) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const BULK_EDIT_ORGANIZATION_LOCATION_PERMISSIONS = `
  mutation BULK_EDIT_ORGANIZATION_LOCATION_PERMISSIONS(
    $users: [String]
    $permissionTypes: [String]
  ) {
    bulkEditOrganizationLocationPermissions(
      users: $users
      permissionTypes: $permissionTypes
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const REMOVE_ALL_LOCATION_PERMISSIONS = `
  mutation REMOVE_ALL_LOCATION_PERMISSIONS($users: [String]) {
    removeAllLocationPermissions(users: $users) {
      ok
      messages {
        code
        description
      }
    }
  }
`;
