import { ICON_NAMES } from 'icons';
import {
  OPS_ACTION_SHIFTS,
  OPS_ACTIVATE_WORKERS,
  OPS_BRANDS,
  OPS_BUDGETS,
  OPS_CAMPAIGNS,
  OPS_CODES,
  OPS_LOCATIONS,
  OPS_ONBOARDINGS,
  OPS_REVIEWS,
  OPS_SETTINGS,
  OPS_SHIFTS,
  OPS_TAGS,
  OPS_TASKS,
  OPS_USERS,
  OPS_WIZARD,
} from 'types/src/routes';

interface NavOption {
  label: string;
  aka?: string;
  icon?: ICON_NAMES;
  href?: string;
  desktopHidden?: boolean;
  homeTile?: boolean;
}

export const navOptions: NavOption[] = [
  {
    label: 'Flexes',
    aka: 'Flex Board',
    href: OPS_SHIFTS,
    icon: ICON_NAMES.sparkle,
    homeTile: true,
  },
  {
    label: 'Flex Tasks',
    href: OPS_ACTION_SHIFTS,
    desktopHidden: true,
    icon: ICON_NAMES.sparkleplus,
    homeTile: true,
  },
  {
    label: 'Worker Tasks',
    href: OPS_TASKS,
    desktopHidden: true,
    icon: ICON_NAMES.clipboardcheck,
    homeTile: true,
  },
  {
    label: 'Users',
    href: OPS_USERS,
    icon: ICON_NAMES.useralt,
    homeTile: true,
  },
  {
    label: 'Workers: Activate',
    aka: 'Activate',
    href: OPS_ACTIVATE_WORKERS,
    desktopHidden: true,
    icon: ICON_NAMES.useradd,
    homeTile: true,
  },
  {
    label: 'Locations',
    href: OPS_LOCATIONS,
    icon: ICON_NAMES.storefrontalt,
    homeTile: true,
  },
  {
    label: 'Brands',
    href: OPS_BRANDS,
    icon: ICON_NAMES.shoppingbag,
    homeTile: true,
  },
  {
    label: 'Budgets',
    href: OPS_BUDGETS,
    desktopHidden: true,
    icon: ICON_NAMES.chatgroup,
    homeTile: false,
  },
  {
    label: 'Reviews',
    href: OPS_REVIEWS,
    icon: ICON_NAMES.star,
    homeTile: true,
  },
  {
    label: 'Onboardings',
    href: OPS_ONBOARDINGS,
    desktopHidden: true,
    icon: ICON_NAMES.rocket,
    homeTile: true,
  },
  {
    label: 'Tags',
    href: OPS_TAGS,
    desktopHidden: true,
    icon: ICON_NAMES.tag,
    homeTile: false,
  },
  {
    label: 'Campaigns',
    href: OPS_CAMPAIGNS,
    desktopHidden: true,
    icon: ICON_NAMES.documentsearch,
    homeTile: false,
  },
  {
    label: 'Codes',
    href: OPS_CODES,
    desktopHidden: true,
    icon: ICON_NAMES.code,
  },
  {
    label: 'Wizard',
    href: OPS_WIZARD,
    desktopHidden: true,
    icon: ICON_NAMES.sparkles,
  },
  {
    label: 'Settings',
    href: OPS_SETTINGS,
    desktopHidden: true,
    icon: ICON_NAMES.cog,
    homeTile: true,
  },
];
