import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

const _TAGS_PARAMS = `
  $id: Int
  $name: String
  $createdSince: Date
  $createdTo: Date
  $createdBy: [String]
  $users: [String]
  $isArchived: Boolean
  $isSystem: Boolean
  $isListVisible: Boolean
  ${_PAGING_PARAMS}
`;
const _TAGS_VARIABLES = `
  id: $id
  name: $name
  createdSince: $createdSince
  createdTo: $createdTo
  createdBy: $createdBy
  users: $users
  isArchived: $isArchived
  isSystem: $isSystem
  isListVisible: $isListVisible
  ${_PAGING_VARIABLES}
`;

export const TAGS_LIST_QUERY = `
  query TAGS_LIST_QUERY (${_TAGS_PARAMS}) {
    tags (${_TAGS_VARIABLES}) {
      id
      name
    }
  }
`;

export const TAGS_FULL_QUERY = `
  query TAGS_FULL_QUERY (${_TAGS_PARAMS}) {
    tags (${_TAGS_VARIABLES}) {
      id
      name
      description
      updatedAt
      createdAt
      createdBy {
        uuid
        firstName
        lastName
      }
      isArchived
      isSystem
      isListVisible
      userCount
    }
  }
`;
