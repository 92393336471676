import { FC } from 'react';
import { Button, Flex, FlexProps, Text } from '@chakra-ui/react';
import { fetchCampaigns, fetchCampaignsProps, Queries } from 'helpers';
import { acquisitionCampaign, DataKeys } from 'types';
import { SelectList, SelectListProps } from 'components/common/SelectList';

interface SelectAcquisitionCampaignItemProps extends acquisitionCampaign {
  onSelect?: (campaign: acquisitionCampaign) => void;
  selectButtonText?: string;
  containerProps?: FlexProps;
}

const SelectAcquisitionCampaignItem = ({
  onSelect,
  selectButtonText = 'Select',
  containerProps,
  ...campaign
}: SelectAcquisitionCampaignItemProps) => {
  return (
    <Flex py={2} px={4} alignItems="center" {...containerProps}>
      <Text flexGrow={1}>{campaign?.name}</Text>
      <Button
        size="sm"
        variant="transparent"
        onClick={() => {
          !!onSelect && onSelect(campaign);
        }}
      >
        {selectButtonText}
      </Button>
    </Flex>
  );
};

export const SelectAcquisitionCampaignList: FC<
  SelectListProps<acquisitionCampaign, fetchCampaignsProps>
> = ({ onSelect, selectButtonText = 'Select', ...props }) => {
  return (
    <SelectList<acquisitionCampaign, fetchCampaignsProps>
      query={Queries.CAMPAIGNS_LIST_QUERY}
      dataKey={DataKeys.CAMPAIGNS}
      fetchFunction={fetchCampaigns}
      pagingKey="acquisitionCampaigns_paging"
      queryVariables={{
        orderBy: 'name',
      }}
      itemComponent={
        <SelectAcquisitionCampaignItem
          onSelect={onSelect}
          selectButtonText={selectButtonText}
        />
      }
      onSelect={onSelect}
      {...props}
    />
  );
};
