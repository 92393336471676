import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DataKeys, ReflexResponse } from 'types';

export const useDataMutation = <T>(
  mutationFunction: (update: T) => Promise<ReflexResponse>,
  cacheKeys: Array<DataKeys | string> = [],
  useExtraInvalidation = true,
) => {
  const queryClient = useQueryClient();

  const mutate = useMutation((update: T) => mutationFunction(update), {
    retry: 0,
    onSuccess: () =>
      invalidateCache(cacheKeys, queryClient, useExtraInvalidation),
  });

  return {
    invalidateCache,
    mutate,
  };
};

export const invalidateCache = (
  cacheKeys: Array<DataKeys | string>,
  queryClient: any,
  useExtraInvalidation = true,
) => {
  if (!queryClient || !cacheKeys || !cacheKeys?.length) return;
  cacheKeys.forEach(key => {
    queryClient.invalidateQueries({ queryKey: [key] });
  });
  // FUTURE: remove need for second technique
  // react-query sometimes doesn't play nicely with the above
  // so we ensure key is invalided by second technique
  // this can sometimes lead to duplicated network calls
  if (useExtraInvalidation) {
    queryClient.invalidateQueries(cacheKeys);
  }
};
