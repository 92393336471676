import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChipComplete: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.318 2.379c.092-.092.246-.195.343-.23a.998.998 0 0 1 1.058.23c.247.248.299.443.299 1.129v.573h4v-.573c0-.686.052-.881.299-1.128a.984.984 0 0 1 1.402 0c.247.247.299.442.299 1.128v.573h.61c.685 0 1.006.04 1.4.178.744.258 1.462.94 1.753 1.667.18.448.205.599.226 1.385l.02.77h.569c.68 0 .876.052 1.123.299a.984.984 0 0 1 0 1.402c-.247.247-.442.299-1.128.299h-.573v.815a6.994 6.994 0 0 0-2-.295c0-3.568-.007-3.914-.182-4.109-.033-.036-.072-.067-.117-.112a1.057 1.057 0 0 0-.361-.233c-.177-.06-.654-.066-5.357-.064-4.21 0-5.191.01-5.313.055a1.038 1.038 0 0 0-.54.454l-.11.189-.011 5.218c-.01 5.037-.027 5.45.172 5.67.032.035.071.066.117.112.046.046.077.084.113.117.202.183.567.184 4.51.183.035.697.172 1.368.394 2H9.018v.573c0 .686-.052.88-.3 1.128a.984.984 0 0 1-1.401 0c-.247-.247-.3-.442-.3-1.123v-.57l-.77-.02c-.497-.012-.84-.04-.97-.078-1.087-.317-1.843-1.073-2.168-2.17-.04-.132-.066-.463-.08-.97l-.02-.77h-.57c-.68 0-.875-.052-1.122-.3a.984.984 0 0 1 0-1.401c.247-.247.442-.3 1.128-.3h.573v-4h-.573c-.686 0-.881-.051-1.128-.298a.984.984 0 0 1 0-1.402c.247-.247.442-.3 1.123-.3h.569l.02-.77c.022-.798.046-.945.239-1.414.273-.666.923-1.31 1.595-1.578.448-.18.599-.205 1.385-.226l.77-.02v-.57c0-.68.052-.875.3-1.123Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.018 10.887c-.002-1.884-.019-2.183-.171-2.374-.035-.044-.077-.083-.128-.133-.051-.051-.09-.094-.134-.129-.226-.18-.603-.17-3.584-.167-2.757.003-3.186.01-3.34.066a1.118 1.118 0 0 0-.343.229c-.052.051-.094.09-.13.134-.18.227-.17.604-.17 3.568 0 2.965-.01 3.341.17 3.567.035.045.078.083.129.134.05.05.089.093.133.128.2.16.52.17 2.669.17a7.1 7.1 0 0 1 .808-2h-1.91v-4h4v1.763a7.027 7.027 0 0 1 2-.956Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.171 21.938a4.054 4.054 0 1 0 0-8.107 4.054 4.054 0 0 0 0 8.107Zm0 1.75a5.804 5.804 0 1 0 0-11.607 5.804 5.804 0 0 0 0 11.607Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.111 15.815a.875.875 0 0 1 0 1.237l-2.902 2.902a.875.875 0 0 1-1.237 0l-1.741-1.741a.875.875 0 1 1 1.237-1.238l1.123 1.123 2.283-2.283a.875.875 0 0 1 1.237 0Z"
      fill="currentColor"
    />
  </Icon>
);

ChipComplete.displayName = 'ChipCompleteIcon';
