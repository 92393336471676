import { user } from './user';

export enum UserAuditLogEvent {
  STATUS_CHANGE = 'STATUS_CHANGE',
  TIER_CHANGE = 'TIER_CHANGE',
}

export type StatusChangeMeta = {
  previousStatus?: string;
  newStatus?: string;
};

export type TierChangeMeta = {
  previousStatus?: string;
  newStatus?: string;
};

export type UserAuditLogMeta = StatusChangeMeta & TierChangeMeta;

export type userAuditLog = {
  createdAt?: string; // DateTime
  event?: UserAuditLogEvent;
  id?: number;
  meta?: UserAuditLogMeta;
  target?: user;
  timestamp?: string; // DateTime
  user?: user;
};
