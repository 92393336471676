import { activePlaybook, playbook, playbookStep } from 'types';
import { create } from 'zustand';

interface PlaybookStore {
  activePlaybook: activePlaybook;
  setActivePlaybook: (playbook: playbook) => void;
  fastForwardSteps: Record<string, { fastForwardTime?: Date } & playbookStep>;
  addFastForwardStep: (step: playbookStep) => void;
  addFastForwardSteps: (steps: playbookStep[]) => void;
  clearFastForwardSteps: () => void;
  easterEggIsActive: boolean;
  setEasterEggIsActive: (isActive: boolean) => void;
  wizardTabsAreActive: boolean;
  setWizardTabsAreActive: (isActive: boolean) => void;
  shouldReload: boolean;
  setShouldReload: (shouldReload: boolean) => void;
  confirmingStep?: string; // playbookStep uuid
  setConfirmingStep: (stepUuid?: string) => void;
}

const defaultState = {
  activePlaybook: null,
  fastForwardSteps: {},
  easterEggIsActive: false,
  wizardTabsAreActive: false,
  shouldReload: false,
  confirmingStep: undefined,
};

const PlaybookStore = (set): PlaybookStore => ({
  ...defaultState,
  setActivePlaybook: (playbook: activePlaybook) => {
    set({ activePlaybook: playbook });
  },
  addFastForwardStep: (step: playbookStep) => {
    set(prev => ({
      fastForwardSteps: {
        ...prev.fastForwardSteps,
        [step.uuid]: { ...step, fastForwardTime: new Date() },
      },
    }));
  },
  addFastForwardSteps: (steps: playbookStep[]) => {
    set(prev => ({
      fastForwardSteps: {
        ...prev.fastForwardSteps,
        ...steps?.reduce(
          (acc, step) => ({
            ...acc,
            [step.uuid]: { ...step, fastForwardTime: new Date() },
          }),
          {},
        ),
      },
    }));
  },
  clearFastForwardSteps: () => {
    set({ fastForwardSteps: {} });
  },
  setEasterEggIsActive: (isActive: boolean) => {
    set({ easterEggIsActive: isActive });
  },
  setWizardTabsAreActive: (isActive: boolean) => {
    set({ wizardTabsAreActive: isActive });
  },
  setShouldReload: (shouldReload: boolean) => {
    set({ shouldReload });
  },
  setConfirmingStep: (stepUuid?: string) => {
    set({ confirmingStep: stepUuid });
  },
});

export const usePlaybookStore = create(PlaybookStore);
