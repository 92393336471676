import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

const _LOCATION_RELATIONS_PARAMS = `
  $users: [String]
  $userStatus: [String]
  $userInitiated: Boolean
  $locations: [String]
  $locationStatus: [String]
  $locationInitiated: Boolean
  ${_PAGING_PARAMS}
`;

const _LOCATION_RELATIONS_VARIABLES = `
  users: $users
  userStatus: $userStatus
  userInitiated: $userInitiated
  locations: $locations
  locationStatus: $locationStatus
  locationInitiated: $locationInitiated
  ${_PAGING_VARIABLES}
`;

export const LOCATION_RELATIONS_QUERY = `
  query LOCATION_RELATIONS_QUERY  (${_LOCATION_RELATIONS_PARAMS}) {
    locationRelations(${_LOCATION_RELATIONS_VARIABLES}) {
      id
      location {
        uuid
        name
        retailer {
          uuid
          name
          logoImage
        }
      }
      locationStatus
      user {
        uuid
        firstName
        lastName
        tier
        profileImg
      }
      userStatus
    }
  }
`;
