import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { NumberFormatValues } from 'react-number-format';
import { Flex, Skeleton, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { format, formatISO } from 'date-fns';
import { constructDateWithTimezone, getFullLocationName } from 'helpers';
import { location, LocationStatus, PageSizes, SelectOption } from 'types';
import {
  ConnectedDatePickerInput,
  ConnectedInput,
  ConnectedSelect,
  FormButton,
} from 'ui';
import { convertDateStringToTimeSelectOption } from 'utils/helpers/form';
import {
  getFormattedPaymentValues,
  getShiftBonusPayment,
} from 'utils/helpers/shift';
import { useShiftActions } from 'utils/hooks/useShiftActions';
import { ActionState, useOverbookStore } from 'utils/stores';
import { MAX_BONUS_AMOUNT } from 'utils/types/configs';
import { ItemSelector, ItemSelectorSize } from 'components/common/filters';
import { SelectLocationsList } from 'components/locations/SelectLocationList';
import {
  useShiftFormComplianceHelper,
  useShiftFormDataHelper,
} from '../ShiftCreateForm/ShiftForm.helpers';
import {
  overbookReasons,
  OverbookShiftFormData,
  OverbookShiftFormDefaults,
  OverbookShiftFormValidationSchema,
} from './OverbookShiftFormSchema';
import { WorkerMeta } from './WorkerMeta';

enum OverbookShiftFormView {
  DEFAULT = 'default',
  LOCATION_SELECT = 'location_select',
  SUCCESS = 'success',
}

interface OverbookShiftFormProps {
  defaultLocation?: location;
}

export const OverbookShiftForm: FC<OverbookShiftFormProps> = ({
  defaultLocation,
}) => {
  const [activeView, setActiveView] = useState(OverbookShiftFormView.DEFAULT);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [formState, setFormState] = useState({
    isProcessing: false,
    isSuccess: false,
  });
  const [shift, workershift, setNewShift, updateMeta] = useOverbookStore(s => [
    s.shift,
    s.workershift,
    s.setNewShift,
    s.updateMeta,
  ]);
  const [selectedLocation, setSelectedLocation] =
    useState<location>(defaultLocation);
  const { roleOptions, shiftTimeOptions } = useShiftFormDataHelper();
  const { moveWsoToNewShift } = useShiftActions();

  const formMethods = useForm<OverbookShiftFormData>({
    resolver: yupResolver(OverbookShiftFormValidationSchema),
  });
  const { handleSubmit, reset, watch } = formMethods || {};

  const startTime = watch('startTime');
  const endTime = watch('endTime');

  const { complianceErrorMessage } = useShiftFormComplianceHelper({
    location: selectedLocation,
    startTime,
    endTime,
  });

  const onSubmit = async (values: OverbookShiftFormData) => {
    if (!selectedLocation) return;
    setFormState(prev => ({ ...prev, isProcessing: true }));
    const { date, startTime, endTime, roles, reason, bonus } = values;
    const locationTimezone = selectedLocation?.timezone;
    const startDate = formatISO(
      constructDateWithTimezone(date, startTime.value, locationTimezone),
    );
    const endDate = formatISO(
      constructDateWithTimezone(date, endTime.value, locationTimezone),
    );
    const rolesArray = roles.map(role => role?.value);
    const bonusAmount = parseFloat(bonus.replace('$', ''));

    const response = await moveWsoToNewShift.mutateAsync({
      workershift: workershift?.uuid,
      location: selectedLocation?.uuid,
      start: startDate,
      end: endDate,
      roles: rolesArray,
      reason: reason?.value,
      bonusAmount,
    });

    setFormState(prev => ({ ...prev, isProcessing: false }));

    if (!response.hasErrors) {
      setNewShift({ uuid: response?.body?.data?.wsoToNewShift?.newShiftUuid });
      setFormState(prev => ({ ...prev, isSuccess: true }));
      updateMeta('actionState', ActionState.FINISHED);
    }
  };

  const handleAllowedBonus = (values: NumberFormatValues): boolean => {
    const { floatValue } = values;
    if (floatValue > MAX_BONUS_AMOUNT) return false;
    return true;
  };

  useEffect(() => {
    if (
      hasLoaded ||
      Object.keys(shift)?.length === 0 ||
      roleOptions?.length === 0
    )
      return;
    setHasLoaded(true);
    const roleNames = shift?.workerRoles.map(r => r?.name);
    const defaultRoles = roleOptions?.filter(o =>
      roleNames.includes(o.value),
    ) as SelectOption[];
    const defaultDate = format(new Date(shift?.start), 'MM/dd/yyyy');
    const defaultStartTime = convertDateStringToTimeSelectOption(
      shift?.start,
      shift?.retailerLocation?.timezone,
    );
    const defaultEndTime = convertDateStringToTimeSelectOption(
      shift?.end,
      shift?.retailerLocation?.timezone,
    );
    const bonusRate =
      getFormattedPaymentValues(getShiftBonusPayment(shift))?.rate || 0;

    reset({
      ...OverbookShiftFormDefaults,
      roles: defaultRoles,
      date: defaultDate,
      startTime: defaultStartTime,
      endTime: defaultEndTime,
      bonus: bonusRate.toString(),
    });
  }, [hasLoaded, shift, reset, roleOptions]);

  return (
    <Flex flexDirection="column">
      <Text variant="header-xl" mt={2} mb={3}>
        {'New Flex For Worker'}
      </Text>
      <WorkerMeta {...workershift?.worker} />

      <FormProvider {...formMethods}>
        <Flex
          direction="column"
          onSubmit={handleSubmit(onSubmit)}
          as="form"
          borderTopWidth={1}
          borderColor="blackAlpha.200"
          mt={2}
          pt={4}
        >
          {!hasLoaded && (
            <>
              <Skeleton h={10} w="100%" mb={4} />
              <Skeleton h={10} w="100%" mb={4} />
              <Skeleton h={10} w="100%" mb={4} />
            </>
          )}
          {hasLoaded && activeView === OverbookShiftFormView.DEFAULT && (
            <>
              <ItemSelector<location>
                label="Location"
                placeholder="Select Location"
                onClick={() => {
                  setActiveView(OverbookShiftFormView.LOCATION_SELECT);
                }}
                size={ItemSelectorSize.LG}
                valueOverride={
                  !!selectedLocation && Object.keys(selectedLocation)?.length
                    ? getFullLocationName(selectedLocation)
                    : undefined
                }
              />
              <Flex gap={2} mt={4}>
                <ConnectedDatePickerInput
                  id="date"
                  containerProps={{ mb: 4, width: 164 }}
                  inputProps={{
                    size: 'lg',
                    placeholder: 'Date',
                  }}
                  labelProps={{ text: 'Date' }}
                />
                <ConnectedSelect
                  selectProps={{ size: 'lg' }}
                  containerProps={{ mb: 4, width: 200 }}
                  id="startTime"
                  placeholder="Time"
                  labelProps={{ text: 'Start' }}
                  options={shiftTimeOptions}
                />
                <ConnectedSelect
                  selectProps={{ size: 'lg' }}
                  containerProps={{ mb: 4, width: 200 }}
                  id="endTime"
                  placeholder="Time"
                  labelProps={{ text: 'End' }}
                  options={shiftTimeOptions}
                />
              </Flex>
              {complianceErrorMessage && (
                <Text color="red" textAlign="center">
                  {complianceErrorMessage}
                </Text>
              )}
              <ConnectedSelect
                selectProps={{ isMulti: true, size: 'lg' }}
                containerProps={{ mb: 4 }}
                id="roles"
                placeholder="Choose Roles"
                labelProps={{ text: 'Roles' }}
                options={roleOptions}
              />
              <ConnectedSelect
                selectProps={{ size: 'lg' }}
                containerProps={{ mb: 4 }}
                id="reason"
                placeholder="Choose Option"
                labelProps={{ text: 'Reason' }}
                options={overbookReasons}
              />
              <ConnectedInput
                inputProps={{ size: 'lg' }}
                containerProps={{ mb: 4, maxWidth: 144 }}
                id="bonus"
                placeholder="Enter Bonus"
                labelProps={{ text: 'Bonus' }}
                numberFormatProps={{
                  thousandSeparator: ',',
                  allowLeadingZeros: false,
                  allowNegative: false,
                  decimalScale: 2,
                  isAllowed: handleAllowedBonus,
                  prefix: '$',
                }}
              />
              <FormButton
                type="submit"
                width="100%"
                alignSelf="center"
                maxWidth={200}
                size="lg"
                isLoading={formState?.isProcessing}
                isDisabled={!!complianceErrorMessage}
                isSuccess={formState?.isSuccess}
                text="Save"
                mt={4}
              />
            </>
          )}
          {hasLoaded &&
            activeView === OverbookShiftFormView.LOCATION_SELECT && (
              <Flex flexDirection="column">
                <Text variant="subheader-md">{'Select Location'}</Text>
                <SelectLocationsList
                  queryVariables={{ status: [LocationStatus.ACTIVE] }}
                  pageSize={PageSizes.five}
                  onSelect={location => {
                    setSelectedLocation(location);
                    setActiveView(OverbookShiftFormView.DEFAULT);
                  }}
                />
              </Flex>
            )}
        </Flex>
      </FormProvider>
    </Flex>
  );
};
