import { FC } from 'react';
import { Button, Flex, FlexProps, Text } from '@chakra-ui/react';
import {
  fetchMarkets,
  fetchMarketsProps,
  getMarketName,
  Queries,
} from 'helpers';
import { ICON_NAMES } from 'icons';
import { DataKeys, market, SelectOption } from 'types';
import { TextAndIcon } from 'ui';
import { SelectList, SelectListProps } from 'components/common/SelectList';

type SelectMarketItemProps = market &
  SelectListProps<market, fetchMarketsProps> & {
    onSelect?: (market: market) => void;
    selectButtonText?: string;
    containerProps?: FlexProps;
    currentValues?: SelectOption[];
  };

const SelectMarketItem: FC<SelectMarketItemProps> = ({
  onSelect,
  selectButtonText = 'Select',
  containerProps,
  currentValues,
  selectedValues, // eslint-disable-line unused-imports/no-unused-vars
  ...market
}) => {
  const isSelected = currentValues
    ?.map(v => v.value)
    .includes(market.id.toString());
  return (
    <Flex
      flexDirection="column"
      flexWrap="wrap"
      py={2}
      px={{ base: 4, md: 6 }}
      userSelect="none"
      position="relative"
      {...containerProps}
    >
      <Flex alignItems="center">
        <TextAndIcon
          text={getMarketName(market)}
          textProps={{
            fontWeight: 'medium',
            ml: 2,
            lineHeight: 1,
          }}
          icon={ICON_NAMES.locationmarker}
          containerProps={{ flexGrow: 1 }}
        />
        {market?.markets?.length > 0 && (
          <Flex
            backgroundColor="oz.backgroundPurple"
            px={2}
            py={0.5}
            borderRadius="md"
            alignSelf="center"
          >
            <Text variant="subheader-2xs" color="oz.primary">
              {'Group'}
            </Text>
          </Flex>
        )}
        <Button
          size="sm"
          variant="transparent"
          onClick={() => {
            !!onSelect && onSelect(market);
          }}
          minWidth="80px"
        >
          {isSelected ? 'Remove' : selectButtonText}
        </Button>
      </Flex>
    </Flex>
  );
};

export const SelectMarketList: FC<
  SelectListProps<market, fetchMarketsProps>
> = ({ onSelect, selectButtonText = 'Select', queryVariables, ...props }) => {
  return (
    <SelectList<market, fetchMarketsProps>
      dataKey={DataKeys.MARKETS}
      query={Queries.MARKET_LIST_QUERY}
      fetchFunction={fetchMarkets}
      pagingKey="markets_paging"
      queryVariables={queryVariables}
      itemComponent={
        <SelectMarketItem
          onSelect={onSelect}
          selectButtonText={selectButtonText}
        />
      }
      onSelect={onSelect}
      {...props}
    />
  );
};
