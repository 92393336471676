import {
  CommonStore,
  commonStore,
  defaultCommonStoreState,
  sortGenerator,
} from 'helpers';
import { location, PageSizes, Prettify, SortState } from 'types';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export const LocationsSortOptions = {
  'retailer__name,name': 'Name',
  retailer__name: 'Brand',
  market__name: 'Market',
} as const;

export enum LocationsListActions {
  NONE = 'none',
  ADD_LOCATION_RELATION = 'add-location-relation',
  ADD_USER_RELATION = 'add-user-relation',
}

export type LocationsStoreBase = {
  selectedLocations?: Record<string, location>;
  selectedLocationsArray?: location[];
  selectedLocationsUuidArray?: string[];
  updateSelectedLocations: (
    location: location,
    action: 'add' | 'remove',
  ) => void;
  setSelectedLocations: (locations: Record<string, location>) => void;
  clearSelectedLocations: () => void;
  selectionIsActive: boolean;
  setSelectionIsActive: (value: boolean) => void;
  prevSelectedRowIndex: number;
  setPrevSelectedRowIndex: (index: number) => void;
  activePageLocations: Record<string, location>;
  setActivePageLocations: (locations: location[]) => void;
  selectAllPageLocations: () => void;
  listAction: LocationsListActions;
  setListAction: (action: LocationsListActions) => void;
  totalLocations: number;
  setTotalLocations: (total: number) => void;
};

export type LocationsStore = Prettify<
  CommonStore<typeof LocationsSortOptions> & LocationsStoreBase
>;

const defaultState = {
  sort: {
    ...sortGenerator(SortState.NONE, LocationsSortOptions),
    'retailer__name,name': SortState.ASC,
  },
  pagination: {
    ...defaultCommonStoreState.pagination,
    pageSize: PageSizes.twentyFive,
  },
  selectedLocations: {},
  selectedLocationsArray: [],
  selectedLocationsUuidArray: [],
  updateSelectedLocations: () => {},
  setSelectedLocations: () => {},
  clearSelectedLocations: () => {},
  selectionIsActive: false,
  prevSelectedRowIndex: -1,
  activePageLocations: {},
  listAction: LocationsListActions.NONE,
  totalLocations: 0,
};

const LocationsStore = (set, get): LocationsStore => ({
  ...defaultCommonStoreState,
  ...commonStore(set),
  ...defaultState,
  updateSelectedLocations: (location, action) => {
    if (action === 'add') {
      set(prev => ({
        selectedLocations: {
          ...prev.selectedLocations,
          [location.uuid]: location,
        },
      }));
    } else {
      const currentLocations = get().selectedLocations;
      const newLocations = {};
      Object.keys(currentLocations).forEach(key => {
        if (key !== location?.uuid) newLocations[key] = currentLocations[key];
      });
      set({ selectedLocations: newLocations });
    }
  },
  setSelectedLocations: locations => set({ selectedLocations: locations }),
  clearSelectedLocations: () => set({ selectedLocations: {} }),
  setSelectionIsActive: value => set({ selectionIsActive: value }),
  setPrevSelectedRowIndex: index => set({ prevSelectedRowIndex: index }),
  setActivePageLocations: locations => {
    const locationsObject = locations?.reduce(
      (acc, location) => {
        acc[location.uuid] = location;
        return acc;
      },
      {} as Record<string, location>,
    );
    set({ activePageLocations: locationsObject });
  },
  selectAllPageLocations: () => {
    set({ selectedLocations: get().activePageLocations });
  },
  setListAction: action => set({ listAction: action }),
  setTotalLocations: total => set({ totalLocations: total }),
});

export const useLocationsStore = create(subscribeWithSelector(LocationsStore));

useLocationsStore.subscribe(
  store => store.selectedLocations,
  locations => {
    useLocationsStore.setState({
      selectedLocationsArray: Object.values(locations),
      selectedLocationsUuidArray: Object.keys(locations),
    });
    if (Object.keys(locations).length === 0) {
      useLocationsStore.setState({ prevSelectedRowIndex: -1 });
    }
  },
);
