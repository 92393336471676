
export const WORKFLOWS_QUERY = `
  query WORKFLOWS_QUERY(
    $isVisible: Boolean
  ) {
    workflows (isVisible: $isVisible) {
      name
      uuid
    }
  }
`;
