import { CommonStore, commonStore, defaultCommonStoreState } from 'helpers';
import { ozNotification, PageSizes, Prettify } from 'types';
import { create } from 'zustand';

const ALERT_TIMEOUT = 10000;

type NotificationStore = Prettify<
  CommonStore<unknown> & {
    total: number;
    setTotal: (total: number) => void;

    notifications: Record<string, ozNotification>;
    updateNotifications: (
      notification: ozNotification,
      action: 'add' | 'remove',
    ) => void;
    clearNotifications: () => void;

    alerts: Record<string, ozNotification & { added: Date }>;
    updateAlerts: (
      notification: ozNotification,
      action: 'add' | 'remove',
    ) => void;
    clearAlerts: () => void;

    unread: Record<string, boolean>;
    updateUnread: (
      notification: ozNotification,
      action: 'add' | 'remove',
    ) => void;
    clearUnread: () => void;
  }
>;

const defaultState = {
  pagination: {
    ...defaultCommonStoreState.pagination,
    pageSize: PageSizes.five,
  },
  total: 0,
  notifications: {},
  alerts: {},
  unread: {},
};

const NotificationsStore = (set, get): NotificationStore => ({
  ...defaultCommonStoreState,
  ...commonStore(set),
  ...defaultState,
  setTotal: (total: number) => set({ total }),
  updateNotifications: (notification, action) => {
    if (action === 'add') {
      set(prev => ({
        notifications: {
          ...prev.notifications,
          [notification.uuid]: notification,
        },
      }));
      get().updateAlerts(notification, 'add');
      get().updateUnread(notification, 'add');
    } else {
      const currentNotifications = get().notifications;
      const newNotifications = {};
      Object.keys(currentNotifications).forEach(key => {
        if (key !== notification?.uuid)
          newNotifications[key] = currentNotifications[key];
      });
      set({ notifications: newNotifications });
    }
  },
  clearNotifications: () => set({ notifications: {} }),
  updateAlerts: (notification, action) => {
    if (action === 'add') {
      set(prev => ({
        alerts: {
          ...prev.alerts,
          [notification.uuid]: notification,
        },
      }));
      setTimeout(() => {
        set(prev => {
          const { [notification.uuid]: _, ...remainingAlerts } = prev.alerts;
          return { alerts: remainingAlerts };
        });
      }, ALERT_TIMEOUT);
    } else {
      const currentNotifications = get().alerts;
      const newNotifications = {};
      Object.keys(currentNotifications).forEach(key => {
        if (key !== notification?.uuid)
          newNotifications[key] = currentNotifications[key];
      });
      set({ alerts: newNotifications });
    }
  },
  clearAlerts: () => set({ alerts: {} }),
  updateUnread: (notification, action) => {
    if (action === 'add') {
      set(prev => ({
        unread: {
          ...prev.unread,
          [notification.uuid]: notification,
        },
      }));
    } else {
      const currentNotifications = get().unread;
      const newNotifications = {};
      Object.keys(currentNotifications).forEach(key => {
        if (key !== notification?.uuid)
          newNotifications[key] = currentNotifications[key];
      });
      set({ unread: newNotifications });
    }
  },
  clearUnread: () => set({ unread: {} }),
});

export const useNotificationsStore = create(NotificationsStore);
