import { location, SortState, user } from 'types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  defaultLocationUsersSort,
  LocationUserModalViews,
  LocationUsersSortMap,
} from './LocationStore.helpers';

interface LocationStore {
  usersSort: LocationUsersSortMap;
  updateSort: (key: string, value: SortState) => void;
  search: string;
  updateSearch: (value: string) => void;
  activeUser: user;
  updateActiveUser: (update: user) => void;
  activeLocation: location;
  updateActiveLocation: (update: location) => void;
  clearActiveLocation: () => void;
  userModalView: LocationUserModalViews;
  updateUserModalView: (view: LocationUserModalViews) => void;
  resetUserModalState: () => void;
}

const defaultState = {
  search: '',
  usersSort: defaultLocationUsersSort,
  activeUser: {},
  activeLocation: {},
};

const defaultUserModalState = {
  userModalView: LocationUserModalViews.DEFAULT,
};

const LocationStore = (set): LocationStore => ({
  ...defaultState,
  ...defaultUserModalState,
  updateSort: (key, value) =>
    set(
      prev => ({
        usersSort: { ...prev?.usersSort, [key]: value },
      }),
      false,
      'updateUsersSort',
    ),
  updateSearch: (value: string) =>
    set({
      search: value,
    }),
  updateActiveUser: user =>
    set({
      activeUser: user,
    }),
  updateActiveLocation: location =>
    set(prev => ({
      activeLocation: {
        ...prev?.activeLocation,
        ...location,
      },
    })),
  clearActiveLocation: () => set({ activeLocation: {} }),
  updateUserModalView: view => set({ userModalView: view }),
  resetUserModalState: () =>
    set(prev => ({
      ...prev,
      activeUser: {},
      ...defaultUserModalState,
    })),
});

export const useLocationStore = create(devtools(LocationStore));
