import { CamelToSnakeCaseNested, Prettify } from './_helpers';
import { amountThreshold } from './amountThreshold';
import { billingInvoiceMixin, PaymentContractTypeOptions } from './billing';
import { uuid } from './common';
import { customer } from './customer';
import { document } from './document';
import { location } from './location';
import { paymentMethod } from './paymentMethod';

export enum TermsStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export type terms = Prettify<
  billingInvoiceMixin & {
    cancellationFee?: amountThreshold;
    id?: string;
    uuid?: uuid;
    createdAt?: string; // DateTime
    updatedAt?: string; // DateTime
    name?: string;
    activeFrom?: string; // DateTime
    activeTo?: string; // DateTime
    autoRenew?: boolean;
    paymentType?: PaymentContractTypeOptions;
    customer?: customer;
    paymentMethod?: paymentMethod;
    status?: TermsStatus;
    taxRateId?: string;
    locations?: Array<location>;
    documents?: Array<document>;
  }
>;

export type apiTerms = CamelToSnakeCaseNested<terms>;

// LATER: be more specific about actual api, for example
// export type apiTerms = Omit<
//   CamelToSnakeCaseNested<terms>,
//   'locations' | 'documents'
// > & {
//   documents?: Array<apiDocument>;
// }
