import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  convertDataToSelectOptions,
  createTimeIntervalOptions,
  fetchLocationBudgets,
  fetchLocationBudgetsProps,
  fetchRoles,
  fetchRolesProps,
  getFormattedBudgetPeriodDates,
  limitToMaxDecimalPlaces,
  pluralize,
  Queries,
  roundBudgetValue,
  timeToHours,
} from 'helpers';
import { useDataQuery } from 'hooks';
import { DataKeys, location, locationBudget, role, SelectOption } from 'types';

interface useShiftFormComplianceHelperProps {
  location: location;
  startTime: SelectOption;
  endTime: SelectOption;
}

export const useShiftFormComplianceHelper = (
  props?: useShiftFormComplianceHelperProps,
) => {
  const { location, startTime, endTime } = props || {};

  const [shiftMaxLength, setShiftMaxLength] = useState(Infinity);
  const [shiftMinLength, setShiftMinLength] = useState(0);

  const maxLength = location?.compliance?.shiftMaxLength;
  const minLength = location?.compliance?.shiftMinLength;
  const startTimeValue = startTime?.value;
  const endTimeValue = endTime?.value;

  const flexLength = (() => {
    if (startTimeValue && endTimeValue) {
      const startHour = timeToHours(startTimeValue);
      let endHour = timeToHours(endTimeValue);
      if (endHour < startHour) {
        // Overnight Flex
        endHour += 24;
      }
      return endHour - startHour;
    }
    return 0;
  })();

  const isFlexLengthValid = (() => {
    if (startTimeValue && endTimeValue) {
      return flexLength <= shiftMaxLength && flexLength >= shiftMinLength;
    }
    return true;
  })();

  const complianceErrorMessage = (() => {
    if (isFlexLengthValid) return '';
    return flexLength > shiftMaxLength
      ? `Maximum flex time for this location is ${limitToMaxDecimalPlaces(shiftMaxLength, 2)} hours`
      : `Minimum flex time for this location is ${limitToMaxDecimalPlaces(shiftMinLength, 2)} hours`;
  })();

  const complianceWarningMessage = (() => {
    if (!startTimeValue || !endTimeValue) return '';
    const startHour = timeToHours(startTimeValue);
    const endHour = timeToHours(endTimeValue);
    if (startHour > endHour) {
      return 'WARNING: This is an overnight shift!';
    }
  })();

  useEffect(() => {
    if (maxLength) {
      setShiftMaxLength(maxLength / 60);
    } else {
      setShiftMaxLength(Infinity);
    }
  }, [maxLength]);

  useEffect(() => {
    if (minLength) {
      setShiftMinLength(minLength / 60);
    } else {
      setShiftMinLength(0);
    }
  }, [minLength]);

  return {
    flexLength,
    isFlexLengthValid,
    complianceErrorMessage,
    complianceWarningMessage,
  };
};

export const useShiftFormDataHelper = () => {
  const [roleOptions, setRoleOptions] = useState([]);
  const { roles } = useDataQuery<role, fetchRolesProps>({
    canFetch: true,
    query: Queries.ROLES_QUERY,
    fetchFunction: fetchRoles,
    dataKey: DataKeys.ROLES,
    queryVariables: {
      isRequestable: true,
    },
  });

  const shiftTimeOptions = createTimeIntervalOptions({
    startHour: 0,
    startMinute: 0,
    endHour: 23,
    endMinute: 45,
    interval: 15,
  }).map(value => ({
    label: format(new Date(`${new Date().toDateString()} ${value}`), 'h:mmaaa'),
    value,
  }));

  useEffect(() => {
    if (roles.length === 0) return;
    setRoleOptions(
      convertDataToSelectOptions(
        roles?.filter(role => role?.isActive && role?.isRequestable),
        'name',
        'name',
      ),
    );
  }, [roles]);

  return {
    roles,
    roleOptions,
    shiftTimeOptions,
  };
};

interface useShiftFormBudgetHelperProps {
  location: location;
  date: string;
  startTime: SelectOption;
  endTime: SelectOption;
}

export const useShiftFormBudgetHelper = (
  props?: useShiftFormBudgetHelperProps,
) => {
  const { location, date, startTime, endTime } = props || {};

  const startTimeValue = startTime?.value;
  const endTimeValue = endTime?.value;

  const duration = (() => {
    if (startTimeValue && endTimeValue) {
      return timeToHours(endTimeValue) - timeToHours(startTimeValue);
    }
    return 0;
  })();

  const { locationBudgets } = useDataQuery<
    locationBudget,
    fetchLocationBudgetsProps
  >({
    query: Queries.BUDGETS_QUERY,
    fetchFunction: fetchLocationBudgets,
    canFetch: !!location?.uuid,
    dataKey: DataKeys.BUDGETS,
    queryVariables: {
      locations: [location?.uuid],
    },
  });

  const locationBudgetPeriods = locationBudgets?.map(lb => lb?.budgetPeriod);
  const matchedPeriod = locationBudgetPeriods?.find(
    lb =>
      new Date(date) >= new Date(lb?.start) &&
      new Date(date) <= new Date(lb?.end),
  );
  const matchedBudget =
    locationBudgets?.find(
      lb => lb?.budgetPeriod?.uuid === matchedPeriod?.uuid,
    ) || ({} as locationBudget);

  const { formattedStart, formattedEnd } =
    getFormattedBudgetPeriodDates(matchedBudget);
  const budgetPeriodName = matchedBudget?.budgetPeriod?.name;

  const blendedDecimal =
    (matchedBudget?.blendedTotalHours + matchedBudget?.totalFeeHours) /
    matchedBudget?.amountHours;
  const blendedPercentToGoal = roundBudgetValue(blendedDecimal * 100);

  const hasDuration = duration > 0;
  const hasActiveBudget = Object.keys(matchedBudget)?.length > 0;
  const willBeOverBudget =
    duration + matchedBudget?.blendedTotalHours > matchedBudget?.amountHours;
  const budgetAmountIsNotNull = matchedBudget?.amountHours !== null;
  return {
    duration,
    matchedBudget,
    budgetHelperText:
      budgetAmountIsNotNull && hasDuration && hasActiveBudget
        ? `Requesting ${duration} ${pluralize('hour', duration)} against the ${budgetPeriodName} budget period (${formattedStart} – ${formattedEnd}). Limit for this period is ${limitToMaxDecimalPlaces(matchedBudget?.amountHours, 2)} ${pluralize('hour', duration)}. Current utilization is ${limitToMaxDecimalPlaces(matchedBudget?.blendedTotalHours, 2)} ${pluralize('hour', duration)} (${limitToMaxDecimalPlaces(blendedPercentToGoal * 100, 2)}%). Request will make utilization total ${matchedBudget?.blendedTotalHours + duration} ${pluralize('hour', duration)}`
        : '',
    budgetWarning:
      budgetAmountIsNotNull && hasDuration && willBeOverBudget
        ? `Request will exceed the ${budgetPeriodName} budget period limit of ${limitToMaxDecimalPlaces(matchedBudget?.amountHours, 2)} ${pluralize('hour', duration)}. Current utilization is ${limitToMaxDecimalPlaces(matchedBudget?.blendedTotalHours, 2)} ${pluralize('hour', duration)} (${limitToMaxDecimalPlaces(blendedPercentToGoal * 100, 2)}%). Request will make utilization total ${limitToMaxDecimalPlaces(matchedBudget?.blendedTotalHours + duration, 2)} ${pluralize('hour', duration)}.`
        : '',
  };
};
