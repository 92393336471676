import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const MobileDisabled: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      d="M4.983 8.798c-.023 4.463-.006 11.64.053 11.866.28 1.096 1.047 1.881 2.184 2.237.175.055.735.065 4.56.076 2.923.01 4.45 0 4.633-.03a2.998 2.998 0 0 0 1.762-.957l-1.402-1.402h-.001c-.032.032-.069.057-.111.1-.048.048-.082.088-.122.122-.224.19-.62.177-4.58.177-3.958 0-4.355.013-4.579-.177-.04-.034-.074-.075-.122-.123-.043-.042-.08-.068-.11-.1-.208-.204-.189-.638-.178-7.682l.003-2.117-1.99-1.99ZM16.96 15.18v-2.193c0-6.963.019-7.396-.188-7.6-.032-.032-.069-.058-.111-.1a1.057 1.057 0 0 0-.361-.234c-.176-.06-.584-.066-4.357-.064-3.373.001-4.191.012-4.313.055-.18.065-.342.184-.464.342L5.752 3.971a2.965 2.965 0 0 1 1.078-.757c.217-.088.342-.122.75-.202.197-.039 8.615-.022 8.86.018 1.18.19 2.137 1.083 2.444 2.28.058.226.075 7.382.053 11.846L16.96 15.18Zm-6 3.807c0-.385.288-.803.643-.934.23-.084.607-.064.818.045.712.366.715 1.411.004 1.776-.651.335-1.465-.158-1.465-.887ZM2.1 2.988a1 1 0 0 1 1.414 0l18.384 18.384a1 1 0 0 1-1.414 1.415L2.1 4.402a1 1 0 0 1 0-1.414Z"
      fill="currentColor"
      stroke="none"
      fillRule="evenodd"
    ></path>
  </Icon>
);

MobileDisabled.displayName = 'MobileDisabledIcon';
