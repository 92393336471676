import { SortState } from 'types';

export const ReviewsSortOptions = {
  updated_at: 'Updated',
  created_at: 'Shift Date',
} as const;

export type ReviewsSortOptionsType = keyof typeof ReviewsSortOptions;

export type ReviewsSortMap = Partial<{
  [value in ReviewsSortOptionsType]: SortState;
}>;

export enum ReviewsQuickPicks {
  RECENT = 'Recent',
  THIS_WEEK = 'This Week',
  THIS_MONTH = 'This Month',
  LAST_WEEK = 'Last Week',
  LAST_MONTH = 'Last Month',
  ALL_TIME = 'All Time',
  CUSTOM = 'Custom',
}
