export enum WebsocketEventType {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  JOB_STATUS = 'job-status',
  MESSAGE_EVENT = 'message-event',
  PLAYBOOK_EVENT = 'playbook-event',
  OZ_NOTIFICATION = 'oz-notification',
  ONBOARDING_SCHEDULE = 'onboarding-schedule',
  ACTION_ITEM = 'action-item',
}
