import { differenceInMinutes, differenceInHours } from 'date-fns';
import {
  amountThresholdEntry,
  AmountThresholdEntryUnit,
  shift,
  terms,
  UrgentBookingFeeLabels,
} from 'types';
import { limitToMaxDecimalPlaces } from '../data';
import { formatNumWithCommas } from '../strings';

export const getCancelPenaltyForShift = (
  shift: shift,
): amountThresholdEntry | null => {
  if (!shift) return null;
  if (
    !shift.retailerLocation?.terms?.cancellationFee?.amounts?.length ||
    !shift.retailerLocation?.terms?.billingRate
  )
    return null;
  const minutesSinceShiftCreated = differenceInMinutes(
    new Date(),
    new Date(shift?.createdAt),
  );
  const GRACE_PERIOD =
    process.env
      .NEXT_PUBLIC_RETAILER_SHIFT_CANCELLATION_FEE_GRACE_PERIOD_MINUTES || 0;
  if (minutesSinceShiftCreated < GRACE_PERIOD) return null;

  const hoursUntilShiftStart = differenceInHours(
    new Date(shift?.start),
    new Date(),
  );
  const cancelFeesSorted = [
    ...shift.retailerLocation.terms.cancellationFee.amounts,
  ].sort((a, b) => a.timing - b.timing);
  return cancelFeesSorted.filter(
    amount => hoursUntilShiftStart < amount.timing,
  )[0];
};

export const getWorkerPayoutForShift = (
  shift: shift,
): amountThresholdEntry | null => {
  if (!shift) return null;
  if (
    !shift.retailerLocation?.compliance?.cancellationPayout?.amounts?.length ||
    !shift.retailerLocation?.payRate
  )
    return null;

  const hoursUntilShiftStart = differenceInHours(
    new Date(shift?.start),
    new Date(),
  );
  const workerPayoutsSorted = [
    ...shift.retailerLocation.compliance.cancellationPayout.amounts,
  ].sort((a, b) => a.timing - b.timing);
  return workerPayoutsSorted.filter(
    amount => hoursUntilShiftStart < amount.timing,
  )[0];
};

interface getDollarsFromAmountThresholdEntryProps {
  entry: amountThresholdEntry;
  rate: number;
  duration: number;
  bonus?: number;
}

export const getDollarsFromAmountThresholdEntry = (
  options: getDollarsFromAmountThresholdEntryProps,
): number => {
  if (!options || !options?.entry) return 0;
  if (!options.rate || !options.duration) return 0;
  const { entry, rate, duration, bonus = 0 } = options;
  let total;
  if (entry.unit === AmountThresholdEntryUnit.HOURS) {
    total = entry.amount * rate;
  } else if (entry.unit === AmountThresholdEntryUnit.DOLLARS) {
    total = entry.amount;
  } else if (entry.unit === AmountThresholdEntryUnit.PERCENT) {
    total = limitToMaxDecimalPlaces(
      (duration * rate + (bonus || 0)) * (entry.amount / 100),
      2,
    );
  }
  if (entry.minimum && total < entry.minimum) {
    total = entry.minimum;
  }
  if (entry.maximum && total > entry.maximum) {
    total = entry.maximum;
  }
  return total;
};

export const getTermsInfo = (terms: terms) => {
  const {
    billingRate,
    locations,
    billingUrgentBookingFee,
    billingUrgentBookingType,
  } = terms || {};

  const formatCurrency = (value: string) => {
    return formatNumWithCommas(
      limitToMaxDecimalPlaces(parseFloat(value), 2),
      2,
    );
  };

  const getFormattedCurrency = (value: string, includeHourly = false) => {
    if (!value) return '';
    return `$${formatCurrency(value)}${includeHourly ? '/hr' : ''}`;
  };

  const getUrgentBookingFeeLabel = () => {
    if (!billingUrgentBookingType) return '';
    return UrgentBookingFeeLabels[billingUrgentBookingType] || '';
  };

  return {
    formattedBillingRate: getFormattedCurrency(billingRate, true),
    formattedUrgentBookingFee: getFormattedCurrency(billingUrgentBookingFee),
    urgentBookingFeeLabel: getUrgentBookingFeeLabel(),
    numberOfLocations: locations?.length,
  };
};
