import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import { ActionItemType, GQL, paging } from 'types';

export interface fetchActionItemsProps extends paging {
  actionTypes?: ActionItemType[];
  assignedTo?: string[]; // staff uuid
  consolidateShifts?: boolean;
  isResolved?: boolean;
  markets?: number[]; // market id
  shiftStartSince?: Date;
  shiftStartTo?: Date;
  resolvedSince?: Date;
  resolvedTo?: Date;
  restrictTypesTo?: ActionItemType[];
  unassigned?: boolean;
}

export const fetchActionItems = async (
  data: fetchActionItemsProps,
  query = Queries.ACTION_ITEMS_QUERY,
  contextOverride?: string,
  returnKey: string | string[] = ['actionItems', 'actionItems_paging'],
) => {
  return await fetchObject({
    route: applyContext(GQL, contextOverride || 'ACTION_ITEMS_QUERY'),
    query,
    data,
    returnKey,
  });
};

export interface mutateAssignActionItemsProps {
  actionItems: string[]; // action item uuids
  assignedTo: string; // staff uuid
}

export const mutateAssignActionItems = async (
  data: mutateAssignActionItemsProps,
) => {
  return await mutateObject({
    route: applyContext(GQL, 'ASSIGN_ACTION_ITEM_MUTATION'),
    query: Mutations.ASSIGN_ACTION_ITEM_MUTATION,
    data,
    returnKey: 'assignActionItem',
  });
};

export interface mutateClearActionItemAssignmentProps {
  actionItems: string[]; // action item uuid
}

export const mutateClearActionItemAssignment = async (
  data: mutateClearActionItemAssignmentProps,
) => {
  return await mutateObject({
    route: applyContext(GQL, 'CLEAR_ACTION_ITEM_ASSIGNMENT_MUTATION'),
    query: Mutations.CLEAR_ACTION_ITEM_ASSIGNMENT_MUTATION,
    data,
    returnKey: 'clearActionItemAssignment',
  });
};

export interface mutateCompleteActionItemProps {
  actionItems: string[]; // action item uuids
}

export const mutateCompleteActionItem = async (
  data: mutateCompleteActionItemProps,
) => {
  return await mutateObject({
    route: applyContext(GQL, 'COMPLETE_ACTION_ITEM_MUTATION'),
    query: Mutations.COMPLETE_ACTION_ITEM_MUTATION,
    data,
    returnKey: 'completeActionItem',
  });
};
