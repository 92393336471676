import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const MailCheck: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.573 13.706 21 7.808V13h2V5.887c0-1.652-1.348-3-3-3H4c-1.652 0-3 1.348-3 3v12c0 1.652 1.348 3 3 3h7v-2H4c-.548 0-1-.452-1-1V7.807l8.427 5.9a1 1 0 0 0 1.146 0ZM12 11.666 3.107 5.442c.165-.327.505-.554.893-.554h16c.388 0 .728.227.893.554L12 11.667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.64 15.232a1 1 0 0 1 .128 1.408l-5 6a1 1 0 0 1-1.408.128l-3-2.5a1 1 0 0 1 1.28-1.536l2.232 1.86 4.36-5.232a1 1 0 0 1 1.408-.128Z"
      fill="currentColor"
    />
  </Icon>
);

MailCheck.displayName = 'MailCheckIcon';
