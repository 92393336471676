import { FC } from 'react';
import { Box, BoxProps, Tooltip } from '@chakra-ui/react';
import { WorkerTierOptions } from 'types/src/user';
import { getTierColor } from 'utils/helpers/workers';

interface WorkerImageProps {
  image: string;
  name?: string;
  uuid?: number;
  tier?: WorkerTierOptions;
  hasTooltip?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  containerProps?: BoxProps;
  isThumbnail?: boolean;
}

const styles = {
  xs: { imageSize: 6 },
  sm: { imageSize: 8 },
  md: { imageSize: 10 },
  lg: { imageSize: 12 },
  xl: { imageSize: 20 },
};

const getStylesBySize = size => styles[size];

export const WorkerImage: FC<WorkerImageProps> = ({
  image,
  name,
  tier,
  size = 'md',
  hasTooltip = false,
  containerProps,
  isThumbnail = false,
}) => {
  const imageUrl = isThumbnail
    ? `url(${process.env.NEXT_PUBLIC_BUCKET_URL}${image.substring(0, image.lastIndexOf('.'))}_thumbnail.jpg)`
    : `url(${process.env.NEXT_PUBLIC_BUCKET_URL}${image})`;

  const avatar = (
    <Box
      borderRadius="full"
      zIndex={1}
      position="relative"
      overflow="hidden"
      backgroundColor="main.light"
      backgroundSize="cover"
      backgroundPosition="center center"
      backgroundImage={imageUrl}
      h={getStylesBySize(size).imageSize}
      w={getStylesBySize(size).imageSize}
      flexShrink={0}
      borderStyle="solid"
      borderWidth={tier && 2}
      borderColor={tier ? `${getTierColor(tier)}.300` : 'transparent'}
      {...containerProps}
    />
  );

  if (!hasTooltip) return avatar;

  return (
    <Tooltip
      label={name}
      placement="top"
      backgroundColor="blackAlpha.900"
      openDelay={500}
      gutter={4}
    >
      {avatar}
    </Tooltip>
  );
};
