/**
 * @function requireProperties
 * @summary Ensures thata given data object has all the required data properties. Used to define minimum scope for component functionality.
 * @param Object - Data
 * @param properties - List of keys to look up in the data object
 * @param callback - function to invoke in the event of missing properties
 * @returns void
 */
export const requireProperties = (
  obj: Object,
  properties: Array<string>,
  callback: (err: string) => void,
) => {
  if (!obj) return;
  if (!(obj instanceof Object)) return;
  if (!Object.entries(obj).length) return;
  const missing = [];
  // Recursive lookup, resolves bool
  const lookup = (ob: Object, property: string): boolean => {
    if (property.includes(".")) {
      const [first, ...rest] = property.split(".")
      if (!Object.keys(ob).includes(first)){
        return false
      }
      return lookup(ob[first], rest.join("."))
    }
    return Object.keys(ob).includes(property)
  };

  properties.forEach(prop => {
    if(!lookup(obj, prop)){
      missing.push(prop)
    }
  });
  if (missing.length) {
    callback(`Missing data: ${missing.join(', ')}`);
  }
};
