import { _PAGING_PARAMS, _PAGING_VARIABLES } from '../_paging';
import { _USERS_PARAMS, _USERS_VARIABLES } from './user.helpers';

export * from './user.oz.retailer';
export * from './user.oz.worker';

export const USER_SELECT_QUERY = `
  query selectUsersQuery (${_USERS_PARAMS}) {
    users (${_USERS_VARIABLES}) {
      uuid
      firstName
      lastName
      profileImg
    }
  }
`;

export const STAFF_USERS_LIST_QUERY = `
  query STAFF_USERS_LIST_QUERY (
    $status: [String]
    $role: [String]
    ${_PAGING_PARAMS}
  ) {
    users (
      status: $status
      role: $role
      ${_PAGING_VARIABLES}
    ) {
      firstName
      lastName
      uuid
    }
  }
`;
