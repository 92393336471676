import {
  convertEnumToSelectOptions,
  fetchOnboardingScheduleProps,
  getGenericDateRanges,
} from 'helpers';
import { SelectOption } from 'types';

export enum OnboardingTimingFilters {
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  NEXT_7_DAYS = 'next 7',
  THIS_WEEK = 'this week',
  LAST_WEEK = 'last week',
  THIS_MONTH = 'this month',
  LAST_MONTH = 'last month',
}

export enum OnboardingActivateStatus {
  ACTIVE = 'active',
  REJECT = 'reject',
}

export const scheduleTimingOptions = convertEnumToSelectOptions(
  OnboardingTimingFilters,
);

export const shiftTimingOptions = [
  ...convertEnumToSelectOptions(OnboardingTimingFilters),
  { label: 'No Flex', value: 'none' },
];

export interface OnboardingsFiltersState {
  onboardingTiming?: SelectOption[];
  onboardingShiftTiming?: SelectOption[];
  onboardingOwners?: SelectOption[];
  onboardingMarkets?: SelectOption[];
  onboardingIsComplete?: SelectOption[];
}

export interface OnboardingsFilterSlice extends OnboardingsFiltersState {
  resetOnboardingsFilters: () => void;
}

export const defaultOnboardingsFiltersState: OnboardingsFiltersState = {
  onboardingTiming: [],
  onboardingShiftTiming: [],
  onboardingOwners: [],
  onboardingMarkets: [],
  onboardingIsComplete: [{ value: 'false', label: 'No' }],
};

export const onboardingsFiltersSlice = set => ({
  ...defaultOnboardingsFiltersState,
  resetOnboardingsFilters: () => set(defaultOnboardingsFiltersState),
});

export const getParsedOnboardingFilters = (
  filters: Partial<OnboardingsFiltersState>,
) => {
  const {
    onboardingTiming,
    onboardingShiftTiming,
    onboardingOwners,
    onboardingMarkets,
    onboardingIsComplete,
  } = filters || {};
  const graphFilters = {} as fetchOnboardingScheduleProps;
  graphFilters.owners = onboardingOwners?.map(o => o.value);
  graphFilters.markets = onboardingMarkets?.map(m => parseInt(m.value));

  if (onboardingIsComplete?.length > 0) {
    graphFilters.isComplete = onboardingIsComplete[0].value === 'true';
  } else {
    graphFilters.isComplete = null;
  }

  if (onboardingTiming?.length > 0) {
    const onboardingTimingValue = onboardingTiming[0]
      .value as OnboardingTimingFilters;
    const parsedTiming = timingParser(onboardingTimingValue);
    graphFilters.since = parsedTiming?.since
      ? new Date(parsedTiming.since)
      : null;
    graphFilters.to = parsedTiming?.to ? new Date(parsedTiming.to) : null;
  } else {
    graphFilters.since = null;
    graphFilters.to = null;
  }

  if (onboardingShiftTiming?.length > 0) {
    const onboardingShiftTimingValue =
      onboardingShiftTiming[0].value === 'none'
        ? null
        : (onboardingShiftTiming[0].value as OnboardingTimingFilters);
    if (!onboardingShiftTimingValue) {
      graphFilters.shiftSince = null;
      graphFilters.shiftTo = null;
      graphFilters.hasShift = false;
      return graphFilters;
    }
    const parsedTiming = timingParser(onboardingShiftTimingValue);
    graphFilters.shiftSince = parsedTiming?.since
      ? new Date(parsedTiming.since)
      : null;
    graphFilters.shiftTo = parsedTiming?.to ? new Date(parsedTiming.to) : null;
  } else {
    graphFilters.shiftSince = null;
    graphFilters.shiftTo = null;
    graphFilters.hasShift = null;
  }

  return graphFilters;
};

export const timingParser = (
  timePeriod: OnboardingTimingFilters,
): {
  since: Date;
  to: Date;
} => {
  const {
    today,
    endOfToday,
    tomorrow,
    endOfTomorrow,
    next7,
    startOfWeek,
    endOfWeek,
    startOfPrevWeek,
    endOfPrevWeek,
    startOfMonth,
    endOfMonth,
    startOfPrevMonth,
    endOfPrevMonth,
  } = getGenericDateRanges();

  const timePeriodValue = OnboardingTimingFilters[timePeriod];

  switch (timePeriodValue) {
    case OnboardingTimingFilters.TODAY:
      return {
        since: today,
        to: endOfToday,
      };
    case OnboardingTimingFilters.TOMORROW:
      return {
        since: tomorrow,
        to: endOfTomorrow,
      };
    case OnboardingTimingFilters.NEXT_7_DAYS:
      return {
        since: today,
        to: next7,
      };
    case OnboardingTimingFilters.THIS_WEEK:
      return {
        since: startOfWeek,
        to: endOfWeek,
      };
    case OnboardingTimingFilters.LAST_WEEK:
      return {
        since: startOfPrevWeek,
        to: endOfPrevWeek,
      };
    case OnboardingTimingFilters.THIS_MONTH:
      return {
        since: startOfMonth,
        to: endOfMonth,
      };
    case OnboardingTimingFilters.LAST_MONTH:
      return {
        since: startOfPrevMonth,
        to: endOfPrevMonth,
      };
    default:
      return {
        since: null,
        to: null,
      };
  }
};
