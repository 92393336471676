import { FC } from 'react';
import { BoxProps, Flex } from '@chakra-ui/react';
import { useFriendlyDate } from 'hooks';
import { ICON_NAMES } from 'icons';
import { shift } from 'types';
import { TextAndIcon } from 'ui';

const defaultTextAndIconProps = {
  containerProps: { flexShrink: 0 },
  iconProps: { color: 'text.hint' },
  textProps: {
    color: 'text.hint',
    fontSize: 'sm',
    fontWeight: 'semi',
    lineHeight: 'short',
    ml: 2,
  },
};

interface ShiftMicroMetaProps {
  shift: shift;
  containerProps?: BoxProps;
}

export const ShiftMicroMeta: FC<ShiftMicroMetaProps> = ({
  shift,
  containerProps,
}) => {
  const { friendly: friendlyDate } = useFriendlyDate(
    shift?.start,
    shift?.end,
    shift?.retailerLocation?.timezone,
  );
  return (
    <Flex flexDirection="column" {...containerProps}>
      <TextAndIcon
        icon={ICON_NAMES.locationmarker}
        text={`${shift?.retailerLocation?.retailer?.name} - ${shift?.retailerLocation?.name}`}
        {...defaultTextAndIconProps}
      />
      <TextAndIcon
        icon={ICON_NAMES.calendar}
        text={friendlyDate}
        {...defaultTextAndIconProps}
      />
      <TextAndIcon
        icon={ICON_NAMES.clock}
        text={`${shift?.duration} hours`}
        {...defaultTextAndIconProps}
      />
    </Flex>
  );
};
