import { FC, Fragment } from 'react';
import {
  Box,
  BoxProps,
  Flex,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { Icon, ICON_NAMES } from 'icons';

export const menuOptionContainerStyles = {
  _disabled: {
    cursor: 'not-allowed',
    backgroundColor: 'blackAlpha.300',
  },
  _hover: {
    backgroundColor: 'blackAlpha.100',
  },
  align: 'center',
  color: 'blackAlpha.700',
  cursor: 'pointer',
  px: 3,
  py: 2,
  width: '100%',
};

export interface MenuOption {
  action?: (any) => void;
  label?: JSX.Element;
  data?: any; // eslint-disable-line
  icon?: JSX.Element;
  component?: JSX.Element;
}

interface MenuProps {
  menuOptions: Array<MenuOption>;
  popoverProps?: PopoverProps;
  containerProps?: BoxProps;
  contentProps?: PopoverContentProps;
  onClose?: () => void;
}

export const Menu: FC<MenuProps> = ({
  menuOptions,
  popoverProps,
  containerProps,
  contentProps,
  onClose = () => {},
}) => {
  return (
    <Popover
      onClose={onClose}
      closeOnBlur={true}
      placement="bottom-end"
      isLazy
      {...popoverProps}
    >
      <PopoverTrigger>
        <Box {...containerProps}>
          <IconButton
            zIndex={1}
            aria-label="more"
            variant="transparent"
            size="sm"
            color="text.disabled"
            icon={<Icon name="dotshorizontal" w={5} h={5} />}
          />
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent borderColor="main.medium" {...contentProps}>
          <PopoverBody p={0}>
            {menuOptions.map((option, index) => (
              <Fragment key={index}>
                {option?.component ? (
                  option?.component
                ) : (
                  <Flex
                    onClick={() => option.action(option.data)}
                    {...menuOptionContainerStyles}
                  >
                    {option?.icon && <Flex mr={1}>{option?.icon}</Flex>}
                    {option.label}
                  </Flex>
                )}
              </Fragment>
            ))}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

const getBackgroundColorByState = (state: MenuActionState) => {
  if (state === MenuActionState.CONFIRMING) {
    return 'orange.50';
  }
  if (state === MenuActionState.SUCCESS) {
    return 'green.50';
  }
  if (state === MenuActionState.ERROR) {
    return 'red.50';
  }
  return 'transparent';
};

const getColorByState = (state: MenuActionState) => {
  if (state === MenuActionState.CONFIRMING) {
    return 'orange.500';
  }
  if (state === MenuActionState.PROCESSING) {
    return 'blackAlpha.400';
  }
  if (state === MenuActionState.SUCCESS) {
    return 'green.500';
  }
  if (state === MenuActionState.ERROR) {
    return 'red.500';
  }
  return 'blackAlpha.700';
};

const getTextByState = (state: MenuActionState, fallback: string) => {
  if (state === MenuActionState.CONFIRMING) {
    return 'Are You Sure?';
  }
  if (state === MenuActionState.PROCESSING) {
    return 'Processing...';
  }
  if (state === MenuActionState.SUCCESS) {
    return 'Success!';
  }
  if (state === MenuActionState.ERROR) {
    return 'Error';
  }
  return fallback;
};

const getIconByState = (state: MenuActionState, fallback: ICON_NAMES) => {
  if (state === MenuActionState.CONFIRMING) {
    return ICON_NAMES.exclamation;
  }
  if (state === MenuActionState.PROCESSING) {
    return ICON_NAMES.loader;
  }
  if (state === MenuActionState.SUCCESS) {
    return ICON_NAMES.check;
  }
  if (state === MenuActionState.ERROR) {
    return ICON_NAMES.x;
  }
  return fallback;
};

export const menuOptionStyles = (
  text,
  icon,
  state = MenuActionState.DEFAULT,
) => ({
  containerProps: {
    _disabled: {
      cursor: 'not-allowed',
      backgroundColor: 'blackAlpha.300',
    },
    _hover: {
      backgroundColor: 'blackAlpha.100',
    },
    align: 'center',
    cursor: 'pointer',
    px: 3,
    py: 2,
    width: '100%',
    minWidth: 180,
    color: getColorByState(state),
    backgroundColor: getBackgroundColorByState(state),
  },
  textProps: {
    ml: 2,
  },
  text: getTextByState(state, text),
  icon: getIconByState(state, icon),
});

export enum MenuActionState {
  DEFAULT = 'default',
  CONFIRMING = 'confirming',
  PROCESSING = 'processing',
  SUCCESS = 'success',
  ERROR = 'error',
}
