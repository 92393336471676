import { useQuery } from '@tanstack/react-query';
import { fetchJobs, JobNamespaceType } from 'helpers';
import { ReflexResponse } from 'types';

export const useJobsQuery = ({ uuid, loadOnMount = true }) => {
  const { data, error } = useQuery<ReflexResponse>(
    ['jobs'],
    () => fetchJobs({ uuid: uuid, objectType: JobNamespaceType.SHIFT }),
    { enabled: loadOnMount },
  );
  return { data, error };
};
