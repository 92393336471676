import { addDays, endOfDay } from 'date-fns';
import { fetchActionItemsProps } from 'helpers';
import {
  ActionItemStatus,
  ActionItemTree,
  ActionItemType,
  getActionItemGraphTypes,
  getActionItemTypesByBoard,
  SelectOption,
} from 'types';
import { convertEnumToFilterOptions } from 'utils/helpers/filters';

export interface ActionItemsFiltersState {
  actionItemsMarkets: SelectOption[];
  actionItemsStatus: SelectOption[];
  actionItemsTypes: SelectOption[];
  actionItemsAssignedTo: SelectOption[];
  actionItemsUnassigned: SelectOption[];
}

export interface ActionItemsFiltersSlice extends ActionItemsFiltersState {
  resetActionItemsFilters: () => void;
}

export const defaultActionItemsFiltersState: ActionItemsFiltersState = {
  actionItemsMarkets: [],
  actionItemsStatus: convertEnumToFilterOptions(ActionItemStatus)?.filter(
    o => o.value === ActionItemStatus.OPEN,
  ),
  actionItemsTypes: [],
  actionItemsAssignedTo: [],
  actionItemsUnassigned: [],
};

export const actionItemsFiltersSlice = set => ({
  ...defaultActionItemsFiltersState,
  resetActionItemsFilters: () => {
    set(prev => ({ ...prev, ...defaultActionItemsFiltersState }));
  },
});

export const getParsedActionItemsFilters = (
  filters: Partial<ActionItemsFiltersState>,
) => {
  const {
    actionItemsMarkets,
    actionItemsStatus,
    actionItemsTypes,
    actionItemsAssignedTo,
    actionItemsUnassigned,
  } = filters;

  const graphFilters = {} as fetchActionItemsProps;

  graphFilters.restrictTypesTo = getActionItemTypesByBoard('worker');

  if (actionItemsMarkets?.length) {
    graphFilters.markets = actionItemsMarkets.map(market =>
      parseInt(market.value),
    );
  }

  if (actionItemsStatus?.length) {
    const statusValue = actionItemsStatus?.[0]?.value;
    switch (statusValue) {
      case ActionItemStatus.OPEN:
        graphFilters.isResolved = false;
        break;
      case ActionItemStatus.RESOLVED:
        graphFilters.isResolved = true;
        graphFilters.resolvedSince = addDays(new Date(), -2);
        break;
      default:
        break;
    }
  }

  if (actionItemsTypes?.length) {
    const actionItemTypeValues = actionItemsTypes.map(
      type => type.value,
    ) as (keyof typeof ActionItemTree)[];

    graphFilters.actionTypes = getActionItemGraphTypes(actionItemTypeValues);
  }

  if (actionItemsAssignedTo?.length) {
    graphFilters.assignedTo = actionItemsAssignedTo.map(user => user.value);
  }

  if (actionItemsUnassigned?.length) {
    graphFilters.unassigned = actionItemsUnassigned?.[0]?.value === 'true';
  }

  return graphFilters;
};

export const getParsedShiftActionItemsFilters = (
  filters: Partial<ActionItemsFiltersState>,
) => {
  const {
    actionItemsMarkets,
    actionItemsTypes,
    actionItemsAssignedTo,
    actionItemsUnassigned,
  } = filters;

  const graphFilters = {} as fetchActionItemsProps;

  graphFilters.isResolved = false;
  graphFilters.consolidateShifts = true;
  graphFilters.restrictTypesTo = getActionItemTypesByBoard('shift');
  graphFilters.shiftStartTo = endOfDay(addDays(new Date(), 2));

  if (actionItemsMarkets?.length) {
    graphFilters.markets = actionItemsMarkets.map(market =>
      parseInt(market.value),
    );
  }

  if (actionItemsTypes?.length) {
    graphFilters.actionTypes = [
      ...new Set(
        actionItemsTypes.flatMap(
          type =>
            ActionItemTree[type.value as keyof typeof ActionItemTree].types,
        ),
      ),
    ];
  }

  if (actionItemsAssignedTo?.length) {
    graphFilters.assignedTo = actionItemsAssignedTo.map(user => user.value);
  }

  if (actionItemsUnassigned?.length) {
    graphFilters.unassigned = actionItemsUnassigned?.[0]?.value === 'true';
  }

  return graphFilters;
};
