import { useEffect, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { invalidateCache } from 'hooks';
import { useSocketHelperStore } from './SocketHelperStore';

const REFRESH_INTERVAL = process.env.NEXT_PUBLIC_SOCKET_REFETCH_INTERVAL
  ? parseInt(process.env.NEXT_PUBLIC_SOCKET_REFETCH_INTERVAL)
  : 10000;

export const useSocketDataRefresher = () => {
  const queryClient = useQueryClient();
  const toInvalidate = useSocketHelperStore(s => s.toInvalidate);
  const clearToInvalidate = useSocketHelperStore(s => s.clearToInvalidate);
  const setIsReloading = useSocketHelperStore(s => s.setIsReloading);
  const invalidatedQueriesRef = useRef(new Set());

  useEffect(() => {
    const checkAndInvalidate = () => {
      const queriesToInvalidate = Object.keys(toInvalidate);
      if (queriesToInvalidate?.length > 0 && REFRESH_INTERVAL >= 0) {
        setIsReloading(true);
        invalidatedQueriesRef.current = new Set(queriesToInvalidate);
        invalidateCache(queriesToInvalidate, queryClient, false);
        clearToInvalidate();

        const unsubscribe = queryClient.getQueryCache().subscribe(event => {
          if (event.type === 'updated') {
            const { query } = event || {};
            if (invalidatedQueriesRef.current.has(query?.queryKey?.[0])) {
              if (
                query?.state?.status === 'success' ||
                query?.state?.status === 'error'
              ) {
                invalidatedQueriesRef.current.delete(query?.queryKey?.[0]);
              }
            }

            if (invalidatedQueriesRef?.current?.size === 0) {
              setIsReloading(false);
              unsubscribe();
            }
          }
        });
      }
    };

    const intervalId = setInterval(checkAndInvalidate, REFRESH_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [toInvalidate, clearToInvalidate, queryClient, setIsReloading]);
};
