import { shift, workershift } from 'types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  ActionState,
  overbookGraphResponse,
} from 'components/shift/OverbookTools';

export { ActionState };

interface OverbookStore {
  shift: shift;
  setShift: (shift: shift) => void;
  newShift: shift;
  setNewShift: (shift: shift) => void;
  workershift: workershift;
  setWorkershift: (workershift: workershift) => void;
  meta: {
    actionState: ActionState;
    defaultActionState: ActionState;
    graphResponse?: overbookGraphResponse;
  };
  updateMeta: <K extends keyof OverbookStore['meta']>(
    key: K,
    value: OverbookStore['meta'][K],
  ) => void;
  resetStore: () => void;
}

const defaultMeta = {
  actionState: ActionState.CHOICE,
  defaultActionState: ActionState.CHOICE,
  graphResponse: null,
};

const overbookStore = (set): OverbookStore => ({
  shift: {},
  setShift: shift => set(() => ({ shift }), false, 'setShift'),
  newShift: {},
  setNewShift: newShift => set(() => ({ newShift }), false, 'setNewShift'),
  workershift: {},
  setWorkershift: workershift =>
    set(() => ({ workershift }), false, 'setWorkershift'),
  meta: defaultMeta,
  updateMeta: (key, value) =>
    set(
      prev => ({
        ...prev,
        meta: { ...prev?.meta, [key]: value },
      }),
      false,
      'updateMeta',
    ),
  resetStore: () =>
    set({
      meta: defaultMeta,
      shift: {},
      newShift: {},
      workershift: {},
    }),
});

export const useOverbookStore = create(devtools(overbookStore));
