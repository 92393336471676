import { ICON_NAMES } from 'icons';
import {
  OPS_ACTION_SHIFTS,
  OPS_BRAND,
  OPS_BRANDS,
  OPS_HOME,
  OPS_LOCATION,
  OPS_LOCATIONS,
  OPS_ONBOARDINGS,
  OPS_SHIFT,
  OPS_SHIFTS,
  OPS_USER,
  OPS_USERS,
  ozNotification,
  OzNotificationType,
} from 'types';

const getNotificationType = (link: string): OzNotificationType => {
  return link.split('/')[0] as OzNotificationType;
};

const getNotificationDetails = (link: string) => {
  return link.split('/').slice(1);
};

export const getNotificationRoute = (notification: ozNotification) => {
  if (!notification?.link) return null;

  const type = getNotificationType(notification?.link);
  const details = getNotificationDetails(notification?.link);

  const getRouteWithTab = (route: string, details: string[]) => {
    return `${route.replace(/\[uuid\]/i, details[0])}${details?.[1] ? `?tab=${details[1]}` : ''}`;
  };

  switch (type) {
    case OzNotificationType.ACTION_ITEM:
      return OPS_ACTION_SHIFTS;
    case OzNotificationType.RETAILER:
      if (details?.length) {
        return getRouteWithTab(OPS_BRAND, details);
      }
      return OPS_BRANDS;
    case OzNotificationType.LOCATION:
      if (details?.length) {
        return getRouteWithTab(OPS_LOCATION, details);
      }
      return OPS_LOCATIONS;
    case OzNotificationType.ONBOARDING_SCHEDULE:
      return OPS_ONBOARDINGS;
    case OzNotificationType.SHIFT:
      if (details?.length) {
        return getRouteWithTab(OPS_SHIFT, details);
      }
      return OPS_SHIFTS;
    case OzNotificationType.USER:
      if (details?.length) {
        return getRouteWithTab(OPS_USER, details);
      }
      return OPS_USERS;
    default:
      return OPS_HOME;
  }
};

export const getNotificationIcon = (notification: ozNotification) => {
  const route = getNotificationRoute(notification);
  if (route.includes('flags')) return ICON_NAMES.flag;

  const title = notification?.title?.toLowerCase();
  if (title?.includes('blocked')) return ICON_NAMES.ban;
  if (title?.includes('claimed')) return ICON_NAMES.sparkles;
  if (title?.includes('requested')) return ICON_NAMES.atsign;

  switch (getNotificationType(notification?.link)) {
    case OzNotificationType.ACTION_ITEM:
      return ICON_NAMES.clipboardcheck;
    case OzNotificationType.RETAILER:
      return ICON_NAMES.shoppingbag;
    case OzNotificationType.LOCATION:
      return ICON_NAMES.storefrontalt;
    case OzNotificationType.ONBOARDING_SCHEDULE:
      return ICON_NAMES.calendar;
    case OzNotificationType.SHIFT:
      return ICON_NAMES.sparkle;
    case OzNotificationType.USER:
      return ICON_NAMES.user;
    default:
      return ICON_NAMES.bell;
  }
};
