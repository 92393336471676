import { messageTemplate } from './messageTemplate';
import { WorkerTierOptions } from './user';
import { Prettify } from './_helpers';

export enum PlaybookStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  PAUSED = 'PAUSED',
  COMPLETE = 'COMPLETE',
  STOPPED = 'STOPPED',
}

export const PlaybookStatusLabel = {
  [PlaybookStatus.PENDING]: 'Starting',
  [PlaybookStatus.IN_PROGRESS]: 'Running',
  [PlaybookStatus.PAUSED]: 'Paused',
  [PlaybookStatus.COMPLETE]: 'Complete',
  [PlaybookStatus.STOPPED]: 'Cancelled',
} as const;

export enum PlaybookStepStatus {
  // model statuses
  QUEUED = 'QUEUED',
  SKIPPED = 'SKIPPED',
  COMPLETE = 'COMPLETE',
  // virtual statuses
  INACTIVE = 'INACTIVE',
  PAUSED = 'PAUSED',
  PROCESSING = 'PROCESSING',
}

export const PlaybookStepStatusLabel = {
  [PlaybookStepStatus.QUEUED]: 'Scheduled',
  [PlaybookStepStatus.SKIPPED]: 'Skipped',
  [PlaybookStepStatus.COMPLETE]: 'Complete',
  [PlaybookStepStatus.INACTIVE]: 'Inactive',
  [PlaybookStepStatus.PAUSED]: 'Paused',
  [PlaybookStepStatus.PROCESSING]: 'Processing',
} as const;

export enum PlaybookAction {
  CREATE_WSOS = 'CREATE_WSOS',
  SEND_PUSH = 'SEND_PUSH',
}

export const PlaybookActionTypeLabels = {
  [PlaybookAction.CREATE_WSOS]: 'Add WSOs',
  [PlaybookAction.SEND_PUSH]: 'Message (Push)',
} as const;

export type playbookStep = {
  uuid?: string;
  action?: PlaybookAction;
  lastProcessed?: Date;
  messagingTemplate?: messageTemplate;
  name?: string;
  processTime?: Date;
  projectedCount?: number;
  status?: PlaybookStepStatus;
  timingPercentage?: number;
  playbookStepNumber?: number;
  actualCount?: number;
};

export type playbook = {
  uuid?: string;
  name?: string;
  globalWait?: number;
  version?: number;
  configuration?: string; // JSON
};

export type activePlaybook = Prettify<
  playbook & {
    currentStep?: playbookStep;
    messagingEnabled?: boolean;
    nextStep?: playbookStep;
    projectedFinalMessagingCount?: number;
    projectedFinalWsoCount?: number;
    actualMessagingCount?: number;
    actualWsoCount?: number;
    status?: PlaybookStatus;
    steps?: playbookStep[];
  }
>;

export type parsedConfigurationStep = {
  name?: string;
  action?: PlaybookAction;
  timing_percentage?: number;
  cohort?: {
    tier?: WorkerTierOptions[];
    has_worked_at_location?: boolean;
    has_worked_on_platform?: boolean;
    favorite_at?: boolean;
  };
  playbook_message_template_id?: number;
};

export type parsedPlaybookConfiguration = {
  name?: string;
  steps?: parsedConfigurationStep[];
};
