export const headerPatternColorOptions = {
  'navy.500':
    'invert(25%) sepia(17%) saturate(1317%) hue-rotate(162deg) brightness(100%) contrast(94%)',
  'navy.700':
    'invert(18%) sepia(8%) saturate(3204%) hue-rotate(162deg) brightness(91%) contrast(90%)',
  'navy.400':
    'invert(20%) sepia(27%) saturate(305%) hue-rotate(162deg) brightness(91%) contrast(96%)',
  'gray.900':
    'invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)',
  'blue.500':
    'invert(25%) sepia(20%) saturate(6451%) hue-rotate(203deg) brightness(87%) contrast(84%)',
  'blue.700':
    'invert(16%) sepia(46%) saturate(2915%) hue-rotate(204deg) brightness(95%) contrast(85%)',
  'purple.500':
    'invert(16%) sepia(40%) saturate(6408%) hue-rotate(252deg) brightness(92%) contrast(98%)',
  'purple.700':
    'invert(16%) sepia(32%) saturate(3000%) hue-rotate(220deg) brightness(100%) contrast(100%)',
  'green.600':
    'invert(12%) sepia(67%) saturate(7030%) hue-rotate(163deg) brightness(95%) contrast(94%)',
  'green.700':
    'invert(11%) sepia(95%) saturate(5864%) hue-rotate(164deg) brightness(89%) contrast(93%)',
  'pink.500':
    'invert(90%) sepia(15%) saturate(3175%) hue-rotate(296deg) brightness(88%) contrast(104%)',
  'pink.600':
    'invert(60%) sepia(18%) saturate(1020%) hue-rotate(314deg) brightness(106%) contrast(63%)',
  'orange.400':
    'invert(30%) sepia(66%) saturate(1356%) hue-rotate(359deg) brightness(100%) contrast(104%)',
  'red.500':
    'invert(16%) sepia(93%) saturate(3000%) hue-rotate(353deg) brightness(90%) contrast(90%)',
};

export const patternOptions = {
  squaresAlt: 'squares-alt.png',
  squares: 'squares.png',
  squaresNeat: 'squares-neat.png',
  intersection: 'intersection.png',
  motion: 'motion.png',
  houndstooth: 'houndstooth.png',
  connections: 'connections.png',
  circuitBoard: 'circuit-board.png',
  parkay: 'parkay.png',
  circles: 'circles.png',
  glam: 'glam.png',
  leaf: 'leaf.png',
  typography: 'typography.png',
  food: 'food.png',
  overlappingHexagons: 'overlapping-hexagons.png',
  tile: 'tile.png',
  randomShapes: 'random-shapes.png',
  fancyRectangles: 'fancy-rectangles.png',
  overlappingCircles: 'overlapping-circles.png',
  bubbles: 'bubbles.png',
  groovy: 'groovy.png',
  snowflakes: 'snowflakes.png',
  fall: 'fall.png',
  cheers: 'cheers.png',
  lfg: 'lfg.png',
  values: 'values.png',
  retail: 'retail.png',
  cutout: 'cutout.png',
  piano: 'piano.png',
};
