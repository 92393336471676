import { CamelToSnakeCaseNested } from './_helpers';
import { uuid } from './common';
import { terms } from './terms';
import { user } from './user';

enum DocumentServiceProviderOptions {
  HELLOSIGN = 'HELLOSIGN',
  INTERNAL = 'INTERNAL',
}

export type document = {
  id?: string;
  uuid?: uuid;
  createdAt?: string; // DateTime
  updatedAt?: string; // DateTime
  name?: string; // Internal
  title?: string; // External
  body?: string;
  externalId?: string;
  signatureRequired?: boolean;
  signed?: string | null; // DateTime
  signedBy?: user;
  serviceProvider?: DocumentServiceProviderOptions;
  terms?: terms;
};

export type apiDocument = CamelToSnakeCaseNested<document>;
