import { _SHIFTS_LIST_PLAYBOOK } from './playbook';
import {
  _SHIFTS_CORE,
  _SHIFTS_NOTES,
  _SHIFTS_TAGS,
  _SHIFTS_WORKERS,
} from './shift';
import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

const _ACTION_ITEMS_META_VARIABLES = `
  meta: {
    total: "total"
  }
`;

const _ACTION_ITEMS_SHARED_PARAMS = `
  $actionTypes: [String]
  $assignedTo: [String]
  $isResolved: Boolean
  $restrictTypesTo: [String]
  $unassigned: Boolean
`;

const _ACTION_ITEMS_SHARED_VARIABLES = `
  actionTypes: $actionTypes
  assignedTo: $assignedTo
  isResolved: $isResolved
  restrictTypesTo: $restrictTypesTo
  unassigned: $unassigned
`;

const _ACTION_ITEMS_PARAMS = `
  $consolidateShifts: Boolean
  $markets: [Int]
  $shiftStartSince: DateTime
  $shiftStartTo: DateTime
  $resolvedSince: DateTime
  $resolvedTo: DateTime
  ${_ACTION_ITEMS_SHARED_PARAMS}
  ${_PAGING_PARAMS}
`;

const _ACTION_ITEMS_VARIABLES = `
  consolidateShifts: $consolidateShifts
  markets: $markets
  shiftStartSince: $shiftStartSince
  shiftStartTo: $shiftStartTo
  resolvedSince: $resolvedSince
  resolvedTo: $resolvedTo
  ${_ACTION_ITEMS_SHARED_VARIABLES}
  ${_PAGING_VARIABLES}
`;

const _ACTION_ITEM_ASSIGNED_TO = `
  assignedTo {
    uuid
    firstName
    lastName
  }
`;

const _ACTION_ITEM_RESOLVED_BY = `
  resolvedBy {
    uuid
    firstName
    lastName
  }
`;

const _ACTION_ITEM_FIELDS = `
  id
  uuid
  type
  priority
  createdAt
  resolvedAt
  ${_ACTION_ITEM_ASSIGNED_TO}
  ${_ACTION_ITEM_RESOLVED_BY}
`;

export const ACTION_ITEMS_QUERY = `
  query ACTION_ITEMS_QUERY (${_ACTION_ITEMS_PARAMS}) {
    actionItems (${_ACTION_ITEMS_VARIABLES}) {
      ${_ACTION_ITEM_FIELDS}
      worker {
        uuid
        firstName
        lastName
        preferredName
        tier
        profileImg
        markets {
          name
          state
          abbreviation
        }
      }
    }
  }
`;

const _SHIFT_ACTION_ITEM_WORKER = `
  worker {
    uuid
    firstName
    lastName
    preferredName
    tier
    profileImg
    markets {
      name
      state
      abbreviation
    }
  }
`;

const _SHIFT_ACTION_ITEMS_FIELDS_AND_WORKER = `
  shift {
    ${_SHIFTS_CORE}
    ${_SHIFTS_WORKERS}
    ${_SHIFTS_TAGS}
    ${_SHIFTS_NOTES}
    ${_SHIFTS_LIST_PLAYBOOK}
  }
  ${_SHIFT_ACTION_ITEM_WORKER}
`;

export const SHIFTS_ACTION_ITEMS_QUERY = `
  query SHIFTS_ACTION_ITEMS_QUERY (${_ACTION_ITEMS_PARAMS}) {
    actionItems (
      ${_ACTION_ITEMS_VARIABLES}
      ${_ACTION_ITEMS_META_VARIABLES}
    ) {
      ${_ACTION_ITEM_FIELDS}
      ${_SHIFT_ACTION_ITEMS_FIELDS_AND_WORKER}       
      siblingTasks (${_ACTION_ITEMS_SHARED_VARIABLES}) {
        ${_ACTION_ITEM_FIELDS}
        ${_SHIFT_ACTION_ITEM_WORKER}
      }
    }
  }
`;

export const ACTION_ITEMS_SELECT_QUERY = `
  query ACTION_ITEMS_SELECT_QUERY (${_ACTION_ITEMS_PARAMS}) {
    actionItems (${_ACTION_ITEMS_VARIABLES}) {
      uuid
      ${_ACTION_ITEM_ASSIGNED_TO}
      siblingTasks (${_ACTION_ITEMS_SHARED_VARIABLES}) {
        uuid
        ${_ACTION_ITEM_ASSIGNED_TO}
      }
    }
  }
`;
