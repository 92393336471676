import { OPS_ROUTES } from 'types/src/routes';

/**
 * ## Action Origins
 * Can be concatenated and appended to requests
 * for tracking, events, analysis, etc
 *
 *  example: API/GQL?origin='page-shifts-section-table'
 *  example: API/GQL?origin='page-retailers-section-modal'
 */
export enum ActionOrigin {
  // Page, routes, views
  PAGE_LOCATION = 'page-location',
  PAGE_LOCATIONS = 'page-locations',
  PAGE_SHIFTS = 'page-shifts',
  PAGE_SHIFT = 'page-shift',
  PAGE_WORKERS = 'page-workers',
  PAGE_WORKER = 'page-worker',
  PAGE_REVIEWS = 'page-reviews',

  // FUTURE: add UI sections, like dialog, modal, table, etc
}

export const actionOriginRouteMap = {
  [OPS_ROUTES.home]: ActionOrigin.PAGE_SHIFTS,
  [OPS_ROUTES.shifts]: ActionOrigin.PAGE_SHIFTS,
  [OPS_ROUTES.shift]: ActionOrigin.PAGE_SHIFT,
  [OPS_ROUTES.workers]: ActionOrigin.PAGE_WORKERS,
  [OPS_ROUTES.worker]: ActionOrigin.PAGE_WORKER,
  [OPS_ROUTES.location]: ActionOrigin.PAGE_LOCATION,
  [OPS_ROUTES.locations]: ActionOrigin.PAGE_LOCATIONS,
  [OPS_ROUTES.reviews]: ActionOrigin.PAGE_REVIEWS,
};
