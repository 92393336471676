import { FC } from 'react';
import { ContainerProps, Flex, IconButton, Text } from '@chakra-ui/react';
import { Icon, ICON_NAMES } from 'icons';
import { useRouter } from 'next/router';
import { ozNotification } from 'types';
import {
  getNotificationIcon,
  getNotificationRoute,
} from 'utils/helpers/notifications';
import { useNotificationsStore } from 'utils/stores';

const MAX_ALERT_CHARS = 74;

export type NotificationAlertProps = {
  notification: ozNotification;
  containerProps?: ContainerProps;
};

export const NotificationAlert: FC<NotificationAlertProps> = ({
  notification,
  containerProps,
}) => {
  const [updateAlerts] = useNotificationsStore(s => [s.updateAlerts]);

  const handleDismiss = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    updateAlerts(notification, 'remove');
  };

  const route = getNotificationRoute(notification);
  const router = useRouter();
  const handleNavigation = () => {
    if (!route) return;
    updateAlerts(notification, 'remove');
    router.push(route);
  };

  return (
    <Flex
      backgroundColor="main.white"
      shadow="md"
      gap={2}
      py={3}
      px={2}
      borderRadius="md"
      borderWidth={1}
      borderColor="blackAlpha.200"
      w={320}
      h={20}
      onClick={handleNavigation}
      cursor={route ? 'pointer' : 'default'}
      position="relative"
      {...containerProps}
    >
      <Icon name={getNotificationIcon(notification)} />
      <Flex flexDirection="column" gap={0.5}>
        <Text variant="header-sm">{notification?.title}</Text>
        <Text variant="body-xs">
          {notification?.subtext?.length > MAX_ALERT_CHARS
            ? `${notification?.subtext?.slice(0, MAX_ALERT_CHARS)}...`
            : notification?.subtext}
        </Text>
      </Flex>
      <IconButton
        aria-label="Dismiss"
        size="sm"
        variant="transparent"
        icon={<Icon name={ICON_NAMES.x} w={4} h={4} />}
        onClick={handleDismiss}
      />
    </Flex>
  );
};
