import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { Logo } from './Logo';

interface LogoSpecialProps {
  animate?: boolean;
}

export const LogoSpecial: FC<LogoSpecialProps> = ({ animate = false }) => {
  return (
    <Flex
      position="relative"
      sx={{
        '@keyframes shine': {
          from: { '-webkit-mask-position': '150%' },
          to: { '-webkit-mask-position': '-50%' },
        },
        ...(animate
          ? {
              '-webkit-mask-image':
                'linear-gradient(-75deg, rgba(0,0,0,.6) 20%, #000 50%, rgba(0,0,0,.6) 80%)',
              '-webkit-mask-size': '200%',
              animation: 'shine 2s infinite',
            }
          : {}),
      }}
    >
      <Logo {...logoProps} />
    </Flex>
  );
};

const logoProps = {
  height: { base: '30px', md: '40px' },
  width: { base: '89px', md: '118px' },
  color: 'main.white',
};
