import { workerBenefit } from 'types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface WorkerBenefitSpendStore {
  workerBenefit: workerBenefit;
  setWorkerBenefit: (workerBenefit: workerBenefit) => void;
}

const workerBenefitSpendStore = (set): WorkerBenefitSpendStore => ({
  workerBenefit: {},
  setWorkerBenefit: workerBenefit =>
    set(() => ({ workerBenefit }), false, 'setWorkerBenefit'),
});

export const useWorkerBenefitSpendStore = create(
  devtools(workerBenefitSpendStore),
);
