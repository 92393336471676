import { FC, ReactElement } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { convertDataToSelectOptions, formatUserNameForDisplay } from 'helpers';
import {
  acquisitionCampaign,
  location,
  LocationStatus,
  market,
  PageSizes,
  retailer,
  SelectOption,
  tag,
  user,
} from 'types';
import { SliceFilterKeys, useFiltersStore } from 'utils/stores/FiltersStore';
import { SelectBrandsList } from 'components/brands/SelectBrandList';
import { SelectAcquisitionCampaignList } from 'components/campaigns/SelectAcquisitionCampaignList';
import { SelectLocationsList } from 'components/locations/SelectLocationList';
import { SelectMarketList } from 'components/markets/SelectMarketList';
import { SelectTagsList } from 'components/tags/SelectTagsList';
import { SelectStaffUserList } from 'components/user/SelectStaffUserList';

export interface ListViewProps {
  onSelect?: () => void;
  filterKey?: keyof SliceFilterKeys;
  isMulti?: boolean;
  title?: string | ReactElement;
}

export const ListViewBrands: FC<ListViewProps> = ({ onSelect, filterKey }) => {
  const [updateFilter] = useFiltersStore(s => [s.updateFilter]);
  const handleSelectBrand = (brand: retailer) => {
    const update = convertDataToSelectOptions([brand], 'name', 'uuid');
    updateFilter(filterKey, update);
    onSelect?.();
  };
  return (
    <Flex flexDirection="column">
      <Text variant="subheader-md">{'Select Brand'}</Text>
      <SelectBrandsList
        queryVariables={{ hasLocations: true }}
        pageSize={PageSizes.ten}
        onSelect={handleSelectBrand}
      />
    </Flex>
  );
};

export const ListViewMarkets: FC<ListViewProps> = ({
  onSelect,
  filterKey,
  isMulti,
  title = 'Select Markets',
}) => {
  const [updateFilter] = useFiltersStore(s => [s.updateFilter]);
  const { [filterKey]: currentValues } = useFiltersStore();
  const handleSelectMarket = (market: market) => {
    const update = convertDataToSelectOptions([market], 'name', 'id');
    if (!isMulti) {
      updateFilter(filterKey, update);
      onSelect?.();
    }
    if (isMulti) {
      const valuesAsSelectOptions = currentValues as SelectOption[];
      const shouldRemove = valuesAsSelectOptions
        ?.map(v => v.value)
        .includes(update[0].value);
      if (filterKey) {
        if (shouldRemove) {
          updateFilter(
            filterKey,
            valuesAsSelectOptions.filter(v => v.value !== update[0].value),
          );
        } else {
          updateFilter(filterKey, [...valuesAsSelectOptions, update[0]]);
        }
      }
      onSelect?.();
    }
  };

  return (
    <Flex flexDirection="column">
      {title && typeof title === 'string' ? (
        <Text variant="subheader-md">{title}</Text>
      ) : (
        title
      )}
      <SelectMarketList
        queryVariables={{ isActive: true, orderBy: '-group,name' }}
        pageSize={PageSizes.ten}
        onSelect={handleSelectMarket}
        currentValues={currentValues as SelectOption[]}
      />
    </Flex>
  );
};

export const ListViewLocations: FC<ListViewProps> = ({
  onSelect,
  filterKey,
}) => {
  const [updateFilter] = useFiltersStore(s => [s.updateFilter]);
  const handleSelectLocation = (location: location) => {
    const update = convertDataToSelectOptions([location], 'name', 'uuid');
    updateFilter(filterKey, update);
    onSelect?.();
  };
  return (
    <Flex flexDirection="column">
      <Text variant="subheader-md">{'Select Location'}</Text>
      <SelectLocationsList
        queryVariables={{ status: [LocationStatus.ACTIVE] }}
        pageSize={PageSizes.ten}
        onSelect={handleSelectLocation}
        highlightSelectedValues={false}
      />
    </Flex>
  );
};

export const ListViewTags: FC<ListViewProps> = ({ onSelect, filterKey }) => {
  const [updateFilter] = useFiltersStore(s => [s.updateFilter]);
  const handleSelectTag = (tag: tag) => {
    const update = convertDataToSelectOptions([tag], 'name', 'id');
    updateFilter(filterKey, update);
    onSelect?.();
  };
  return (
    <Flex flexDirection="column">
      <Text variant="subheader-md">{'Select Tag'}</Text>
      <SelectTagsList
        pageSize={PageSizes.ten}
        onSelect={handleSelectTag}
        highlightSelectedValues={false}
      />
    </Flex>
  );
};

export const ListViewStaff: FC<ListViewProps> = ({ onSelect, filterKey }) => {
  const [updateFilter] = useFiltersStore(s => [s.updateFilter]);
  const handleSelectStaff = (user: user) => {
    const update = convertDataToSelectOptions(
      [user],
      user => formatUserNameForDisplay(user),
      'uuid',
    );
    updateFilter(filterKey, update);
    onSelect?.();
  };
  return (
    <Flex flexDirection="column">
      <Text variant="subheader-md">{'Select User'}</Text>
      <SelectStaffUserList
        pageSize={PageSizes.ten}
        onSelect={handleSelectStaff}
        highlightSelectedValues={false}
      />
    </Flex>
  );
};

export const ListViewAcquisitionCampaigns: FC<ListViewProps> = ({
  onSelect,
  filterKey,
}) => {
  const [updateFilter] = useFiltersStore(s => [s.updateFilter]);
  const handleSelectAcquisitionCampaign = (campaign: acquisitionCampaign) => {
    const update = convertDataToSelectOptions([campaign], 'name', 'uuid');
    updateFilter(filterKey, update);
    onSelect?.();
  };
  return (
    <Flex flexDirection="column">
      <Text variant="subheader-md">{'Select Acquisition Campaign'}</Text>
      <SelectAcquisitionCampaignList
        pageSize={PageSizes.ten}
        onSelect={handleSelectAcquisitionCampaign}
        highlightSelectedValues={false}
      />
    </Flex>
  );
};
