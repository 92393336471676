import { cloneElement, FC, ReactElement, ReactNode } from 'react';
import {
  CloseButtonProps,
  Drawer as ChakraDrawer,
  DrawerBody as ChakraDrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerContentProps,
  DrawerOverlay,
  Flex,
  FlexProps,
  ModalBodyProps,
  Text,
} from '@chakra-ui/react';
import { hideScrollBarStyles } from 'ui';

interface DrawerProps {
  onClose?: () => void;
  isOpen?: boolean;
  header?: ReactElement;
  footer?: ReactElement;
  children?: ReactNode;
  contentProps?: DrawerContentProps;
  bodyProps?: ModalBodyProps;
  closeButtonProps?: CloseButtonProps;
}

export const Drawer: FC<DrawerProps> = ({
  onClose,
  isOpen,
  header,
  footer,
  children,
  contentProps,
  bodyProps,
  closeButtonProps,
}) => {
  const CloseButton = (
    <DrawerCloseButton
      className="test-drawer-close-button"
      top={4}
      {...closeButtonProps}
    />
  );

  return (
    <ChakraDrawer onClose={onClose} isOpen={isOpen} size="sm">
      <DrawerOverlay />
      <DrawerContent
        borderTopLeftRadius={8}
        borderBottomLeftRadius={8}
        overflow="hidden"
        {...contentProps}
      >
        {header
          ? cloneElement(
              header,
              {
                closeButton: CloseButton,
              },
              null,
            )
          : CloseButton}
        <ChakraDrawerBody display="flex" flexDirection="column" {...bodyProps}>
          {children}
        </ChakraDrawerBody>
        {footer && <DrawerFooter>{footer}</DrawerFooter>}
      </DrawerContent>
    </ChakraDrawer>
  );
};

export const DrawerHeading: FC<{
  label: string;
  containerProps?: FlexProps;
  wrapperProps?: FlexProps;
  extraContent?: ReactNode;
  closeButton?: ReactElement;
}> = ({ label, closeButton, containerProps, wrapperProps, extraContent }) => {
  return (
    <Flex backgroundColor="main.light" px={3} py={4} {...containerProps}>
      <Flex {...wrapperProps}>
        <Text fontSize="xl" fontWeight="medium">
          {label}
        </Text>
        {extraContent}
      </Flex>
      {closeButton}
    </Flex>
  );
};

interface DrawerBodyProps extends FlexProps {
  children: ReactNode;
}

export const DrawerBody: FC<DrawerBodyProps> = ({ children, ...props }) => {
  return (
    <Flex
      flexDirection="column"
      pt={4}
      px={3}
      flex={1}
      {...hideScrollBarStyles}
      {...props}
    >
      {children}
    </Flex>
  );
};

export const DrawerFooter: FC<{
  children: ReactNode;
}> = ({ children }) => {
  return (
    <Flex
      position="sticky"
      bottom={0}
      zIndex={1}
      width="100%"
      justifyContent="center"
      alignItems="center"
      borderTopWidth={1}
      borderTopColor="gray.100"
      backgroundColor="main.white"
      gap={2}
      py={4}
    >
      {children}
    </Flex>
  );
};
