const _COMMUNICATION_PREFERENCE_VARS = `
  $users: [String]
  $locations: [String]
  $types: [String]
`;

const _COMMUNICATION_PREFERENCE_PARAMS = `
  users: $users
  locations: $locations
  types: $types
`;

export const ADD_LOCATION_COMMUNICATION_PREFERENCES = `
  mutation ADD_LOCATION_COMMUNICATION_PREFERENCES(${_COMMUNICATION_PREFERENCE_VARS}) {
    addLocationCommunicationPreferences(${_COMMUNICATION_PREFERENCE_PARAMS}) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const REMOVE_LOCATION_COMMUNICATION_PREFERENCES = `
  mutation REMOVE_LOCATION_COMMUNICATION_PREFERENCES(${_COMMUNICATION_PREFERENCE_VARS}) {
    removeLocationCommunicationPreferences(${_COMMUNICATION_PREFERENCE_PARAMS}) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const REMOVE_ALL_LOCATION_COMMUNICATION_PREFERENCES = `
  mutation REMOVE_ALL_LOCATION_COMMUNICATION_PREFERENCES($users: [String]) {
    removeAllLocationCommunicationPreferences(users: $users) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const BULK_EDIT_ORGANIZATION_LOCATION_COMMUNICATION_PREFERENCES = `
  mutation BULK_EDIT_ORGANIZATION_LOCATION_COMMUNICATION_PREFERENCES(
    $users: [String]
    $types: [String]
  ) {
    bulkEditOrganizationLocationCommunicationPreferences(
      users: $users
      types: $types
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;
