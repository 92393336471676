import { titleCase } from 'helpers';
import { CheckboxOption, SelectOption } from 'types';

const defaultEnumToSelectArgs = {
  labels: 'key',
  fixedOptions: [],
};

interface convertEnumToSelectArgs {
  fixedOptions?: Array<string>;
  labelTransform?: (obj: unknown, key: string) => string;
}

export const convertEnumToSelectOptions = (
  en: object,
  args: convertEnumToSelectArgs = defaultEnumToSelectArgs,
): Array<SelectOption> => {
  const { fixedOptions, labelTransform } = args;
  return Object.keys(en).map(key => ({
    value: key,
    label: labelTransform
      ? labelTransform(en, key)
      : titleCase(en[key].toLowerCase()).replace('_', ' '),
    isFixed: fixedOptions?.includes(en[key]),
  }));
};

const defaultEnumToCheckboxArgs = {
  labels: 'value',
};

interface convertEnumToCheckboxArgs {
  labels: string;
}

export const convertEnumToCheckboxOptions = (
  en: object,
  args: convertEnumToCheckboxArgs = defaultEnumToCheckboxArgs,
): Array<CheckboxOption> => {
  const { labels } = args;
  return Object.keys(en).map((key, index) => ({
    id: index,
    key,
    name: labels === 'value' ? en[key] : key,
  }));
};

export const convertDataToSelectOptions = (
  data: Array<object>,
  labelKey: string | ((obj) => string),
  valueKey: string,
  disabledOptions: Array<string> = [],
): Array<SelectOption> => {
  if (!data) return [];
  return data?.map(item => {
    if (!item) {
      return;
    }
    return {
      value: item[valueKey],
      label: typeof labelKey === 'string' ? item[labelKey] : labelKey(item),
      isFixed: false,
      isDisabled: disabledOptions.includes(item[valueKey]),
    };
  });
};

export const convertArrayToSelectOptions = (
  data: Array<string>,
): Array<SelectOption> => {
  return data.map(item => ({
    value: item,
    label: item,
  }));
};

export const selectOptionsFromNumericalRange = (
  start,
  end,
  type = null,
  iterator = 0.25,
) => {
  const output = [];
  for (let step = start; step <= end; step = step + iterator) {
    output.push(step);
  }
  if (type === 'dollar') {
    return output.map(val => ({
      label: `$${val.toFixed(2)}`,
      value: val,
    }));
  }
  if (type === 'day') {
    return output.map(val => ({
      label: `${val} day${val > 1 ? 's' : ''}`,
      value: val,
    }));
  }
  if (type === 'percent') {
    return output.map(val => ({
      label: `${val}%`,
      value: val,
    }));
  }
  return output.map(val => ({
    label: val,
    value: val,
  }));
};

interface createNumericIntervalOptionsProps {
  start: number;
  end: number;
  interval: number;
}

export const createNumericIntervalOptions = (
  configs: createNumericIntervalOptionsProps,
): Array<number> => {
  const out = [];
  for (let i = configs.start; i <= configs.end; i += configs.interval) {
    // Rounding to 2 decimal places to avoid floating point math problems
    out.push(Math.round(i * 100) / 100);
  }
  return out;
};

export const booleanSelectOptions = [
  {
    value: 'true',
    label: 'Yes',
  },
  {
    value: 'false',
    label: 'No',
  },
];
