import { FC } from 'react';
import { Props, Select as ChakraSelect } from 'chakra-react-select';
import { SelectOption } from 'types';
import {
  OptionTag,
  OptionWithImage,
  SingleValueTag,
  SingleValueWithImage,
  TagWithImage,
} from './components';
import { selectStyleOverrides } from './select.config';

// eslint-disable-next-line no-prototype-builtins
const propsIncludesImage = props =>
  props?.options?.[0]?.hasOwnProperty('image');

// FUTURE: move this be at prop level
// so that we can isolate to oz only
const getOzOptionColor = option => {
  if (option?.isGroup) return 'purple';
  if (option?.isActive) return 'green';
  if (option?.isActive === false) return 'red';
  return 'gray';
};

export interface SelectProps extends Props {
  singleValueStyle?: 'default' | 'tag' | 'retailer';
  className?: string;
  additionalStyleOverrides?: any;
}

export const Select: FC<SelectProps> = props => {
  const {
    singleValueStyle = 'default',
    options,
    additionalStyleOverrides,
    ...rest
  } = props;
  const optionsWithColors = options?.map((option: SelectOption) => {
    return {
      colorScheme: getOzOptionColor(option),
      ...option,
    };
  });
  return (
    <ChakraSelect
      {...props}
      options={optionsWithColors}
      errorBorderColor="red.400"
      components={{
        ...(singleValueStyle === 'tag' && {
          SingleValue: SingleValueTag,
          Option: OptionTag,
        }),
        ...(propsIncludesImage(rest) && {
          Option: OptionWithImage,
          MultiValueContainer: TagWithImage,
          SingleValue: SingleValueWithImage,
        }),
        ...props.components,
      }}
      chakraStyles={{
        ...props.chakraStyles,
        ...selectStyleOverrides,
        ...props?.additionalStyleOverrides,
      }}
    />
  );
};
