import { isEqual, reduce } from 'lodash';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { useFiltersStore } from '../FiltersStore';
import {
  defaultActionItemsFiltersState,
  defaultCampaignsFiltersState,
  defaultLocationsFiltersState,
  defaultShiftsFiltersState,
  defaultUsersFiltersState,
} from '../slices';

export const useUsersFiltersApplied = () => {
  const filterKeys = Object.keys(defaultUsersFiltersState);

  const filterValues = useStoreWithEqualityFn(
    useFiltersStore,
    state =>
      filterKeys.reduce((acc, key) => {
        acc[key] = state[key];
        return acc;
      }, {}),
    shallow,
  );

  return {
    areFiltersDefault: isEqual(filterValues, defaultUsersFiltersState),
    filtersApplied: reduce(
      filterValues,
      (result, value, key) =>
        isEqual(value, defaultUsersFiltersState[key])
          ? result
          : result.concat(key),
      [],
    ),
  };
};

export const useLocationsFiltersApplied = () => {
  const filterKeys = Object.keys(defaultLocationsFiltersState);

  const filterValues = useStoreWithEqualityFn(
    useFiltersStore,
    state =>
      filterKeys.reduce((acc, key) => {
        acc[key] = state[key];
        return acc;
      }, {}),
    shallow,
  );

  return {
    areFiltersDefault: isEqual(filterValues, defaultLocationsFiltersState),
    filtersApplied: reduce(
      filterValues,
      (result, value, key) =>
        isEqual(value, defaultLocationsFiltersState[key])
          ? result
          : result.concat(key),
      [],
    ),
  };
};

export const useShiftsFiltersApplied = () => {
  const filterKeys = Object.keys(defaultShiftsFiltersState);

  const filterValues = useStoreWithEqualityFn(
    useFiltersStore,
    state =>
      filterKeys.reduce((acc, key) => {
        acc[key] = state[key];
        return acc;
      }, {}),
    shallow,
  );

  return {
    areFiltersDefault: isEqual(filterValues, defaultShiftsFiltersState),
    filtersApplied: reduce(
      filterValues,
      (result, value, key) =>
        isEqual(value, defaultShiftsFiltersState[key])
          ? result
          : result.concat(key),
      [],
    ),
  };
};

export const useCampaignsFiltersApplied = () => {
  const filterKeys = Object.keys(defaultCampaignsFiltersState);

  const filterValues = useStoreWithEqualityFn(
    useFiltersStore,
    state =>
      filterKeys.reduce((acc, key) => {
        acc[key] = state[key];
        return acc;
      }, {}),
    shallow,
  );

  return {
    areFiltersDefault: isEqual(filterValues, defaultCampaignsFiltersState),
    filtersApplied: reduce(
      filterValues,
      (result, value, key) =>
        isEqual(value, defaultCampaignsFiltersState[key])
          ? result
          : result.concat(key),
      [],
    ),
  };
};

export const useActionItemsFiltersApplied = () => {
  const filterKeys = Object.keys(defaultActionItemsFiltersState);

  const filterValues = useStoreWithEqualityFn(
    useFiltersStore,
    state =>
      filterKeys.reduce((acc, key) => {
        acc[key] = state[key];
        return acc;
      }, {}),
    shallow,
  );

  return {
    areFiltersDefault: isEqual(filterValues, defaultActionItemsFiltersState),
    filtersApplied: reduce(
      filterValues,
      (result, value, key) =>
        isEqual(value, defaultActionItemsFiltersState[key])
          ? result
          : result.concat(key),
      [],
    ),
  };
};
