import { lineHeightsByFontSize } from './typography.config';

const headerFontWeight = 'semi';
const subheaderFontWeight = 'medium';
const labelFontWeight = 'regular';
const bodyFontWeight = 'regular';

export const TextVariants = {
  'header-4xl': {
    fontSize: '4xl',
    fontWeight: headerFontWeight,
    lineHeight: lineHeightsByFontSize['4xl'],
  },
  'header-3xl': {
    fontSize: '3xl',
    fontWeight: headerFontWeight,
    lineHeight: lineHeightsByFontSize['3xl'],
  },
  'header-2xl': {
    fontSize: '2xl',
    fontWeight: headerFontWeight,
    lineHeight: lineHeightsByFontSize['2xl'],
  },
  'header-xl': {
    fontSize: 'xl',
    fontWeight: headerFontWeight,
    lineHeight: lineHeightsByFontSize['xl'],
  },
  'header-lg': {
    fontSize: 'lg',
    fontWeight: headerFontWeight,
    lineHeight: lineHeightsByFontSize['lg'],
  },
  'header-md': {
    fontSize: 'md',
    fontWeight: headerFontWeight,
    lineHeight: lineHeightsByFontSize['md'],
  },
  'header-sm': {
    fontSize: 'sm',
    fontWeight: headerFontWeight,
    lineHeight: lineHeightsByFontSize['sm'],
  },
  'header-xs': {
    fontSize: 'xs',
    fontWeight: headerFontWeight,
    lineHeight: lineHeightsByFontSize['xs'],
  },
  'subheader-3xl': {
    fontSize: '3xl',
    fontWeight: subheaderFontWeight,
    lineHeight: lineHeightsByFontSize['3xl'],
  },
  'subheader-2xl': {
    fontSize: '2xl',
    fontWeight: subheaderFontWeight,
    lineHeight: lineHeightsByFontSize['2xl'],
  },
  'subheader-xl': {
    fontSize: 'xl',
    fontWeight: subheaderFontWeight,
    lineHeight: lineHeightsByFontSize['xl'],
  },
  'subheader-lg': {
    fontSize: 'lg',
    fontWeight: subheaderFontWeight,
    lineHeight: lineHeightsByFontSize['lg'],
  },
  'subheader-md': {
    fontSize: 'md',
    fontWeight: subheaderFontWeight,
    lineHeight: lineHeightsByFontSize['md'],
  },
  'subheader-sm': {
    fontSize: 'sm',
    fontWeight: subheaderFontWeight,
    lineHeight: lineHeightsByFontSize['sm'],
  },
  'subheader-xs': {
    fontSize: 'xs',
    fontWeight: subheaderFontWeight,
    lineHeight: lineHeightsByFontSize['xs'],
  },
  'subheader-2xs': {
    fontSize: '2xs',
    fontWeight: subheaderFontWeight,
    lineHeight: lineHeightsByFontSize['2xs'],
  },
  'label-3xl': {
    fontSize: '3xl',
    fontWeight: labelFontWeight,
    lineHeight: lineHeightsByFontSize['3xl'],
  },
  'label-2xl': {
    fontSize: '2xl',
    fontWeight: labelFontWeight,
    lineHeight: lineHeightsByFontSize['2xl'],
  },
  'label-xl': {
    fontSize: 'xl',
    fontWeight: labelFontWeight,
    lineHeight: lineHeightsByFontSize['xl'],
  },
  'label-lg': {
    fontSize: 'lg',
    fontWeight: labelFontWeight,
    lineHeight: lineHeightsByFontSize['lg'],
  },
  'label-md': {
    fontSize: 'md',
    fontWeight: labelFontWeight,
    lineHeight: lineHeightsByFontSize['md'],
  },
  'label-sm': {
    fontSize: 'sm',
    fontWeight: labelFontWeight,
    lineHeight: lineHeightsByFontSize['sm'],
  },
  'label-xs': {
    fontSize: 'xs',
    fontWeight: labelFontWeight,
    lineHeight: lineHeightsByFontSize['xs'],
  },
  'label-2xs': {
    fontSize: '2xs',
    fontWeight: labelFontWeight,
    lineHeight: lineHeightsByFontSize['2xs'],
  },
  'body-3xl': {
    fontSize: '3xl',
    fontWeight: bodyFontWeight,
    lineHeight: lineHeightsByFontSize['3xl'],
  },
  'body-2xl': {
    fontSize: '2xl',
    fontWeight: bodyFontWeight,
    lineHeight: lineHeightsByFontSize['2xl'],
  },
  'body-xl': {
    fontSize: 'xl',
    fontWeight: bodyFontWeight,
    lineHeight: lineHeightsByFontSize['xl'],
  },
  'body-lg': {
    fontSize: 'lg',
    fontWeight: bodyFontWeight,
    lineHeight: lineHeightsByFontSize['lg'],
  },
  'body-md': {
    fontSize: 'md',
    fontWeight: bodyFontWeight,
    lineHeight: lineHeightsByFontSize['md'],
  },
  'body-sm': {
    fontSize: 'sm',
    fontWeight: bodyFontWeight,
    lineHeight: lineHeightsByFontSize['sm'],
  },
  'body-xs': {
    fontSize: 'xs',
    fontWeight: bodyFontWeight,
    lineHeight: lineHeightsByFontSize['xs'],
  },
  'body-2xs': {
    fontSize: '2xs',
    fontWeight: bodyFontWeight,
    lineHeight: lineHeightsByFontSize['2xs'],
  },
};

export const Text = {
  baseStyle: {},
  sizes: {},
  defaultProps: {},
  variants: TextVariants,
};
