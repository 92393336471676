import {
  communicationUser,
  location,
  LocationCommunicationPreference,
  user,
  userCommunicationPreferenceMap,
} from 'types';

export const defaultUserCommunicationPreferenceMap = (
  value = false,
): userCommunicationPreferenceMap => {
  return Object.keys(LocationCommunicationPreference).reduce(
    (acc, key) => {
      acc[
        LocationCommunicationPreference[
          key as keyof typeof LocationCommunicationPreference
        ]
      ] = value;
      return acc;
    },
    {} as Record<LocationCommunicationPreference, boolean>,
  );
};

// useful when you are querying ~location~ node
// and have communicationUsers results
export const getUserCommunicationPreferencesFromLocation = (
  location: location,
  userUuid: string,
): userCommunicationPreferenceMap => {
  if (!location || !Object?.keys(location)?.length || !userUuid)
    return defaultUserCommunicationPreferenceMap();
  const communicationUser =
    location?.communicationUsers?.filter(
      cu => cu?.user?.uuid === userUuid,
    )[0] || ({} as communicationUser);
  if (!communicationUser?.user?.uuid)
    return defaultUserCommunicationPreferenceMap();

  return Object.keys(LocationCommunicationPreference).reduce(
    (acc, key) => {
      const preference =
        LocationCommunicationPreference[
          key as keyof typeof LocationCommunicationPreference
        ];
      acc[preference] = communicationUser?.types?.includes(preference);
      return acc;
    },
    {} as Record<LocationCommunicationPreference, boolean>,
  );
};

// useful when you have users list
// and you need to get a map of preferences for specific location
export const getUserCommunicationPreferencesFromUser = (
  user: user,
  location: location,
) => {
  if (!location?.uuid || !user?.uuid) {
    return defaultUserCommunicationPreferenceMap();
  }
  return Object.keys(LocationCommunicationPreference).reduce(
    (acc, key) => {
      const preference =
        LocationCommunicationPreference[
          key as keyof typeof LocationCommunicationPreference
        ];
      acc[preference] =
        user?.locationCommunicationPreferences?.filter(
          cp =>
            cp?.location?.uuid === location?.uuid && cp?.type === preference,
        )?.length > 0;
      return acc;
    },
    {} as Record<LocationCommunicationPreference, boolean>,
  );
};
