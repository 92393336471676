import { compliance } from './compliance';
import { terms } from './terms';

export enum AmountThresholdEntryUnit {
  HOURS = 'HOURS',
  DOLLARS = 'DOLLARS',
  PERCENT = 'PERCENT',
}

export type amountThresholdEntry = {
  amount?: number; // Float
  amountThreshold?: amountThreshold;
  createdAt?: string; // DateTime
  maximum?: number; // Float
  minimum?: number; // Float
  timing?: number; // number of hours to determine when to apply fee
  unit?: AmountThresholdEntryUnit;
  updatedAt?: string; // DateTime
};

export type amountThreshold = {
  amounts?: Array<amountThresholdEntry>;
  createdAt?: string; // DateTime
  updatedAt?: string; // DateTime
  name?: string;
  terms?: terms;
  compliance?: compliance;
};
