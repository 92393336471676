import { FC } from 'react';
import { Button, ButtonProps, Spinner, useTheme } from '@chakra-ui/react';
import { Icon } from 'icons';
import { MotionBox } from 'ui';

interface FormButtonProps extends ButtonProps {
  text?: string;
  isSuccess?: boolean;
}

export const FormButton: FC<FormButtonProps> = ({
  text = 'Save',
  isLoading,
  isDisabled,
  isSuccess,
  ...rest
}) => {
  const {
    colors: { main },
  } = useTheme();
  return (
    <Button
      type="submit"
      width="100%"
      isLoading={isLoading}
      isDisabled={isDisabled}
      sx={
        isSuccess && {
          backgroundColor: `${main.green} !important`,
          opacity: '1 !important',
        }
      }
      position="relative"
      {...rest}
    >
      {isLoading && <Spinner />}
      {!isSuccess && !isLoading && text}
      {isSuccess && (
        <MotionBox
          initial={{ opacity: 0, marginTop: 12 }}
          animate={{ opacity: 1, marginTop: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.2 }}
          position="relative"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Icon name="checkcircle" w={6} h={6} />
        </MotionBox>
      )}
    </Button>
  );
};
