import { createContext, useMemo, useState } from 'react';
import { reflexLogger, ReflexLoggerReturn } from 'logging';

export const LoggingContext = createContext(null);

export const LoggingProvider = ({ children }) => {
  const [logger, setLogger] = useState<ReflexLoggerReturn>(reflexLogger(false));

  useMemo(() => {
    // FUTURE, maybe ENV var this to make config-able?
    if (process.env.NODE_ENV === 'development') {
      setLogger(reflexLogger(true));
    }
  }, [process.env.NODE_ENV]);

  return (
    <LoggingContext.Provider value={logger}>{children}</LoggingContext.Provider>
  );
};
