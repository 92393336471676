import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import {
  GQL,
  mutationReturn,
  paging,
  ReflexResponse,
  RetailerCategory,
  RetailerSize,
  ShiftCancelClassification,
  ShiftRequestType,
  ShiftStatus,
  uuid as uuidType,
  WorkerShiftStatus,
} from 'types';

export interface fetchShiftProps extends paging {
  id?: number;
  uuid?: uuidType;
  uuids?: uuidType[];
  excludeStatus?: ShiftStatus[];
  excludeUserWorkershiftStatus?: WorkerShiftStatus[];
  hasUnfinishedPaperwork?: boolean;
  isCancelled?: boolean;
  isInProgress?: boolean;
  isRetailerApproved?: boolean;
  isWorkerAssigned?: boolean;
  locations?: uuidType[];
  locationTags?: string[]; // Tag ID
  market?: number; // Market ID
  markets?: number[]; // Market ID
  requestType?: ShiftRequestType[];
  retailerCategories?: RetailerCategory[];
  retailers?: uuidType[];
  retailerSizes?: RetailerSize[];
  since?: string; // yyyy-MM-dd
  status?: ShiftStatus[];
  tags?: string[]; // Tag ID
  to?: string; // yyyy-MM-dd
  users?: uuidType[];
  userTags?: string[]; // Tag ID
  userTagsMissing?: string[]; // Tag ID
  userWorkershiftStatus?: WorkerShiftStatus[];
  workershiftStatus?: WorkerShiftStatus[];
}

export const fetchShifts = async (
  data: fetchShiftProps,
  query = Queries.SHIFTS_UUID_QUERY,
  contextOverride?: string,
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(
      GQL,
      !contextOverride ? 'SHIFT_QUERY' : contextOverride,
    ),
    query,
    data,
    returnKey: ['shifts', 'shifts_paging'],
  });

export interface mutateUpdateShiftProps {
  uuid: string;
  flexibleStartTime?: boolean;
  start?: string;
  flexibleEndTime?: boolean;
  end?: string;
  overbookLimit?: number;
}

export interface cancelShiftProps {
  shift: string; // uuid
  cancelNote: string;
  cancelClassification: ShiftCancelClassification;
  workerCancellationPayoutRate?: number;
}

export const mutateUpdateShift = async (shiftData: mutateUpdateShiftProps) =>
  await mutateObject({
    route: applyContext(GQL, 'EDIT_SHIFT'),
    query: Mutations.UPDATE_SHIFT_MUTATION,
    data: shiftData,
    returnKey: 'editShift',
  });

export const mutateCancelShift = async (shiftCancelData: cancelShiftProps) =>
  await mutateObject({
    route: applyContext(GQL, 'CANCEL_SHIFT'),
    query: Mutations.STAFF_CANCEL_SHIFT_MUTATION,
    data: shiftCancelData,
    returnKey: 'cancelShift',
  });

export interface createShiftItem {
  start: string; // ISO8061
  end: string; // ISO8061
  roles: Array<string>; // Role names
}

export interface mutateCreateShiftProps {
  location: string; // uuid
  shifts: createShiftItem[];
}

export const mutateCreateShifts = async (data: mutateCreateShiftProps) =>
  await mutateObject({
    route: applyContext(GQL, 'CREATE_SHIFT'),
    query: Mutations.CREATE_SHIFT_MUTATION,
    data,
    returnKey: 'createShift',
  });

export interface mutateWsoToNewShiftProps {
  workershift: string; // uuid
  location: string; // uuid
  start: string; // ISO8061
  end: string; // ISO8061
  roles: Array<string>; // Role names
  reason: string;
  bonusAmount?: number;
}

export interface mutateWsoToExistingShiftReturn extends mutationReturn {
  newShiftUuid?: string;
}

export const mutateWsoToNewShift = async (data: mutateWsoToNewShiftProps) =>
  await mutateObject({
    route: applyContext(GQL, 'MOVE_WSO_TO_NEW_SHIFT'),
    query: Mutations.MOVE_WSO_TO_NEW_SHIFT,
    data,
    returnKey: 'wsoToNewShift',
  });

export interface mutateWsoToExistingShiftProps {
  workershift: string;
  shift: string;
}

export const mutateWsoToExistingShift = async (
  data: mutateWsoToExistingShiftProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'MOVE_WSO_TO_EXISTING_SHIFT'),
    query: Mutations.MOVE_WSO_TO_EXISTING_SHIFT,
    data,
    returnKey: 'wsoToExistingShift',
  });

export interface mutateCancelShiftRetailerProps {
  id: number;
  retailerInput?: string;
}

export const mutateCancelRetailerShift = async (
  data: mutateCancelShiftRetailerProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'RETAILER_CANCEL_SHIFT_MUTATION'),
    query: Mutations.RETAILER_CANCEL_SHIFT_MUTATION,
    data,
    returnKey: 'retailerCancelShift',
  });

export interface mutateClaimShiftProps {
  shift?: string; // uuid
}

export const mutateClaimShift = async (data: mutateClaimShiftProps) =>
  await mutateObject({
    route: applyContext(GQL, 'CLAIM_FLEX_MUTATION'),
    query: Mutations.CLAIM_FLEX_MUTATION,
    data: {
      shift: data?.shift,
      status: WorkerShiftStatus.ACCEPTED,
    },
    returnKey: 'shiftAction',
  });
