import { fetchShiftProps, getEnumKeyByValue } from 'helpers';
import {
  RetailerCategory,
  RetailerSize,
  SelectOption,
  ShiftRequestType,
  ShiftStatus,
} from 'types';
import { CalendarValues } from 'ui';
import {
  convertEnumToFilterOptions,
  dateFilterObject,
  formatDateFilter,
  getGenericDateRangesAsStrings,
} from 'utils/helpers/filters';

export enum ShiftsPaperworkOptions {
  COMPLETE = 'Complete',
  INCOMPLETE = 'Incomplete',
}

export enum ShiftsStatusOptions {
  OPEN = 'Open',
  FILLED = 'Filled',
  COMPLETE = 'Complete',
  FILLED_PARTIAL = 'Filled Partial',
  OVERBOOKED = 'Overbooked',
  CANCELLED = 'Cancelled',
}

export enum ShiftsDateQuickPicks {
  TODAY = 'Today',
  TOMORROW = 'Tomorrow',
  YESTERDAY = 'Yesterday',
  NEXT_3 = 'Next 3',
  NEXT_7 = 'Next 7',
  UPCOMING = 'Upcoming',
  THIS_WEEK = 'This Week',
  THIS_MONTH = 'This Month',
  PAST = 'Past',
  LAST_WEEK = 'Last Week',
  LAST_MONTH = 'Last Month',
  CUSTOM = 'Custom',
}

export interface ShiftsFiltersState {
  shiftsMarkets: SelectOption[];
  shiftsStatus: SelectOption[];
  shiftsRetailers: SelectOption[];
  shiftsLocations: SelectOption[];
  shiftsPaperwork: SelectOption[];
  shiftsRetailerSize: SelectOption[];
  shiftsRetailerCategory: SelectOption[];
  shiftsShiftTags: SelectOption[];
  shiftsLocationTags: SelectOption[];
  shiftsIsRetailerApproved: SelectOption[];
  shiftsRequestType: SelectOption[];
  shiftsSelectedStart: Date | null;
  shiftsSelectedEnd: Date | null;
  shiftsDateQuickPick: ShiftsDateQuickPicks;
  shiftsAction: SelectOption[];
}

export interface ShiftsFiltersSlice extends ShiftsFiltersState {
  resetShiftsFilters: () => void;
  updateShiftsSelectedDates: (dates: CalendarValues) => void;
  updateShiftsDateQuickPick: (dateQuickPick: ShiftsDateQuickPicks) => void;
  resetShiftsTimingFilter: () => void;
}

export const defaultShiftsFiltersState: ShiftsFiltersState = {
  shiftsMarkets: [],
  shiftsStatus: convertEnumToFilterOptions(ShiftsStatusOptions)?.filter(o =>
    [
      ShiftsStatusOptions.FILLED,
      ShiftsStatusOptions.FILLED_PARTIAL,
      ShiftsStatusOptions.OPEN,
      ShiftsStatusOptions.OVERBOOKED,
    ].includes(o?.value as ShiftsStatusOptions),
  ),
  shiftsRetailers: [],
  shiftsLocations: [],
  shiftsPaperwork: [],
  shiftsRetailerSize: [],
  shiftsRetailerCategory: [],
  shiftsShiftTags: [],
  shiftsLocationTags: [],
  shiftsIsRetailerApproved: [],
  shiftsRequestType: [],
  shiftsSelectedStart: null,
  shiftsSelectedEnd: null,
  shiftsDateQuickPick: ShiftsDateQuickPicks.UPCOMING,
  shiftsAction: [],
};

export const shiftsFiltersSlice = set => ({
  ...defaultShiftsFiltersState,
  resetShiftsFilters: () => {
    set(prev => ({ ...prev, ...defaultShiftsFiltersState }));
  },
  updateShiftsSelectedDates: (dates: CalendarValues) => {
    set({
      shiftsSelectedStart: dates.start,
      shiftsSelectedEnd: dates.end,
    });
  },
  updateShiftsDateQuickPick: (dateQuickPick: ShiftsDateQuickPicks) => {
    set({ shiftsDateQuickPick: dateQuickPick });
  },
  resetShiftsTimingFilter: () => {
    set(prev => ({
      ...prev,
      ...defaultShiftsFiltersState,
      shiftsSelectedStart: null,
      shiftsSelectedEnd: null,
    }));
  },
});

export const getParsedShiftsFilters = (
  filters: Partial<ShiftsFiltersState>,
): fetchShiftProps => {
  const {
    shiftsMarkets,
    shiftsLocations,
    shiftsRetailers,
    shiftsStatus,
    shiftsDateQuickPick,
    shiftsSelectedStart,
    shiftsSelectedEnd,
    shiftsPaperwork,
    shiftsIsRetailerApproved,
    shiftsRequestType,
    shiftsShiftTags,
    shiftsLocationTags,
    shiftsRetailerCategory,
    shiftsRetailerSize,
  } = filters || {};

  const graphFilters = {} as fetchShiftProps;

  if (shiftsMarkets?.length) {
    graphFilters.markets = shiftsMarkets.map(market => parseInt(market.value));
  }

  if (shiftsDateQuickPick !== ShiftsDateQuickPicks.CUSTOM) {
    const { since, to } = parseShiftsDateQuickPick(shiftsDateQuickPick);
    graphFilters.since = since;
    graphFilters.to = to;
  }

  if (shiftsDateQuickPick === ShiftsDateQuickPicks.CUSTOM) {
    if (!shiftsSelectedStart && !shiftsSelectedEnd) {
      graphFilters.since = formatDateFilter(new Date());
      graphFilters.to = formatDateFilter(new Date());
    } else {
      graphFilters.since = shiftsSelectedStart
        ? formatDateFilter(new Date(shiftsSelectedStart))
        : null;
      graphFilters.to = shiftsSelectedEnd
        ? formatDateFilter(new Date(shiftsSelectedEnd))
        : null;
    }
  }

  if (shiftsLocations?.length) {
    graphFilters.locations = shiftsLocations.map(location => location.value);
  }

  if (shiftsRetailers?.length) {
    graphFilters.retailers = shiftsRetailers.map(retailer => retailer.value);
  }

  if (shiftsStatus?.length) {
    graphFilters.status = shiftsStatus.map(
      status =>
        getEnumKeyByValue(ShiftsStatusOptions, status.value) as ShiftStatus,
    );
  }

  if (shiftsPaperwork?.length) {
    const paperwork = shiftsPaperwork[0]?.value;
    graphFilters.hasUnfinishedPaperwork =
      paperwork === ShiftsPaperworkOptions.INCOMPLETE;
  }

  if (shiftsIsRetailerApproved?.length) {
    const isRetailerApproved = shiftsIsRetailerApproved[0]?.value;
    graphFilters.isRetailerApproved = isRetailerApproved === 'true';
  }

  if (shiftsRequestType?.length) {
    graphFilters.requestType = shiftsRequestType?.map(
      requestType => requestType?.value as ShiftRequestType,
    );
  }

  if (shiftsShiftTags?.length) {
    graphFilters.tags = shiftsShiftTags.map(tag => tag.value);
  }

  if (shiftsLocationTags?.length) {
    graphFilters.locationTags = shiftsLocationTags.map(tag => tag.value);
  }

  if (shiftsRetailerCategory?.length) {
    graphFilters.retailerCategories = shiftsRetailerCategory.map(
      category => category.value as RetailerCategory,
    );
  }

  if (shiftsRetailerSize?.length) {
    graphFilters.retailerSizes = shiftsRetailerSize.map(
      size => size.value as RetailerSize,
    );
  }

  return graphFilters;
};

export const parseShiftsDateQuickPick = (
  pick: ShiftsDateQuickPicks,
): dateFilterObject => {
  const {
    yesterday,
    today,
    tomorrow,
    next3,
    next7,
    startOfWeek,
    endOfWeek,
    startOfMonth,
    endOfMonth,
    startOfPrevWeek,
    endOfPrevWeek,
    startOfPrevMonth,
    endOfPrevMonth,
  } = getGenericDateRangesAsStrings();
  switch (pick) {
    case ShiftsDateQuickPicks.YESTERDAY:
      return {
        since: yesterday,
        to: yesterday,
      };
    case ShiftsDateQuickPicks.TODAY:
      return {
        since: today,
        to: today,
      };
    case ShiftsDateQuickPicks.TOMORROW:
      return {
        since: tomorrow,
        to: tomorrow,
      };
    case ShiftsDateQuickPicks.NEXT_3:
      return {
        since: today,
        to: next3,
      };
    case ShiftsDateQuickPicks.NEXT_7:
      return {
        since: today,
        to: next7,
      };
    case ShiftsDateQuickPicks.UPCOMING:
      return {
        since: today,
        to: null,
      };
    case ShiftsDateQuickPicks.THIS_WEEK:
      return {
        since: startOfWeek,
        to: endOfWeek,
      };
    case ShiftsDateQuickPicks.THIS_MONTH:
      return {
        since: startOfMonth,
        to: endOfMonth,
      };
    case ShiftsDateQuickPicks.PAST:
      return {
        since: null,
        to: today,
      };
    case ShiftsDateQuickPicks.LAST_WEEK:
      return {
        since: startOfPrevWeek,
        to: endOfPrevWeek,
      };
    case ShiftsDateQuickPicks.LAST_MONTH:
      return {
        since: startOfPrevMonth,
        to: endOfPrevMonth,
      };
    case ShiftsDateQuickPicks.CUSTOM:
    default:
      return {
        since: null,
        to: null,
      };
  }
};
