import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChipNoMessaging: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.294.868c.092-.092.246-.195.343-.23a.998.998 0 0 1 1.058.23c.247.248.3.443.3 1.129v.573h4v-.573c0-.686.051-.881.298-1.128a.984.984 0 0 1 1.402 0c.247.247.3.442.3 1.128v.573h.61c.684 0 1.005.04 1.4.178.743.258 1.461.94 1.752 1.667.18.448.205.599.226 1.385l.02.77h.57c.68 0 .875.052 1.122.299a.984.984 0 0 1 0 1.402c-.247.247-.442.299-1.128.299h-.573v.346a7.527 7.527 0 0 0-1.853-.23h-.146c0-3.19-.014-3.518-.183-3.705-.032-.036-.071-.067-.117-.112a1.057 1.057 0 0 0-.36-.233c-.178-.06-.655-.066-5.358-.064-4.209 0-5.19.01-5.313.055a1.038 1.038 0 0 0-.539.454l-.11.189-.012 5.218c-.01 5.037-.026 5.45.172 5.669.033.036.072.067.117.113.046.045.077.084.114.117.191.174.531.183 3.952.183a7.49 7.49 0 0 0 .373 2h-.737v.573c0 .686-.052.88-.299 1.128a.984.984 0 0 1-1.402 0c-.247-.247-.299-.442-.299-1.123v-.57l-.77-.02c-.498-.012-.84-.04-.97-.078-1.088-.317-1.844-1.073-2.169-2.17-.039-.132-.066-.463-.08-.97l-.02-.77h-.569c-.68 0-.876-.052-1.123-.3a.984.984 0 0 1 0-1.401c.247-.247.442-.3 1.128-.3h.573v-4h-.573c-.686 0-.88-.051-1.128-.298a.984.984 0 0 1 0-1.402c.247-.247.442-.3 1.123-.3h.57l.02-.77c.021-.798.045-.945.238-1.414.273-.666.923-1.31 1.595-1.578.448-.18.6-.205 1.385-.226l.77-.02v-.57c0-.68.052-.875.3-1.123Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.993 8.92c-.004-1.48-.03-1.744-.17-1.918-.034-.044-.077-.083-.128-.133-.05-.051-.09-.094-.133-.129-.226-.18-.603-.17-3.585-.167-2.757.003-3.186.01-3.34.066a1.118 1.118 0 0 0-.343.229c-.051.05-.094.09-.129.134-.18.227-.17.604-.17 3.568 0 2.965-.01 3.341.17 3.567.035.044.077.083.128.134.051.05.09.093.133.128.182.144.46.167 2.1.17.158-.71.415-1.381.757-2H7.994v-4h4v1.188a7.468 7.468 0 0 1 2-.837Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.398 11.244a6.125 6.125 0 1 1 1.156 10.564l-3.107 1.053a.875.875 0 0 1-1.109-1.11l1.053-3.106a6.125 6.125 0 0 1 2.007-7.4Zm3.947.589a4.375 4.375 0 0 0-4.233 6.359c.108.21.126.456.05.68l-.598 1.763 1.763-.598a.875.875 0 0 1 .68.05 4.374 4.374 0 1 0 2.338-8.254Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.369 13.83a.875.875 0 0 1 0 1.238l-3.5 3.5a.875.875 0 1 1-1.238-1.237l3.5-3.5a.875.875 0 0 1 1.238 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.631 13.83a.875.875 0 0 1 1.238 0l3.5 3.5a.875.875 0 1 1-1.238 1.238l-3.5-3.5a.875.875 0 0 1 0-1.237Z"
      fill="currentColor"
    />
  </Icon>
);

ChipNoMessaging.displayName = 'ChipNoMessagingIcon';
