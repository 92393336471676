import { PaperworkStatus } from 'types';
import { _PAGING_PARAMS, _PAGING_VARIABLES } from '../_paging';
import { _USERS_PARAMS, _USERS_VARIABLES } from './user.helpers';

export const OZ_WORKER_USERS_LIST = `
  query usersQuery (${_USERS_PARAMS}) {
    users (${_USERS_VARIABLES}) {
      id
      uuid
      firstName
      lastName
      profileImg
      isRetail
      isWorker
      isSupport
      isCurrentlyWorking
      createdAt
      tier
      assignedTier
      textingLink
      totalShiftsCompleted
      markets {
        id
        name
        state
      }
    }
  }
`;

export const USER_BASIC_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      uuid
      assignedTier
      birthdate
      firstName
      lastName
      phoneNumber
      preferredName
      profileImg
      textingLink
      status
      markets {
        id
        name
        state
      }
    }
  }
`;

export const USER_CORE_INFO_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      id
      uuid
      assignedTier
      birthdate
      firstName
      hoursThisWeek
      isStaff
      isRetail
      lastName
      phoneNumber
      preferredName
      profileImg
      pronouns
      status
      isFlagged
      tier
      isCurrentlyWorking
      totalShiftsCompleted
      lastShiftDate
      urgentCancelScore
      tardyScore
      favoritedBy {
        uuid
      }
      approvedBy {
        uuid
      }
      blockedBy {
        uuid
      }
      markets {
        id
        name
        state
      }
      attributes
      notificationPreferences {
        isPushNotificationEnabled
      }
    }
  }
`;

export const USER_SHIFTS_QUERY = `
  query usersQuery($uuid: String!, $wsoStatus: [String]) {
    users(uuid: $uuid) {
      shifts(status: $wsoStatus){
        uuid
        status
        cancelClassification
        cancelNote
        shift {
          uuid
          start
          end
          status
          acceptedCount
          acceptLimit
          overbookLimit
          reviews {
            id
            prioritization
            recommended
            rating
            status
            reviewType
          }
          retailerLocation {
            name
            type
            timezone
            retailer {
              logoImage
              name
            }
          }
        }
      }
    }
  }
`;

export const USER_ONBOARDING_AND_EXPERIENCE_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      status
      onboarding
      onboardingDate
      dateJoined
      onboardedBy {
        firstName
        lastName
        uuid
      }
      activatedBy {
        firstName
        lastName
        uuid
      }
      canScheduleCall
      referrer {
        code
        owner {
          firstName
          lastName
          preferredName
          uuid
        }
      }
      referralCode {
        code
        usedBy {
          firstName
          lastName
          preferredName
          uuid
        }
      }
      experience {
        retailer {
          name
          logoImage
        }
        name
        location
        start
        end
        duration
        roles {
          name
          icon
        }
      }
      notes {
        uuid
        content
        updatedAt
        type
        targetUuid
        createdBy {
          firstName
          lastName
        }
      }
      fitFor {
        id
        name
      }
    }
  }
`;

export const USER_DETAILS_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      aboutMe
      address {
        address1
        address2
        city
        state
        zip
      }
      birthdate
      email
      phoneNumber
    }
  }
`;

export const USER_METRICS_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      lastLogin
      lastActivity
      lastShiftDate
      totalShiftsCompleted
      tardyScore
      urgentCancelScore
      favoritedBy {
        uuid
      }
      approvedBy {
        uuid
      }
      blockedBy {
        uuid
      }
      shifts {
        uuid
        status
        cancelClassification
        cancellationPenalty
        shift {
          uuid
          end
          status
          retailerLocation {
            uuid
            name
            type
            retailer {
              name
            }
          }
        }
      }
    }
  }
`;

export const USER_METRICS_FOR_SHIFT_WIDGET_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      totalShiftsCompleted
      lastShiftDate
      tardyScore
      urgentCancelScore
      favoritedBy {
        uuid
      }
      approvedBy {
        uuid
      }
      blockedBy {
        uuid
      }
    }
  }
`;

export const USER_NOTIFICATION_RULES_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      notificationRules {
        mon
        tue
        wed
        thu
        fri
        sat
        sun
        startDate
        endDate
        uuid
      }
    }
  }
`;

export const USER_RELATIONS_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      firstName
      lastName
      preferredName
      markets {
        id
      }
      favoritedBy {
        name
        uuid
        retailer {
          logoImage
          name
        }
      }
      approvedBy {
        name
        uuid
        retailer {
          logoImage
          name
        }
      }
      blockedBy {
        name
        uuid
        retailer {
          logoImage
          name
        }
      }
      userBlocked {
        name
        uuid
        retailer {
          logoImage
          name
        }
      }
    }
  }
`;

export const USER_NOTES_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      notes {
        uuid
        content
        updatedAt
        type
        targetUuid
        createdBy {
          firstName
          lastName
        }
      }
    }
  }
`;

export const USER_EDIT_FORM_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      status
      tier
      markets {
        id
        name
        state
      }
      fitFor {
        name
        id
      }
      canScheduleCall
    }
  }
`;

export const USER_EDIT_PAPERWORK_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      is1099PayproviderComplete
      isBackcheckComplete
      isI9Verified
      isW2PayproviderComplete
    }
  }
`;

export const USER_TAGS_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      uuid
      profileImg
      firstName
      lastName
      preferredName
      tags {
        id
        name
        description
        isArchived
        isListVisible
      }
    }
  }
`;

export const USER_FLAGS_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      uuid
      isFlagged
      flags {
        uuid
        flagType
        status
        blocking
        expiresAt
        flaggedNote
        resolutionNote
        flaggedBy {
          uuid
          firstName
          lastName
        }
        createdAt
        resolvedBy {
          uuid
          firstName
          lastName
        }
        resolvedAt
      }
    }
  }
`;

export const USER_INTEGRATION_IDS_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      uuid
      integrationIds {
        integration
        externalId
        integrationState
        updatedAt
        lastModifiedBy {
          firstName
        }
      }
    }
  }
`;

export const USER_POPUP_SUMMARY_QUERY = `
  query USER_POPUP_SUMMARY_QUERY($uuid: String!) {
    users(uuid: $uuid) {
      firstName
      lastName
      preferredName
      profileImg
      phoneNumber
      tier
      assignedTier
      lastShiftDate
      totalShiftsCompleted
      tardyScore
      urgentCancelScore
      textingLink
      isFlagged
      favoritedBy {
        uuid
      }
      approvedBy {
        uuid
      }
      blockedBy {
        uuid
      }
    }
  }
`;

export const USER_EMPLOYMENT_REQUIREMENTS_QUERY = `
  query USER_EMPLOYMENT_REQUIREMENTS_QUERY (
    $user: String!
  ) {
    users (uuid: $user) {
      uuid
      employmentRequirements {
        id
        requirementType
        state
        expiresAt
        externalLink
        logs {
          id
          note
          createdAt
          user {
            firstName
            lastName
          }
          meta
        }
      }
    }
    activePaperwork: paperwork (
      status: "${PaperworkStatus.ACTIVE}"
    ) {
      id
      uuid
      name
      paperworkType
      status
      required
      document {
        uuid
        name
      }
      userPaperwork (
        user: $user
      ) {
        id
        uuid
        lastSentAt
        createdAt
        completed
        paperworkLink
        logs {
          id
          note
          createdAt
          user {
            firstName
            lastName
          }
          meta
        }
      }
    }
    archivedPaperwork: paperwork (
      status: "${PaperworkStatus.ARCHIVED}"
      user: $user
    ) {
      id
      uuid
      name
      paperworkType
      status
      required
      document {
        uuid
        name
      }
      userPaperwork (
        user: $user
      ) {
        id
        uuid
        lastSentAt
        createdAt
        completed
        paperworkLink
      }
    }
  }
`;

export const USER_DANGER_ZONE_WSO_QUERY = `
  query usersQuery($uuid: String!, $wsoStatus: [String]) {
    users(uuid: $uuid) {
      shifts(status: $wsoStatus){
        uuid
        status
        shift {
          end
        }
      }
    }
  }
`;

export const WORKER_DANGER_ZONE_QUERY = `
  query usersQuery (${_USERS_PARAMS}) {
    users (${_USERS_VARIABLES}) {
      uuid
      email
    }
  }
`;

export const USER_WORKER_BENEFITS_QUERY = `
  query usersQuery($uuid: String!) {
    users(uuid: $uuid) {
      workerBenefits {
        availableBenefits
        benefitType
        totalBenefits
        uuid
        compliance {
          name
        }
      }
    }
  }
`;

export const USER_NOTIFICATION_PREFERENCES_QUERY = `
  query USERS_QUERY($uuids: [String]) {
    users (uuids: $uuids) { 
      notificationPreferences {
        isPushNotificationEnabled
      }
    }
  }
`;

export const ACTIVATE_USERS_LIST_QUERY = `
  query ACTIVATE_USERS_LIST_QUERY(
    $hasAddress: Boolean
    $hasExperience: Boolean
    $hasProfileImage: Boolean
    $markets: [Int]
    $since: Date
    $status: [String]
    $tags: [Int]
    $tagsMissing: [Int]
    $tier: [String]
    $to: Date
    $zipcode: String
    $zipcodeMaxDistance: Float
    $zipcodeMinDistance: Float

    $pageSize: Int
    $page: Int
    $search: String
    $orderBy: String
  ) {
    users (
      hasAddress: $hasAddress
      hasExperience: $hasExperience
      hasProfileImage: $hasProfileImage
      markets: $markets
      since: $since
      status: $status
      tags: $tags
      tagsMissing: $tagsMissing
      tier: $tier
      to: $to
      zipcode: $zipcode
      zipcodeMinDistance: $zipcodeMinDistance
      zipcodeMaxDistance: $zipcodeMaxDistance
      
      pageSize: $pageSize
      page: $page
      search: $search
      orderBy: $orderBy
    ) { 
      uuid
      firstName
      lastName
      preferredName
      profileImg
      tier
      assignedTier
      aboutMe
      dateJoined
      onboarding
      status
      isRecentlyActive
      totalShiftsCompleted
      markets {
        name
        id
      }
      experience {
        id
        retailer {
          name
          logoImage
        }
        name
        location
        start
        end
        duration
      }
      referrer {
        code
      }
      tags {
        id
        name
        description
        isArchived
        isListVisible
      }
    }
  }
`;

export const USER_AUDIT_LOGS_QUERY = `
  query USER_AUDIT_LOG_QUERY(
    $worker: String
    $user: String
    $since: Date,
    $to: Date,
    ${_PAGING_PARAMS}
  ) {
    userAuditLogs(
      worker: $worker
      user: $user
      since: $since
      to: $to
      ${_PAGING_VARIABLES}
    ) {
        event
        timestamp
        user {
          firstName
          lastName
          isStaff
          uuid
        }
        meta
     }
  }
`;
