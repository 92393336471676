import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

const _MARKET_PARAMS = `
  $isActive: Boolean
  $isGroup: Boolean
  $isChild: Boolean
  ${_PAGING_PARAMS}
`;

const _MARKET_VARIABLES = `
  isActive: $isActive
  isGroup: $isGroup
  isChild: $isChild
  ${_PAGING_VARIABLES}
`;

export const MARKET_LIST_QUERY = `
  query MARKETS_LIST_QUERY(${_MARKET_PARAMS}) {
    markets (${_MARKET_VARIABLES}) {
      id
      name
      state
      isActive
      hasLiveLocations
      markets {
        id
      }
    }
  }
`;
