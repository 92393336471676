import { FC } from 'react';
import { Flex, FlexProps, Switch, Tooltip } from '@chakra-ui/react';
import {
  LocationCommunicationPreferenceDescriptions,
  LocationCommunicationPreferenceIcons,
  LocationCommunicationPreferenceLabels,
  PreferenceOptionState,
} from 'types';
import { TextAndIcon } from '../TextAndIcon';

interface PreferenceToggleOptionProps {
  state?: PreferenceOptionState;
  preference: string; // LocationCommunicationPreference
  handleUpdateState: (preference: string) => void;
  containerProps?: FlexProps;
  isDisabled?: boolean;
}

export const PreferenceToggleOption: FC<PreferenceToggleOptionProps> = ({
  state = PreferenceOptionState.NEGATIVE,
  preference,
  handleUpdateState,
  containerProps,
  isDisabled = true,
}) => {
  const onUpdate = () => handleUpdateState(preference);

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      p={2}
      pr={4}
      {...containerProps}
    >
      <Flex flexGrow={1}>
        <Tooltip
          label={LocationCommunicationPreferenceDescriptions[preference]}
          openDelay={1000} // ms
          gutter={0}
        >
          <Flex>
            <TextAndIcon
              text={LocationCommunicationPreferenceLabels[preference]}
              textProps={{ variant: 'label-md' }}
              icon={LocationCommunicationPreferenceIcons[preference]}
              iconProps={{ h: 5, w: 5, ml: 1 }}
              containerProps={{ flexGrow: 1, gap: 2 }}
            />
          </Flex>
        </Tooltip>
      </Flex>
      <Switch
        isChecked={state === PreferenceOptionState.POSITIVE}
        onChange={onUpdate}
        colorScheme="green"
      />
    </Flex>
  );
};
