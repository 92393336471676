import { uuid } from './common';
import { extendedUserDetails } from './user';

export enum MessageEvents {
  TEST = 'TEST',
  DJANGO_USER_ACTIVATED = 'DJANGO_USER_ACTIVATED',
  DJANGO_USER_DEACTIVATED = 'DJANGO_USER_DEACTIVATED',
  USER_CREATED = 'USER_CREATED',
  USER_LOGGED_IN = 'USER_LOGGED_IN',
  USER_STAFF_ENABLED = 'USER_STAFF_ENABLED',
  USER_STAFF_DISABLED = 'USER_STAFF_DISABLED',
  USER_SUPERUSER_ENABLED = 'USER_SUPERUSER_ENABLED',
  USER_SUPERUSER_DISABLED = 'USER_SUPERUSER_DISABLED',
  USER_WORKER_ENABLED = 'USER_WORKER_ENABLED',
  USER_WORKER_DISABLED = 'USER_WORKER_DISABLED',
  FLEX_BOOKED = 'FLEX_BOOKED',
  FLEX_CREATED = 'FLEX_CREATED',
  FLEX_ENDED = 'FLEX_ENDED',
  FLEX_SHIFT_CANCELLED = 'FLEX_SHIFT_CANCELLED',
  FLEX_SHIFT_DECLINED = 'FLEX_SHIFT_DECLINED',
  FLEX_STARTED = 'FLEX_STARTED',
  FLEX_WORKER_CANCELLED = 'FLEX_WORKER_CANCELLED',
  WORKER_FLAGGED = 'WORKER_FLAGGED',
  WORKER_STATUS_LEAD = 'WORKER_STATUS_LEAD',
  WORKER_STATUS_QUALIFIED = 'WORKER_STATUS_QUALIFIED',
  WORKER_STATUS_REGISTERED = 'WORKER_STATUS_REGISTERED',
  WORKER_STATUS_ACTIVE = 'WORKER_STATUS_ACTIVE',
  WORKER_STATUS_INACTIVE = 'WORKER_STATUS_INACTIVE',
  WORKER_STATUS_NURTURE = 'WORKER_STATUS_NURTURE',
  WORKER_STATUS_DISMISSED = 'WORKER_STATUS_DISMISSED',
  WORKER_STATUS_OFFBOARDED = 'WORKER_STATUS_OFFBOARDED',
  WORKER_NOTIFICATION_7D = 'WORKER_NOTIFICATION_7D',
  WORKER_NOTIFICATION_NEXT_DAY = 'WORKER_NOTIFICATION_NEXT_DAY',
  WORKER_NOTIFICATION_22HR = 'WORKER_NOTIFICATION_22HR',
  WORKER_NOTIFICATION_20HR = 'WORKER_NOTIFICATION_20HR',
  WORKER_NOTIFICATION_16HR = 'WORKER_NOTIFICATION_16HR',
  WORKER_NOTIFICATION_2HR = 'WORKER_NOTIFICATION_2HR',
  WORKER_NOTIFICATION_25M = 'WORKER_NOTIFICATION_25M',
  WORKER_DURING_NOTIFICATION_10M = 'WORKER_DURING_NOTIFICATION_10M',
  WORKER_POST_NOTIFICATION_1HR = 'WORKER_POST_NOTIFICATION_1HR',
  WORKER_POST_NOTIFICATION_24HR = 'WORKER_POST_NOTIFICATION_24HR',
  RETAILER_STATUS_LEAD = 'RETAILER_STATUS_LEAD',
  RETAILER_STATUS_QUALIFIED = 'RETAILER_STATUS_QUALIFIED',
  RETAILER_STATUS_REGISTERED = 'RETAILER_STATUS_REGISTERED',
  RETAILER_STATUS_ACTIVE = 'RETAILER_STATUS_ACTIVE',
  RETAILER_STATUS_INACTIVE = 'RETAILER_STATUS_INACTIVE',
  RETAILER_STATUS_FLAGGED = 'RETAILER_STATUS_FLAGGED',
  RETAILER_STATUS_NURTURE = 'RETAILER_STATUS_NURTURE',
  RETAILER_STATUS_DISMISSED = 'RETAILER_STATUS_DISMISSED',
  RETAILER_POST_FLEX_NOTIFICATION = 'RETAILER_POST_FLEX_NOTIFICATION',
  RETAILER_REMINDER_24HR = 'RETAILER_REMINDER_24HR',
  WORKER_1099_FOLLOWUP_PAPERWORK = 'WORKER_1099_FOLLOWUP_PAPERWORK',
  WORKER_W2_FOLLOWUP_PAPERWORK = 'WORKER_W2_FOLLOWUP_PAPERWORK',
  WORKER_PAYMENT_PROCESSED = 'WORKER_PAYMENT_PROCESSED',
  INVOICES_PROCESSED = 'INVOICES_PROCESSED',
  OZ_MESSAGE = 'OZ_MESSAGE',
  OZ_MESSAGE_PUSH = 'OZ_MESSAGE_PUSH',
  OZ_MESSAGE_SMS = 'OZ_MESSAGE_SMS',
  PLAYBOOK_MESSAGE = 'PLAYBOOK_MESSAGE',
  NUDGE_FAVORITES = 'NUDGE_FAVORITES',
  NUDGE_REPEAT = 'NUDGE_REPEAT',
  WORKER_CONFIRMATION_ARRIVAL_LINK = 'WORKER_CONFIRMATION_ARRIVAL_LINK',
  WORKER_CONFIRMATION_ARRIVAL_MANUAL = 'WORKER_CONFIRMATION_ARRIVAL_MANUAL',
  WORKER_CONFIRMATION_HOUR_2_LINK = 'WORKER_CONFIRMATION_HOUR_2_LINK',
  WORKER_CONFIRMATION_HOUR_2_MANUAL = 'WORKER_CONFIRMATION_HOUR_2_MANUAL',
  WORKER_CONFIRMATION_HOUR_24_LINK = 'WORKER_CONFIRMATION_HOUR_24_LINK',
  WORKER_CONFIRMATION_HOUR_24_MANUAL = 'WORKER_CONFIRMATION_HOUR_24_MANUAL',
  WORKER_BIRTHDAY = 'WORKER_BIRTHDAY',
  WORKERSHIFT_EVENT_CLOCK_IN_DISTANCE = 'WORKERSHIFT_EVENT_CLOCK_IN_DISTANCE',
  WORKERSHIFT_EVENT_CLOCK_OUT_DISTANCE = 'WORKERSHIFT_EVENT_CLOCK_OUT_DISTANCE',
  WORKER_REVIEW_COMPLETED = 'WORKER_REVIEW_COMPLETED',
  RETAILER_REVIEW_COMPLETED = 'RETAILER_REVIEW_COMPLETED',
  RETAILER_CANCELLED_SHIFT = 'RETAILER_CANCELLED_SHIFT',
  RETAILER_CANCEL_SHIFT_REQUEST = 'RETAILER_CANCEL_SHIFT_REQUEST',
}

export enum MessageMethods {
  SLACK = 'SLACK',
  TEXT = 'TEXT',
  EMAIL = 'EMAIL',
}

export enum MessageStatus {
  SCHEDULED = 'SCHEDULED',
  OK = 'OK',
  ERROR = 'ERROR',
  NO_TEMPLATE = 'NO_TEMPLATE',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
}

export type messageRecipient = {
  to?: string;
  user: Partial<extendedUserDetails>;
  content: string;
};

export type messagePreview = {
  properties?: string;
  recipients?: Array<messageRecipient>;
};

export type messageTemplate = {
  id: number;
  body: string;
  subject: string;
  method: MessageMethods;
  preview: messagePreview;
};

export type message = {
  id: number;
  event: MessageEvents;
  dispatchTime: string;
  user?: { uuid: string };
  shift?: { uuid: string };
  templates?: Array<messageTemplate>;
  template?: messageTemplate;
  recipients?: Array<messageRecipient>;
  status?: MessageStatus;
  paused?: boolean;
};

export interface graphMessages {
  scheduled: Array<message>;
  history: Array<message>;
}

export type messages = graphMessages;

export type normalizedMessage = {
  id: number;
  randomUUID: uuid;
  event: MessageEvents;
  dispatchTime: string;
  user?: { uuid: string };
  shift?: { uuid: string };
  template?: messageTemplate;
  recipient?: messageRecipient;
  status?: MessageStatus;
  paused?: boolean;
};

export enum GQLResponseStatus {
  SUCCESS = 'success',
  PARTIAL = 'partial',
  FAILED = 'failed',
  ERROR = 'error',
}

export type previewMessageUsersFilterCounts = {
  name?: string;
  overridable?: boolean;
  count?: number;
}[];

export enum MessageAudienceFilterGroup {
  NoMobileSetup = 'NoMobileSetup',
  NoDevices = 'NoDevices',
  PushNotificationsOff = 'PushNotificationsOff',
  NoMobileNumber = 'NoMobileNumber',
  CantReceiveCommunications = 'CantReceiveCommunications',
  NotTextSubscribed = 'NotTextSubscribed',
  UserStatus = 'UserStatus',
  UserIsDormant = 'UserIsDormant',
  ExceedsMaxNotificationCount = 'ExceedsMaxNotificationCount',
  RecentlyMessaged = 'RecentlyMessaged',
  HasUpcomingPlaybookMessage = 'HasUpcomingPlaybookMessage',
  IsBlocked = 'IsBlocked',
  HasConflictingShift = 'HasConflictingShift',
  DirtyWSO = 'DirtyWSO',
  AlreadyMessagedForShift = 'AlreadyMessagedForShift',
  AboutToBeMessagedForShift = 'AboutToBeMessagedForShift',
  DeclinedWSO = 'DeclinedWSO',
  ExceedsWeeklyHoursCap = 'ExceedsWeeklyHoursCap',
  ViolatesGapRule = 'ViolatesGapRule',
  OutOfZipRange = 'OutOfZipRange',
}

export const MessageAudienceFilterGroupLabels = {
  [MessageAudienceFilterGroup.NoMobileSetup]: 'No Mobile Downloaded',
  [MessageAudienceFilterGroup.NoDevices]: 'No Mobile Devices',
  [MessageAudienceFilterGroup.PushNotificationsOff]:
    'Push Notifications Turned Off',
  [MessageAudienceFilterGroup.NoMobileNumber]: 'No Phone Number',
  [MessageAudienceFilterGroup.CantReceiveCommunications]:
    'Cannot Receive Communications',
  [MessageAudienceFilterGroup.NotTextSubscribed]: 'Not Text Subscribed',
  [MessageAudienceFilterGroup.UserStatus]: 'Is Inactive or Offboarded',
  [MessageAudienceFilterGroup.UserIsDormant]: 'User Is Dormant',
  [MessageAudienceFilterGroup.ExceedsMaxNotificationCount]:
    'Has Exceeded Max Notification Count',
  [MessageAudienceFilterGroup.RecentlyMessaged]: 'User Was Recently Messaged',
  [MessageAudienceFilterGroup.HasUpcomingPlaybookMessage]:
    'User Has Upcoming Playbook Message',
  [MessageAudienceFilterGroup.IsBlocked]: 'User Is Blocked At Location',
  [MessageAudienceFilterGroup.HasConflictingShift]:
    'User Has a Conflicting Shift',
  [MessageAudienceFilterGroup.DirtyWSO]: 'WSO Previously Accepted',
  [MessageAudienceFilterGroup.AlreadyMessagedForShift]:
    'Already Messaged For Shift',
  [MessageAudienceFilterGroup.AboutToBeMessagedForShift]:
    'About To Be Messaged For Shift',
  [MessageAudienceFilterGroup.DeclinedWSO]: 'Has Declined WSO',
  [MessageAudienceFilterGroup.ExceedsWeeklyHoursCap]: 'Over Hours For Week',
  [MessageAudienceFilterGroup.ViolatesGapRule]: 'Violates Compliance Gap Rule',
  [MessageAudienceFilterGroup.OutOfZipRange]: 'Out of Zip Bounds',
};

export const SkippableMessageAudienceFilterGroups = [
  MessageAudienceFilterGroup.UserIsDormant,
  MessageAudienceFilterGroup.ExceedsMaxNotificationCount,
  MessageAudienceFilterGroup.AlreadyMessagedForShift,
  MessageAudienceFilterGroup.OutOfZipRange,
];
