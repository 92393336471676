export const UPDATE_ONBOARDING_SCHEDULE_MUTATION = `
  mutation UPDATE_ONBOARDING_SCHEDULE_MUTATION (
    $uuid: String
    $when: Date
    $workerUuid: String
  ) {
    updateOnboardingSchedule (
      uuid: $uuid
      when: $when
      workerUuid: $workerUuid
    ) {
      ok
      messages {
        code
      }
    }
  }
`;

export const CANCEL_ONBOARDING_CALL_MUTATION = `
  mutation CANCEL_ONBOARDING_CALL_MUTATION (
    $workerUuid: UUID
  ) {
    cancelOnboardingCall (
      workerUuid: $workerUuid
    ) {
      ok
      messages {
        code
      }
    }
  }
`;
