import { FC } from 'react';
import { useOverbookStore } from 'utils/stores';
import { QueryParamModal, useViewStore } from 'utils/stores/ViewStore';
import { Modal } from 'components/common/Modal';
import { OverbookSettings } from './OverbookSettings';

export const OverbookSettingsModalContainer: FC = () => {
  const [modalOpen, closeModal] = useViewStore(s => [
    s.modalOpen,
    s.closeModal,
  ]);
  const [setShift] = useOverbookStore(s => [s.setShift]);

  const handleClose = () => {
    setShift({});
    closeModal();
  };

  return (
    <Modal
      isOpen={modalOpen === QueryParamModal.OVERBOOK_SETTINGS}
      onClose={handleClose}
      size="sm"
    >
      <OverbookSettings onSuccess={handleClose} />
    </Modal>
  );
};
