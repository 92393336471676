import { applyContext, mutateObject, Mutations } from 'helpers';
import { GQL, LocationCommunicationPreference } from 'types';

export interface LocationCommunicationPreferenceMutationInterface {
  users: string[];
  locations: string[];
  types: LocationCommunicationPreference[];
}

export type mutateAddLocationCommunicationPreferencesProps =
  LocationCommunicationPreferenceMutationInterface;
export type mutateRemoveLocationCommunicationPreferencesProps =
  LocationCommunicationPreferenceMutationInterface;

export const mutateAddLocationCommunicationPreferences = async (
  data: mutateAddLocationCommunicationPreferencesProps,
) => {
  return await mutateObject({
    route: applyContext(GQL, 'ADD_LOCATION_COMMUNICATION_PREFERENCES'),
    query: Mutations.ADD_LOCATION_COMMUNICATION_PREFERENCES,
    data,
    returnKey: 'addLocationCommunicationPreferences',
  });
};

export const mutateRemoveLocationCommunicationPreferences = async (
  data: mutateRemoveLocationCommunicationPreferencesProps,
) => {
  return await mutateObject({
    route: applyContext(GQL, 'REMOVE_LOCATION_COMMUNICATION_PREFERENCES'),
    query: Mutations.REMOVE_LOCATION_COMMUNICATION_PREFERENCES,
    data,
    returnKey: 'removeLocationCommunicationPreferences',
  });
};

export type mutateRemoveAllLocationCommunicationPreferencesProps = {
  users: string[];
};

export const mutateRemoveAllLocationCommunicationPreferences = async (
  data: mutateRemoveAllLocationCommunicationPreferencesProps,
) => {
  return await mutateObject({
    route: applyContext(GQL, 'REMOVE_ALL_LOCATION_COMMUNICATION_PREFERENCES'),
    query: Mutations.REMOVE_ALL_LOCATION_COMMUNICATION_PREFERENCES,
    data,
    returnKey: 'removeAllLocationCommunicationPreferences',
  });
};

export type mutateBulkEditOrganizationLocationCommunicationPreferencesProps = {
  users: string[];
  types: LocationCommunicationPreference[];
};

export const mutateBulkEditOrganizationLocationCommunicationPreferences =
  async (
    data: mutateBulkEditOrganizationLocationCommunicationPreferencesProps,
  ) => {
    return await mutateObject({
      route: applyContext(
        GQL,
        'BULK_EDIT_ORGANIZATION_LOCATION_COMMUNICATION_PREFERENCES',
      ),
      query:
        Mutations.BULK_EDIT_ORGANIZATION_LOCATION_COMMUNICATION_PREFERENCES,
      data,
      returnKey: 'bulkEditOrganizationLocationCommunicationPreferences',
    });
  };
