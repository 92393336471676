import { FC, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { BetterButton } from 'ui';
import { useShiftActions } from 'utils/hooks/useShiftActions';
import { ActionState, useOverbookStore } from 'utils/stores';
import { ShiftMicroMeta } from '../ShiftMicroMeta';
import { WorkerMeta } from './WorkerMeta';

export const OverbookConfirm: FC = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [workershift, shift, newShift, updateMeta] = useOverbookStore(s => [
    s.workershift,
    s.shift,
    s.newShift,
    s.updateMeta,
  ]);
  const { moveWsoToExistingShift } = useShiftActions();

  const handleConfirm = async () => {
    setIsProcessing(true);

    const response = await moveWsoToExistingShift.mutateAsync({
      shift: newShift?.uuid,
      workershift: workershift?.uuid,
    });

    setIsProcessing(false);

    if (!response.hasErrors) {
      const details = response?.body?.data?.wsoToExistingShift;
      updateMeta('graphResponse', details);
      updateMeta('actionState', ActionState.FINISHED);
    } else {
      console.error(response.errors[0]);
    }
  };

  return (
    <Flex flexDirection="column">
      <Text fontSize="lg" fontWeight="semi" mt={2} mb={3}>
        {'Confirm Move'}
      </Text>
      <WorkerMeta {...workershift?.worker} />
      <Flex flexDirection="column" gap={3}>
        <Flex flexDirection="column">
          <Text fontSize="sm" fontWeight="semi" color="blackAlpha.400" mb={1}>
            {'From'}
          </Text>
          <ShiftMicroMeta shift={shift} />
        </Flex>
        <Flex flexDirection="column">
          <Text fontSize="sm" fontWeight="semi" color="blackAlpha.400" mb={1}>
            {'To'}
          </Text>
          <ShiftMicroMeta shift={newShift} />
        </Flex>
      </Flex>
      <BetterButton
        label="Confirm Move"
        size="lg"
        mt={6}
        onClick={handleConfirm}
        width="100%"
        maxWidth={180}
        alignSelf="center"
        isProcessing={isProcessing}
      />
    </Flex>
  );
};
