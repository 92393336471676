import { DataKeys } from 'types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface SocketHelperStore {
  currentConnectionId: string | null;
  setCurrentConnectionId: (id: string) => void;
  toInvalidate: Partial<Record<DataKeys, Date>>;
  updateToInvalidate: (key: DataKeys, action: 'add' | 'remove') => void;
  clearToInvalidate: () => void;
  isReloading: boolean;
  setIsReloading: (isReloading: boolean) => void;
}

const defaultState = {
  currentConnectionId: null,
  toInvalidate: {},
  isReloading: false,
};

const SocketHelperStore = (set, get): SocketHelperStore => ({
  ...defaultState,
  setCurrentConnectionId: id => set({ currentConnectionId: id }),
  updateToInvalidate: (key, action) => {
    if (action === 'add') {
      set(prev => ({
        toInvalidate: { ...prev.toInvalidate, [key]: new Date() },
      }));
    } else {
      const currentKeys = get().toInvalidate;
      const newKeys = {};
      Object.keys(currentKeys).forEach(currentKey => {
        if (currentKey !== key) newKeys[currentKey] = currentKeys[currentKey];
      });
      set({ toInvalidate: newKeys });
    }
  },
  clearToInvalidate: () => set({ toInvalidate: {} }),
  setIsReloading: isReloading => set({ isReloading }),
});

export const useSocketHelperStore = create(
  persist(SocketHelperStore, {
    name: 'oz-socket',
    partialize: state => ({
      toInvalidate: state.toInvalidate,
      currentConnectionId: state.currentConnectionId,
    }),
  }),
);
