import { fetchLocationsProps } from 'helpers';
import {
  LocationStatus,
  LocationType,
  PaymentContractTypeOptions,
  RetailerCategory,
  RetailerSize,
  SelectOption,
} from 'types';

export interface LocationsFiltersState {
  locationsStatus: SelectOption[];
  locationsMarkets: SelectOption[];
  locationsRetailers: SelectOption[];
  locationsType: SelectOption[];
  locationsPayment: SelectOption[];
  locationsRetailerSize: SelectOption[];
  locationsRetailerCategory: SelectOption[];
  locationsAccountOwner: SelectOption[];
  locationsTags: SelectOption[];
}

export interface LocationsFiltersSlice extends LocationsFiltersState {
  resetLocationsFilters: () => void;
}

export const defaultLocationsFiltersState: LocationsFiltersState = {
  locationsStatus: [
    { label: 'Active', value: LocationStatus.ACTIVE },
    { label: 'Onboarded', value: LocationStatus.ONBOARDED },
  ],
  locationsMarkets: [],
  locationsRetailers: [],
  locationsType: [],
  locationsPayment: [],
  locationsRetailerSize: [],
  locationsRetailerCategory: [],
  locationsAccountOwner: [],
  locationsTags: [],
};

export const locationsFiltersSlice = set => ({
  ...defaultLocationsFiltersState,
  resetLocationsFilters: () => {
    set(prev => ({ ...prev, ...defaultLocationsFiltersState }));
  },
});

export const getParsedLocationsFilters = (
  filters: Partial<LocationsFiltersState>,
) => {
  const {
    locationsStatus,
    locationsMarkets,
    locationsRetailers,
    locationsType,
    locationsPayment,
    locationsRetailerSize,
    locationsRetailerCategory,
    locationsAccountOwner,
    locationsTags,
  } = filters || {};

  const graphFilters = {} as fetchLocationsProps;

  if (locationsStatus?.length) {
    graphFilters.status = locationsStatus.map(
      option => option?.value as LocationStatus,
    );
  }

  if (locationsMarkets?.length) {
    graphFilters.markets = locationsMarkets.map(market =>
      parseInt(market.value),
    );
  }

  if (locationsRetailers?.length) {
    graphFilters.retailers = locationsRetailers.map(retailer => retailer.value);
  }

  if (locationsType?.length) {
    graphFilters.types = locationsType.map(type => type.value as LocationType);
  }

  if (locationsPayment?.length) {
    graphFilters.paymentTypes = locationsPayment.map(
      payment => payment.value as PaymentContractTypeOptions,
    );
  }

  if (locationsRetailerCategory?.length) {
    graphFilters.retailerCategories = locationsRetailerCategory.map(
      category => category.value as RetailerCategory,
    );
  }

  if (locationsRetailerSize?.length) {
    graphFilters.retailerSizes = locationsRetailerSize.map(
      size => size.value as RetailerSize,
    );
  }

  if (locationsTags?.length) {
    graphFilters.tags = locationsTags.map(tag => tag.value);
  }

  if (locationsAccountOwner?.length) {
    graphFilters.accountOwner = locationsAccountOwner.map(
      owner => owner.value,
    )[0];
  }

  return graphFilters;
};
