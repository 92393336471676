import { ActionMap } from 'types';
import { uuid } from 'types/src/common';
import { graphUserLight, worker } from 'types/src/user';
import { FilterOption } from 'utils/types/filters';
import { formState, formStateDefaults } from 'utils/types/form';

export interface WorkersStateInterface {
  selectedWorkers?: worker; // @deprecated: please use UsersStore
  formState: formState;
}

export const initialState: WorkersStateInterface = {
  selectedWorkers: {},
  formState: formStateDefaults,
};

export enum WorkersActions {
  ADD_WORKER = 'ADD_WORKER',
  ADD_WORKERS = 'ADD_WORKERS',
  REMOVE_WORKER = 'REMOVE_WORKER',
  REMOVE_WORKERS = 'REMOVE_WORKERS',
  REMOVE_ALL_WORKERS = 'REMOVE_ALL_WORKERS',
  UPDATE_SEARCH = 'UPDATE_USERS_SEARCH',
  RESET_WORKERS_CONTEXT = 'RESET_WORKERS_CONTEXT',
  UPDATE_FORM_STATE = 'UPDATE_WORKER_FORM_STATE',
  UPDATE_FORM_PROCESSING = 'UPDATE_WORKER_FORM_IS_PROCESSING',
  UPDATE_FORM_SUCCESS = 'UPDATE_WORKER_FORM_IS_SUCCESS',
  UPDATE_FORM_ERRORS = 'UPDATE_WORKER_FORM_ERRORS',
}

type WorkersPayload = {
  [WorkersActions.ADD_WORKER]: Partial<graphUserLight>;
  [WorkersActions.ADD_WORKERS]: Array<Partial<graphUserLight>>;
  [WorkersActions.REMOVE_WORKER]: {
    uuid: string;
  };
  [WorkersActions.REMOVE_WORKERS]: Array<uuid>;
  [WorkersActions.REMOVE_ALL_WORKERS]: null;
  [WorkersActions.RESET_WORKERS_CONTEXT]: null;
  [WorkersActions.UPDATE_FORM_PROCESSING]: boolean;
  [WorkersActions.UPDATE_FORM_SUCCESS]: boolean;
  [WorkersActions.UPDATE_FORM_ERRORS]: Array<string>;
};

type WorkersActionTypes =
  ActionMap<WorkersPayload>[keyof ActionMap<WorkersPayload>];

export const workersReducer = (draft, action: WorkersActionTypes) => {
  switch (action.type) {
    case WorkersActions.ADD_WORKER:
      draft.selectedWorkers[action?.payload?.uuid] = action?.payload;
      break;
    case WorkersActions.ADD_WORKERS:
      action?.payload.forEach(worker => {
        draft.selectedWorkers[worker.uuid] = worker;
      });
      break;
    case WorkersActions.REMOVE_WORKER:
      delete draft.selectedWorkers[action?.payload.uuid];
      break;
    case WorkersActions.REMOVE_WORKERS:
      action?.payload.forEach(uuid => {
        delete draft.selectedWorkers[uuid];
      });
      break;
    case WorkersActions.REMOVE_ALL_WORKERS:
      draft.selectedWorkers = {};
      break;
    case WorkersActions.RESET_WORKERS_CONTEXT:
      draft = initialState;
      break;
    case WorkersActions.UPDATE_FORM_PROCESSING:
      draft.formState.isProcessing = action?.payload;
      break;
    case WorkersActions.UPDATE_FORM_SUCCESS:
      draft.formState.isSuccess = action?.payload;
      break;
    case WorkersActions.UPDATE_FORM_ERRORS:
      draft.formState.errors = action?.payload;
      break;
    default:
      break;
  }
  return draft;
};
