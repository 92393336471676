import {
  addDays,
  addMonths,
  addWeeks,
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { fetchReviewsProps, sortGenerator } from 'helpers';
import { PageSizes, paging, review, SortState } from 'types';
import { CalendarValues } from 'ui';
import {
  arrayOfStringsParser,
  marketsParser,
  reviewsHasCoachingParser,
  reviewsHasFeedbackParser,
  reviewsPrioritizationParser,
  reviewsRatingParser,
} from 'utils/helpers/filters';
import { FilterOption } from 'utils/types/filters';
import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import {
  ReviewsQuickPicks,
  ReviewsSortMap,
  ReviewsSortOptions,
} from './ReviewsStore.helpers';

interface ReviewsStore {
  sort: ReviewsSortMap;
  updateSort: (key: string, value: SortState) => void;
  selectedDates: CalendarValues;
  updateSelectedDates: (dates: CalendarValues) => void;
  selectedDateQuickPick: ReviewsQuickPicks;
  updateDateQuickPick: (pick: ReviewsQuickPicks) => void;
  filters: fetchReviewsProps;
  updateFilter: <K extends keyof ReviewsStore['filters']>(
    key: K,
    value: ReviewsStore['filters'][K],
  ) => void;
  activeFilterOptions: Record<string, FilterOption[]>; // FUTURE: stronger type for string key
  updateActiveFilter: (key: string, value: FilterOption[]) => void;
  resetFilters: () => void;
  prevSelectedRowIndex: number;
  updatePrevSelectedRowIndex: (index: number) => void;
  selectedReviews: string[]; // uuid
  selectedReviewsDict: Record<string, Partial<review>>;
  updateSelectedReviews: (params: {
    reviews?: review[];
    action: 'add' | 'remove';
  }) => void;
  clearSelectedReviews: () => void;
  pagination: paging;
  updatePageSize: (pageSize: number) => void;
  updateCurrentPage: (page: number) => void;
}

const defaultFilters = {
  status: null,
  createdSince: null,
  createdTo: null,
  rating: null,
  prioritization: null,
  hasCoaching: null,
  hasFeedback: null,
  retailers: null,
  locations: null,
  markets: null,
};

const defaultActiveFilterOptions = {
  rating: [],
  prioritization: [],
  hasCoaching: [],
  hasFeedback: [],
};

const reviewsStore = (set, get): ReviewsStore => ({
  sort: {
    ...sortGenerator(SortState.NONE, ReviewsSortOptions),
    updated_at: SortState.DESC,
    created_at: null,
  },
  updateSort: (key, value) => {
    set(
      prev => ({
        ...prev,
        sort: { ...prev?.sort, [key]: value },
      }),
      false,
      'updateSort',
    ),
      get().clearSelectedReviews();
  },
  selectedDates: {
    start: addDays(new Date(), -7),
    end: null,
  },
  updateSelectedDates: dates => {
    const newDates = {};
    Object.keys(dates).map(key => (newDates[key] = dates[key]));
    set({ selectedDates: newDates });
  },
  selectedDateQuickPick: ReviewsQuickPicks.RECENT,
  updateDateQuickPick: (pick: ReviewsQuickPicks) => {
    const createNewSelectedDates = () => {
      switch (pick) {
        case ReviewsQuickPicks.ALL_TIME:
          return {
            start: null,
            end: null,
          };
        case ReviewsQuickPicks.THIS_WEEK:
          return {
            start: startOfWeek(new Date()),
            end: endOfWeek(new Date()),
          };
        case ReviewsQuickPicks.LAST_WEEK:
          return {
            start: addWeeks(startOfWeek(new Date()), -1),
            end: addWeeks(endOfWeek(new Date()), -1),
          };
        case ReviewsQuickPicks.THIS_MONTH:
          return {
            start: startOfMonth(new Date()),
            end: endOfMonth(new Date()),
          };
        case ReviewsQuickPicks.LAST_MONTH:
          return {
            start: addMonths(startOfMonth(new Date()), -1),
            end: addMonths(endOfMonth(new Date()), -1),
          };
        case ReviewsQuickPicks.RECENT:
        default:
          return {
            start: addDays(new Date(), -7),
            end: null,
          };
      }
    };
    set(prev => ({
      selectedDateQuickPick: pick,
      selectedDates: createNewSelectedDates(),
      pagination: {
        ...prev?.pagination,
        currentPage: 0,
      },
    }));
    get().clearSelectedReviews();
  },
  filters: defaultFilters,
  updateFilter: (key, value) => {
    set(
      prev => ({
        filters: { ...prev?.filters, [key]: value },
      }),
      false,
      'updateFilter',
    );
    get().clearSelectedReviews();
  },
  activeFilterOptions: defaultActiveFilterOptions,
  updateActiveFilter: (key, value) => {
    set(
      prev => ({
        activeFilterOptions: { ...prev?.activeFilterOptions, [key]: value },
      }),
      false,
      'updateActiveFilter',
    );
    get().clearSelectedReviews();
  },
  resetFilters: () => {
    set({
      filters: defaultFilters,
      activeFilterOptions: defaultActiveFilterOptions,
    });
  },
  prevSelectedRowIndex: null,
  updatePrevSelectedRowIndex: (index: number) =>
    set({
      prevSelectedRowIndex: index,
    }),
  selectedReviews: [],
  selectedReviewsDict: {},
  updateSelectedReviews: ({ reviews, action }) => {
    const { selectedReviews: prevSelectedReviews } = get();
    switch (action) {
      case 'add': {
        const newReviewUuids = reviews?.map(r => r?.uuid);
        const updateUuids = [
          ...new Set([...prevSelectedReviews, ...newReviewUuids]),
        ];
        set({
          selectedReviews: updateUuids,
          selectedReviewsDict: {},
        });
        break;
      }
      case 'remove': {
        const removeReviewUuids = reviews?.map(r => r?.uuid);
        const newReviews = prevSelectedReviews.filter(
          uuid => !removeReviewUuids.includes(uuid),
        );
        set({
          selectedReviews: newReviews,
          selectedReviewsDict: {},
        });
        break;
      }
      default:
        break;
    }
  },
  clearSelectedReviews: () =>
    set({
      selectedReviews: [],
      selectedReviewsDict: {},
    }),
  pagination: {
    pageSize: PageSizes.ten,
    currentPage: 0,
  },
  updatePageSize: pageSize =>
    set(prev => ({
      pagination: {
        ...prev?.pagination,
        pageSize,
      },
    })),
  updateCurrentPage: page =>
    set(prev => ({
      pagination: {
        ...prev?.pagination,
        currentPage: page,
      },
    })),
});

export const useReviewsStore = create(
  subscribeWithSelector(devtools(reviewsStore)),
);

useReviewsStore.subscribe(
  store => store.selectedDates,
  dates => {
    const { filters: prevFilters } = useReviewsStore.getState();
    useReviewsStore.setState({
      filters: {
        ...prevFilters,
        createdSince: dates?.start
          ? format(new Date(dates?.start), 'yyyy-MM-dd')
          : null,
        createdTo: dates?.end
          ? format(new Date(dates?.end), 'yyyy-MM-dd')
          : null,
      },
    });
  },
);

useReviewsStore.subscribe(
  store => store.activeFilterOptions,
  activeFilterOptions => {
    const { filters: prevFilters, pagination } = useReviewsStore.getState();
    const {
      locations,
      retailers,
      hasCoaching,
      hasFeedback,
      rating,
      prioritization,
      markets,
    } = activeFilterOptions;

    // * FUTURE: more eloquent way to do this
    useReviewsStore.setState({
      pagination: {
        ...pagination,
        currentPage: 0,
      },
      filters: {
        ...prevFilters,
        ...(locations?.length
          ? arrayOfStringsParser('locations', activeFilterOptions?.locations)
          : { locations: null }),
        ...(retailers?.length
          ? arrayOfStringsParser('retailers', activeFilterOptions?.retailers)
          : { retailers: null }),
        ...(hasFeedback?.length
          ? reviewsHasFeedbackParser(hasFeedback)
          : { hasFeedback: null }),
        ...(hasCoaching?.length
          ? reviewsHasCoachingParser(hasCoaching)
          : { hasCoaching: null }),
        ...(rating?.length ? reviewsRatingParser(rating) : { rating: null }),
        ...(prioritization?.length
          ? reviewsPrioritizationParser(prioritization)
          : { prioritization: null }),
        ...(markets?.length ? marketsParser(markets) : { markets: null }),
      },
    });
  },
);
