import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export enum TasksTabViews {
  ONBOARDING = 'onboarding',
  SHIFTS = 'flex-tasks',
  WORKERS = 'workers',
  ACTION_ITEMS = 'worker-tasks',
}

interface TasksStore {
  activeTab: TasksTabViews;
  updateActiveTab: (view: TasksTabViews) => void;
}

const defaultState = {
  activeTab: null,
};

const tasksStore = (set): TasksStore => ({
  ...defaultState,
  updateActiveTab: view => set({ activeTab: view }),
});

export const useTasksStore = create(devtools(tasksStore));
