import { mutateWsoToExistingShiftReturn } from 'helpers';
import { GQLResponseStatus, mutationReturn, shift } from 'types';
import { convertEnumToFilterOptions } from 'utils/helpers/filters';
import {
  ShiftsDateQuickPicks,
  ShiftsStatusOptions,
  useFiltersStore,
} from 'utils/stores/FiltersStore';
import { GraphResponseProps } from 'components/common/GraphResponse';

export type overbookGraphResponse =
  | mutateWsoToExistingShiftReturn
  | mutationReturn;

export enum ActionState {
  CHOICE = 'choice',
  FORM = 'form',
  SELECT = 'select',
  CONFIRM = 'confirm',
  FINISHED = 'finished',
}

export const responseHasIssues = (response: overbookGraphResponse): boolean => {
  return response?.messages?.some(message => message?.code === 'ERROR');
};

export const getGraphResponseProps = (
  response: overbookGraphResponse,
): GraphResponseProps => {
  const hasBlockingIssues = responseHasIssues(response);

  if (hasBlockingIssues)
    return {
      headingText: 'Move Failed',
      messages: response?.messages,
      dropdownText: 'Details',
      gqlResponseLevel: GQLResponseStatus.FAILED,
    };

  return {
    headingText: 'Worker Moved',
    messages: [],
    gqlResponseLevel: GQLResponseStatus.SUCCESS,
  };
};

export const useUpdateFiltersForMove = (shift: shift) => {
  const [updateFilter] = useFiltersStore(state => [state.updateFilter]);

  const handleDates = () => {
    updateFilter('shiftsDateQuickPick', ShiftsDateQuickPicks.CUSTOM);
    updateFilter('shiftsSelectedStart', new Date(shift?.start));
  };

  const handleMarkets = () => {
    const { id, name } = shift?.retailerLocation?.market || {};
    const marketUpdate = {
      label: name,
      value: id?.toString(),
    };
    updateFilter('shiftsMarkets', [marketUpdate]);
  };

  const handleStatuses = () => {
    updateFilter(
      'shiftsStatus',
      convertEnumToFilterOptions(ShiftsStatusOptions).filter(x =>
        [
          ShiftsStatusOptions.OPEN,
          ShiftsStatusOptions.OVERBOOKED,
          ShiftsStatusOptions.FILLED_PARTIAL,
        ].includes(x?.value as ShiftsStatusOptions),
      ),
    );
  };

  const updateFilters = () => {
    handleDates();
    handleMarkets();
    handleStatuses();
  };

  return { updateFilters };
};
