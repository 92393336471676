import { FC } from 'react';
import { Button, Flex, FlexProps, Text } from '@chakra-ui/react';
import { Icon, ICON_NAMES } from 'icons';
import { SelectOption } from 'types';
import { chakraComponents, Select, SelectProps, selectStyles } from 'ui';

interface UncontrolledFilterComponentProps {
  label?: string;
  placeholder?: string;
  options?: SelectOption[];
  containerProps?: FlexProps;
  selectProps?: SelectProps;
  canClear?: boolean;
  value?: SelectOption[];
  onUpdate?: (value: SelectOption[] | SelectOption) => void;
}

export const UncontrolledFilterComponent: FC<
  UncontrolledFilterComponentProps
> = ({
  label,
  placeholder,
  options,
  containerProps,
  selectProps,
  canClear,
  value,
  onUpdate,
}) => {
  const isMultiSelect = selectProps?.isMulti;
  const selectValue = isMultiSelect ? value : value[0];
  const showClear = canClear && !!value && value.length > 0;
  const handleChange = (options: SelectOption | SelectOption[]) => {
    onUpdate?.(options);
  };

  const handleClear = () => {
    onUpdate?.([]);
  };

  return (
    <Flex flexDirection="column" gap={1} {...containerProps}>
      <Flex width="100%" alignItems="center">
        <Text flexGrow={1} variant="label-sm">
          {label}
        </Text>
        {showClear &&
          (!value || (Array.isArray(value) && value.length !== 0)) && (
            <Button
              size="xs"
              variant="transparent"
              height="initial"
              onClick={handleClear}
            >
              {'Reset'}
            </Button>
          )}
      </Flex>
      <Select
        placeholder={placeholder}
        options={options}
        value={selectValue}
        onChange={handleChange}
        components={{
          Option: ({ children, ...props }) => (
            <chakraComponents.Option {...props}>
              <Text flexGrow={1} variant="subheader-md">
                {children}
              </Text>
              {props.isSelected && <Icon name={ICON_NAMES.check} />}
            </chakraComponents.Option>
          ),
          ValueContainer: ({ children, ...props }) => {
            const selectedOptions = props?.getValue() as SelectOption[];
            const count = selectedOptions.length;

            return (
              <chakraComponents.ValueContainer {...props}>
                <Flex
                  width="100%"
                  maxWidth="90%"
                  cursor="pointer"
                  alignItems="center"
                >
                  {!count && children}
                  {!!count && (
                    <Flex alignItems="center">
                      <Text
                        variant="body-md"
                        as="span"
                        width="100%"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {count === 1
                          ? selectedOptions[0]?.label
                          : `${count} Selected...`}
                      </Text>
                      {children}
                    </Flex>
                  )}
                </Flex>
              </chakraComponents.ValueContainer>
            );
          },
          MultiValue: ({ children, ...props }) => {
            return (
              <chakraComponents.MultiValue {...props}>
                {children}
              </chakraComponents.MultiValue>
            );
          },
        }}
        additionalStyleOverrides={{
          control: (provided, state) => ({
            ...selectStyles(provided, state).control,
            width: '100%',
            cursor: 'pointer',
            _hover: {
              backgroundColor: 'blackAlpha.50',
            },
          }),
          valueContainer: (provided, state) => ({
            ...selectStyles(provided, state).valueContainer,
            display: 'flex',
            pt: state.hasValue ? 1 : null,
            pl: 2,
            flexWrap: 'nowrap',
            width: '100%',
          }),
          option: (provided, state) => ({
            backgroundColor: state.isSelected
              ? 'green.50'
              : provided.backgroundColor,
            color: state?.isSelected ? 'green.500' : 'oz.primary',
            display: 'flex',
            alignItems: 'center',
            py: '7px',
            px: 2,
            cursor: 'pointer',
            _hover: {
              backgroundColor: 'blackAlpha.100',
            },
          }),
          clearIndicator: () => ({ display: 'none' }),
          multiValue: () => ({ display: 'none' }),
          singleValue: () => ({ display: 'none' }),
        }}
        hideSelectedOptions={false}
        closeMenuOnSelect={true} // FUTURE: not working because of re-renders
        {...selectProps}
      />
    </Flex>
  );
};
