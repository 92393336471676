import { FC, ReactNode } from 'react';
import { Box, Flex, Text, TextProps } from '@chakra-ui/react';

const BlockQuote = ({ children }) => {
  return (
    <Flex
      borderLeft="4px solid"
      borderColor="red.300"
      bg={'gray.50'}
      px={4}
      py={1}
      my={4}
      borderRadius="sm"
      alignItems="center"
      sx={{
        p: {
          marginTop: 2,
          color: 'stone.700',
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      {children}
    </Flex>
  );
};

interface MarkdownLinkProps {
  href?: string;
  title?: string;
  children?: ReactNode;
}

export const linkStyles: TextProps = {
  display: 'inline',
  fontSize: 'inherit',
  as: 'span',
  textDecoration: 'underline',
  sx: {
    textDecorationColor: '#c0bbbc',
    textDecorationThickness: '.12em',
    textUnderlineOffset: '2px',
  },
  _hover: {
    color: 'text.hint',
  },
};

export const MarkdownLink: FC<MarkdownLinkProps> = ({
  href,
  title,
  children,
}) => {
  return (
    <Text {...linkStyles}>
      <a href={href} title={title} target="blank" referrerPolicy="no-referrer">
        {children}
      </a>
    </Text>
  );
};

export const MarkdownConfig = (includeSpacing = true) => ({
  h1: {
    component: Text,
    props: {
      fontSize: { base: '2xl', md: '3xl' },
      fontWeight: 'semi',
      mt: includeSpacing && 2,
      mb: includeSpacing && 3,
      as: 'h2',
    },
  },
  h2: {
    component: Text,
    props: {
      fontSize: { base: 'xl', md: '2xl' },
      fontWeight: 'semi',
      mb: includeSpacing && 3,
      as: 'h3',
    },
  },
  h3: {
    component: Text,
    props: {
      fontSize: { base: 'lg', md: 'xl' },
      fontWeight: 'semi',
      mb: includeSpacing && 2,
      as: 'h4',
    },
  },
  h4: {
    component: Text,
    props: {
      fontSize: { base: 'md', md: 'lg' },
      fontWeight: 'semi',
      mb: includeSpacing && 2,
      as: 'h5',
    },
  },
  h5: {
    component: Text,
    props: {
      fontSize: { base: 'md', md: 'lg' },
      fontWeight: 'semi',
      mb: includeSpacing && 2,
      as: 'h6',
    },
  },
  h6: {
    component: Text,
    props: {
      fontSize: { base: 'sm', md: 'md' },
      fontWeight: 'semi',
      color: 'text.secondary',
      mb: includeSpacing && 2,
    },
  },
  p: {
    component: Text,
    props: {
      fontSize: { base: 'md', md: 'md' },
      lineHeight: { base: 'normal', md: 'short' },
      color: 'text.secondary',
      mb: includeSpacing && 3,
      mt: includeSpacing && 2,
    },
  },
  // pre
  ol: {
    component: Box,
    props: {
      ml: 8,
      mb: includeSpacing && 2,
      as: 'ol',
    },
  },
  ul: {
    component: Box,
    props: {
      pl: 2,
      ml: 4,
      mb: includeSpacing && 4,
      as: 'ul',
    },
  },
  li: {
    component: Text,
    props: {
      fontSize: { base: 'sm', md: 'md' },
      lineHeight: { base: 'normal', md: 'short' },
      color: 'text.secondary',
      mb: includeSpacing && 2,
      as: 'li',
    },
  },
  hr: {
    component: Box,
    props: {
      as: 'hr',
      mt: includeSpacing && 4,
      py: includeSpacing && 4,
      borderColor: 'navy.100',
    },
  },
  // img
  pre: {
    component: Box,
    props: {
      backgroundColor: 'navy.50',
      borderRadius: 'lg',
      px: 4,
      py: includeSpacing && 4,
      mb: includeSpacing && 5,
      as: 'pre',
    },
  },
  code: {
    component: Text,
    props: {
      fontSize: 'inherit',
      lineHeight: { base: 'normal', md: 'short' },
      font: 'mono',
      as: 'code',
    },
  },
  a: {
    component: MarkdownLink,
    props: {},
  },
  blockquote: {
    component: BlockQuote,
  },
});
