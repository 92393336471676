import { SortState } from 'types';

export const UsersSortOptions = {
  first_name: 'Name',
  created_at: 'Join Date',
} as const;

export type UsersSortOptionsType = keyof typeof UsersSortOptions;

export type UsersSortMap = Partial<{
  [value in UsersSortOptionsType]: SortState;
}>;

export enum AddPermissionsViews {
  LOCATIONS = 'locations',
  PERMISSIONS = 'permissions',
  BULK_PERMISSIONS = 'bulk-permissions',
}

export enum AddLocationsViews {
  LOCATIONS = 'locations',
  PERMISSIONS = 'permissions',
  COMMUNICATIONS = 'communications',
  BULK_PERMISSIONS = 'bulk-permissions',
  BULK_COMMUNICATIONS = 'bulk-communications',
}

export enum EditLocationsViews {
  PERMISSIONS = 'permissions',
  COMMUNICATIONS = 'communications',
}

export enum UsersTabViews {
  WORKERS = 'workers',
  RETAILERS = 'retailers',
}

export enum UsersListActions {
  NONE = 'none',
  ADD_SHIFT = 'add-shift',
  ADD_TAG = 'add-tag',
  REMOVE_TAG = 'remove-tag',
  ADD_RELATION = 'add-relation',
  REMOVE_RELATION = 'remove-relation',
  ADD_CAMPAIGN = 'add-campaign',
  REMOVE_CAMPAIGN = 'remove-campaign',
}
