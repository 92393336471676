import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import {
  paging,
  ReflexResponse,
  uuid,
  WorkerShiftConfirmationStatus,
  WorkerShiftEventsEnum,
  WorkerShiftStatus,
} from 'types';
import { GQL } from 'types/src/routes';

export interface fetchWorkerShiftProps extends paging {
  shift?: uuid;
  shifts?: uuid[];
  status?: WorkerShiftStatus[];
}

export const fetchWorkerShift = async (
  data: fetchWorkerShiftProps,
  query = Queries.WORKER_SHIFTS_QUERY,
  contextOverride?: string,
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(
      GQL,
      !contextOverride ? 'WORKER_SHIFTS_QUERY' : contextOverride,
    ),
    query,
    data,
    returnKey: ['workerShifts', 'workerShifts_paging'],
  });

export interface mutateWorkershiftDelete {
  wso?: Array<uuid>;
  users?: Array<uuid>;
  shift?: uuid;
  force?: boolean;
}

export const mutateWorkershiftDelete = async (data: mutateWorkershiftDelete) =>
  await mutateObject({
    route: applyContext(GQL, 'DELETE_WORKER_SHIFT_MUTATION'),
    query: Mutations.REMOVE_WORKERSHIFT_USERS_MUTATION,
    data,
    returnKey: 'deleteWorkerShift',
  });

export interface mutateUpsertWorkershiftEvent {
  workershift: uuid;
  timestamp: string;
  eventType: WorkerShiftEventsEnum;
  note: string;
  userConfirmed: boolean;
  distanceApproved: boolean;
}

export const mutateUpsertWorkershiftEvent = async (
  data: mutateUpsertWorkershiftEvent,
) =>
  await mutateObject({
    route: applyContext(GQL, 'UPSERT_WORKERSHIFT_EVENT'),
    query: Mutations.UPSERT_WORKERSHIFT_EVENT,
    data,
    returnKey: 'upsertWorkershiftEvent',
  });

export interface mutateRemoveWorkerShiftsProps {
  users?: string[];
  wsos?: string[];
  shift: string;
}

export const mutateRemoveWorkerShifts = async (
  data: mutateRemoveWorkerShiftsProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'MUTATE_REMOVE_WORKER_SHIFTS'),
    query: Mutations.REMOVE_WORKERSHIFT_USERS_MUTATION,
    data,
    returnKey: 'deleteWorkerShift',
  });

export interface mutateCreateWorkerShiftProps {
  users?: string[];
  wsos?: string[];
  shifts: string[];
}

export const mutateCreateWorkerShift = async (
  data: mutateCreateWorkerShiftProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'MUTATE_ADD_WORKER_SHIFTS'),
    query: Mutations.CREATE_WORKER_SHIFT_MUTATION,
    data,
    returnKey: 'createWorkerShift',
  });

export interface mutateUpdateWorkerShiftsProps {
  users?: string[];
  wso?: string[];
  shift?: string;
  status?: string;
  confirmationStatus?: WorkerShiftConfirmationStatus;
  cancelClassification?: string;
  cancelNote?: string;
  workerCancellationPayoutRate?: number;
}

export const mutateUpdateWorkerShifts = async (
  data: mutateUpdateWorkerShiftsProps,
) => {
  return await mutateObject({
    route: applyContext(GQL, 'UPDATE_WORKER_SHIFT_MUTATION'),
    query: Mutations.UPDATE_WORKER_SHIFT_MUTATION,
    data,
    returnKey: 'updateWorkerShift',
  });
};
