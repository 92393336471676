import { user } from './user';

export type onboardingSchedule = {
  uuid?: string;
  when?: string; // DateTime field
  worker?: user;
};

enum ScheduleDOW {
  MON = 'Mon',
  TUE = 'Tue',
  WED = 'Wed',
  THU = 'Thu',
  FRI = 'Fri',
  SAT = 'Sat',
  SUN = 'Sun',
}

export type schedulingConfiguration = {
  name?: string;
  dow?: ScheduleDOW;
  date?: string; // Date
  start?: string; // Time
  end?: string; // Time
  count?: number;
};
