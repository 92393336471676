import { user } from 'types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export enum WorkerTabViews {
  DETAILS = 'details',
  METRICS = 'metrics',
  ONBOARDING = 'onboarding',
}

interface WorkerStore {
  worker: user;
  setWorker: (user: user) => void;
}

const workerStore = (set): WorkerStore => ({
  worker: {},
  setWorker: user => set(() => ({ worker: user }), false, 'setWorker'),
});

export const useWorkerStore = create(devtools(workerStore));
