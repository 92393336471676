import { applyContext, fetchObject, Queries } from 'helpers';
import { paging, ReflexResponse, ShiftAuditLogEvent } from 'types';
import { GQL } from 'types/src/routes';

export interface fetchUserAuditLogsProps extends paging {
  worker?: string; // UUID
  user?: string; // UUID
  since?: string; // Date YYYY-MM-DD
  to?: string; // Date YYYY-MM-DD
}

export const fetchUserAuditLogs = async (
  data: fetchUserAuditLogsProps,
  query = Queries.USER_AUDIT_LOGS_QUERY,
  contextOverride?: string,
  returnKey: string | string[] = ['userAuditLogs', 'userAuditLogs_paging'],
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(
      GQL,
      contextOverride ? contextOverride : 'USER_AUDIT_LOGS_QUERY',
    ),
    query,
    data,
    returnKey,
  });
