export const USER_FLAG_CONFIGS_QUERY = `
query USER_FLAG_CONFIG_QUERY{
  userFlagConfigs {
      active
      flagType
      blocking
      duration
  }
}
`;
