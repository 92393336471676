import { applyContext, fetchObject, Queries } from 'helpers';
import { GQL, paging, ReflexResponse, uuid } from 'types';

export interface fetchCommunicationBatchProps extends paging {
  uuid?: uuid;
  createdSince?: Date;
  createdTo?: Date;
  shifts?: string[]; // shift uuids
  sentBy?: string[]; // user uuids
  sentBySystem?: boolean;
}

export const fetchCommunicationBatch = async (
  data: fetchCommunicationBatchProps,
  query = Queries.SHIFT_COMMUNICATION_BATCH_QUERY,
  contextOverride?: string,
  returnKey: string | string[] = [
    'communicationBatch',
    'communicationBatch_paging',
  ],
): Promise<ReflexResponse> => {
  return await fetchObject({
    route: applyContext(
      GQL,
      contextOverride || 'SHIFT_COMMUNICATION_BATCH_QUERY',
    ),
    query,
    data,
    returnKey,
  });
};

export interface fetchCommunicationProps extends paging {
  batch?: string[];
}

export const fetchCommunication = async (
  data: fetchCommunicationProps,
  query = Queries.COMMUNICATION_QUERY,
  contextOverride?: string,
  returnKey: string | string[] = ['communication', 'communication_paging'],
): Promise<ReflexResponse> => {
  return await fetchObject({
    route: applyContext(GQL, contextOverride || 'COMMUNICATION_QUERY'),
    query,
    data,
    returnKey,
  });
};
