import {
  CommonStore,
  commonStore,
  defaultCommonStoreState,
  sortGenerator,
} from 'helpers';
import {
  market,
  onboardingSchedule,
  Prettify,
  SortState,
  UserFitFor,
  UserStatus,
  WorkerTierOptions,
} from 'types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { OnboardingFormButtonState } from './onboardingsStore.helpers';

export const OnboardingsSortOptions = {
  when: 'When',
  worker__next_shift__start: 'Shift Date',
  worker__first_name: 'Worker Name',
  worker__onboarded_by__first_name: 'Owner',
} as const;

export enum FormViews {
  FORM = 'FORM',
  MARKETS = 'MARKETS',
}

export type OnboardingsStoreBase = {
  activeOnboardingSchedule: onboardingSchedule;
  setActiveOnboardingSchedule: (os: onboardingSchedule) => void;
  form: {
    status?: UserStatus; // ACTIVE | REJECTED
    tier?: WorkerTierOptions;
    fitFor?: UserFitFor[];
    markets?: market[];
    note?: string;
  };
  updateForm: <K extends keyof OnboardingsStoreBase['form']>(
    key: K,
    update: OnboardingsStoreBase['form'][K],
  ) => void;
  resetForm: () => void;
  formView: FormViews;
  setFormView: (view: FormViews) => void;
  hasLoadedFormMarkets: boolean;
  setHasLoadedFormMarkets: (value: boolean) => void;
  getButtonState: () => OnboardingFormButtonState;
};

export type OnboardingsStore = Prettify<
  CommonStore<typeof OnboardingsSortOptions> & OnboardingsStoreBase
>;

const defaultForm = {
  status: UserStatus.ACTIVE,
  fitFor: [],
  markets: [],
  note: '',
};

const defaultState = {
  activeOnboardingSchedule: null,
  form: defaultForm,
  formView: FormViews.FORM,
  sort: {
    ...sortGenerator(SortState.NONE, OnboardingsSortOptions),
    when: SortState.ASC,
  },
  hasLoadedFormMarkets: false,
};

const OnboardingsStore = (set, get): OnboardingsStore => ({
  ...defaultCommonStoreState,
  ...commonStore(set),
  ...defaultState,
  setActiveOnboardingSchedule: os => set({ activeOnboardingSchedule: os }),
  updateForm: (key, update) => {
    set(prev => ({
      ...prev,
      form: { ...prev?.form, [key]: update },
    }));
  },
  resetForm: () => set({ form: defaultForm }),
  getButtonState: () => {
    const { form } = get();
    const { status, markets, tier } = form;
    if (!status || !markets?.length || !tier)
      return OnboardingFormButtonState.DISABLED;
    return OnboardingFormButtonState.NORMAL;
  },
  setFormView: view => set({ formView: view }),
  setHasLoadedFormMarkets: value => set({ hasLoadedFormMarkets: value }),
});

export const useOnboardingsStore = create(devtools(OnboardingsStore));
