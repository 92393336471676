export const OZ_NOTIFICATION_RECEIPT_MUTATION = `
  mutation ozNotificationReceipt(
      $uuids: [String]
      $status: String
  ) {
    ozNotificationReceipt(
      uuids: $uuids
      status: $status
    ) {
      ok
      messages {
        code 
      }
    }
  }
`;

export const RESTORE_OZ_NOTIFICATION_MUTATION = `
  mutation restoreOzNotification {
    restoreOzNotifications {
      ok
      messages {
        code
      }
    }
  }
`;

export const DISMISS_ALL_OZ_NOTIFICATIONS_MUTATION = `
  mutation dismissAllOzNotifications {
    dismissAllOzNotifications {
      ok
      messages {
        code
      }
    }
  }
`;
