import { FC, ReactNode } from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import { ADMIN_VIEW_LINK } from 'types/src/routes';

interface AdminLinkProps {
  uuid?: number | string;
  textProps?: TextProps;
  children?: ReactNode;
}

export const AdminLink: FC<AdminLinkProps> = ({
  uuid,
  textProps,
  children,
}) => {
  return (
    <Text
      href={`${ADMIN_VIEW_LINK}/${uuid}`}
      target="_blank"
      referrerPolicy="no-referrer"
      {...textProps}
      as="a"
    >
      {children}
    </Text>
  );
};
