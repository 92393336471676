import { SortState } from 'types';

export const LocationUsersSortOptions = {
  first_name: 'Name',
  created_at: 'Join Date',
} as const;
export const defaultLocationUsersSort = {
  first_name: SortState.ASC,
  created_at: SortState.NONE,
};
export type LocationUsersSortOptionsType =
  keyof typeof LocationUsersSortOptions;
export type LocationUsersSortMap = Partial<{
  [value in LocationUsersSortOptionsType]: SortState;
}>;

export enum LocationUserModalViews {
  DEFAULT = 'default',
  NEW = 'new',
  EXISTING = 'existing',
  PERMISSIONS = 'permissions',
  PREFERENCES = 'preferences',
}
