import { SelectOption } from 'types';

export interface GlobalFiltersState {
  globalMarkets: SelectOption[];
}

export interface GlobalFiltersSlice extends GlobalFiltersState {
  resetGlobalFilters: () => void;
  resetGlobalMarkets: () => void;
}

export const defaultGlobalFiltersState: GlobalFiltersState = {
  globalMarkets: [],
};

export const globalFiltersSlice = set => ({
  ...defaultGlobalFiltersState,
  resetGlobalFilters: () => {
    set(prev => ({ ...prev, ...defaultGlobalFiltersState }));
  },
  resetGlobalMarkets: () => {
    set(prev => ({ ...prev, globalMarkets: [] }));
  },
});

export interface globalFilterProps {
  markets: number[];
}

export const getParsedGlobalFilters = (
  filters: Partial<GlobalFiltersState>,
) => {
  const { globalMarkets } = filters || {};

  const graphFilters = {} as globalFilterProps;

  if (globalMarkets?.length) {
    graphFilters.markets = globalMarkets.map(market => parseInt(market.value));
  }

  return graphFilters;
};
