import { FC } from 'react';
import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import { clearSocketToken } from 'helpers';
import { USER_TOKEN } from 'types/src/config';

interface BottomNavigationProps {
  containerProps?: BoxProps;
}

export const BottomNavigation: FC<BottomNavigationProps> = ({
  containerProps,
}) => {
  const handleLogOut = () => {
    clearSocketToken();
    // ELEGANT HACK: This strips out the subdomain to clear the token with the correct domain.
    // A token that was created with a domain must be destroyed with that same domain.
    const domain = /^(oz\.)*(.+)$/gm.exec(document.domain)[2];
    document.cookie = `${USER_TOKEN}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${domain}`;
    document.location.reload();
  };

  return (
    <Box as="footer" w="full" {...containerProps}>
      <Box maxW="6xl" mx="auto" py={{ base: 1, md: 6 }} px={{ base: 2, md: 2 }}>
        <Flex width="100%" justifyContent="center">
          <Text color="text.disabled" mr={8}>
            {'Oz'}
          </Text>
          <Text color="text.disabled" mr={8}>
            {`© Reflex ${new Date().getFullYear()}`}
          </Text>
          <Text onClick={handleLogOut} color="text.disabled" cursor="pointer">
            {'Logout'}
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};
