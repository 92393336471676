import { user, UserRole } from 'types';
import { isNullDate } from '../dates';
import { limitToMaxDecimalPlaces } from '../data';

export const getUserRole = (user: Partial<user>): Partial<UserRole> => {
  if (user?.isStaff) return UserRole.STAFF;
  if (user?.isRetail) return UserRole.RETAILER;
  if (user?.isWorker) return UserRole.WORKER;
  return null;
};

export const isOnboardingComplete = (user: Partial<user>): boolean => {
  return !!user?.onboardingDate && !isNullDate(new Date(user.onboardingDate));
};

export const workerFavoriteRateAsPercent = (user: user): number => {
  const { favoritedBy, approvedBy, blockedBy } = user || {};

  const result = favoritedBy?.length
    ? (favoritedBy.length /
        (favoritedBy.length + approvedBy.length + blockedBy.length)) *
      100
    : 0;
  return limitToMaxDecimalPlaces(result, 2);
};

export const doesUserNeedOnboarding = (user: user): boolean => {
  return !user?.onboardingDate;
};
