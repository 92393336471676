import { create } from 'zustand';
import { devtools, persist, subscribeWithSelector } from 'zustand/middleware';
import {
  ActionItemsFiltersSlice,
  actionItemsFiltersSlice,
  ActionItemsFiltersState,
  campaignsFilterSlice,
  CampaignsFiltersSlice,
  CampaignsFiltersState,
  defaultActionItemsFiltersState,
  defaultCampaignsFiltersState,
  defaultGlobalFiltersState,
  defaultLocationsFiltersState,
  defaultOnboardingsFiltersState,
  defaultShiftsFiltersState,
  defaultUsersFiltersState,
  GlobalFiltersSlice,
  globalFiltersSlice,
  GlobalFiltersState,
  LocationsFiltersSlice,
  locationsFiltersSlice,
  LocationsFiltersState,
  OnboardingsFilterSlice,
  onboardingsFiltersSlice,
  OnboardingsFiltersState,
  ShiftsFiltersSlice,
  shiftsFiltersSlice,
  ShiftsFiltersState,
  UsersFiltersSlice,
  usersFiltersSlice,
  UsersFiltersState,
} from './slices';

export type SliceFilterKeys = ActionItemsFiltersState &
  CampaignsFiltersState &
  GlobalFiltersState &
  LocationsFiltersState &
  OnboardingsFiltersState &
  ShiftsFiltersState &
  UsersFiltersState;

type FiltersStoreBase = {
  updateFilter: <K extends keyof SliceFilterKeys>(
    filter: K,
    value: SliceFilterKeys[K],
  ) => void;
};

export type FiltersStore = FiltersStoreBase &
  ActionItemsFiltersSlice &
  CampaignsFiltersSlice &
  GlobalFiltersSlice &
  LocationsFiltersSlice &
  OnboardingsFilterSlice &
  ShiftsFiltersSlice &
  UsersFiltersSlice;

export const defaultFiltersValues = {
  ...defaultActionItemsFiltersState,
  ...defaultCampaignsFiltersState,
  ...defaultGlobalFiltersState,
  ...defaultShiftsFiltersState,
  ...defaultLocationsFiltersState,
  ...defaultOnboardingsFiltersState,
  ...defaultUsersFiltersState,
};

const FiltersStore = (set): FiltersStore => ({
  ...actionItemsFiltersSlice(set),
  ...campaignsFilterSlice(set),
  ...globalFiltersSlice(set),
  ...locationsFiltersSlice(set),
  ...onboardingsFiltersSlice(set),
  ...shiftsFiltersSlice(set),
  ...usersFiltersSlice(set),
  updateFilter: (filter, value) => {
    set({ [filter]: value });
  },
});

export const useFiltersStore = create(
  persist(subscribeWithSelector(devtools(FiltersStore)), {
    name: 'oz-filters',
  }),
);
