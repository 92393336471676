import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { NumberFormatValues } from 'react-number-format';
import { Flex, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ConnectedInput, FormButton } from 'ui';
import { useShiftActions } from 'utils/hooks/useShiftActions';
import { useOverbookStore } from 'utils/stores';
import { MAX_OVERBOOK_SLOTS } from 'utils/types/configs';
import * as yup from 'yup';

interface OverbookSettingsFormData {
  overbookLimit?: string;
}

const OverbookSettingsFormDefaults = {
  overbookLimit: '0',
};

const OverbookFormValidationSchema = yup.object().shape({
  overbookLimit: yup.string().nullable().required('Limit Required'),
});

interface OverbookSettingsProps {
  onSuccess?: () => void;
}

export const OverbookSettings: FC<OverbookSettingsProps> = ({
  onSuccess = () => null,
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [formState, setFormState] = useState({
    isProcessing: false,
    isSuccess: false,
  });
  const [shift] = useOverbookStore(s => [s.shift]);
  const { updateShift } = useShiftActions();

  const formMethods = useForm<OverbookSettingsFormData>({
    resolver: yupResolver(OverbookFormValidationSchema),
  });
  const { handleSubmit, reset } = formMethods || {};

  const handleAllowedNumbers = (values: NumberFormatValues): boolean => {
    const { floatValue } = values;
    if (floatValue > MAX_OVERBOOK_SLOTS) return false;
    return true;
  };

  const onSubmit = async (values: OverbookSettingsFormData) => {
    const { overbookLimit } = values;
    setFormState(prev => ({ ...prev, isProcessing: true }));
    const response = await updateShift.mutateAsync({
      uuid: shift?.uuid,
      overbookLimit: parseInt(overbookLimit, 10),
    });

    setFormState(prev => ({ ...prev, isProcessing: false }));
    if (response.hasErrors) {
      console.error(response?.errors);
    } else {
      setFormState(prev => ({ ...prev, isSuccess: true }));
      setTimeout(() => {
        onSuccess();
        setFormState(prev => ({ ...prev, isSuccess: false }));
      }, 600);
    }
  };

  useEffect(() => {
    if (hasLoaded || !reset || !shift || Object.keys(shift)?.length === 0)
      return;
    setHasLoaded(true);
    if (shift?.overbookLimit > 0) {
      reset({
        overbookLimit: shift?.overbookLimit.toString(),
      });
    } else {
      reset({
        overbookLimit: OverbookSettingsFormDefaults.overbookLimit,
      });
    }
  }, [shift, reset, hasLoaded]);

  return (
    <Flex flexDirection="column">
      <Text variant="header-xl" mt={2} mb={4}>
        {'Overbook Settings'}
      </Text>
      <FormProvider {...formMethods}>
        <Flex
          as="form"
          flexDirection="column"
          onSubmit={handleSubmit(onSubmit)}
        >
          <ConnectedInput
            inputProps={{ size: 'lg' }}
            containerProps={{ width: '100%' }}
            id="overbookLimit"
            placeholder="Enter Number"
            labelProps={{ text: 'Overbook Limit' }}
            numberFormatProps={{
              thousandSeparator: ',',
              allowLeadingZeros: false,
              allowNegative: false,
              isNumericString: true,
              isAllowed: handleAllowedNumbers,
            }}
          />
          <FormButton
            type="submit"
            alignSelf="flex-start"
            width="100%"
            isLoading={formState.isProcessing}
            isSuccess={formState.isSuccess}
            text="Save"
            mt={4}
            mb={4}
            size="lg"
          />
        </Flex>
      </FormProvider>
    </Flex>
  );
};
