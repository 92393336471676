import {
  convertArrayToObjects,
  fetchUsers,
  fetchWorkerShift,
  getEnumKeyByValue,
  Queries,
  sortGenerator,
} from 'helpers';
import { shift, user } from 'types';
import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import {
  getFilteredSelectedUsers,
  MessageSelectionMap,
  MessageSelectKeys,
  messageSelectOptionsGenerator,
  selectUserDefinitions,
  ShiftSortMap,
  ShiftSortOptions,
  SortState,
  updateUsersParams,
  usersDict,
  UserSelectionMap,
  UserSelectKeys,
  userSelectOptionsGenerator,
} from './ShiftStore.helpers';

interface ShiftStore {
  // ######################
  // Component States
  meta: {
    numberWsos: number;
    isSelectModalOpen: boolean;
    isSortModalOpen: boolean;
    isFilterModalOpen: boolean;
    isFetching: boolean;
    mainQueryStatus?: string;
  };
  updateMeta?: <K extends keyof ShiftStore['meta']>(
    key: K,
    value: ShiftStore['meta'][K],
  ) => void;

  // ######################
  // Core
  shift: shift;
  setShift: (shift: shift) => void;
  assignedAndOverbooked: user[];

  // ######################
  // User Selection
  allSelectedUsers: user[]; // without filters
  allSelectedUsersDict: usersDict;
  filteredSelectedUsers: user[]; // with filters
  filteredSelectedUsersDict: usersDict;
  setAllSelectedUsers: (users: user[]) => void;
  updateAllSelectedUsers: (params: updateUsersParams) => void;
  updateAllSelectedUsersFromServer: (
    key: string,
    action: 'add' | 'remove',
  ) => Promise<void>;
  selectAll: () => void;
  selectNone: () => void;

  // Select User Cohort Tiles
  userSelectOptions: UserSelectionMap;
  getActiveUserSelectOptions: () => UserSelectionMap;
  toggleUserSelectOption: (key: UserSelectKeys) => void;
  toggleAllUserSelectOptions: (value: boolean) => void;

  // Select Message Count Tiles
  messageSelectOptions: MessageSelectionMap;
  toggleMessageSelectOption: (key: MessageSelectKeys) => void;
  getActiveMessageSelectOptions: () => MessageSelectionMap;
  resetMessageSelectOption: () => void;
  messageCounts: Record<string, number>;
  updateMessageCountsFromServer: () => Promise<void>;

  // ######################
  // Search + Sort
  search: string;
  updateSearch: (value: string) => void;
  sort: ShiftSortMap;
  updateSort: (key: string, value: SortState) => void;

  // ######################
  // [All] WSO Data Results
  allWorkers: user[];
  setAllWorkers: (users: user[]) => void;

  // ######################
  // Messages
  expandedMessageBatches: string[];
  updateExpandedMessageBatches: (
    uuid: string,
    action: 'add' | 'remove',
  ) => void;
}

const shiftStore = (set, get): ShiftStore => ({
  // ######################
  // Component States
  meta: {
    numberWsos: 0,
    isSelectModalOpen: false,
    isSortModalOpen: false,
    isFilterModalOpen: false,
    isFetching: false,
    mainQueryStatus: null,
  },
  updateMeta: (key, value) =>
    set(
      prev => ({
        ...prev,
        meta: { ...prev?.meta, [key]: value },
      }),
      false,
      'updateMeta',
    ),

  // ######################
  // Core
  shift: {},
  setShift: shift => set(() => ({ shift }), false, 'setShift'),
  assignedAndOverbooked: [],

  // ######################
  // User Selection
  allSelectedUsers: [],
  allSelectedUsersDict: {},
  filteredSelectedUsers: [],
  filteredSelectedUsersDict: {},
  setAllSelectedUsers: users =>
    set({ allSelectedUsers: users }, false, 'setAllSelectedUsers'),
  updateAllSelectedUsers: ({ users, action }) => {
    const { setAllSelectedUsers, allSelectedUsers: prevSelectedUsers } = get();

    switch (action) {
      case 'add': {
        const prevSelectedUsersUuids = prevSelectedUsers.map(u => u?.uuid);
        const newUserUuids = users?.map(u => u?.uuid);
        const updateUuids = [
          ...new Set([...prevSelectedUsersUuids, ...newUserUuids]),
        ];
        setAllSelectedUsers(updateUuids.map(uuid => ({ uuid })));
        break;
      }
      case 'remove': {
        const removeUsersUuid = users.map(u => u.uuid);
        const newUsers = prevSelectedUsers.filter(
          u => !removeUsersUuid.includes(u?.uuid),
        );
        setAllSelectedUsers(newUsers);
        break;
      }
      default:
        break;
    }
  },
  updateAllSelectedUsersFromServer: async (optionKey, action) => {
    const { meta, shift, allWorkers, updateAllSelectedUsers } = get();
    const updateIsFetching = (value: boolean) => {
      set({ meta: { ...meta, isFetching: value } });
    };

    const getFetchProps = selectUserDefinitions[UserSelectKeys[optionKey]];

    updateIsFetching(true);

    if (!allWorkers || allWorkers.length === 0) {
      // eslint-disable-next-line no-console
      console.log('looks like there is no "all" data at the moment');
      // NOTE
      // Sometimes when the you are going to select workers
      // the allWorkers[] store is empty
      // in this case, we could re-fetch all workershifts
      // { first_name, last_name, profile_img, uuid }
    }

    const response = await fetchUsers(
      {
        ...getFetchProps(shift),
        uuids: allWorkers.map(u => u?.uuid),
      },
      Queries.USER_SELECT_QUERY,
      `USERS_SELECT-${optionKey}`,
    );

    if (!response.hasErrors) {
      const { users } = response?.data || {};
      updateAllSelectedUsers({ users, action });
    }

    updateIsFetching(false);
  },
  selectAll: () => {
    const { allWorkers, setAllSelectedUsers } = get();
    setAllSelectedUsers(allWorkers);
  },
  selectNone: () => {
    const { setAllSelectedUsers } = get();
    setAllSelectedUsers([]);
  },
  userSelectOptions: userSelectOptionsGenerator(false),
  getActiveUserSelectOptions: () => {
    return Object.entries(get().userSelectOptions).reduce(
      (acc, [key, value]) => {
        if (!get().userSelectOptions[key]) return acc;
        acc[key] = value;
        return acc;
      },
      {},
    );
  },
  toggleUserSelectOption: key => {
    const {
      userSelectOptions: prevSelectedOptions,
      updateAllSelectedUsersFromServer,
    } = get();
    set({
      userSelectOptions: {
        ...prevSelectedOptions,
        [key]: !prevSelectedOptions[key],
      },
    });
    const action = prevSelectedOptions[key] ? 'remove' : 'add';
    updateAllSelectedUsersFromServer(key, action);
  },
  toggleAllUserSelectOptions: value =>
    set(
      prev => ({
        ...prev,
        userSelectOptions: userSelectOptionsGenerator(value),
      }),
      false,
      'toggleAllUserSelectOptions',
    ),
  messageSelectOptions: {
    ...messageSelectOptionsGenerator(false),
    [getEnumKeyByValue(MessageSelectKeys, MessageSelectKeys.ANY)]: true,
  },
  toggleMessageSelectOption: key =>
    set(
      {
        messageSelectOptions: {
          ...messageSelectOptionsGenerator(false),
          [key]: true,
        },
      },
      false,
      'toggleMessageSelectOption',
    ),
  getActiveMessageSelectOptions: () => {
    return Object.entries(get().messageSelectOptions).reduce(
      (acc, [key, value]) => {
        if (!get().messageSelectOptions[key]) return acc;
        acc[key] = value;
        return acc;
      },
      {},
    );
  },
  resetMessageSelectOption: () => {
    const key = getEnumKeyByValue(
      MessageSelectKeys,
      MessageSelectKeys.ANY,
    ) as MessageSelectKeys;
    get().toggleMessageSelectOption(key);
  },
  messageCounts: {},
  updateMessageCountsFromServer: async () => {
    const { shift } = get();
    const response = await fetchWorkerShift(
      { shifts: [shift?.uuid] },
      Queries.WORKER_SHIFTS_MESSAGE_COUNTS_QUERY,
      'WORKER_SHIFTS_MESSAGE_COUNTS_QUERY',
    );

    if (!response.hasErrors) {
      const { workerShifts } = response?.data || [];
      const messageCounts = workerShifts.reduce((acc, ws) => {
        acc[ws?.worker?.uuid] = ws?.messageCount;
        return acc;
      }, {});
      set({ messageCounts }, false, 'updateMessageCountsFromServer');
    }
  },

  // ######################
  // Search + Sort
  search: '',
  updateSearch: value =>
    set(prev => ({ ...prev, search: value }), false, 'updateSearch'),
  sort: {
    ...sortGenerator(SortState.NONE, ShiftSortOptions),
    custom_status: SortState.ASC,
    worker__first_name: SortState.ASC,
  },
  updateSort: (key, value) =>
    set(
      prev => ({
        ...prev,
        sort: { ...prev?.sort, [key]: value },
      }),
      false,
      'updateSort',
    ),

  // ######################
  // [All] WSO Data Results
  allWorkers: [],
  setAllWorkers: users =>
    set(
      () => ({
        allWorkers: users,
      }),
      false,
      'setAllWorkers',
    ),

  // ######################
  // Messages
  expandedMessageBatches: [],
  updateExpandedMessageBatches: (uuid, action) =>
    set(prev => ({
      expandedMessageBatches:
        action === 'add'
          ? [...prev.expandedMessageBatches, uuid]
          : prev.expandedMessageBatches.filter(id => id !== uuid),
    })),
});

export const useShiftStore = create(
  subscribeWithSelector(devtools(shiftStore)),
);

useShiftStore.subscribe(
  store => store.allSelectedUsers,
  newSelectedUsers => {
    const { messageSelectOptions, messageCounts, allWorkers } =
      useShiftStore.getState();
    useShiftStore.setState({
      allSelectedUsersDict: convertArrayToObjects(
        newSelectedUsers,
        'uuid',
      ) as usersDict,
      filteredSelectedUsers: getFilteredSelectedUsers(
        newSelectedUsers,
        messageSelectOptions,
        messageCounts,
        allWorkers,
      ),
    });
  },
);

useShiftStore.subscribe(
  store => store.filteredSelectedUsers,
  newFilteredSelectedUsers => {
    useShiftStore.setState({
      filteredSelectedUsersDict: convertArrayToObjects(
        newFilteredSelectedUsers,
        'uuid',
      ) as usersDict,
    });
  },
);

useShiftStore.subscribe(
  store => store.messageSelectOptions,
  newMessageSelectOptions => {
    const { allSelectedUsers, messageCounts, allWorkers } =
      useShiftStore.getState();
    useShiftStore.setState({
      filteredSelectedUsers: getFilteredSelectedUsers(
        allSelectedUsers,
        newMessageSelectOptions,
        messageCounts,
        allWorkers,
      ),
    });
  },
);

useShiftStore.subscribe(
  store => store.shift,
  newShift => {
    useShiftStore.setState({
      assignedAndOverbooked: newShift
        ? [...newShift.assignedWorkers, ...newShift.overbookedWorkers]
        : [],
    });
  },
);
