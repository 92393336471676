export const ASSIGN_ACTION_ITEM_MUTATION = `
  mutation ASSIGN_ACTION_ITEM_MUTATION(
    $actionItems: [String]!
    $assignedTo: String!
  ) {
    assignActionItem(
    actionItems: $actionItems
    assignedTo: $assignedTo
  ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const CLEAR_ACTION_ITEM_ASSIGNMENT_MUTATION = `
  mutation CLEAR_ACTION_ITEM_ASSIGNMENT_MUTATION(
    $actionItems: [String]!
  ) {
    clearActionItemAssignment(
      actionItems: $actionItems
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;

export const COMPLETE_ACTION_ITEM_MUTATION = `
  mutation COMPLETE_ACTION_ITEM_MUTATION(
    $actionItems: [String]!
  ) {
    completeActionItem(
      actionItems: $actionItems
    ) {
      ok
      messages {
        code
        description
      }
    }
  }
`;
