import { ChangeEvent, FC } from 'react';
import {
  Checkbox,
  CheckboxProps,
  Flex,
  FlexProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { booleanSelectOptions } from 'helpers';
import { SelectOption } from 'types';
import { SliceFilterKeys, useFiltersStore } from 'utils/stores';

interface CheckboxFilterProps {
  label: string;
  filterKey: keyof SliceFilterKeys;
  containerProps?: FlexProps;
  checkboxProps?: CheckboxProps;
  labelProps?: TextProps;
}

export const CheckboxFilter: FC<CheckboxFilterProps> = ({
  label,
  filterKey,
  containerProps,
  checkboxProps,
  labelProps,
}) => {
  const { [filterKey]: _value, updateFilter } = useFiltersStore();
  const value = _value as SelectOption[];
  const isChecked = value?.length > 0 && value?.[0]?.value === 'true';

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e?.target?.checked;
    updateFilter(
      filterKey,
      booleanSelectOptions?.filter(
        option => option.value === checked.toString(),
      ),
    );
  };

  return (
    <Flex {...containerProps}>
      <Checkbox
        colorScheme="green"
        id="flexible"
        size="sm"
        mr={3}
        ml={1}
        defaultChecked={isChecked}
        isChecked={isChecked}
        onChange={handleChange}
        {...checkboxProps}
      >
        <Text
          fontSize="sm"
          maxWidth="240px"
          color="oz.secondary"
          as="span"
          {...labelProps}
        >
          {label}
        </Text>
      </Checkbox>
    </Flex>
  );
};
