import { FC } from 'react';
import { Flex, VStack } from '@chakra-ui/react';
import { RouterOptions, useViewHelper } from 'hooks';
import { QueryParamDrawer } from 'types';
import { Logo } from 'ui';
import { Drawer } from 'components/common/Drawer';
import { navOptions } from './nav.data';
import { NavItem } from './NavItem';

export const NavDrawer: FC = () => {
  const { drawerOpen, handleCloseDrawer } = useViewHelper();
  const closeDrawer = () => handleCloseDrawer(RouterOptions.REPLACE);

  return (
    <Drawer isOpen={drawerOpen === QueryParamDrawer.MENU} onClose={closeDrawer}>
      <Flex flexDirection="column" pt={2} pb={6} px={2}>
        <Flex justify="space-between" align="center">
          <Logo
            h={6}
            color="navy.500"
            height={{ base: '30px', md: '36px' }}
            width={{ base: '89px', md: '106px' }}
          />
        </Flex>
        <VStack mt={8} spacing={1} alignItems="flex-start">
          {navOptions.map(option => (
            <NavItem
              key={option.label}
              href={option.href}
              label={option.label}
              context="drawer"
            />
          ))}
        </VStack>
      </Flex>
    </Drawer>
  );
};
