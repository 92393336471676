import { FC } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import Link from 'next/link';
import { OPS_HOME } from 'types/src/routes';

interface PageErrorProps {
  title?: string;
  description?: string;
}

export const PageError: FC<PageErrorProps> = ({
  title = 'oops!',
  description = 'something went wrong',
}) => {
  return (
    <Flex
      alignSelf="center"
      height="full"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Text fontSize="6xl" fontWeight="semi" lineHeight="none">
        {title}
      </Text>
      <Text fontSize="xl" fontWeight="semi" lineHeight="none" mt={2} mb={6}>
        {description}
      </Text>
      <Link href={OPS_HOME} passHref>
        <Button size="lg">{'Back To Home'}</Button>
      </Link>
    </Flex>
  );
};
