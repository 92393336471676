import { Flex } from '@chakra-ui/react';
import { useNotificationSocketEvents } from 'utils/hooks/useSocket';
import { useNotificationsStore } from 'utils/stores';
import { NotificationAlert } from './NotificationAlert';

export const NotificationAlerts = () => {
  useNotificationSocketEvents();
  const [alerts] = useNotificationsStore(s => [s.alerts]);
  const alertsArray = Object.values(alerts);
  const numberVisible = alertsArray?.length;

  return (
    <Flex position="fixed" bottom={3} right={2} zIndex={1000}>
      <Flex
        position="relative"
        flexDirection="column-reverse"
        alignItems="center"
      >
        {alertsArray?.map((notification, index) => (
          <NotificationAlert
            notification={notification}
            key={notification.uuid}
            containerProps={{
              mt:
                numberVisible > 0 && (index < 1 || index === numberVisible - 1)
                  ? '-72px'
                  : -20,
              shadow:
                numberVisible > 0 &&
                (index === 0 || index === numberVisible - 1)
                  ? 'md'
                  : 'none',
            }}
          />
        ))}
      </Flex>
    </Flex>
  );
};
