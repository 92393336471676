import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const MailCalendar: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.573 13.706 21 7.808v4.08h2v-6c0-1.653-1.348-3-3-3H4c-1.652 0-3 1.347-3 3v12c0 1.651 1.348 3 3 3h7v-2H4c-.548 0-1-.453-1-1V7.807l8.427 5.898a1 1 0 0 0 1.146 0ZM12 11.666 3.107 5.442c.165-.327.505-.554.893-.554h16c.388 0 .728.227.893.554L12 11.667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.75 13.884h6.5c.966 0 1.75.784 1.75 1.75v5.5a1.75 1.75 0 0 1-1.75 1.75h-6.5a1.75 1.75 0 0 1-1.75-1.75v-5.5c0-.966.784-1.75 1.75-1.75Zm0 1.5a.25.25 0 0 0-.25.25v5.5c0 .138.112.25.25.25h6.5a.25.25 0 0 0 .25-.25v-5.5a.25.25 0 0 0-.25-.25h-6.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.947 12.887a.75.75 0 0 1 .75.75v1.747a.75.75 0 0 1-1.5 0v-1.747a.75.75 0 0 1 .75-.75ZM19.963 12.887a.75.75 0 0 1 .75.75v1.747a.75.75 0 0 1-1.5 0v-1.747a.75.75 0 0 1 .75-.75ZM22 18h-8v-1h8v1Z"
      fill="currentColor"
    />
  </Icon>
);

MailCalendar.displayName = 'MailCalendarIcon';
