import { FC } from 'react';
import { Button } from '@chakra-ui/react';
import { useCopyToClipboard } from 'hooks';
import { Icon } from 'icons';
import {
  APP_WORKER_SHIFT,
  APP_WORKER_SHIFTS_EXPLORE,
  WEB_APP_ROOT,
} from 'types/src/routes';

const ATTRIBUTION_CODE = '?a_id={attribution_id}';

export const ShortLinks = {
  shift: `${WEB_APP_ROOT}${APP_WORKER_SHIFT}`,
  shiftWithAttribution: `${WEB_APP_ROOT}${APP_WORKER_SHIFT}${ATTRIBUTION_CODE}`,
  explore: `${WEB_APP_ROOT}${APP_WORKER_SHIFTS_EXPLORE}`,
};

interface CopyShortLinkProps {
  id?: number;
  link?: string;
  text?: string;
}

export const useCopyShortLink = ({ id, link }: CopyShortLinkProps) => {
  const [value, copy] = useCopyToClipboard();

  const copyShortLink = () => {
    copy(link.replace(/\[id\]/i, id?.toString()));
  };

  return { value, copyShortLink };
};

export const CopyShortLinkButton: FC<CopyShortLinkProps> = ({
  id,
  link = ShortLinks.shift,
  text = 'Copy Short Link',
}) => {
  const { value, copyShortLink } = useCopyShortLink({ id, link });

  return (
    <Button
      size="sm"
      colorScheme="gray"
      color="text.disabled"
      variant="ghost"
      fontWeight="semi"
      rightIcon={<Icon name={value ? 'check' : 'clipboardcopy'} />}
      onClick={copyShortLink}
    >
      {text}
    </Button>
  );
};
