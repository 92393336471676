import { user } from './user';

export enum noteOptionType {
  ONBOARDING = 'ONBOARDING',
  FEEDBACK = 'FEEDBACK',
  CONTACT = 'CONTACT',
  OTHER = 'OTHER',
}

export type noteType = {
  uuid: string;
  type: noteOptionType;
  content: string;
  targetUuid: string;
  createdAt: string; // DateTime
  updatedAt: string; // DateTime
  createdBy: user;
};
