import { FC, ReactElement } from 'react';
import {
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  FlexProps,
  Tag,
  TagProps,
  Text,
} from '@chakra-ui/react';
import { Icon } from 'icons';
import Link from 'next/link';
import {
  FilterOptionsObject,
  FilterOptionsObjectDates,
} from 'utils/types/filters';
import { AdminLink } from 'components/common/AdminLink';
import { CopyShortLinkButton } from 'components/common/CopyShortLinkButton';
import { PageHeaderFilters } from './PageHeaderFilters';

interface PageHeaderProps {
  actions?: ReactElement;
  actionsContainerProps?: FlexProps;
  actionsWrapperProps?: FlexProps;
  buttonProps?: ButtonProps;
  containerProps?: BoxProps;
  description?: ReactElement | 'string';
  // FUTURE: enum this and update refs. Consolidate with PageHeaderFilters interface
  filtersContext?:
    | 'shifts'
    | 'workers'
    | 'locations'
    | 'reviews'
    | 'tags'
    | 'campaigns';
  filters?: FilterOptionsObject | FilterOptionsObjectDates;
  filtersToShow?: Array<string>;
  href?: string;
  meta?: { uuid?: string; id?: number };
  onFiltersClick?: () => void;
  onFilterReset?: (filter: string) => void;
  onFiltersAllReset?: () => void;
  onNavAction?: () => void;
  onSearchUpdate?: (value: string) => void;
  search?: string;
  title?: string;
  titleContainerProps?: BoxProps;
  titleTags?: ReactElement;
  titleMeta?: ReactElement;
  topActions?: ReactElement;
}

const buttonDefault = () => ({
  color: 'gray.300',
  fontWeight: 'semi',
  leftIcon: <Icon name="chevronleft" />,
  py: 0,
  size: 'sm',
  variant: 'transparent',
});

export const PageHeader: FC<PageHeaderProps> = ({
  actions,
  actionsContainerProps,
  actionsWrapperProps,
  buttonProps,
  containerProps,
  description,
  filtersContext,
  filters,
  filtersToShow,
  href,
  meta,
  onFiltersClick,
  onFilterReset,
  onFiltersAllReset,
  onNavAction,
  onSearchUpdate,
  search,
  title,
  titleContainerProps,
  titleTags,
  titleMeta,
  topActions,
}) => {
  return (
    <Flex flexDirection="column" width="100%" {...containerProps}>
      {meta && (
        <>
          <Flex alignItems="center" flexWrap="wrap" mb={{ base: 2, md: 0 }}>
            {onNavAction && (
              <Button
                {...buttonDefault()}
                {...buttonProps}
                onClick={onNavAction}
              >
                {'back'}
              </Button>
            )}
            {href && (
              <Link href={href} passHref>
                <Button {...buttonDefault()} {...buttonProps}>
                  {'back'}
                </Button>
              </Link>
            )}
            {meta.uuid && (
              <AdminLink uuid={meta.uuid}>
                <Button
                  size="sm"
                  variant="transparent"
                  rightIcon={<Icon name="ExternalLink" />}
                >
                  {'View in Admin'}
                </Button>
              </AdminLink>
            )}
            {meta.id && <CopyShortLinkButton id={meta.id} />}
            {topActions && topActions}
          </Flex>
        </>
      )}
      <Flex
        width="100%"
        flexDirection={{
          base: 'column',
          sm: 'row',
        }}
        alignItems={{
          base: 'flex-start',
          sm: 'center',
        }}
        flexWrap="wrap"
      >
        {title && (
          <Flex
            flexGrow={1}
            flexShrink={0}
            alignItems="center"
            {...titleContainerProps}
          >
            <Text
              display="flex"
              fontSize={{ base: '2xl', md: '3xl' }}
              fontWeight="semi"
              lineHeight="short"
            >
              {title}
              {titleTags ? titleTags : <></>}
            </Text>
            {titleMeta ? titleMeta : <></>}
          </Flex>
        )}
        <Flex {...actionsWrapperProps}>
          {actions && (
            <Flex
              alignItems="center"
              justifyContent={{ base: 'start', md: 'end' }}
              pr={2}
              {...actionsContainerProps}
            >
              {actions}
            </Flex>
          )}
          {filters && (
            <PageHeaderFilters
              filtersContext={filtersContext}
              filters={filters}
              filtersToShow={filtersToShow}
              onFiltersClick={onFiltersClick}
              onFilterReset={onFilterReset}
              onFiltersAllReset={onFiltersAllReset}
              search={search}
              onSearchUpdate={onSearchUpdate}
            />
          )}
        </Flex>
      </Flex>
      {description && <PageHeaderDescription description={description} />}
    </Flex>
  );
};

export interface TitleTagProps extends TagProps {
  tagText: string;
}

export const TitleTag: FC<TitleTagProps> = ({ tagText, ...tagProps }) => {
  return (
    <Tag alignSelf="center" ml={2} mt={2} mb={1} {...tagProps}>
      {tagText}
    </Tag>
  );
};

interface PageHeaderDescriptionProps {
  description: ReactElement | 'string';
}

const PageHeaderDescription: FC<PageHeaderDescriptionProps> = ({
  description,
}) => {
  return (
    <>
      {typeof description === 'string' ? (
        <Text color="text.hint" mt={2} mb={4}>
          {description}
        </Text>
      ) : (
        <>{description}</>
      )}
    </>
  );
};
