import React, { FC } from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const ChipInactive: FC<IconProps> = props => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.793 3.76a1 1 0 0 1 1.414 0l17 17a1 1 0 0 1-1.414 1.414l-17-17a1 1 0 0 1 0-1.414Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.991 8.877v.01h-.569c-.68 0-.876.052-1.123.3a.984.984 0 0 0 0 1.401c.247.247.442.3 1.128.3H4v4h-.573c-.686 0-.88.051-1.128.298a.984.984 0 0 0 0 1.402c.247.247.442.3 1.123.3h.57l.02.77c.013.506.04.837.08.97.324 1.096 1.08 1.852 2.168 2.17.13.037.472.065.97.078l.77.02v.57c0 .68.052.875.3 1.122a.984.984 0 0 0 1.401 0c.247-.247.3-.442.3-1.128v-.573h4v.573c0 .686.051.881.298 1.128a.984.984 0 0 0 1.402 0c.247-.247.3-.442.3-1.128v-.573l-2-2h-2c-4.96 0-5.37.016-5.59-.182-.036-.033-.067-.072-.113-.118-.045-.045-.084-.076-.117-.112-.198-.22-.181-.633-.172-5.67l.004-1.906-2.022-2.022Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 12.886v.001c0 2.966-.009 3.342.17 3.568.036.044.078.082.13.133.05.051.088.093.132.128.226.18.603.171 3.568.171h.001l-2-2v-.001l-2-2ZM16 12.653 12.237 8.89c2.75-.003 3.111-.007 3.33.168.045.035.083.077.134.128.051.051.093.09.128.133.175.22.171.583.171 3.334Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 14.653v-1.766c0-4.96.016-5.37-.182-5.588-.033-.036-.071-.067-.117-.113a1.057 1.057 0 0 0-.36-.233c-.178-.06-.655-.066-5.358-.064l-1.745.001L8 4.653v-.344c0-.68.052-.876.3-1.124.092-.092.246-.195.343-.229a.998.998 0 0 1 1.058.23c.247.247.3.442.3 1.128v.573h4v-.573c0-.686.051-.88.298-1.128a.984.984 0 0 1 1.402 0c.247.247.3.442.3 1.128v.573h.61c.684.001 1.005.041 1.4.178.743.258 1.461.941 1.752 1.667.18.448.205.6.226 1.385l.02.77h.57c.68 0 .875.052 1.122.3a.984.984 0 0 1 0 1.401c-.247.247-.442.3-1.128.3H20v4h.573c.686 0 .881.051 1.128.298a.984.984 0 0 1 0 1.402c-.247.247-.442.3-1.123.3h-.344L18 14.652Z"
      fill="currentColor"
    />
  </Icon>
);

ChipInactive.displayName = 'ChipInactiveIcon';
