const fontFamilyMain = '"CircularStd"';
const fontFamilySecondary = '"QuincyCF"';
// eslint-disable-next-line max-len
const fontFamilyFallback =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
const fontFamilyDefault = `${fontFamilyMain}, ${fontFamilyFallback}`;
const fontFamilyDecorative = `${fontFamilySecondary}, ${fontFamilyFallback}`;
const fontFamilyMono =
  'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace';

export const typography = {
  fonts: {
    decorative: fontFamilyDecorative,
    body: fontFamilyDefault,
    mono: fontFamilyMono,
  },

  fontSizes: {
    '3xs': '0.5rem', // 8px
    '2xs': '0.625rem', // 10px
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    '2xl': '1.5rem', // 24px
    '3xl': '1.875rem', // 30px
    '4xl': '2.25rem', // 36px
    '5xl': '2.5rem', // 40px
    '6xl': '3rem', // 48px
    '7xl': '3.5rem', // 56px
    '8xl': '4rem', // 64px
    '9xl': '5rem', // 80px
  },

  fontWeights: {
    normal: 400,
    default: 400,
    medium: 500,
    semi: 600,
    bold: 700,
  },

  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },

  lineHeights: {
    normal: 'normal',
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: '2',
    '3': '.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
  },
};

export const lineHeightsByFontSize = {
  '2xs': '0.875rem', // 14px
  xs: '1rem', // 16px
  sm: '1.125rem', // 18px
  md: '1.25rem', // 20px
  lg: '1.375rem', // 22px
  xl: '1.5rem', // 24px
  '2xl': '1.75rem', // 28px
  '3xl': '2.25rem', // 36px
  '4xl': '2.625rem', // 42px
};
