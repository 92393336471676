import { _SHIFTS_PARAMS, _SHIFTS_VARIABLES } from './shift.helpers';

const _SHIFTS_WORKER_FIELDS_EXTERNAL = `
  firstName
  lastName
  preferredName
  uuid
  profileImg
  hasWorkedAtLocation
  favoritedBy {
    uuid
  }
  approvedBy {
    uuid
  }
  blockedBy {
    uuid
  }
  workerShift {
    workershiftEvents {
      eventType
      timestamp
      approved
    }
    uuid
  }
  hasWorkedAtLocation
`;

export const RETAILER_SHIFTS_LIST_QUERY = `
  query SHIFT_QUERY (${_SHIFTS_PARAMS}) {
    shifts (${_SHIFTS_VARIABLES}) {
      id
      uuid
      createdAt
      start
      end
      duration
      isRetailerApproved
      requestType
      workerRoles {
        name
        icon
      }
      status
      retailerLocation {
        name
        timezone
        uuid
        retailer {
          name
        }
        compliance {
          breakRules {
            shiftMinutesToRequireBreak
            breakLength
            paid
          }
          retailerPreFlexCancelDisclaimers {
            title
            body
          }
        }
        terms {
          billingRate
          cancellationFee {
            amounts {
              timing
              amount
              unit
            }
          }
        }
      }
      assignedWorkers{
        ${_SHIFTS_WORKER_FIELDS_EXTERNAL}
      }
      pendingReviews {
        id
        uuid
      }
    }
  }
`;

export const RETAILER_REPORTS_SHIFTS_QUERY = `
  query RETAILER_REPORTS_SHIFTS_QUERY (
    $since: Date
    $to: Date
    $locations: [String]
    $isRetailerApproved: Boolean
  ) {
    shifts (
      since: $since
      to: $to
      locations: $locations
      isRetailerApproved: $isRetailerApproved
    ) {
      id
      uuid
      status
      start
      end
      duration
      billingDiscount
      assignedWorkers{
        uuid
        workerShift {
          uuid
          workershiftEvents {
            timestamp
            eventType
          }
        }
      }
      retailerLocation{
        uuid
        timezone
        retailer {
          uuid
        }
      }
    }
  }
`;

export const RETAILER_REQUEST_SHIFTS_SUCCESS_QUERY = `
  query SHIFTS_QUERY (${_SHIFTS_PARAMS}) {
    shifts (${_SHIFTS_VARIABLES}) {
      id
      uuid
      start
      end
      duration
      workerRoles{
        name
        icon
      }
      retailerLocation {
        name
        timezone
        terms {
          billingRate
          cancellationFee {
            amounts {
              timing
              amount
              unit
            }
          }
        }
        compliance {
          retailerPreFlexCancelDisclaimers {
            title
            body
          }
        }
      }
    }
  }
`;

export const RETAILER_REQUEST_SHIFTS_CALENDAR_QUERY = `
  query SHIFTS_QUERY (${_SHIFTS_PARAMS}) {
    shifts (${_SHIFTS_VARIABLES}) {
      id
      uuid
      start
      end
      duration
      isRetailerApproved
      workerRoles{
        name
        icon
      }
      retailerLocation {
        timezone
      }
      assignedWorkers {
        uuid
        profileImg
      }
    }
  }
`;
