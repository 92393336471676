import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { formatUserNameForDisplay } from 'helpers';
import { user } from 'types';
import { WorkerImage } from 'components/common/WorkerImage';

export const WorkerMeta: FC<user> = user => {
  return (
    <Flex mt={0} mb={2} alignItems="center">
      <WorkerImage image={user?.profileImg} size="sm" />
      <Text ml={2} color="text.secondary">
        {formatUserNameForDisplay(user)}
      </Text>
    </Flex>
  );
};
