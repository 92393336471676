import { fetchCampaignsProps } from 'helpers';
import { SelectOption } from 'types';

export interface CampaignsFiltersState {
  campaignsMarkets: SelectOption[];
}

export interface CampaignsFiltersSlice extends CampaignsFiltersState {
  resetCampaignsFilters: () => void;
}

export const defaultCampaignsFiltersState: CampaignsFiltersState = {
  campaignsMarkets: [],
};

export const campaignsFilterSlice = set => ({
  ...defaultCampaignsFiltersState,
  resetCampaignsFilters: () => {
    set(prev => ({ ...prev, ...defaultCampaignsFiltersState }));
  },
});

export const getParsedCampaignsFilters = (
  filters: Partial<CampaignsFiltersState>,
) => {
  const { campaignsMarkets } = filters;

  const graphFilters = {} as fetchCampaignsProps;

  if (campaignsMarkets?.length) {
    graphFilters.market = campaignsMarkets[0]?.value;
  }

  return graphFilters;
};
