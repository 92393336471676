import { uuid } from './common';

export enum LocationCommunicationPreference {
  EMAIL_DAILY_SUMMARY = 'EMAIL_DAILY_SUMMARY',
  EMAIL_FLEX_APPROVAL_REQUEST = 'EMAIL_FLEX_APPROVAL_REQUEST',
  EMAIL_FLEX_APPROVAL_NOTICE = 'EMAIL_FLEX_APPROVAL_NOTICE',
}

export const CommunicationPreferenceGroups = {
  summary: [LocationCommunicationPreference.EMAIL_DAILY_SUMMARY],
  budgets: [
    LocationCommunicationPreference.EMAIL_FLEX_APPROVAL_REQUEST,
    LocationCommunicationPreference.EMAIL_FLEX_APPROVAL_NOTICE,
  ],
};

export const LocationCommunicationPreferenceDescriptions = {
  [LocationCommunicationPreference.EMAIL_DAILY_SUMMARY]:
    'Daily summary of Flexes, Reviews, and Time Approvals',
  [LocationCommunicationPreference.EMAIL_FLEX_APPROVAL_REQUEST]:
    'Email when a Flex needs approval',
  [LocationCommunicationPreference.EMAIL_FLEX_APPROVAL_NOTICE]:
    'Email when a Flex is approved',
} as const;

export const LocationCommunicationPreferenceLabels = {
  [LocationCommunicationPreference.EMAIL_DAILY_SUMMARY]: 'Daily Summary',
  [LocationCommunicationPreference.EMAIL_FLEX_APPROVAL_REQUEST]:
    'Flex Approval Request',
  [LocationCommunicationPreference.EMAIL_FLEX_APPROVAL_NOTICE]:
    'Flex Approval Notification',
} as const;

export type communicationPreferenceMap = Record<
  uuid,
  LocationCommunicationPreference[]
>;
export type userCommunicationPreferenceMap = Record<
  LocationCommunicationPreference,
  boolean
>;

export const LocationCommunicationPreferenceIcons = {
  [LocationCommunicationPreference.EMAIL_DAILY_SUMMARY]: 'mailcalendar',
  [LocationCommunicationPreference.EMAIL_FLEX_APPROVAL_REQUEST]: 'mailplus',
  [LocationCommunicationPreference.EMAIL_FLEX_APPROVAL_NOTICE]: 'mailcheck',
} as const;

export enum PreferenceOptionState {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}
