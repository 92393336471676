import { FC, useEffect, useRef, useState } from 'react';
import {
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { CalendarValues } from '@uselessdev/datepicker';
import { format, isValid } from 'date-fns';
import { DatePicker, DatePickerProps } from './DatePicker';
import { PulseDot } from './PulseDot';

interface DatePickerRangeButtonProps {
  onChange: (dates: CalendarValues) => void;
  containerProps?: BoxProps;
  datePickerProps?: Partial<DatePickerProps>;
  buttonProps?: ButtonProps;
  defaultValue?: CalendarValues;
  dateFormat?: string;
  dateMatch?: (value: string) => boolean;
  singleDateSelection?: boolean;
  reset?: boolean;
  placeholder?: string;
  labelOverride?: string;
  pulseDotIsActive?: boolean;
  onClear?: () => void;
  hasClose?: boolean;
  topContent?: React.ReactElement;
  bottomContent?: React.ReactElement;
  hideDatePicker?: boolean;
  contentContainerProps?: BoxProps;
  popoverProps?: PopoverProps;
  shouldClose?: boolean;
}

export const DatePickerRangeButton: FC<DatePickerRangeButtonProps> = ({
  onChange,
  reset = false,
  defaultValue,
  containerProps,
  datePickerProps,
  buttonProps,
  dateFormat = 'MM/dd/yyyy',
  placeholder = 'Select Dates',
  labelOverride,
  pulseDotIsActive = false,
  onClear,
  hasClose = true,
  topContent,
  bottomContent,
  hideDatePicker = false,
  contentContainerProps,
  popoverProps,
  shouldClose = false,
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [dates, setDates] = useState<CalendarValues>({
    start: null,
    end: null,
  });
  const [startValue, setStartValue] = useState('');
  const [endValue, setEndValue] = useState('');
  const [isResetting, setIsResetting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const calendarRef = useRef(null);

  const handleUpdateValues = (update: CalendarValues, force = false) => {
    if (isResetting && !force) return;
    setDates(update);
    const { start, end } = update;
    setStartValue(() => (isValid(start) ? format(start, dateFormat) : ''));
    setEndValue(() => (isValid(end) ? format(end, dateFormat) : ''));
    setIsResetting(false);
  };

  useOutsideClick({
    ref: calendarRef,
    handler: onClose,
    enabled: isOpen,
  });

  const resetState = (force = false) => {
    handleUpdateValues({ start: null, end: null }, force);
  };

  const handleClear = () => {
    if (!onClear) return;
    onClear();
    resetState();
    onClose();
  };

  const buttonLabel = labelOverride
    ? labelOverride
    : !!startValue || !!endValue
      ? `${startValue}${endValue ? ` - ${endValue}` : ''}`
      : placeholder;

  useEffect(() => {
    if (!dates || !hasLoaded) return;
    onChange(dates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, hasLoaded]);

  // handle load default
  useEffect(() => {
    if (!defaultValue || hasLoaded) return;
    setHasLoaded(true);
    handleUpdateValues(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  // handleReset
  useEffect(() => {
    if (!reset) return;
    setIsResetting(true);
    resetState(true);
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const [willClose, setWillClose] = useState(false);
  useEffect(() => {
    if (!shouldClose || willClose) return;
    setWillClose(true);
  }, [shouldClose]);

  useEffect(() => {
    if (!willClose) return;
    setWillClose(false);
    onClose();
  }, [willClose]);

  return (
    <Popover
      placement="auto"
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      isLazy
      {...popoverProps}
    >
      <PopoverTrigger>
        <Flex
          cursor="pointer"
          onClick={onOpen}
          ref={initialRef}
          position="relative"
          {...containerProps}
        >
          <Button {...buttonProps}>{buttonLabel}</Button>
          {pulseDotIsActive && (
            <PulseDot
              size={2}
              containerProps={{ top: 0, left: 0, position: 'absolute' }}
            />
          )}
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        p={0}
        w="min-content"
        border="none"
        outline="none"
        shadow="none"
        _focus={{ boxShadow: 'none' }}
        ref={calendarRef}
      >
        <PopoverBody
          p={0}
          borderColor="main.medium"
          borderWidth="1px"
          shadow="sm"
          borderRadius="md"
          {...contentContainerProps}
        >
          {topContent}
          {!hideDatePicker && (
            <>
              <DatePicker
                value={{ start: dates?.start, end: dates?.end }}
                onSelectDate={handleUpdateValues}
                singleDateSelection={false}
                allowSelectSameDay={true}
                {...datePickerProps}
              />
              {(hasClose || !!onClear) && (
                <Flex flexDirection="column" width="100%" px={4} mb={3}>
                  {hasClose && (
                    <Button
                      onClick={onClose}
                      width="100%"
                      variant="transparent"
                      size="sm"
                    >
                      {'Close'}
                    </Button>
                  )}
                  {!!onClear && (!!startValue || !!endValue) && (
                    <Button
                      onClick={handleClear}
                      width="100%"
                      variant="transparent"
                      size="sm"
                    >
                      {'Clear'}
                    </Button>
                  )}
                </Flex>
              )}
            </>
          )}
          {bottomContent}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
