import { FC, Fragment, ReactElement } from 'react';
import { BoxProps, Flex, FlexProps, Text } from '@chakra-ui/react';

interface RealSimpleTableProps {
  items: Array<Array<ReactElement | string | number>>;
  containerProps?: BoxProps;
  rowProps?: BoxProps;
  cellProps?: BoxProps;
  testId?: string;
  labelProps?: FlexProps;
}

export const RealSimpleTable: FC<RealSimpleTableProps> = ({
  items,
  containerProps,
  rowProps,
  cellProps,
  testId,
  labelProps,
}) => (
  <Flex
    flexDirection="column"
    className="container"
    width="100%"
    data-testid={testId}
    {...containerProps}
  >
    {items
      .filter(i => !!i)
      .map((item, itemIndex) => (
        <Flex
          className="row"
          alignItems={{ base: 'flex-start', sm: 'center' }}
          width="100%"
          key={itemIndex}
          borderStyle="solid"
          borderBottomWidth={itemIndex < items.filter(i => !!i).length - 1 && 1}
          borderColor="main.medium"
          py={1.5}
          mb={1.5}
          flexDirection={{ base: 'column', sm: 'row' }}
          {...rowProps}
        >
          {typeof item === 'object' &&
            item.map((inner, index) => (
              <Flex
                key={index}
                className="column"
                minWidth="200px"
                width={index !== 0 && '100%'}
                flexGrow={index !== 0 && 1}
                fontWeight={index === 0 && 'medium'}
                {...cellProps}
                {...(index === 0 && labelProps)}
              >
                {typeof inner === 'string' && <Text>{inner}</Text>}
                {typeof inner !== 'string' && (
                  <Fragment key={index}>{inner}</Fragment>
                )}
              </Flex>
            ))}
        </Flex>
      ))}
  </Flex>
);
