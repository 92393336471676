import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import { paging, ReflexResponse } from 'types';
import { GQL } from 'types/src/routes';

export interface fetchOnboardingScheduleProps extends paging {
  since?: Date;
  to?: Date;
  showFilled?: boolean;
  showUnfilled?: boolean;
  owners?: string[]; // uuid
  markets?: number[]; // id
  shiftSince?: Date;
  shiftTo?: Date;
  hasShift?: boolean;
  isComplete?: boolean;
}

export const fetchOnboardingSchedule = async (
  data: fetchOnboardingScheduleProps,
  query = Queries.ONBOARDING_SCHEDULE_QUERY,
  contextOverride?: string,
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(GQL, contextOverride || 'ONBOARDING_SCHEDULE_QUERY'),
    query,
    data,
    returnKey: ['onboardingSchedule', 'onboardingSchedule_paging'],
  });

export interface mutateUpdateOnboardingScheduleProps {
  uuid: string;
  when: Date;
  workerUuid: string;
}

export const mutateUpdateOnboardingSchedule = async (
  data: mutateUpdateOnboardingScheduleProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'UPDATE_ONBOARDING_SCHEDULE_MUTATION'),
    query: Mutations.UPDATE_ONBOARDING_SCHEDULE_MUTATION,
    data,
    returnKey: 'updateOnboardingSchedule',
  });

export interface mutateCancelOnboardingCallProps {
  workerUuid: string; // uuid
}

export const mutateCancelOnboardingCall = async (
  data: mutateCancelOnboardingCallProps,
) =>
  await mutateObject({
    route: applyContext(GQL, 'CANCEL_ONBOARDING_CALL_MUTATION'),
    query: Mutations.CANCEL_ONBOARDING_CALL_MUTATION,
    data,
    returnKey: 'cancelOnboardingCall',
  });
