import { useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { setSocketToken } from 'helpers';
import { useAuthToken } from 'hooks';
import { API_USERS_ME } from 'types';
import { BetterButton } from 'ui';
import { getDefaultServerHeaders } from 'utils/helpers/auth';

export const NotificationMissingToken = () => {
  const [isProcessing, setIsProcessing] = useState(false);

  const { token } = useAuthToken();

  const handleFetchToken = async () => {
    setIsProcessing(true);
    const userResponse = await fetchUser(token);
    const { socket_token } = await userResponse.json();
    setSocketToken(socket_token);
    setIsProcessing(false);
    handleReloadPage();
  };

  const handleReloadPage = () => {
    window.location.reload();
  };

  return (
    <Flex
      my={12}
      width="100%"
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
    >
      <Flex flexDirection="column" alignItems="center" gap={1}>
        <Flex flexDirection="column" alignItems="center">
          <Text variant="subheader-lg" mb={2}>
            {'Notifications Error'}
          </Text>
          <BetterButton
            onClick={handleFetchToken}
            variant="light"
            alignSelf="center"
            size="sm"
            label="Reconnect"
            isLoading={isProcessing}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const fetchUser = async (token: string) => {
  return await fetch(API_USERS_ME, {
    method: 'GET',
    headers: getDefaultServerHeaders(token),
  });
};
