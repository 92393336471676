import { FC, useCallback } from 'react';
import { Button, Flex, FlexProps, Image, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import {
  fetchOzNotifications,
  fetchOzNotificationsProps,
  mutateDismissAllOzNotifications,
  mutateDismissAllOzNotificationsProps,
  mutateRestoreOzNotificationProps,
  mutateRestoreOzNotifications,
  Queries,
} from 'helpers';
import { useDataMutation } from 'hooks';
import { DataKeys, ozNotification, PageSizes } from 'types';
import {
  commonPagingListProps,
  commonPagingProps,
  OnPagedListDataUpdate,
  PagedList,
} from 'ui';
import { useNotificationsStore } from 'utils/stores';
import { NotificationItem } from './NotificationItem';

interface NotificationListProps {
  containerProps?: FlexProps;
  canFetch?: boolean;
}

export const NotificationList: FC<NotificationListProps> = ({
  containerProps,
  canFetch = false,
}) => {
  const [pagination, resetPagination, updateCurrentPage, total, setTotal] =
    useNotificationsStore(s => [
      s.pagination,
      s.resetPagination,
      s.updateCurrentPage,
      s.total,
      s.setTotal,
    ]);

  const handleDataUpdate: OnPagedListDataUpdate = useCallback(
    ({ paging }) => {
      setTotal(paging.total_count || 0);
    },
    [setTotal],
  );

  const { mutate: dismissAllNotifications } =
    useDataMutation<mutateDismissAllOzNotificationsProps>(
      mutateDismissAllOzNotifications,
      [DataKeys.OZ_NOTIFICATIONS],
    );

  const handleDismissAllNotifications = async () => {
    await dismissAllNotifications.mutateAsync({});
    resetPagination();
  };

  return (
    <Flex
      flexDirection="column"
      flexGrow={1}
      borderRadius="md"
      overflow="hidden"
      pb={2}
      userSelect="none"
      {...containerProps}
    >
      <PagedList<ozNotification, fetchOzNotificationsProps>
        canFetch={canFetch}
        fetchFunction={fetchOzNotifications}
        query={Queries.OZ_NOTIFICATIONS_QUERY}
        dataKey={DataKeys.OZ_NOTIFICATIONS}
        queryVariables={{
          orderBy: '-created_at',
        }}
        listColumns={() => [
          {
            id: 'notification',
            Cell: ({ row }) => {
              return <NotificationItem {...row?.original} />;
            },
          },
        ]}
        pagingListProps={{
          ...commonPagingListProps({
            numRows: PageSizes.five,
          }),
          emptyComponent: <NotificationsListEmpty />,
        }}
        paginationProps={{
          ...commonPagingProps,
          containerProps: {
            pl: 3,
            mt: 2,
            mb: 0,
            pr: 1,
            flexGrow: 1,
          },
          pagingLabelProps: { fontSize: 'sm', flexGrow: 1, ml: 0 },
          currentPage: pagination.currentPage,
          pageSize: pagination.pageSize,
          handleNext: () => updateCurrentPage(pagination.currentPage + 1),
          handlePrevious: () => updateCurrentPage(pagination.currentPage - 1),
        }}
        containerProps={{ flexGrow: 1 }}
        onDataUpdate={handleDataUpdate}
      />
      {total > 0 && (
        <Button
          variant="transparent"
          size="sm"
          onClick={handleDismissAllNotifications}
          mx={2}
        >
          {'Dismiss All'}
        </Button>
      )}
    </Flex>
  );
};

const NotificationsListEmpty = () => {
  const { mutate: restoreNotifications } =
    useDataMutation<mutateRestoreOzNotificationProps>(
      mutateRestoreOzNotifications,
      [DataKeys.OZ_NOTIFICATIONS],
    );

  const handleRestoreNotifications = async () => {
    await restoreNotifications.mutateAsync({});
  };

  const animationSequence = {
    hidden: { x: '150%', opacity: 1 },
    visible: {
      x: ['150%', '250%', '250%', '50%', '50%', '150%'],
      rotateY: [0, 0, 180, 180, 0, 0],
      transition: {
        duration: 7,
        times: [0, 0.28, 0.35, 0.64, 0.71, 1],
        repeat: Infinity,
        repeatDelay: 0.75,
      },
    },
  };

  return (
    <Flex
      my={12}
      width="100%"
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
    >
      <Flex flexDirection="column" alignItems="center" gap={1}>
        <Flex position="relative" h={8} w="100%">
          <motion.div
            variants={animationSequence}
            initial="hidden"
            animate="visible"
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '32px',
              transformStyle: 'preserve-3d',
            }}
          >
            <Image src="/images/wizzy.svg" h={8} w={8} alt="The Wizard in Oz" />
          </motion.div>
        </Flex>
        <Flex flexDirection="column" alignItems="center">
          <Text variant="subheader-xl" mb={2}>
            {'All Caught Up!'}
          </Text>
          <Button
            onClick={handleRestoreNotifications}
            variant="light"
            alignSelf="center"
            size="sm"
          >
            {'Restore'}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
