import { FC, useEffect, useRef, useState } from 'react';
import {
  BoxProps,
  Flex,
  FlexProps,
  Skeleton,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  roundBudgetValue,
  BUDGET_CLOSE_THRESHOLD,
  pluralize,
  limitToMaxDecimalPlaces,
} from 'helpers';
import { locationBudget } from 'types';

export enum BudgetLabelPosition {
  LEFT = 'left',
  RIGHT = 'right',
  NONE = 'none',
}

export enum BudgetLabelType {
  PERCENT = 'percent',
  HOURS = 'hours',
  HOURS_GOAL = 'hours-goal',
}

const LABEL_BAR_GAP = 2;

interface barSettings {
  width: number;
  leftOffset?: number;
  hideCorners?: 'left' | 'right' | 'both' | 'none';
  showStripes?: boolean;
  color?: string;
  backgroundOpacity?: number;
}

const barDefaults = {
  leftOffset: 0,
  hideCorners: 'none',
  showStripes: false,
  color: 'green',
  backgroundOpacity: 500,
};

export interface BudgetWidgetProps extends locationBudget {
  containerProps?: BoxProps;
  isLoading?: boolean;
  includeTooltips?: boolean;
  realizedTooltip?: string;
  estimatedTooltip?: string;
  labelPosition?: BudgetLabelPosition;
  labelProps?: FlexProps;
  labelType?: BudgetLabelType;
  colorPositive?: string;
  colorClose?: string;
  colorNegative?: string;
  rightBarColorOverride?: number;
  hideStripes?: boolean;
  fullBarTooltip?: string;
}

export const BudgetWidget: FC<BudgetWidgetProps> = ({
  containerProps,
  isLoading = true,
  includeTooltips: includeToolTips = true,
  realizedTooltip,
  estimatedTooltip,
  labelPosition = BudgetLabelPosition.NONE,
  labelType = BudgetLabelType.PERCENT,
  labelProps,
  colorPositive = 'green',
  colorClose = 'yellow',
  colorNegative = 'red',
  rightBarColorOverride,
  hideStripes = true,
  fullBarTooltip,
  ...budget
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const {
    amountHours,
    blendedTotalHours,
    estimatedTotalHours,
    realizedTotalHours,
    totalFeeHours,
  } = budget || {};

  const realizedDecimal = (realizedTotalHours + totalFeeHours) / amountHours;
  const estimatedDecimal = estimatedTotalHours / amountHours;
  const estimatedPercentToGoal = roundBudgetValue(estimatedDecimal * 100);
  const blendedDecimal = (blendedTotalHours + totalFeeHours) / amountHours;
  // we round the blendedPercentToGoal with different strategy
  // because the roundBudgetValue with 0 decimals will round up
  // and in this case, we need to round "half-step"
  const blendedPercentToGoal = limitToMaxDecimalPlaces(blendedDecimal * 100, 0);
  const totalFeesRounded = roundBudgetValue(totalFeeHours, 2);
  const chartWidthCalculations = (() => {
    if (amountHours === 0) {
      const totalProgress = blendedTotalHours + totalFeeHours;
      return {
        estimated: (budget?.estimatedTotalHours / totalProgress) * 100,
        realized: (budget?.realizedTotalHours / totalProgress) * 100,
      };
    }
    if (blendedDecimal >= 1) {
      return {
        estimated: (estimatedDecimal / blendedDecimal) * 100,
        realized: (realizedDecimal / blendedDecimal) * 100,
      };
    }
    return {
      estimated: estimatedDecimal * 100,
      realized: realizedDecimal * 100,
    };
  })();

  const color = (() => {
    if (blendedDecimal > 1) return colorNegative;
    if (blendedDecimal >= BUDGET_CLOSE_THRESHOLD) return colorClose;
    return colorPositive;
  })();

  const label = (() => {
    if (labelType === BudgetLabelType.HOURS_GOAL) return `${amountHours}h`;
    if (labelType === BudgetLabelType.HOURS) return `${realizedTotalHours}h`;
    if (blendedPercentToGoal === Infinity) return '0%';
    if (isNaN(blendedPercentToGoal)) return '0%';
    return `${blendedPercentToGoal}%`;
  })();

  const borderRadius = (() => {
    if (containerProps?.height < 6) return 'sm';
    return 'md';
  })();

  const BarComponent = (settings: barSettings) => {
    const {
      width,
      leftOffset = barDefaults?.leftOffset,
      hideCorners = barDefaults?.hideCorners,
      showStripes = hideStripes ? false : barDefaults?.showStripes,
      color = barDefaults?.color,
      backgroundOpacity = barDefaults?.backgroundOpacity,
    } = settings || {};
    return (
      <Flex
        position="absolute"
        aria-label={`${width}`}
        width={`${width}%`}
        maxWidth="100%"
        height="100%"
        top={0}
        left={`${0 + leftOffset}%`}
        zIndex={3}
        backgroundColor={`${color}.${backgroundOpacity}`}
        borderRadius={borderRadius}
        {...(hideCorners === 'right' && {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderRightWidth: 0,
        })}
        {...(hideCorners === 'left' && {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeftWidth: 0,
        })}
        {...(hideCorners === 'both' && {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderRightWidth: 0,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeftWidth: 0,
        })}
        {...(showStripes && {
          backgroundImage:
            'repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(255, 255, 255, .15) 10px, rgba(255, 255, 255, .15) 20px)', // eslint-disable-line max-len
        })}
      />
    );
  };

  const RealizedLabel = (
    <Text>
      {realizedTooltip
        ? realizedTooltip
        : `${realizedTotalHours ? roundBudgetValue(realizedTotalHours, 2) : 0} hours completed${totalFeesRounded > 0 ? ` (~${totalFeesRounded} ${pluralize('hour', totalFeesRounded)} in fees)` : ''}`}
    </Text>
  );

  const RealizedWithTooltip = (settings: barSettings) => {
    return (
      <Tooltip label={RealizedLabel} gutter={0}>
        {BarComponent(settings)}
      </Tooltip>
    );
  };

  const EstimatedLabel = (
    <Text>
      {estimatedTooltip
        ? estimatedTooltip
        : `${estimatedTotalHours ? roundBudgetValue(estimatedTotalHours, 2) : 0} hours scheduled${budget?.estimatedPendingHours ? ` (${budget?.estimatedPendingHours} pending approval)` : ''}`}
    </Text>
  );

  const EstimatedWithTooltip = (settings: barSettings) => {
    return (
      <Tooltip label={EstimatedLabel} gutter={0}>
        {BarComponent(settings)}
      </Tooltip>
    );
  };

  const RealizedAndEstimatedBars = () => (
    <>
      {BarComponent({
        width: chartWidthCalculations.realized,
        color,
        hideCorners:
          chartWidthCalculations?.estimated < 0.001 ? 'none' : 'right',
      })}
      {estimatedPercentToGoal > 0.01 &&
        BarComponent({
          width: chartWidthCalculations.estimated,
          backgroundOpacity: rightBarColorOverride
            ? rightBarColorOverride
            : 300,
          color,
          hideCorners:
            chartWidthCalculations.realized < 0.001 ? 'none' : 'left',
          leftOffset: chartWidthCalculations.realized,
          showStripes: hideStripes ? false : true,
        })}
    </>
  );

  const RealizedAndEstimatedWithTooltips = () => (
    <>
      {RealizedWithTooltip({
        width: chartWidthCalculations.realized,
        color,
        hideCorners:
          chartWidthCalculations?.estimated < 0.001 ? 'none' : 'right',
      })}
      {estimatedPercentToGoal > 0.01 &&
        EstimatedWithTooltip({
          width: chartWidthCalculations.estimated,
          backgroundOpacity: rightBarColorOverride
            ? rightBarColorOverride
            : 300,
          color,
          hideCorners:
            chartWidthCalculations.realized < 0.001 ? 'none' : 'left',
          leftOffset: chartWidthCalculations.realized,
          showStripes: hideStripes ? false : true,
        })}
    </>
  );

  const getBarComponent = () => {
    if (includeToolTips) {
      return RealizedAndEstimatedWithTooltips();
    }
    return RealizedAndEstimatedBars();
  };

  useEffect(() => {
    if (isLoading) return;
    setContainerWidth(containerRef?.current?.offsetWidth);
  }, [containerRef, isLoading, budget]);

  useEffect(() => {
    if (isLoading) return;
    setLabelWidth(labelRef?.current?.offsetWidth);
  }, [labelRef, isLoading, budget]);

  if (isLoading)
    return (
      <Flex>
        <Skeleton
          width="100%"
          h={containerProps?.height}
          startColor="blackAlpha.50"
          endColor="blackAlpha.300"
        />
      </Flex>
    );

  // FUTURE: handle window resize
  // FUTURE: memoize somewhere to prevent flash on modal open
  // FUTURE?: animate bar width

  return (
    <Flex
      className="widget-container"
      flexDirection={labelPosition === 'right' ? 'row-reverse' : 'row'}
      gap={LABEL_BAR_GAP}
      {...containerProps}
    >
      {labelPosition !== BudgetLabelPosition.NONE && (
        <Flex
          ref={labelRef}
          alignSelf="center"
          fontSize="sm"
          color="blackAlpha.500"
          {...labelProps}
        >
          <Text>{label}</Text>
        </Flex>
      )}
      <Flex
        position="relative"
        borderRadius={borderRadius}
        className="bar-container"
        width={`calc(100% - ${labelWidth + LABEL_BAR_GAP * 4}px)`}
        ref={containerRef}
      >
        <Flex
          width={`${containerWidth}px`}
          maxWidth={`${containerWidth}px`}
          position="relative"
          className="bar-progress-container"
          borderStyle="solid"
          borderWidth={2}
          borderColor={`${color}.100`}
          borderRadius={borderRadius}
        >
          {getBarComponent()}
        </Flex>
        <Flex
          position="absolute"
          width="100%"
          height="100%"
          top={0}
          left={0}
          backgroundColor="navy.50"
          borderRadius={borderRadius}
          zIndex={1}
          className="bar-background"
        />
      </Flex>
    </Flex>
  );
};
