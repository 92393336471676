import { _gql, applyContext, fetchObject, Queries } from 'helpers';
import { GQL, LocationRelations, paging } from 'types';

export interface fetchLocationRelationsProps extends paging {
  users?: string[];
  userStatus?: LocationRelations[];
  userInitiated?: boolean;
  locations?: string[];
  locationStatus?: LocationRelations[];
  locationInitiated?: boolean;
}

export const fetchLocationRelations = async (
  data: fetchLocationRelationsProps,
  query = Queries.LOCATION_RELATIONS_QUERY,
  contextOverride?: string,
  returnKey: string | string[] = [
    'locationRelations',
    'locationRelations_paging',
  ],
) => {
  return await fetchObject({
    route: applyContext(GQL, contextOverride || 'LOCATION_RELATIONS_QUERY'),
    query,
    data,
    returnKey,
  });
};
