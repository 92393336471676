export const MESSAGE_MUTATION = `
  mutation MESSAGE_USERS_MUTATION(
    $title: String,
    $textBody: String,
    $pushBody: String,
    $pushData: GenericScalar,
    $media: [String],
    $users: [String],
    $shift: String,
    $excludedFilters: [String],
    $zipcodeMaxRange: Float,
  ) {
    messageUsers(
      title: $title,
      textBody: $textBody,
      pushBody: $pushBody,
      pushData: $pushData,
      media: $media,
      users: $users,
      shift: $shift,
      excludedFilters: $excludedFilters,
      zipcodeMaxRange: $zipcodeMaxRange
    ) { 
      ok
      messages {
        code
        description
      }
      sendCount
    }
  }
`;
