import { _MESSAGE_TEMPLATE_FIELDS } from './messageTemplate';
import { _PAGING_PARAMS, _PAGING_VARIABLES } from './_paging';

export const _PLAYBOOK_PARAMS = `
  $uuid: String
  $uuids: [String]
  $onlyLatest: Boolean
  ${_PAGING_PARAMS}
`;
export const _PLAYBOOK_VARIABLES = `
  uuid: $uuid
  uuids: $uuids
  onlyLatest: $onlyLatest
  ${_PAGING_VARIABLES}
`;

export const _PLAYBOOK_FIELDS = `
  uuid
  name
  globalWait
  version
`;

export const _PLAYBOOK_READ_FIELDS = `
  uuid
  name
  globalWait
  template
`;

export const _PLAYBOOK_STEP_FIELDS = `
  action
  actualCount
  lastProcessed
  messagingTemplate {
    ${_MESSAGE_TEMPLATE_FIELDS}
  }
  name
  playbookStepNumber
  processTime
  projectedCount
  status
  timingPercentage
  uuid
`;

export const _ACTIVE_PLAYBOOK_FIELDS = `
  ${_PLAYBOOK_FIELDS}
  status
  messagingEnabled
  projectedFinalMessagingCount
  projectedFinalWsoCount
  actualMessagingCount
  actualWsoCount
  currentStep { ${_PLAYBOOK_STEP_FIELDS} }
  nextStep { ${_PLAYBOOK_STEP_FIELDS} }
  steps { ${_PLAYBOOK_STEP_FIELDS} }
`;

export const _SHIFTS_LIST_PLAYBOOK = `
  activePlaybook {
    status
    actualMessagingCount
    messagingEnabled
  }
`;

export const _LOCATION_DEFAULT_PLAYBOOK = `
  defaultPlaybook {
    ${_PLAYBOOK_FIELDS}
    configuration
  }
`;

export const PLAYBOOKS_QUERY = `
  query PLAYBOOKS_QUERY (${_PLAYBOOK_PARAMS}) {
    playbooks (${_PLAYBOOK_VARIABLES}) {
      ${_PLAYBOOK_FIELDS}
      configuration
    }
  }
`;
