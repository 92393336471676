import { FC } from 'react';
import { Button, Flex, FlexProps, Text } from '@chakra-ui/react';
import { fetchTags, fetchTagsProps, Queries } from 'helpers';
import { DataKeys, tag } from 'types';
import { SelectList, SelectListProps } from 'components/common/SelectList';

type SelectTagItemProps = tag &
  SelectListProps<tag, fetchTagsProps> & {
    onSelect: (tag: tag) => void;
    selectButtonText?: string;
    containerProps?: FlexProps;
  };

export const SelectTagItem = ({
  onSelect,
  selectButtonText = 'Select',
  containerProps,
  selectedValues, // eslint-disable-line unused-imports/no-unused-vars
  ...tag
}: SelectTagItemProps) => {
  return (
    <Flex
      flexDirection="column"
      flexWrap="wrap"
      py={2}
      px={{ base: 4, md: 6 }}
      userSelect="none"
      position="relative"
      {...containerProps}
    >
      <Flex mb={1} alignItems="center" justifyContent="space-between">
        <Text fontWeight="medium" fontSize="sm" mr={2}>
          {tag?.name}
        </Text>
        <Button
          size="sm"
          variant="transparent"
          onClick={() => {
            !!onSelect && onSelect(tag);
          }}
        >
          {selectButtonText}
        </Button>
      </Flex>
    </Flex>
  );
};

export const SelectTagsList: FC<SelectListProps<tag, fetchTagsProps>> = ({
  onSelect,
  selectButtonText = 'Select',
  ...props
}) => {
  return (
    <SelectList<tag, fetchTagsProps>
      dataKey={DataKeys.TAGS}
      query={Queries.TAGS_LIST_QUERY}
      fetchFunction={fetchTags}
      returnKey={['tags', 'tags_paging']}
      pagingKey="tags_paging"
      itemComponent={
        <SelectTagItem
          onSelect={onSelect}
          selectButtonText={selectButtonText}
        />
      }
      onSelect={onSelect}
      {...props}
    />
  );
};
