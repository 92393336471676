import { FC } from 'react';
import { Box, Button, Flex, FlexProps, Text } from '@chakra-ui/react';
import { fetchRetailers, fetchRetailersProps, Queries } from 'helpers';
import { DataKeys, retailer } from 'types';
import { SelectList, SelectListProps } from 'components/common/SelectList';

type SelectBrandItemProps = retailer &
  SelectListProps<retailer, fetchRetailersProps> & {
    onSelect?: (retailer: retailer) => void;
    selectButtonText?: string;
    containerProps?: FlexProps;
  };

const SelectBrandItem = ({
  onSelect,
  selectButtonText = 'Select',
  containerProps,
  selectedValues, // eslint-disable-line unused-imports/no-unused-vars
  // using destructuring to remove unused variables included in retailer object
  ...retailer
}: SelectBrandItemProps) => (
  <Flex
    flexDirection="column"
    flexWrap="wrap"
    py={2}
    px={{ base: 4, md: 6 }}
    userSelect="none"
    position="relative"
    {...containerProps}
  >
    <Flex mb={1}>
      <Flex direction="row" align="center" cursor="pointer" flexGrow={1}>
        <Box
          display={{ base: 'none', md: 'flex' }}
          position="relative"
          overflow="hidden"
          backgroundColor="transparent"
          backgroundSize="cover"
          backgroundPosition="center center"
          backgroundImage={`url(${process.env.NEXT_PUBLIC_BUCKET_URL}${retailer?.logoImage})`}
          h={8}
          w={12}
          flexShrink={0}
        />
        <Flex direction="column" flexGrow={1} ml={{ base: 0, md: 2 }}>
          <Flex
            direction="column"
            align="baseline"
            lineHeight="shorter"
            flexWrap="wrap"
          >
            <Text fontWeight="medium" fontSize="sm" mr={2}>
              {retailer?.name}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Button
        size="sm"
        variant="transparent"
        onClick={() => {
          !!onSelect && onSelect(retailer);
        }}
      >
        {selectButtonText}
      </Button>
    </Flex>
  </Flex>
);

export const SelectBrandsList: FC<
  SelectListProps<retailer, fetchRetailersProps>
> = ({ onSelect, selectButtonText = 'Select', ...props }) => {
  return (
    <SelectList<retailer, fetchRetailersProps>
      dataKey={DataKeys.RETAILERS}
      query={Queries.BRANDS_LIST_QUERY}
      fetchFunction={fetchRetailers}
      returnKey={['retailers', 'retailers_paging']}
      pagingKey="retailers_paging"
      itemComponent={
        <SelectBrandItem
          onSelect={onSelect}
          selectButtonText={selectButtonText}
        />
      }
      onSelect={onSelect}
      {...props}
    />
  );
};
